export const clearTypeName = obj => {

    const keys = Object.keys(obj)
    return keys.reduce( (acc,key) => {
        if ( key !== "__typename") {
            acc = {...acc, [key]: obj[key]}
        }
        return acc
    }, {})
}




export const deepClearTypeName = val => {
    if (val === null 
        || ['undefined', 'string', 'number', "boolean"].includes(typeof val)
        || val instanceof Date
    ){
        return val
    } else if (Array.isArray(val)) {
        return val.map( el => deepClearTypeName(el))
    } else if ( typeof val === "object") {
        return Object.keys(val).reduce( (acc,key) => {
            if (key !== "__typename") {
                acc = {...acc, [key]: deepClearTypeName(val[key])}
            } 
            return acc
        }, {})
    }
}