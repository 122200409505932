import React, { Fragment, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { PUBLIC } from '../../apollo/query'
import { AppBar, LinearProgress, Typography, makeStyles } from '@material-ui/core'
import { dateAgoFromNow } from '../../lib/zileLucratoare'
import moment from 'moment'
import Antet from './Antet'
import OfertaModal from './OfertaModal'
import ListaPreModal from './ListaPreModal'
import AcceptariModal from './AcceptariModal'

const Styles = styled.div`
div {
    @media print {
            break-inside: auto;
    }
};
.printable{
    @media print {
        @page { 
            size:  letter;
            margin: 10mm 20mm 10mm 20mm; 
        };
        body { 
        };
        div {break-inside: auto;}

    }
}`

const useStyles = makeStyles(theme => ({
    table: {
        width: "100%",
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize:"0.8em",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5),
            verticalAlign: "top",
            height: "100%"
        },
        "&& tbody>tr:hover": { background: theme.palette.grey[400] }
    }
}))

export default function PublicModule({ match }) {
    const classes = useStyles()
    const [company, setCompany] = useState()
    const [currentItem, setCurrentItem] = useState()
    const [currentDoc, setCurrentDoc] = useState()
    let from = moment(dateAgoFromNow(365))
    // from = from.subtract(30, "days")
    from = from.format("YYYY-MM-DD")
    // const from = moment().startOf("year").format("YYYY-MM-DD")
    const to = moment().clone().format("YYYY-MM-DD")
    const { companyId } = match.params || {}
    const { data, loading, error } = useQuery(PUBLIC, {
        variables: {
            data: {
                companyId: companyId || 0,
                from: from,
                to: to
            }
        }
    })


    useEffect(() => {
        if (data && data.public) {
            console.log("settings:", data.public.settings)
            setCompany({
                ...data.public.company,
                registru: data.public.company.registru.map(el => ({ ...el, anexe: JSON.parse(el.anexe) })),
                ranguri: data.public.ranguri,
                settings: data.public.settings
                
            })
        }
    }, [data])

    
    const setCurrentHandler = (index, type) => ev =>{
        ev.preventDefault()
        let c = {id: company.id, name: company.name, loc:company.loc, str:company.str,nr:company.nr, jud:company.jud,cui:company.cui,
            phone:company.phone, fax:company.fax, email:company.email}
        c = {...c, registru: company.registru[index], ranguri: company.ranguri, settings: company.settings}
        setCurrentItem(c); setCurrentDoc(type)
    }

    return (
        <div style={{ width: "100vw" }}>
         
            {loading && <LinearProgress />}
            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}
            {company &&
                <Fragment>
                    <AppBar position="sticky" color="inherit">
                        <Antet data={data.public.company} />
                        <div align="center">
                            <Typography variant="h6" style={{ fontSize: "1em" }}>
                                OFERTE DE VÂNZĂRI TERENURI AGRICOLE EXTRAVILAN <br />
                                <small>
                                    depuse în perioada {' '}
                                    {moment(data.public.from).format("DD.MM.YYYY")}
                                    {' '}-{' '}
                                    {moment(data.public.to).format("DD.MM.YYYY")}
                                </small>
                            </Typography>
                        </div>

                    </AppBar>
                    <div style={{ height: "60vh", overflow: "auto" , padding: "10px", marginTop: "10px"}}>
                        <table className={classes.table} cellSpacing={0} cellPadding = {0}>
                            <thead>
                                <tr>
                                    <th width="10%">Nr/Data</th>
                                    <th width="25%" align = "left">Ofertant(i)</th>
                                    <th width="5%">Suprafața totală <br/>(ha)</th>
                                    <th width="5%">Cota parte (fracție și ha)</th>
                                    <th  width="15%" align = "center">Nr. C.F / Localitatea</th>
                                    <th width="5%" align = "center">Tarla/lot</th>
                                    <th width="5%" align = "center">Parcelă</th>
                                    <th width="5%" align = "center">Categorie</th>
                                    <th  width="5%" align = "center">Preț(lei)</th>
                                    <th  width="20%" align = "center">Documente</th>
                                </tr>
                            </thead>
                            <tbody>
                                {company.registru.map((e, i) =>  (
                                    <tr key={i}>
                                        <td align="center">{e.nr} / {moment(e.dt).format('DD.MM.YYYY')}</td>
                                        <td>
                                            {e.ofertanti.map((o, ox) => (
                                                <Fragment key={ox}>
                                                    {o.titular.name}
                                                    {ox < e.ofertanti.length - 1 && ", "}
                                                </Fragment>
                                            ))}
                                            
                                        </td>
                                        <td style = {{textAlign:"right", paddingRight: "20px"}}>
                                            {e.anexe.terenHa}
                                        </td>
                                        <td style = {{textAlign:"center"}}>
                                            {e.anexe.cotaParte}
                                            
                                        </td>
                                        <td  align = "center">{e.anexe.terenCf} / {e.anexe.terenCfLoc} </td>
                                      
                                        <td  align = "center">{e.anexe.terenTarlaLot} </td>
                                        <td  align = "center">{e.anexe.terenParcela} </td>
                                        <td  align = "center">{e.anexe.terenCategorie} </td>
                                        <td  align = "center">{e.anexe.terenPret} </td>
                                        <td align = "center">
                                            <a href = "#" onClick = {setCurrentHandler(i, "oferta")}>ofertă vânzare</a> &nbsp;&nbsp;
                                            <a href = "#" onClick = {setCurrentHandler(i, "listapre")}>listă preemptori </a>
                                             &nbsp;&nbsp; 
                                             { e.anexe.acceptari.length > 0 
                                                ? (
                                                    <a href = "#" onClick = {setCurrentHandler(i, "acceptari")}>com. acceptare
                                                    {e.anexe.acceptari && " ("+e.anexe.acceptari.length+")"}</a>
                                                )
                                                : <a>com. acceptare (0) </a>
                                             }
                                           
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                      
                    </div>
                    <Styles>
                        {currentDoc === "oferta"  && (
                            <OfertaModal data = {currentItem} onClose = {ev => {setCurrentItem(); setCurrentDoc();} } />
                        )}
                        {currentDoc === "listapre"  && (
                            <ListaPreModal data = {currentItem} onClose = {ev => {setCurrentItem(); setCurrentDoc();} } />
                        )}
                        {currentDoc === "acceptari"  && (
                            <AcceptariModal data = {currentItem} onClose = {ev => {setCurrentItem(); setCurrentDoc();} } />
                        )}
                    </Styles>
                   {/* <pre>{JSON.stringify(company,null,4)}</pre> */}
                </Fragment>
            }
        </div>
    )
}
