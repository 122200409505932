import { makeStyles, Grid, Button, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import RichCard from '../../../components/RichCard'
import { useReactToPrint } from 'react-to-print'
import { Fragment } from 'react'
import moment from 'moment'
import FunkyInput from '../../../components/FunkyInput'
import Field from '../Common/Field'
import PF_PJ from '../Common/PF_PJ'
import { Alert } from '@material-ui/lab'
import { FaExclamationCircle } from 'react-icons/fa'
import Antet2022 from "../Common/Antet2022"
import { endDate } from '../../../lib/zileLucratoare'
import SemnaturiPrimarSecretarConsilier from '../Common/SemnaturiPrimarSecretarConsilier'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    }
}))

const cod = "pv45"

export default function Pv45(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState(null)
    const [currentItem, setCurrentItem] = useState()

    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const anexaInputHandler = name => ev => {
        let newDosar = { ...dosar }
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    return (
        <Fragment>
            {dosar && dosar.anexe && (dosar.anexe.acceptari && dosar.anexe.acceptari.length === 0)
                ? (
                    <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
                        {/* kitchen             */}
                        <Grid item sm={7} xs={12}>
                            <Grid
                                container alignItems="flex-start"
                                style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                            >
                                <Grid item xs={12}>
                                    <RichCard title=" NR.INREGISRARE " >
                                        <Grid container justifyContent="flex-start" spacing={1}>
                                            <Grid item sm={2} xs={4}>
                                                <FunkyInput value={dosar.anexe[cod].nr} label="NR" onChange={anexaInputHandler('nr')}
                                                    type="number" step="1" />
                                            </Grid>
                                            <Grid item sm={4} xs={6}>
                                                <FunkyInput value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} label="NR.INREG"
                                                    onChange={anexaInputHandler('dt')} type="date" />
                                            </Grid></Grid>


                                    </RichCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* print                     */}
                        <Grid item sm={5} xs={12}>

                            <RichCard
                                title="PREVIZUALIZARE"
                                action={
                                    <Fragment>
                                        <Button variant="contained" color="primary" size="small" onClick={printHandler}>TIPĂRIRE</Button>
                                    </Fragment>

                                }
                            >
                                <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                    {dosar && dosar.anexe
                                        ? (
                                            <Grid
                                                container alignItems="flex-start"
                                                style={{ border: "0px solid red" }}
                                                className={classes.text}
                                                ref={printRef} >
                                                <Grid item xs={12} align="right">
                                                    ANEXA Nr. 11 (Anexa nr. 1K la normele metodologice)
                                                    <br/> <br/>
                                                </Grid>
                                                <Grid item xs={12} align="center" >
                                                    <strong>
                                                    PROCES-VERBAL<br />
                                                    de constatare a derulării etapei procedurale privind exercitarea dreptului de preempţiune
                                                    </strong>
                                                    <br/>
                                                </Grid>
                                                <Grid item xs={12} align="center">
                                                    <Antet2022 data={dosar} />
                                                </Grid>
                                                <Grid item xs={12} align="right">
                                                   
                                                    Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD.MM.YYYY")}
                                                    <br/><br/>
                                                </Grid>
                                                <Grid item xs = {12}>
                                                Încheiat astăzi <Field>{moment(dosar.anexe[cod].dt).format("DD.MM.YYYY")}</Field> în urma constatării derulării etapei procedurale privind exercitarea dreptului de preempţiune,
                                                privind oferta de vânzare nr.{' '}
                                                <Field>{dosar.anexe['anexa1b'].nr}</Field> /
                                                <Field>{moment(dosar.anexe['anexa1b'].dt).format("DD.MM.YYYY")}</Field> {' '}
                                                a terenului agricol situat în extravilanul oraşului/comunei {' '}
                                                <Field>{dosar.company.loc}</Field>,{' '}
                                                tarlaua <Field>{dosar.anexe.terenTarlaLot}</Field>,{' '}
                                                parcela <Field>{dosar.anexe.terenParcela}</Field>,{' '}
                                                categoria de folosinţă <Field>{dosar.anexe.terenCategorie}</Field>,{' '} 
                                                identificat prin nr. cadastral <Field>{dosar.anexe.terenNrCadastral}</Field>,{' '} 
                                                şi număr carte funciară <Field>{dosar.anexe.terenCf}</Field>,{' '} 
                                                în suprafaţă de <Field>{dosar.anexe.terenHa} </Field> ha, {' '}
                                                reprezentând cota-parte.<Field>{dosar.anexe.cotaParte} </Field>,{' '}
                                                judeţul <Field>{dosar.anexe.terenJud} </Field>,{' '}
                                                depusă de.
                                                <PF_PJ data = {dosar.ofertanti} acceptari={true} noPrefix/>    
                                                 în conformitate cu prevederile art. 7 alin. (8) din Legea nr. 17/2014 privind unele 
                                                 măsuri de reglementare a vânzării terenurilor agricole situate în extravilan şi de 
                                                 modificare a Legii nr. 268/2001 privind privatizarea societăţilor ce deţin în 
                                                 administrare terenuri proprietate publică şi privată a statului cu destinaţie agricolă 
                                                 şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările şi completările ulterioare,
                                                  şi ale art. 3 lit. l) şi m) şi art. 8 alin. (1) din Normele metodologice privind 
                                                  exercitarea de către Ministerul Agriculturii şi Dezvoltării Rurale a atribuţiilor ce 
                                                  îi revin pentru aplicarea titlului I din Legea nr. 17/2014 privind unele măsuri de 
                                                  reglementare a vânzării terenurilor agricole situate în extravilan şi de modificare 
                                                  a Legii nr. 268/2001 privind privatizarea societăţilor ce deţin în administrare terenuri 
                                                  proprietate publică şi privată a statului cu destinaţie agricolă şi înfiinţarea Agenţiei
                                                   Domeniilor Statului, aprobate prin Ordinul viceprim-ministrului, ministrul agriculturii 
                                                   şi dezvoltării rurale, al viceprim-ministrului, ministrul dezvoltării regionale şi 
                                                   administraţiei publice, al ministrului apărării naţionale şi al viceprim-ministrului, 
                                                   ministrul culturii, nr. 719/740/M.57/2.333/2014, cu modificările şi completările 
                                                   ulterioare, denumite în continuare norme metodologice.
                                                   <br/>
                                                Prezentul proces-verbal a fost întocmit la expirarea termenului de 45 de zile lucrătoare prevăzut de dispoziţiile art. 6 alin. (1) din Legea nr. 17/2014, cu modificările şi completările ulterioare, termen până la care niciun preemptor din categoria celor enumeraţi în cuprinsul art. 4 alin. (1) din actul normativ antemenţionat nu şi-a exercitat dreptul de preempţiune pentru vânzarea terenului agricol înscris în 
                                                cartea funciară nr. <Field>{dosar.anexe.terenCf}</Field>, {' '}
                                                , nr. cadastral.<Field>{dosar.anexe.terenNrCadastral}</Field>,  {' '}
                                                suprafaţă <Field>{dosar.anexe.terenHa}</Field>, {' '}
                                                sens în care certificăm următoarele:
                                                <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                1. înregistrarea cererii vânzătorului 
                                                nr. <Field>{dosar.anexe["anexa1a"]["nr"]}</Field>{' '}
                                                / <Field>{moment(dosar.anexe["anexa1a"]["dt"]).format("DD.MM.YYYY")}</Field>, {' '}
                                                a ofertei de vânzare nr. <Field>{dosar.anexe["anexa1b"]["nr"]}</Field>, {' '}
                                                pentru terenul agricol în suprafaţă de <Field>{dosar.anexe.terenHa}</Field>, {' '}
                                                depusă de {' '}
                                                {dosar.ofertanti.map( (e,i) => (
                                                    <Fragment key={i}>
                                                        <strong>{e.titular.name}</strong>,{' '}
                                                        {dosar.ofertanti.length === 1 && "în calitate de proprietar, "}
                                                        {dosar.ofertanti.length > 1 && "în calitate de coproprietar, "}

                                                    </Fragment>
                                                ))}, {' '}
                                                 precum şi a documentelor justificative prevăzute de art. 5 alin. (1) din normele metodologice;
                                                <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                2. încheierea Procesului-verbal de afişare a ofertei de vânzare nr. 
                                                <Field>{dosar.anexe["pv1"]["nr"]}</Field>{' '}
                                                / <Field>{moment(dosar.anexe["pv2"]["dt"]).format("DD.MM.YYYY")}</Field> {' '}
                                                şi afişarea ofertei de vânzare la sediul 
                                                primăriei/site <Field>{dosar.company.obs}</Field>{' '}
                                                <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                3. notificarea preemptorilor în termenul prevăzut la art. 6 alin. (6) din Legea nr. 17/2014, cu modificările şi completările ulterioare, precum şi afişarea notificării 
                                                nr. 
                                                {dosar.anexe.notificari.map( (e,i) => (
                                                    <Fragment key={i}>
                                                        <Field>{e.nr}</Field>/<Field>{moment(e.dt).format("DD.MM.YYYY")}</Field>,{' '}
                                                    </Fragment>
                                                ))}
                                                
                                                 a preemptorilor la sediul primăriei sau pe site-ul primăriei, cu respectarea prevederilor legale privind protecţia datelor cu caracter personal;
                                                <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                4. transmiterea la structura centrală, respectiv teritorială şi la A.D.S., în termenul prevăzut la art. 6 alin. (5) din Legea nr. 17/2014, cu modificările şi completările ulterioare, a dosarului privind cererea şi oferta de vânzare a terenului agricol, împreună cu documentele justificative.
                                                <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                În conformitate cu prevederile art. 41 alin. (3) din Legea nr. 17/2014, cu modificările şi completările ulterioare, în termen de 30 de zile 
                                                de la data {' '}
                                                <Field>{moment(endDate(dosar.dt, 45)).format('DD.MM.YYYY')}</Field>, {' '}
                                                
                                                potenţialii cumpărători, persoane fizice sau juridice, pot depune la primărie o cerere de înregistrare a ofertei de cumpărare, însoţită de documentele justificative prevăzute la art. 8 alin. (2) şi (3) din normele metodologice, 
                                                până la data{' '}
                                                <Field>{moment(endDate(dosar.dt, 45)).add(30, "days").format('DD.MM.YYYY')}</Field>.{' '}
                                                <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                Prezentul proces-verbal de constatare a derulării etapei procedurale privind exercitarea dreptului de preempţiune a fost încheiat în 2 exemplare originale şi afişat la sediul primăriei/site <Field>{dosar.company.website||"----"}</Field>, {' '}
                                                în data de <Field>{moment(dosar.anexe["pv45"]["dt"]).format("DD.MM.YYYY")}</Field>


                                                </Grid>
                                               

                                                <Grid item xs={12} align="center">
                                                    <br/>
                                                    <SemnaturiPrimarSecretarConsilier data = {dosar}/>
                                                 </Grid>
                                            </Grid>

                                        )
                                        : (
                                            <div>alegeti o anexa</div>
                                        )}

                                </div>
                            </RichCard>

                        </Grid>
                    </Grid>
                )
                : (
                    <Alert severity="info" icon={<FaExclamationCircle size="5rem" color="red" />}>
                        <Typography variant="overline" color="inherit" style={{ fontWeight: 500 }}>
                            Pentru că  există deja înregistrate 
                            comunicări de acceptare
                            (anexe 1E)
                            în termenul de 45 de zile lucrătoare,  vă rugăm să generați celalalt proces-verbal (PV46)
                        </Typography>
                    </Alert>
                )
            }
        </Fragment>
    )
}
