import React, { Fragment, useState } from 'react'
import { Divider, List, ListItem } from '@material-ui/core'
import moment from 'moment'
import { useHistory } from 'react-router-dom'



export default function ConexeList(props) {
    const items = props.data
    const history = useHistory()


    const closeNewConexHandler = ev => console.log("ev:", ev)

    return (
        <Fragment>
            <List>
                
                {items && items.children && items.children.map( (e,i) => (
                <Fragment key = {i}>
                    <ListItem button onClick = {()=>history.push(`/registru-arendare/detalii/${e.id}`)}>
                        {e.nr} / {moment(e.dt).format('DD.MM.YYYY')} - {e.tip}
                    </ListItem>
                    <Divider/>
                </Fragment>
                
                ))}
            </List>
        </Fragment>
    )
}
