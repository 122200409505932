import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Tabs, Tab } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import AcceptareTemplate from './AcceptareTemplate'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    dialogPaper: { minWidth: "70vw", minHeight: "100vh"}
}))



export default function AcceptariModal(props) {
    const {data} = props
    const classes = useStyles()
    const [tabIndex, setTabIndex] = useState(0)

    const closeHandler = () => {props.onClose()}

    const tabHandler = (_, newVal) => setTabIndex(newVal)

    return (
        <Fragment>
            <Dialog open = {Boolean(data)} onClose = {closeHandler} classes = {{paper:classes.dialogPaper}}>
            
            <DialogTitle>
            <Grid container alignItems = "center" justifyContent="space-between">
                    <Grid item>
                        COMUNICĂRI DE ACCEPTARE DOSAR NR.  {data && data.registru.nr} / 
                        {data && moment(data.registru.dt).format('DD.MM.YYYY')}
                    </Grid>
                    <IconButton size = "small" onClick = {closeHandler} >
                        <FaTimesCircle color = "lightgrey" size = "2rem"/>
                    </IconButton>
                </Grid>
                <Tabs value ={tabIndex} onChange = {tabHandler}>
                    {data && data.registru && data.registru.anexe && data.registru.anexe.acceptari
                    .map( (e,i) => (
                        <Tab label = {e.titular.name} key = {i}/>
                    ))}
                </Tabs>
            </DialogTitle>

            <DialogContent>

                 <AcceptareTemplate data = {{
                     ...data.registru,
                     acc: data.registru.anexe.acceptari[tabIndex], 
                        
                     company: data,
                     dosar: data.registru, 
                     regNr: data.registru.nr, regDt: data.registru.dt,
                     user: {}}} />
                
                {/* {data && data.registru && data.registru.anexe && data.registru.anexe.acceptari && (
                    <pre>{JSON.stringify(data, null, 4)}</pre>
                )} */}
            </DialogContent>
            </Dialog>
        </Fragment>
    )
}
