import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Typography, TextareaAutosize, Divider } from '@material-ui/core'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { FaRegThumbsDown, FaThumbsDown, FaThumbsUp, FaTimesCircle } from 'react-icons/fa'
import { MdPrint } from 'react-icons/md'
import FunkyInput from "../../components/FunkyInput"


export default function EditAvizModal({data, onClose}) {

    const [item, setItem] = useState()
    useEffect(() => setItem(data), [data])

    const closeHandler = elem => () => 
    onClose(elem)

    const inputHandler =  ev => setItem({...item, nr: ev.target.value })
    const dtHandler =  ev => setItem({...item, dt: moment(ev.target.value).toDate() })
    const areaHandler = name => ev => setItem({...item, [name]: ev.target.value})

    const avizHandler = state => () => setItem({...item, aviz: Boolean(state) })
    
    return (
        <Dialog open={Boolean(item)} onClose = {closeHandler(null)}>
                 {console.log(item)}
            <DialogTitle >
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item sm ={10} style = {{lineHeight:1}}>
                     {
                        item && item.originalItem 
                        && <small >
                            Aviz pentru oferta nr. {' '}<strong>
                            {item.originalItem.nr} / {item.originalItem.dtHuman}
                            </strong>{' '}<br/>
                            în perioada {' '}
                            {item.tip === "etapa45" && "de 45 de zile (etapa preemptorilor)"}
                            <br/>
                           
                        </small>
                    }
                    </Grid>
                    <Grid item  align="center">
                        <IconButton onClick = {closeHandler(null)}>
                            <FaTimesCircle size="2em" color="lightgrey"/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {item && (
                    <Grid container spacing = {1}>

                    <Grid item sm = {2}>
                        <FunkyInput value = {item.nr} type = "number" label = "NR. AVIZ" onChange = {inputHandler}/>
                    </Grid>
                    <Grid item sm = {4}>
                        <FunkyInput value = {moment(item.dt).format("YYYY-MM-DD")} type = "date" label = "DIN DATA"  
                        onChange = {dtHandler}/>
                    </Grid>
                    <Grid item sm = {true}/>
                    <Grid item sm = {12} align='center'>
                        <Typography variant = "subtitle2" >AVIZAT</Typography>
                    </Grid>
                    <Grid item sm = {6}>
                        <Button fullWidth
                            variant = {item.aviz ? "contained" : "outlined"} 
                            color = {item.aviz ? "primary" : "default"} 
                            // startIcon = {<FaThumbsUp size="2rem"/>}
                            onClick = {avizHandler(true)}
                        >
                            <Typography variant = "h4" style={{fontWeight:800}}>POZITIV</Typography>
                        </Button>
                    </Grid>
                    <Grid item sm = {6}>
                        <Button fullWidth
                            variant = {!item.aviz ? "contained" : "outlined"}
                            color = {!item.aviz ? "secondary" : "default"} 
                            // startIcon = {<FaThumbsDown  size="2rem"/>}
                            onClick = {avizHandler(false)}
                        >
                             <Typography variant = "h4" style={{fontWeight:800}}>NEGATIV</Typography>
                        </Button>
                    </Grid>
                    {Boolean(item.aviz) === false && (
                        <Fragment>
                            <Grid item sm = {12} align="center">
                                <hr/>
                            
                                <Typography variant = "subtitle2" color="secondary">
                                    
                                    MOTIVAREA AVIZULUI NEGATIV
                                </Typography>
                            </Grid>
                            <Grid item sm = {12}>ÎN FAPT</Grid>
                            <Grid item sm = {12}>
                                <TextareaAutosize 
                                    fullWidth  style = {{width: "100%", height: "15vh", overflow:"auto", padding: "10px"}}
                                    value = { item.inFapt }
                                    onChange = {areaHandler("inFapt")}
                                >
                                    {/* {item.inFapt} */}
                                </TextareaAutosize>
                            </Grid>
                            <Grid item sm = {12}>ÎN DREPT</Grid>
                            <Grid item sm = {12}>
                            <TextareaAutosize 
                                    fullWidth  style = {{width: "100%", height: "15vh", overflow:"auto", padding: "10px"}}
                                    value = { item.inDrept }
                                    onChange = {areaHandler("inDrept")}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
                )}
{/* 
                {item && <Fragment>
                    <pre>{JSON.stringify(item, null, 4)}</pre>
                </Fragment>} */}
            </DialogContent>
            <DialogActions>
                <Button 
                    color = "default" variant="outlined" 
                    disabled = {item && item.originalItem && item.originalItem.anexe && !item.originalItem.anexe.avizDadr}
                    startIcon = {<MdPrint/>}
                >TIPĂREȘTE</Button>
                <Button color="secondary" variant="contained" onClick={closeHandler(null)}>
                    RENUNȚĂ
                </Button>
                <Button color="primary" variant="contained" onClick={closeHandler(item)}>
                    CONFIRMĂ
                </Button>
            </DialogActions>
            

        </Dialog>
    )
}
