import React, { useState, useEffect, Fragment } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { PRODUCT } from '../../../apollo/queryAtestate'
import { useQuery } from "@apollo/client"
import { useDebounce } from '../../../lib/reactHooks'
import FunkyInput from '../../../components/FunkyInput'
import {IconButton, makeStyles} from '@material-ui/core'
import { FaPlusCircle } from 'react-icons/fa'


const useStyles = makeStyles(theme => ({
    autoc: {
        fontSize: "0.7rem", fontWeight:600, color: theme.palette.primary.dark
    }
}))

const NoOption = ({data, onNew}) => {
    return <div align="center">
        <small>Produsul nu există</small><br/>
        <IconButton 
            variant = "contained" color = "primary" size="small"
            onMouseDown = {()=>onNew(true)}
        >
            <FaPlusCircle size="2rem"/>
        </IconButton>
    </div>
}

export default function PRODUS(props) {
    const classes = useStyles()
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState()
    const [searchString, setSearchString] = useState()
    const debounced = useDebounce(searchString, 100)

    const {data, loading, error } = useQuery(PRODUCT, {
        variables:{
            data: debounced,
            tip: props.tip
        }
    })
    

    useEffect(() => {
        if (props.data) {
            setProducts([props.data])
            setProduct(props.data)
            if ( props.src) {setSearchString(props.src)}
        } 
    }, [props, data])



    useEffect(() => {
        if (data && data.lookupProdus) setProducts(data.lookupProdus)
    }, [data])


  

    const changeHandler = (ev, val, reason) => {
        if (reason === "clear") {
        //   setSearchString(" ")
        console.log("onChange cleae: ", ev)
          props.onChange(null)
        }
        if (val && reason === "select-option") {

            setProduct(val)
            // console.log("val changed:", val)
            props.onChange(val)
        }
    }

    return (
        <Fragment>
           
                <div style = {{display:"flex", alignItems:"center"}}>
                    <Autocomplete
                        size = "small"
                        options={product ? products : []}
                        classes = {{"input":classes.autoc}}
                        getOptionLabel={option => option.den_produs || ""}
                        value={product ? product : ""}
                        
                        renderInput={
                            params => <FunkyInput {...params} label={props.label} 
                            
                            placeholder = {props.placeholder || ""}
                            variant="outlined" size="small" fullWidth />
                        }
                        noOptionsText={<NoOption data = {product} onNew = {()=>props.onNew(true)}/>}
                        onInputChange={(__, value) => setSearchString(value || "xxxxxxx")}
                        onChange={changeHandler}
                    />
                   
                   
                </div>
          
        </Fragment>



    )
}