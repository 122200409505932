import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Fragment } from 'react'
import ANTET_CERERE from '../../Common/ANTET_CERERE'
import PF_PJ from '../../Common/PF_PJ'
import Field from '../../Common/Field'
import inLitere from '../../../../lib/inLitere'
import moment from 'moment'
import Antet2022 from '../../Common/Antet2022'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },

}))

export default function Anexa1FPrintTpl(props) {

    const data = props.data || {}
    const item = data.item || {}
    const dosar = data.dosar || {}
    const classes = useStyles()
    return (
        <div className = {classes.text}>
            <div align="right">
                    ANEXA Nr. 6 (Anexa nr. 1F la normele metodologice)<br/>
                    
            </div>
            <p align="center">
            
                <strong>CERERE<br/>pentru înregistrarea ofertei de cumpărare</strong>

            </p>
            { data && (
                <Fragment>
                   
                    <Antet2022 data = {dosar} />
                    <div align = "right">
                     Nr.înreg. {item.nr} / {moment(item.dt).format("DD.MM.YYYY")}
                    </div>
                    <br/>
                    <p>Stimată doamnă/Stimate domnule primar,</p>
                    
                        <PF_PJ data = {item.ofertant.map( el => ({...el,  calitate: item.mdtMandat, prin: item.mdtPrin, conform: item.mdtConform}))} acceptare = "ofertant" />
                    <p>
                    prin prezenta îmi exprim intenţia de cumpărare şi accept oferta de vânzare pentru terenul agricol 
                    în suprafaţă de <Field>{dosar.anexe.terenHa}</Field> <Field>ha</Field>, 
                    identificat cu număr cadastral <Field>{dosar.anexe.terenNrCadastral}</Field>,{' '}
                    înscris în cartea funciară nr. <Field>{dosar.anexe.terenCf}</Field>, {' '} 
                    a localităţii <Field>{dosar.anexe.terenCfLoc}</Field>, {' '}  
                    făcută de {' '}
                    {dosar.ofertanti.map( (e,i) => (
                        <Fragment key = {i}>
                            <Field>
                                {e.titular.name}
                            </Field>
                             {i-1 < item.ofertant.length ? ", ":  "--"}
                        </Fragment>
                    ))}
                     şi afişată în data de <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>{' '} 
                     la sediul <Field>Primăriei</Field> <Field>{dosar.company.loc}</Field> .
                    </p>
                    <p>
                   

                        Preţul oferit pentru cumpărare este de <sup>(*)</sup>{' '}
                    <Field>{item.pret}</Field> <strong>lei.</strong> {'  '}
                     (<Field>{inLitere(item.pret||0)}</Field>)  
                      (Preţul se va scrie în cifre şi litere.)
                    </p>
                    <div>
                    În susţinerea comunicării de acceptare şi a calităţii de potențial cumpărător, depun următoarele acte doveditoare:
                        <div style = {{width: "100%"}}>
                        <div style = {{fontFamily: "Arial"}}>{item.acte || "- - - "}</div>
                        </div>
                    </div>
                   <p>
                   Îmi exprim acordul cu privire la utilizarea şi prelucrarea datelor cu caracter personal şi la introducerea datelor şi
                    informaţiilor din cerere şi documentele anexate în bazele de date care se organizează în temeiul Legii nr. 
                    17/2014 privind unele măsuri de reglementare a vânzării terenurilor agricole situate în extravilan şi de modificare 
                    a Legii nr. 268/2001 privind privatizarea societăţilor ce deţin în administrare terenuri proprietate publică şi 
                    privată a statului cu destinaţie agricolă şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările şi 
                    completările ulterioare, şi al legislaţiei subsecvente, cu respectarea prevederilor Regulamentului (UE) 2016/679 
                    al Parlamentului European şi al Consiliului din 27 aprilie 2016 privind protecţia persoanelor fizice în ceea 
                    ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare 
                    a Directivei 95/46/CE (Regulamentul general privind protecţia datelor) şi ale Legii nr. 190/2018 privind măsuri 
                    de punere în aplicare a Regulamentului (UE) 2016/679 al Parlamentului European şi al Consiliului din 27 aprilie 
                    2016 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi 
                    privind libera circulaţie a acestor date şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor), cu modificările ulterioare.<br/>
                    Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu modificările 
                    şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                    </p>   
                    <p align="center">
                        Potenţial cumpărător/Împuternicit, 
                    </p>
                    <p align="center">
                        <Field>{item.ofertant[0].titular && item.ofertant[0].titular.name}</Field>
                        <br/><small>(numele şi prenumele în clar)</small>
                    </p>
                    {item.ofertant[0].mandatar && item.ofertant[0].mandatar.name && (
                        <p> prin <Field>{item.ofertant[0].mandatar.name}</Field></p>
                    )}

                    <p align="center">..........................................................</p>
                    <p> Data <Field>{moment(item.dt).format('DD.MM.YYYY')}</Field></p>

<p>
   <small>
NOTE:<br/>
- Câmpurile notate cu (*) sunt obligatoriu de completat.<br/>
- Câmpurile notate cu (**) se completează de către cetăţenii unui stat membru al Uniunii Europene, 
ai statelor care sunt parte la Acordul privind Spaţiul Economic European (ASEE) sau ai Confederaţiei Elveţiene.<br/>
- Câmpurile notate cu (***) se completează dacă este cazul.

</small>
</p>
                </Fragment>

            )}
            

        </div>
    )
}
