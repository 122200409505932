import { gql } from "@apollo/client";

export const PRODUCT = gql`
query lookup($data:String $tip:Boolean) {
	lookupProdus(data:$data tip:$tip) {
			id tip_produs den_produs
		}
}
`

export const LIST_QUERY = gql`
query list($data: ComplexQuery) {
  atCereri(data:$data) {
	  id nr dt contactId avizNr avizDt avizEmitent
	  contact { id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
	  taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara 
	  }
	  atProcesVerbal {
		  companyId contactId atCerereId nr dt expiry 
		  agricolVol agricolNr agricolArabil agricolLivezi agricolVii agricolAnimale agricolPasari agricolAlbine 
		  atestatSeria atestatNr atestatDt
			  atCarnete {
				  id
				  contactId
				  nr seria dt filaStart filaEnd dtElib dtPredare 
				  produse {
					  id 
					  ha ari efective estimat solicitat
					  atProdus {
						  id tip_produs den_produs
					  }
				  }
				  gospodari {
					  id 
					  contact { 
						  id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
						  taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara 
					  }
				  }
			  }
	  }
  }

  
}
`

export const PRODUSE = gql`
query produse{
	atProduse {
			id tip_produs den_produs
		}
}`

export const CARNETE = gql`
query carnete($data: ComplexQuery){
	atCarnete(data:$data) {
			id seria nr dt  filaStart filaEnd dtElib dtPredare
			contact { id name }
			produse {
				id ha ari efective estimat solicitat
				atProdus { id den_produs tip_produs }
			}
		atProcesVerbal {
			id atestatSeria atestatNr atestatDt
		}
		}
}`

export const PROCESEVERBALE = gql`
query atProceseVerbale($data:ComplexQuery) {
	atProceseVerbale(data:$data) {
		id nr dt
		agricolVol agricolNr
		agricolArabil agricolLivezi agricolVii
		agricolAnimale agricolPasari agricolAlbine
		contact { id name }
}
}`