import { Button, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { MdArrowForward, MdMail, MdPrint, MdReply } from 'react-icons/md'
import moment from "moment"

export default function Updates() {
    return (
        <div>
        <Alert severity = "info">
            ULTIMELE ACTUALIZARI
        </Alert>
        
        <hr/>
        <strong>27.96.2023</strong>
        <ul>
            <li>
                <strong>A fost implementată anexa 2c în formatul exact solicitat de către M.Ap.N.</strong>
            </li>
        </ul>
        <strong>12.01.2022</strong>
        <ul>
            <li>
                A fost schimbată ordonarea implicită de prezentare a registrului pentru a răpunde mai bine contextului multianual.
                Așadar, începând de azi, registrul de oferte va fi implicit sortat după data înregistrării cereri, <strong>
                    DESCRESCĂTOR!</strong>
            </li>
        </ul>
        <strong>02.01.2022</strong>
        <ul>
            <li>
                <strong>IMPLEMENTARE ZILE LIBERE 2022</strong><br/>
                <div>
                    Pentru calculul corect al celor 45 de zile lucrătoare din etapa I, am implementat în aplicație și 
                    sărbătorile legale pe anul 2022 (zile libere), după cum urmează:<br/>
                    <ul>
                    {[{dt:"2022-01-01" , desc: "Anul Nou"},
                    {dt:"2022-01-02" , desc: "Anul Nou"},
                    {dt:"2022-04-22" , desc: "Vinerea Mare"},
                    {dt:"2022-04-24" , desc: "Paștele Ortodox"},
                    {dt:"2022-04-25" , desc: "Paștele Ortodox"},
                    {dt:"2022-05-01" , desc: "Ziua Muncii"},
                    {dt:"2022-06-01" , desc: "Ziua Copilului"},
                    {dt:"2022-06-12" , desc: "Duminica Rusaliilor"},
                    {dt:"2022-06-13" , desc: "A doua zi de Rusalii"},
                    {dt:"2022-08-15" , desc: "Adormirea Maicii Domnului"},
                    {dt:"2022-11-30" , desc: "Sf.Andrei"},
                    {dt:"2022-12-01" , desc: "Ziua Națională a Romnâniei"},
                    {dt:"2022-12-25" , desc: "Crăciunul"},
                    {dt:"2022-12-26" , desc: "Crăciunul"}].map( (e,i) => <li key={i}>
                        { moment(new Date(e.dt)).format("DD.MM.YYYY")} - {e.desc}
                    </li> )}
                    </ul>
                </div>

            </li>
        </ul>
        <strong>27.10.2021</strong>
        <ul>
            <li>
                <strong>PRECIZĂRI SUPLIMENTARE ÎN PV30 SOLICIITATE DE ANUMIȚI NOTARI</strong><br/>
                <div>
                    - conform dorinței de rigurizitate absolută a unor anumiți notari publici, am amendat PV30 introducând precizări (de altfel cuprinse în PV45) referitoare la nedepunderea de oferte în prima etapă procedurală (45 zile), precum și 
                    explicitarea condițiilor conceptului de "liber la vânzare" (de altfel definite de lege)
                </div>

            </li>
        </ul>
        <strong>14.10.2021</strong>
        <ul>
            <li><strong>Optiunea LOGO </strong> din Setări -- Companie a devenit utilă. Alegeți link-ul spre logo-ul Dvs. găzduit
             undeva online și toate documentele care nu au un antet predefinit de legislație (adrese, procese-verbale etc. ) vor 
             afișa antetul Dvs. personalizat. <br/>
             <strong>NOTĂ:</strong> <small><i>se poate încărca și un logo ca poză în format <b>base64</b> prin copy-paste, însă acest fapt este
              absolut nerecomandat, putând duce la o degradare vizibilă a performanțelor aplicației (reducerea semnificativă a timpilor
              de încărcare a paginilor, întrucât logo-ul va fi generat în browser, cu fiecare pagină pe care veți deschide.)</i></small>
            </li>
        </ul>
        <strong>18.05.2021</strong>
        <ul>
            <li>
                Soluție pentru situația în care un preemptor (legitim) dar neidentificat în lista preemptorilor depune o comunicare{' '}
                de acceptare în termenul legal de 45 de zile. <br/>
                A se vedea la <strong>ANEXA 1E (comunicări de acceptare)</strong> <br/>-> butonul{' '}
                <Button variant="contained" color="primary" size="small">
                                            <Grid container alignItems="center">
                                                <FaPlusCircle size="1.2rem"/>
                                            <div style = {{lineHeight: 1, fontSize:"0.65rem", textTransform:"none", marginLeft: "10px"}}>
                                               comunicare de acceptare<br/>preemptor suplimentar
                                            </div>
                                            </Grid>
                                        </Button>
                
            </li>
        </ul>
        <strong>17.05.2021</strong>
        <ul>
            <li>Introducerea adreselor de înaintare a comunicărilor de acceptare câtre vânzător(i) și D.A.D.R.</li>
        </ul>
        <strong>30.04.2021</strong>
        <ul>
            <li>Reimplementare completă a proceselor verbale (după 45 de zile, respectiv 30 de zile)</li>
            <li>Introducere drawer informativ pe dosar</li>
            <li>Mai multe informatii utile (termene) în registrul dosarelor</li>
            <li>Reimplemtare anexa 1F și mecanism de preluare a ofertelor în intervalul de 30 de zile</li>
            <li>Implementarea procedurii de anulare a ofertei de vânzare</li>
        </ul>
        <strong>01.04.2021</strong>
        <ul>
            <li>corectat calitate persoană la comunicările de acceptare. Este PREEMPTOR, nu PROPRIETAR.</li>
        </ul>
        <strong>30.03.2021</strong>
        <ul>
            <li>adăugare nr. de înregistrare pe formularul tipăribil al Anexei 1D - notificare preemptori</li>
        </ul>
        <strong>28.02.2021</strong>
        <ul>
            <li>Implementat butoane grafice pentru <MdMail size = "1.2em"/> <MdArrowForward/> PLIC(DL), <MdReply  size = "1.2em"/> 
            <MdArrowForward/> CONFIRMARE DE PRIMIRE(A.R.)., 
            <MdPrint  size = "1.2em"/> <MdArrowForward/> PRINT </li>
            <li>implementat PLIC+A.R. la Adresa către A.D.S. și la Adresa 2C-M.Ap.N.</li>
        </ul>
        <strong>27.02.2021</strong>
        <ul>
            <li>Finalizare procedură acceptare oferte de vânzare</li>
            <li>Finalizare procedură alegere preemptor potențial cumpărător</li>
            <li>Implementare Proces-verbal final de constatatare a îndeplinirii etapelor procedurale</li>
            <li>Optimizări printare</li>
        </ul>
        <strong>25.02.2021</strong>
        <ul>
            <li>modulul public este funcțional. pentru a-l integra în site-ul UAT, contactați-mă. 
                În principiu, trebuie pus fie un link spre https://2021.legea17.ro/idUAT (ex. 9=Zimandu Nou) sau 
                creat un "iframe" care să conțină registrul de la adresa de mai sus.
            </li>
            <li>
                am introdus o validare care dezactivează butonul CONFIRMĂ atunci când există un ofertant (titular sau mandatar) sau un 
                preemptori (anexa1c / titular ) fără a avea aleasă o persoană (un contact deja creat). 
            </li>
            <li>reparat datele preemptorului PF din anexa 1D (comunicare)</li>
        </ul>
        <strong>23.02.2021</strong>
        <ul>
            <li>validari elementare dosar/evitare erori critice de relatie intre indecsi, ex ofertant.titular=0 sau preemptori.titular=0
                ducea la o eroare critică având ca rezultat imposibilitatea încărcării paginii principale
            </li>
        </ul>
        <strong>22.02.2021</strong>
        <ul>
            <li>Implementat ștergerea dosarelor</li>
            <li>Implementat clonarea unui dosar</li>
            <li>Remediere formular 1B Oferta persoane fizice - introduc câmpurile lipsă (tarla, parcela, cat.teren, observații etc)</li>
            <li>Proces-verbal afișare listă preemptori PF - corectat titlul documentului</li>
        </ul>


          
        </div>
    )
}
