import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import RichCard from '../../../../components/RichCard';
import Boxy from '../../../../components/Boxy';
import FunkyInput from '../../../../components/FunkyInput';
import moment from 'moment';
import { FaDog, FaPagelines, FaPlus, FaPlusCircle, FaSeedling, FaSpa, FaTimes, FaTimesCircle } from 'react-icons/fa';
import PRODUS from '../PRODUS';
import { clearTypeName } from '../../../../lib/graph-stuff';
import CarnetIdentificare from './CarnetIdentificare';
import CarnetProduse from './CarnetProduse';
import CarnetGospodari from './CarnetGosopodari';
import { MdPrint } from 'react-icons/md';
import PrintPvCarnetEliberare from '../../PrintModals/PrintPvCarnetEliberare';
import PrintPvCarnetActualizare from '../../PrintModals/PrintPvCarnetActualizare';
import PrintPvCarnetRestituire from '../../PrintModals/PrintPvCarnetRestituire';
import PrintCerereCarnetActualizare from '../../PrintModals/PrintCerereCarnetActualizare';
import PrintCerereCarnetEliberare from '../../PrintModals/PrintCerereCarnetEliberare';

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: "100vw",
        padding:0,
        minHeight: "90vh"
    }
}))

const isDisabled = item => {
    let res1 = false, res2 = false;
    if (item && item.produse) {
        res1 = item.produse.reduce( (acc,el) => {
            return el.atProdus.id ? acc : true
        }, false)
    }
    if (item && item.gospodari) {
        res2 = item.gospodari.reduce( (acc,el) => {
            return el.contact.id ? acc : true
        }, false)
    }

    return Boolean(res1+res2)
}

export default function CarnetModal({ data, context, onClose }) {

    const [item, setItem] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)
    const classes = useStyles()
    const [print, setPrint] = useState()

    useEffect(() => setItem(data), [data])

    const tabHandler = (_, index) => setTabIndex(index)

    const newProductHandler = type => () => {
        let ni = { ...item, produse: item.produse.map(e => ({ ...e })) }
        ni.produse.push({
            id: makeid(5),
            ha: 0,
            ari: 0,
            efective: 0,
            estimat: 0,
            solicitat: 0,
            atProdus: {
                tip_produs: type || false,
                den_produs: ""
            }
        })
        setItem(ni)
        setTabIndex(Boolean(type) ? 0 : 1)

    }

    const childHandler = ev => {
        if (ev) setItem(ev)
    }

    const onPrintHandler = ev => setPrint(ev)

    const closeHandler = item => () => onClose(item)



    return (
        <Fragment>
        <Dialog open={Boolean(data)} onClose={closeHandler(null)} classes={{ paper: classes.dialogPaper }} >
            {/* {console.log("item in carnt modal:", item)} */}
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant = "body2">
                                CARNET DE COMERCIALIZARE A PRODUSELOR AGRICOLE
                      </Typography>
                        </Grid>
                    <Grid item>
                        {/* <Button
                            color="primary"
                            onClick = {() => setPrint({
                                type: "eliberare",
                                data: item
                            })}
                        >
                            <MdPrint size = "2em"/> &nbsp; PV ELIBERARE
                        </Button> */}
                        
                        {/* <Button
                            disabled = {item && !item.dtPredare}
                            color="secondary"
                            onClick = {() => setPrint({
                                type: "restituire",
                                data: item
                            })}
                        >
                            <MdPrint size = "2em"/> &nbsp; PV RESTITUIRE
                        </Button> */}
                        <IconButton onClick={closeHandler(null)}><FaTimesCircle color="lightgrey" size="2em" /></IconButton>
                    </Grid>
                </Grid>

            </DialogTitle>
            <DialogContent>
                {item && (
                    <Fragment>
                        <Boxy title="DATE DE IDENTIFICARE">
                            <CarnetIdentificare data={item} onChange={childHandler} onPrint = {onPrintHandler} />
                        </Boxy>
                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                            <Boxy
                                style = {{background: "none"}}
                                contentStyle={{
                                    minHeight: "40vh", overflow: "auto",
                                    background: "none"
                            }}
                            title="PRODUSE"
                            action={
                                <div>
                                    <Button onClick={newProductHandler(true)} variant="outlined">
                                        <FaPlus />&nbsp;
                                        <FaPagelines color="green" /> &nbsp;
                                        PRODUS VEGETAL
                                    </Button>
                                    &nbsp;
                                    <Button onClick={newProductHandler(false)} variant="outlined">
                                        <FaPlus />&nbsp;
                                        <FaDog color="brown" size="1em" /> &nbsp;
                                        PRODUS ANIMAL
                                    </Button>
                                </div>
                            }
                        >


                            <Tabs style = {{marginTop: "0px"}}
                                value={tabIndex}
                                textColor="primary" indicatorColor='primary' color="primary"
                                onChange={tabHandler}
                            >
                                <Tab
                                    label={
                                        <div style ={{display:"flex", alignItems:"flex-end", color: "black"}} >
                                            <FaPagelines size="2em" color="DarkOliveGreen" />                                             
                                            <small>&nbsp;VEGETALE</small>
                                            <Badge 
                                                badgeContent = {item.produse.filter( el => el.atProdus.tip_produs ).length} 
                                                color="primary"
                                            > <div style = {{height: "20px", width: "10px"}}></div>
                                        </Badge>

                                        </div>}
                                />
                                <Tab
                                    label={
                                        <div style ={{display:"flex", alignItems:"flex-end", color: "black"}}>
                                            <FaDog size="2em" color="darkred" />

                                        <small>&nbsp;ANIMALE</small>
                                        <Badge 
                                                badgeContent = {item.produse.filter( el => !el.atProdus.tip_produs ).length} 
                                                color="secondary"
                                            > <div style = {{height: "20px", width: "10px"}}></div>
                                        </Badge>
                                        
                                    </div>
                                    }
                                />

                            </Tabs>
                            {tabIndex === 0 && (
                                <Fragment>
                                    <br />
                                    <CarnetProduse
                                        data={item}
                                        tip={true}
                                        title="PRODUSE VEGETALE"
                                        onChange={childHandler}
                                    />
                                </Fragment>
                            )}
                            {tabIndex === 1 && (
                                <Fragment>
                                    <br />
                                    <CarnetProduse
                                        data={item}
                                        tip={false}
                                        title="PRODUSE ANIMALE"
                                        onChange={childHandler}
                                    />
                                </Fragment>
                            )}
                        </Boxy>
                            </Grid>
                            <Grid item xs = {4}>
                                <Boxy
                                    title = "MEMBRI GOSPODĂRIEI"
                                    contentStyle={{height: "45.2vh", overflow: "auto"}}
                                    
                                >
                                    <CarnetGospodari data = {item} onChange={childHandler} />
                                </Boxy>
                            </Grid>
                        </Grid>
 
                        
                    </Fragment>
                )}
                {/* {item &&<pre>{JSON.stringify(item, null, 2)}</pre>} */}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={closeHandler(null)}>
                    RENUNȚĂ
                </Button>
                <Button 
                    variant="contained" color="primary" onClick={closeHandler(item)}
                    disabled = {isDisabled(item)}
                >
                    CONFIRMĂ
                </Button>
               
            </DialogActions>
        </Dialog>
        {print && print.type ==="eliberare" && <PrintPvCarnetEliberare data = {print.data} context ={context} onClose = {()=>setPrint(null)} />}
        {print && print.type ==="restituire" && <PrintPvCarnetRestituire data = {print.data} context ={context} onClose = {()=>setPrint(null)} />}
        {print && print.type ==="actualizare" && <PrintPvCarnetActualizare data = {print.data} context ={context} onClose = {()=>setPrint(null)} />}
        
        {print && print.type ==="cerere-eliberare" && <PrintCerereCarnetEliberare data = {print.data} context ={context} onClose = {()=>setPrint(null)} />}
        {print && print.type ==="cerere-actualizare" && <PrintCerereCarnetActualizare data = {print.data} context ={context} onClose = {()=>setPrint(null)} />}
        
    </Fragment>
    )

}
