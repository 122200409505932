import { AppBar, Button, CssBaseline, Drawer, Grid, IconButton, Link, makeStyles, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useContext, Fragment } from 'react';
// import { useRouter } from 'next/router'
import { FaBalanceScaleRight, FaCarrot, FaCog, FaHandshake, FaHome, FaPowerOff, FaUsers } from 'react-icons/fa';
import { withRouter } from "react-router-dom";
// import Head from '../components/Head'
import Copyright from '../components/Copyright';
import storage from '../lib/storage';
import { Context } from './Store';
import { useApolloClient } from "@apollo/client"

// import Logo from '../assets/AQUIS.svg'

import config from '../config'


const drawerWidth = 100;

const useStyles = makeStyles(theme => {
  // console.log("theme:", theme)
  return {
    root: {
      display: 'flex', width: "100%"
    },
    toolbar: {
      paddingLeft: "10px",
      paddingTop: 0,
      paddingRight: 0, // keep right padding when drawer closed
      background: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // padding: '0 20px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      // whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        //    width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      // flexGrow: 1
      height: '100vh',
      overflow: 'auto',
      width: "100%",

    },
    container: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),

    },
    backContainer: {
      position: 'absolute',
      top: 0, left: 0, bottom: 0, right: 0,
      backgroundImage: `url('/cioara.jpg')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: '5px solid red',
      padding: '20px',
      opacity: 0.07,
      zIndex: -1,
      zoom: 1
    },
    paper: {
      padding: theme.spacing(0),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    wrapText: {
      wordWrap: 'break-word'
    }
  }
});



const Layout = (props) => {

  const client = useApolloClient()
  const theme = useTheme()
  const width = useMediaQuery(theme.breakpoints.up('sm'))
  const [state, dispatch] = useContext(Context)
  const iconSize = "2rem"
  // console.log("width: ", width)
  // const router = useHistory()
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const userModalCloseHandler = ev => {


  }

  // useEffect(() => {
  //   setOpen(width)
  // }, [width])





  const UserData = usr => (
    <Grid container justifyContent="flex-start" alignItems="flex-end">
      <Grid item xs={12}>
        <small>{usr.name} ({usr.username})</small>
      </Grid>
      <Grid item xs={12}>
        <small>
          email: {usr.email} / rol: {usr.role ? usr.role : ""}
        </small>
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.root}>

     
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} variant="dense">
          <IconButton

            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>

            {/* <img src={Logo} style={{ height: "48px", width: "auto" }} /> */}

            <IconButton color="inherit" onClick={() => props.history.push('/')}><FaHome /></IconButton>

            <Grid item xs align="left" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

              <Typography variant={width ? "h4" : "caption"}
                style={{ fontWeight: 800, textShadow: "1px 1px 2px black", color: "#e0e0e0" }}
                onClick={() => props.history.push('/')}>&nbsp;&nbsp;
                {config.APP_NAME}
              </Typography>
              {width && (
                <Link href={process.env.NODE_ENV === "production"
                  ? `https://terenuri.agricole.ro/public/${state.companyId}`
                  : "http://localhost:3000/public/" + state.companyId
                } target="_blank" >
                  <Button variant="outlined" style={{ color: "yellow", border: "1px solid yellow" }}>
                    LINK SITE
                  </Button>
                </Link>
              )}




            </Grid>
            {width && (
            <Grid item xs={3} align="right">
            {state && <UserData {...state} />}
          </Grid>
            )}

            <Grid item>
              <IconButton color="inherit" onClick={
                () => {
                  storage.remove()
                  client.cache.reset()
                  dispatch({});
                  props.history.push("/login")
                }}
                style={{ marginRight: "8px" }}
              >
                <FaPowerOff />
              </IconButton>
            </Grid>


          </Grid>


        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}

        open={open}
      >
        <div className={classes.toolbarIcon}>

          <IconButton onClick={handleDrawerClose} aria-label="close side menu">
            <ChevronLeftIcon />
          </IconButton>


        </div>

        <Grid container justifyContent="space-around" alignItems="center" style={{ marginTop: '1em' }}>

          <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
            <Button onClick={() => props.history.push('/registru-vanzari')}>
              <span style={{ display: "block" }}>
                <FaHandshake style={{ fontSize: iconSize, color: 'blue' }} /><br />
                <Typography variant="caption" color="inherit" >
                  <div style={{ lineHeight: 1.2 }}>VÂNZĂRI<br />TERENURI</div>
                </Typography>
              </span>
            </Button>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
            <Button onClick={() => props.history.push('/registru-arendare')}>
              <span style={{ display: "block" }}>
                <FaBalanceScaleRight style={{ fontSize: iconSize, color: 'Magenta' }} /><br />
                <Typography variant="caption" color="inherit">
                  <div style={{ lineHeight: 1.2 }}>REGISTRU<br />ARENDARE</div>
                </Typography>
              </span>
            </Button>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
            <Button onClick={() => props.history.push('/registru-atestate/cereri')}>
              <span style={{ display: "block" }}>
                <FaCarrot style={{ fontSize: iconSize, color: 'Tomato' }} /><br />
                <Typography variant="caption" color="inherit">
                  <div style={{ lineHeight: 1.2 }}>ATESTATE<br />PRODUCĂTOR</div>
                </Typography>
              </span>
            </Button>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
            <Button onClick={() => props.history.push('/agenda')}>
              <span style={{ display: "block" }}>
                <FaUsers style={{ fontSize: iconSize, color: 'orange', opacity: 0.7 }} /><br />
                <Typography variant="caption" color="inherit">AGENDĂ</Typography>
              </span>
            </Button>
          </Grid>
          {/* <Grid item xs={12} style={{ textAlign: 'center',   marginBottom: '1rem'  }} >
            <Button onClick={() => props.history.push('/rapoarte')}>
              <span style={{ display: "block" }}>
                <FaToiletPaper style={{ fontSize: iconSize, color: 'lightgreen' }} /><br/>
                <Typography variant="caption" color="inherit">RAPOARTE</Typography>
              </span>
            </Button>
          </Grid> */}
          <Fragment>
            {/* <Grid item xs={12} style={{ textAlign: 'center',   marginBottom: '1rem'  }} >
                <Button onClick={() => props.history.push('/reports')}>
                  <span style={{ display: "block" }}>
                    <FaToiletPaper style={{ fontSize: iconSize, color: 'lightskyblue' }} /><br/>
                    <Typography variant="caption">RAPOARTE</Typography>
                  </span>
                </Button>
              </Grid> */}
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
              <Button onClick={() => props.history.push('/setari')}>
                <span style={{ display: "block" }}>
                  <FaCog style={{ fontSize: iconSize, color: 'mediumorchid' }} /><br />
                  <Typography variant="caption">SETĂRI</Typography>
                </span>
              </Button>
            </Grid>
          </Fragment>



        </Grid>



        {/* <Divider /> */}
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content} >
        <div className={classes.appBarSpacer} style={{ marginBottom: "-15px" }} />
        {/* <Container maxWidth="xl" className={classes.container} > */}

        {props.children}


        {/* </Container> */}
        <div className={classes.backContainer} />
        <Copyright />
      </main>
    </div>
  );
}

export default withRouter(Layout)