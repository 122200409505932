import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CssBaseline, makeStyles } from "@material-ui/core"
import Main from "./routes/Main"
import Login from './routes/Login'
import ApolloClientProvider from './apollo/ApolloClient'
import  {Context} from './components/Store'
import storage from './lib/storage'
import PublicModule from './routes/PublicModule'



const useStyles = makeStyles(theme => ({
  root: {
    display: "flex", justifyContent: "center", alignItems: "flex-start",
    height: '100vh', width: "100vw",
    overflow: "auto", flexGrow: 1
  }
}))

const App = (props) => {
  const classes = useStyles()
  const [state, dispatch] = useContext(Context);

  useEffect(()=>dispatch({notification: null}), [dispatch])

  useEffect(() => {
    dispatch({token:storage.get().token})
  }, [state.token, dispatch])




  return (
    <ApolloClientProvider token = {storage.get().token}>

      
    <div className = {classes.root}>
      <CssBaseline/>
  
          <BrowserRouter>
            <Switch>
              
             
              <Route path = "/login" exact component = { Login } />
              <Route path = "/public/:companyId?"   component = { PublicModule } />
              <Route path = "/"   component = { Main } />

              
            </Switch>
          </BrowserRouter>
        
    </div>
    {/* <Notification show = {state.notification} onClose = {()=>dispatch({notification: null})}/> */}
    </ApolloClientProvider>
  );
}

export default App;
