import { Dialog, DialogTitle, DialogContent, Grid, IconButton, makeStyles, Button, DialogActions, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { MdClose, MdPrint } from 'react-icons/md'
import styled, { createGlobalStyle } from 'styled-components'
import { useReactToPrint } from 'react-to-print'
import { Fragment } from 'react'

const useStyles = makeStyles(theme => ({
    dialog: { height: "90vh", minWidth: "90vw" }

}))



const Styles = styled.div`


    @media print {
        @page {
            size:  A4;
            margin: 0;
        }

    .containerlaser {
        width: 100%;
        height:100%;
        position:absolute;
        top:0px;
        bottom:0px;
        margin: auto;
        margin-top: 0px !important;
        transform: rotate(270deg) translate(-100%, -22.5vh);
        transform-origin: 0 0;

        // border: 1px solid magenta; 
        display:flex; align-items:center; justify-content:flex-end;
    }
    .containerjet {
        width: 100%;
        height:100%;
        position:absolute;
        top:0px;
        bottom:0px;
        margin: auto;
        margin-top: 0px !important;
        // border: 1px solid magenta; 
        transform: rotate(270deg) translate(-100%,0);
        transform-origin: 0 0;
        zoom:1.3;
        display:flex; align-items:flex-start; justify-content:flex-end;
    }
    .envelope {
        box-shadow: 0;
        border: none;
        // background: lightgrey;
        width: 220mm;
        height: 110mm;
        position:relative;

    }
    .exp,.dest { box-shadow: 0;}

};

@media screen {
    .container {
        border: 2px dashed red;
        height:100%;width:100%;
        margin: 10px;
    }
    .envelope {
        background: white;
        width: 220mm;
        height: 110mm;
        display: block;
        margin: 0 auto;
        margin-bottom: 0cm;
        box-shadow: 0 0 0.2cm rgba(0,0,0,0.5);
    }

};

.exp {
    position: relative;
    top: 20mm;
    z-index:999;
    border: 3px solid grey;
    border-radius: 10px;
    width: 80mm;
    height: 40mm;
    position: relative;
    margin-left: 5mm;
    font-size:0.8em;
}

.dest {
    position: relative;
    z-index:0;
    top: 20mm;
    border: 3px solid grey;
    border-radius: 10px;
    width: 80mm;
    height: 40mm;
    margin-left: 120mm;
    font-size:0.8em;
}
.title {  background:lightgrey; padding: 5px 5px 5px 10px; font-size:1.2em; font-weight:700; }
.content { padding: 10px; display:block; }
  
.bkgTxt {
    position:absolute; 
    z-index:1;
    margin-top:10mm;
    margin-left:0mm;
    font-size:8em;
    font-weight:800;
    color:light-grey;
    opacity:0.2;
    line-height:3;
    transform: rotate(-20deg);
   
} 
`




const Item = props => (
    <Typography variant="caption">{props.title ? props.title + ": " : ""} <strong>{props.text || "- - - - - "}</strong></Typography>
)

export default function PlicB5(props) {
    const classes = useStyles()
    const [printerType, setPrinterType] = useState('Laser')
    const closeHandler = () => props.onClose()
    const printRef = useRef()
    const data = props.data
// console.log("Data:", data)
    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const printerChange = tip => () => setPrinterType(tip)

    return (

        <Dialog open={Boolean(data)} onClose={closeHandler} classes={{ paper: classes.dialog }} >
            <DialogTitle>
                <Grid container justifyContent="space-between">
                    <div>TIPĂRIRE PLIC</div>
                    <div>
                    <Button 
                        onClick = {printerChange('Laser')} 
                        variant = {printerType === 'Laser' ? "outlined": "text"}
                        >LASER
                    </Button>
                    <Button 
                        onClick = {printerChange('Jet')} 
                        variant = {printerType === 'Jet' ? "outlined": "text"}
                        >INKJET
                    </Button>
                    </div>

                    <IconButton onClick={closeHandler}><MdClose color="grey" /></IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent ref={printRef}>
                {data && (
                    <Fragment>
                    <Styles>
                        <div 
                        className={printerType === "Laser" ? "containerlaser" : "containerjet"} 
                        >

                            <div className="envelope">
                                <div className = 'bkgTxt'>www.legea17.ro</div>
                                <div className='exp'>
                                    <div className='title'>E X P E D I T O R</div>
                                    <div className ='content'>
                                        <Item text={data.company.name} />
                                            <Grid container justifyContent= "flex-start">
                                                <Item title="str" text={data.company.str} /> &nbsp;
                                                <Item title="nr" text={data.company.nr} />{' '}
                                            </Grid>
                                        <Grid container justifyContent= "flex-start">
                                            <Item title="loc" text={data.company.loc} /> &nbsp;
                                            <Item title="jud" text={data.company.jud} />{' '}
                                        </Grid>
                                        <Grid container justifyContent= "flex-start">
                                            <Item title="cod poștal" text={data.company.cod} /> &nbsp;
                                        </Grid>
                                    </div>
                                </div>
                                <div className='dest'>
                                    <div className='title'>D E S T I N A T A R</div>
                                    <div className ='content'>
                                        <Item text={data.data.titular.name} />
                                            <Grid container justifyContent= "flex-start">
                                                <Item title="str" text={data.data.titular.domStr||data.data.titular.pjStr} /> &nbsp;
                                                <Item title="nr" text={data.data.titular.domNr||data.data.titular.pjNr} />&nbsp;
                                            </Grid>
                                            <Grid container justifyContent= "flex-start">        
                                                <Item title="bl" text={data.data.titular.domBl||data.data.titular.pjBl} />&nbsp;
                                                <Item title="sc" text={data.data.titular.domSc||data.data.titular.pjSc} />&nbsp;
                                                <Item title="et" text={data.data.titular.domEt||data.data.titular.pjEt} />&nbsp;
                                                <Item title="ap" text={data.data.titular.domAp||data.data.titular.pjAp} />&nbsp;
                                            </Grid>
                                            <Grid container justifyContent= "flex-start">
                                                <Item title="loc" text={data.data.titular.domLoc||data.data.titular.pjLoc} /> &nbsp;
                                                <Item title="jud" text={data.data.titular.domJud||data.data.titular.pjJud} />{' '}
                                            </Grid>

                                        <Grid container justifyContent= "flex-start">
                                            <Item title="cod poștal" text={data.data.titular.domCod||data.data.titular.pjCod} /> &nbsp;
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Styles>
                    </Fragment>
                )}

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" size="large" onClick={printHandler}>
                    <MdPrint size="2rem" /> &nbsp; TIPĂREȘTE
                </Button>
            </DialogActions>
        </Dialog>

    )
}
