import { Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'

export default function Antet2022({ data, withUat = false }) {

    const {company} = data|| {}
    const {nr, dt} = data || {}

    return (
        <table style={{ borderCollapse: "collapse", width: "100%", border: 1 }} >
            <tbody>
                <tr>
                    <td style={{width: "40%", border: "1px solid black", padding: "5px"}}>
                        <Typography variant = "caption"> Judeţul/localitatea</Typography><br/>
                        
                        <Typography variant = "caption" > <strong>{company.jud || "........................"}</strong></Typography>{' '} / {' '}
                        <Typography variant = "caption"> <strong>{company.loc || "........................"}</strong></Typography>
                        
                    </td>
                    <td style={{width: "60%", border: "1px solid black", padding: "5px"}} rowSpan={2}>
                    <Typography variant="caption">Nr. unic de înregistrare al ofertei de vânzare din Registrul de evidenţă</Typography>
                        <br/>
                        <Typography variant="caption">
                            Nr. <strong>{nr || "............."}</strong> din {' '}
                            <strong>
                            {dt ? moment(dt).format("DD") : "............."} / {' '}
                            {dt ? moment(dt).format("MM") : "............."} / {' '}
                            {dt ? moment(dt).format("YYYY") : "............."} {' '}
                            </strong>

                            (zi/lună/an) (<sup>*</sup>)
                        </Typography>
                    </td>
                </tr>
                <tr>
                    <td style={{width: "40%", border: "1px solid black", padding: "5px"}}>
                        <Typography variant = "caption"> 
                            {withUat ? "Unitatea administrativ-teritorială (*)" : "Primăria (*)"}
                            <br/>
                            <strong>{company.name 
                            ? company.name.replace("COMUNA", "").replace("ORAȘUL", "")
                            :"......................"}</strong>
                        </Typography><br/>

                    </td>
                </tr>
            </tbody>
        </table>
    )
}
