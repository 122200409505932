import { Button, Grid, Paper, Typography, makeStyles, Slide, Zoom } from '@material-ui/core'
import { useHistory } from 'react-router'
import React, { Fragment } from 'react'
import meanyme from "../assets/meanyme.png"
import pfa from "../assets/pfa.svg"
import land from "../assets/wadirum.jpg"

const useStyles = makeStyles( theme => ({
    btnYellow: {  background: "yellow", color: "black"},
    btnRed: {  background: "brown", color: "white",   }
}))

export default function Landing(props) {
    const history = useHistory()
    const classes = useStyles() 

    return (
        <Fragment>
            <Grid container justifyContent="center" alignItems='center' style={{ height: "85%" }}>

                <Grid item xs={12} sm={11}>
                    <Fragment>

                    <Paper style={{ padding: "3%", margin: "10px", background:`url(${land})`, backgroundRepeat: "no-repeat",
                    backgroundPosition:"center", position: "relative",
                height: "70vh", zIndex: "1", borderRadius: "0px"}}>

                        <Grid container justifyContent="space-around" spacing={1}>
                            <Grid item xs ={12}>
                            <div align="center">
                            <Zoom in = {true}  timeout={1000} mountOnEnter unmountOnExit >
                        <img src = {pfa} width = "80%" height="auto" />
                        </Zoom>
                        </div>
                        <br/>
                            </Grid>
                            <Grid item sm={3} />
                            <Grid item sm={6} xs={12}>
                            <Slide direction="left" timeout={1000} in={true} mountOnEnter unmountOnExit >
                                <Button
                                    variant="contained" size="large" color="primary" fullWidth
                                    onClick={() => history.push('/registru-vanzari')}
                                >
                                    <div>
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>VÂNZĂRI TERENURI</Typography>
                                        <small>legea 17/2014, legea 145/2020, ordinul 311/2020 </small>
                                    </div>
                                </Button>
                                </Slide>
                            </Grid>
                            <Grid item sm={3} />
                            <Grid item sm={3} />
                            <Grid item sm={6} xs={12}>
                            <Slide direction="right" timeout={1000} in={true} mountOnEnter unmountOnExit >
                                <Button
                                    variant="contained"   fullWidth
                                    classes={{root: classes.btnYellow}}
                                    
                                    onClick={() => history.push('/registru-arendare')}
                                >
                                    <div>
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>REGISTRU ARENDARE</Typography>
                                        <small>art. 1838 alin. (2) - (5) Noul Cod Civil</small>
                                    </div>
                                    
                                </Button>
                                </Slide>
                            </Grid>
                            <Grid item sm={3} />
                            <Grid item sm={3} />
                            <Grid item sm={6} xs={12}>
                            <Slide direction="up" timeout={1000} in={true} mountOnEnter unmountOnExit >
                                <Button
                                    variant="contained" size="large" color="secondary" fullWidth
                                   classes = {{root: classes.btnRed}}
                                    onClick={() => history.push('/registru-atestate/cereri')}
                                >
                                    <div>
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            PRODUCĂTOR AGRICOL</Typography>
                                        <small>cf. Legii 145/2014 actualizată prin legea 70/2018</small>
                                    </div>
                                </Button>
                                </Slide>
                            </Grid>
                            <Grid item sm={3} />
                        </Grid>

                        {/* <div style={{ position: "absolute", right: 0, bottom: "-5px", textAlign: "right" ,
                            zIndex: "-1"
                            }}>
                            <img
                                src={meanyme}
                                
                                width="50%" height="auto"
                            />
                        </div> */}
                    </Paper>
                    </Fragment>
                </Grid>
                
            </Grid>

        </Fragment>
    )
}
