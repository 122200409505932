import { gql } from "@apollo/client";

export const CERERE_ATESTAT_MUTATION = gql`
mutation cerereMutation($data:String) {
	atUpdateCerere(data:$data) {
		id nr dt contactId avizNr avizDt avizEmitent
		contact { id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
        taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara 
        }
		atProcesVerbal {
            companyId contactId atCerereId nr dt expiry 
            agricolVol agricolNr agricolArabil agricolLivezi agricolVii agricolAnimale agricolPasari agricolAlbine 
            atestatSeria atestatNr atestatDt
				atCarnete {
					id
                    contactId
					nr dt filaStart filaEnd dtElib dtPredare 
					produse {
						id 
                        ha ari efective estimat solicitat
						atProdus {
						    id tip_produs den_produs
					    }
					}
                    gospodari {
                        id 
                        contact { 
                            id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
                            taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara 
                        }
                    }
				}
		}
	}
}
`

export const PRODUS_MUTATION = gql`
mutation produsMutation(
	$id:ID 
	$tip_produs:Boolean 
	$den_produs: String
) {
	atProdus(id:$id tip_produs:$tip_produs den_produs:$den_produs) {
		id tip_produs den_produs
	}
}`

export const PRODUS_RM = gql`
mutation rmProdusMutation(	$id:ID ) {
	rmProdus(id:$id)
}
`

export const PRODUSE_UPSERT = gql`
mutation produsMutation($data: [AtProdusInput]) {
	upsertProduse(data:$data) {
		id tip_produs den_produs
	}
}`

export const UPDATE_ATPROCESVERBAL = gql`
mutation updateAtProcesVerbal($data:AtProcesVerbalInput) {
	upsertAtProcesVerbal(data:$data) {
		id nr dt
		agricolVol agricolNr
		agricolArabil agricolLivezi agricolVii
		agricolAnimale agricolPasari agricolAlbine
		

}
}`