import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, makeStyles, Grid, IconButton, DialogActions, List, ListItem, Divider } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import Container from '../../../components/Container'
import moment from 'moment'
import { FaChevronCircleLeft, FaSync } from 'react-icons/fa'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import { ARENDA_REGISTRU_MUTATION } from '../../../apollo/mutationArenda'
import { REGISTRU } from '../../../apollo/queryArenda'
import { useMutation, useLazyQuery, useApolloClient } from '@apollo/client'

const useStyles = makeStyles(theme=>({
    dialogPaper: {height: "80vh", minWidth: "75vw"}
}))

export default function Conex(props) {
    const classes = useStyles()
    const history = useHistory()
    const [item, setItem] = useState()
    const [contracte, setContracte] = useState([])
    const [results, setResults] = useState([])
    const [mutateRegistru] = useMutation(ARENDA_REGISTRU_MUTATION)
    const [parentModal, setParentModal] = useState()
    const [srcContractText, setSsrcContractText] = useState("")
    const [lazyQueryRegistru, dispatchRegistru] = useLazyQuery(REGISTRU)
    const client = useApolloClient()

    const ctr =client.readQuery({
        query: REGISTRU, 
        variables: {}
    })

    useEffect(()=>{
        setItem(props)
       setContracte(ctr && [...ctr.registruArenda].filter(el => el.tip === "contract"))
    }, [props])

    const inputHandler = name => ev => setItem({
        ...item,
        [name]: name === 'dt' ? moment(ev.target.value).toDate() : ev.target.value
    })

    const checkBoxHandler = ev => {
        console.log("name", ev.target.name)
            setItem({...item, tip: ev.target.name})
    }

    const confirmHandler = async () => {
        console.log("item:", item)
        let res;
        res = await mutateRegistru({variables: {data: {
            id:+item.id, companyId:+item.companyId, userId:+item.userId, parentId:+item.parentId,
            nr:+item.nr, dt:item.dt, tip: item.tip, desc:item.desc
        }}})
        if ( res && res.data && res.data.arendaRegistru) {
            props.onChange(true)
        }
    }

    const srcHandler = ev => {
       
        setSsrcContractText(ev.target.value)
        setResults(contracte.filter(el =>el.nr.toString() === ev.target.value.toString()))

    }

    const setParentHandler = elem => () => {
        setItem({...item, parentId: elem.id, parent: elem })
        setSsrcContractText(); setParentModal() 
    }

    return (
        <Fragment>
            {item && (
                <Container
                    style = {{height: "89vh", overflow:"auto"}}
                    title = {<Grid container justifyContent="flex-start" alignItems="center">
                        <IconButton size = "small" onClick = {()=>history.push('/registru-arendare')}>
                            <FaChevronCircleLeft color ="navy"/> &nbsp;&nbsp;&nbsp;
                        </IconButton>
                        {item.tip.toUpperCase()} nr. {item.nr} / {moment(item.dt).format('DD.MM.YYYY')} la contractul &nbsp;
                        {item.parent 
                            ? <Fragment>
                                nr. {item.parent.nr} / {moment(item.dt).format('DD.MM.YYYY')} &nbsp;&nbsp;&nbsp;
                                <Button variant = "text" color="secondary" size = "small" 
                                    onClick = {()=>setParentModal(true)}>
                                    <FaSync/> &nbsp;&nbsp;&nbsp; schimbă contractul
                                </Button>
                            </Fragment>
                            : <Button size = "small" color = "secondary" variant = "contained" onClick = {()=>setParentModal(true)}>
                                ALEGE CONTRACTUL PĂRINTE
                            </Button>
                        }
                    
                    </Grid>}
                    action={<Button
                        disabled = {!item.parent}
                        onClick = {confirmHandler}
                        size="small" color="primary" variant="contained" style={{ marginRight: "10px" }}>
                        CONFIRMĂ
                        </Button>}
                    >
                        {item &&  item.parent && (
                            <Grid container spacing = {1}>
                            <Grid item xs = {12}>
                                <RichCard>
                                    <div>
                                        
                                        <Button 
                                            size = "small" variant = "contained" color = "primary"
                                            onClick = {() => history.push(`/registru-arendare/detalii/${item && item.parent.id}`)} 
                                        >
                                            CONTACT NR. {item.parent.nr} DIN {' '}
                                        {moment(item.parent.dt).format('DD.MM.YYYY')} {' '}
                                        </Button>
                                    </div>
                                    <hr/>
                                        <strong><small>CONTRACT  </small></strong><small>
                                            {item.parent.nr} / 
                                        {moment(item.parent.dt).format('DD.MM.YYYY')} {' '}
                                        <strong>ÎN VIGOARE DE LA:  </strong>
                                        {moment(item.parent.from).format('DD.MM.YYYY')} {' '}
                                        <strong>PÂNĂ LA:  </strong>
                                        {moment(item.parent.to).format('DD.MM.YYYY')} {' '}
                                        </small>
                                        
                                       <br/>
                                        <strong><small>ARENDAȘI</small></strong>: {' '}
                                        {item.parent.arendaArendas.map( (e,i) => (
                                            <small key={i}>
                                                {e.contact.name}
                                                {i+1 < item.parent.arendaArendas.length   && ", "}
                                            </small >
                                        ))} 
                                        <br/>
                                        <strong><small>ARENDATORI</small></strong>: {' '}
                                        {item.parent.arendaArendator.map( (e,i) => (
                                            <small key={i}>
                                                {e.contact.name}
                                                {i+1 < item.parent.arendaArendator.length   && ", "}
                                            </small >
                                        ))} <br/>
                                        
                                   
                                </RichCard>

                                
                            </Grid>
                            <Grid item xs = {12}>
                                <RichCard title = {<strong>DOCUMENT CONEX / {item && item.tip.toUpperCase()}</strong>}>
                                    <Grid container spacing = {1} alignItems = "center">
                                        <Grid item xs = {2}>
                                            <FunkyInput value = {item.nr} label = "NR DOCUMENT" onChange = {inputHandler('nr')} 
                                            type="number" step="1"/>
                                        </Grid>
                                        <Grid item xs = {2}>
                                            <FunkyInput value = {moment(item.dt).format('YYYY-MM-DD')} onChange = {inputHandler('dt')}
                                            type="date" label = "DATA DOCUMENT"/>
                                        </Grid>
                                        <Grid item xs = {8}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <FormControlLabel
                                                control = {
                                                <Checkbox size = "small"
                                                    name = "aditional"
                                                    checked = {item.tip === "aditional"}
                                                    onChange = {checkBoxHandler}
                                                />
                                            }
                                                label = {<small>act adițional</small>}
                                            />
                                            <FormControlLabel
                                                control = {
                                                <Checkbox size = "small"
                                                    name = "reziliere"
                                                    checked = {item.tip === "reziliere"}
                                                    onChange = {checkBoxHandler}
                                                />
                                            }
                                                label = {<small>reziliere</small>}
                                            />
                                            <FormControlLabel
                                                control = {
                                                    <Checkbox size = "small"
                                                    name = "suspendare"
                                                    checked = {item.tip === "suspendare"}
                                                    onChange = {checkBoxHandler}
                                                /> }
                                                label = {<small>suspendare</small>}
                                            /> 
                                            <FormControlLabel
                                                control = {
                                                    <Checkbox size = "small"
                                                    name = "conex"
                                                    checked = {item.tip === "conex"}
                                                    onChange = {checkBoxHandler}
                                                />
                                                }
                                                label = {<small>altele</small>}
                                            />                                            

                                        </Grid>   
                                        <Grid item xs = {12}>
                                            <FunkyInput multiline minRows={20} value = {item.desc} label = "CONȚINUT DOCUMENT"
                                                onChange = {inputHandler('desc')}
                                            />
                                        </Grid>
                                                
                                           
                                   

                                    </Grid>
                                </RichCard>
                            </Grid>
                            {/* <Grid item xs = {12} style = {{height: "60vh", overflow:"auto"}}>
                           
                                {item && <pre>{JSON.stringify(item, null,4)}</pre>}
                               
                            </Grid> */}
                        </Grid>
                        )}
                        
                       

                </Container>
            )}
            {parentModal && (
                <Dialog open = {Boolean(parentModal)} onClose = {()=> setParentModal()} classes = {{paper: classes.dialogPaper}}>
                    <DialogTitle>ALEGERE CONTRACT PĂRINTE PENTRU ACT CONEX</DialogTitle>
                    <DialogContent>
                        <FunkyInput value = {srcContractText} onChange = {srcHandler} label = "CAUTĂ CONTRACT"/>
                        <List>
                        { 
                        // dispatchRegistru && dispatchRegistru.data && dispatchRegistru.data.registruArenda 
                        //     && dispatchRegistru.data.registruArenda.
                            results.map( (e,i) => (
                                <Fragment key = {i}>
                                    <ListItem onClick = {setParentHandler(e)}>
                                        Contract nr. {e.nr} din {moment(e.dt).format('DD.MM.YYYY')}
                                    </ListItem>
                                    <Divider/>
                                </Fragment>
                            ))
                        }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button variant = "contained" color = "secondary" 
                            onClick = {() => {setSsrcContractText(); setParentModal();}}
                        >
                            RENUNȚĂ
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            
        </Fragment>
        
    )
}
