import { Grid, makeStyles, Button, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import { FaCheck } from 'react-icons/fa'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'
import UAT from '../Common/UAT'
import { getSetting } from '../../../lib/settings'
import { MdPrint } from 'react-icons/md'

const cod = "da2";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function AdresaDA2(props) {
    const codAnexa = "da2"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()
    const [loaded, setLoaded] = useState(false)
    const [adrese, setAdrese] = useState(false)

    useEffect(()=>setLoaded(true), [])

    useEffect(()=>{
        let adr = localStorage.getItem("adrese") 
        adr = adr === "true" ? true : false
        setAdrese(adr)
    }, [loaded])
   
    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                {   dosar 
                                    && dosar.anexe 
                                    && dosar.anexe.alegerePreemptor 
                                    && dosar.anexe.alegerePreemptor.titular  
                                    && dosar.anexe.alegerePreemptor.titular.name 
                                    && (
                                        <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                        <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                            <Grid item sm={2} xs={4}>
                                                <FunkyInput
                                                    value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                    onChange={anexaInputHandler('nr')} />
                                            </Grid>
                                            <Grid item sm={4} xs={6}>
                                                <FunkyInput
                                                    value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                    onChange={anexaInputHandler('dt')}
                                                />
                                            </Grid>
    
                                        </Grid>
                                    </RichCard>
                                    )
                                }
                              
                            </Grid>
                          
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action = {
                                <Button variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                    <MdPrint size = "1.6em"/>
                                </Button>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar 
                                    && dosar.anexe 
                                    && dosar.anexe.alegerePreemptor 
                                    && dosar.anexe.alegerePreemptor.titular  
                                    && dosar.anexe.alegerePreemptor.titular.name 
                                    ? (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className="printable"
                                    ref={printRef} >
                                     <Grid item xs={12}>
                                        <ANTET_INSTITUTIE data = {dosar.company}/>
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD-MM-YYYY")}
                                    </Grid>
                                    <Grid item xs={12} align = "left">
                                        <br/>
                                       Către:
                                       <strong>Direcția pentru Agricultură și Dezvoltare Rurală a Județului {'  '}
                                           {dosar && dosar.company.jud}
                                       </strong>
                                    </Grid>
                                    <Grid item xs={12} align = "center">
                                        <br/><br/><br/>
                                        <strong>
                                        ADRESĂ DE ÎNAINTARE
                                        <br/>
                                        A DECIZIEI DE ALEGERE A PREEMPTORULUI POTENȚIAL CUMPĂRĂTOR<br/>
                                        în dosarul 
                                        nr. {dosar.nr} din {moment(dosar.dt).format('DD.MM.YYYY')}
                                        </strong>
                                        <br/><br/><br/><br/>
                                    </Grid>
                                    <Grid item xs={12}  className = {classes.text}>
                                        Subscrisa, <UAT data = {dosar.company} />, 
                                        referitor la {' '}
                                        oferta de vânzare teren extravilan{' '} înregistrată la sediul nostru
                                        sub nr. <Field>{dosar.nr} / {moment(dosar.dt).format('DD.MM.YYYY')} </Field> de către {' '}
                                        {/* <PF_PJ data = {dosar.ofertanti} noPrefix ={true}/> */}
                                        { dosar.ofertanti.map( (e,i) => (
                                            <Field key = {i}>
                                                {e.titular.name}, {' '}
                                            </Field>
                                        ))}
                                        vă transmitem alăturat decizia de alegere a preemptorului potențial cumpărător (Anexa 1G),
                                        {' '} nr. 
                                        <Field>{dosar.anexe.anexa1g.nr }</Field> / {' '}
                                        <Field>{moment(dosar.anexe.anexa1g.dt).format('DD.MM.YYYY') }</Field>, {' '}
                                        (preemptor ales <Field>{dosar.anexe.alegerePreemptor.titular.name }</Field>), {' '}
                                        precum și procesul-verbal de constatare a derulării etapelor procedurale, prevâzute de legea 17/2014.
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={12}  align = "left"> 
                                    
                                        <Field>{dosar.company.loc}, la {moment(dosar.anexe[cod]['dt']).format('DD.MM.YYYY')}</Field>
                                    </Grid>     
                        
                                    {!adrese && (<Fragment>
                                            <Grid item sm={6} style={{ paddingTop: "10px" }} />
                                            <Grid item sm={6}>
                                                Întocmit, <br />
                                                <Field>{getSetting(dosar.settings, "operator")}</Field>
                                                <br />
                                                .......................................................<br />
                                                <small>(numele şi prenumele/semnătura)</small><br />
                                                L.S.
                                            </Grid>
                                        </Fragment>


                                        )}
                                        {adrese && (
                                            <Grid item sm={12} style={{ paddingTop: "2cm" }}>
                                                <Grid container alignItems="flex-start" justifyContent='space-between' >
                                                    <Grid item sm={6} align="center">
                                                        Primar,<br />
                                                        <Field>{getSetting(dosar.settings, "primar")}</Field>
                                                        <br />
                                                        <strong>{getSetting(dosar.settings, "primar", "name")}</strong>

                                                        .......................................................<br />
                                                        <small>(numele şi prenumele/semnătura)</small><br />
                                                        L.S.
                                                    </Grid>
                                                    <Grid item sm={6} align="center">
                                                        Întocmit, <br />
                                                        <Field>{getSetting(dosar.settings, "operator")}</Field>
                                                        <br />
                                                        .......................................................<br />
                                                        <small>(numele şi prenumele/semnătura)</small><br />
                                                        L.S.
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}                               
                                    
                                
                            </Grid>
                             )
                            : (<div align = "center">
                            <Typography variant = "h2" style = {{color:"LightSteelBlue"}}>întrucât nu a fost ales nici un preemptor potențial cumpărător, generarea acestui document este imposibilă</Typography>
                            </div>
                        )}
                            </div>
                            </RichCard>
                        
                    </Grid>
                </Grid>
            )}
        </Fragment>

    )
}

