import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import FunkyInput from '../../components/FunkyInput'
import {useMutation} from "@apollo/client"
import {CONTACT_MUTATION} from '../../apollo/mutation'
import {clearTypeName} from '../../lib/graph-stuff'
import {getInputFieldsProps} from './getFields'
import FormPf from './FormPf'
import FormPj from './FormPj'
import { FaTimesCircle } from 'react-icons/fa'


const useStyles = makeStyles(theme => ({
    dialogPaper: { minHeight: "30vh", minWidth: "85vw"}
}))

const FIELDS = getInputFieldsProps

export default function ContactModal(props) {
    const classes = useStyles()
    const [item,setItem] = useState()
    const [mutateCustomer] = useMutation(CONTACT_MUTATION)

    useEffect(() => {
        setItem(props.data)
        // console.log("item: ", props.data)
    }, [props])

    const closeHandler = () => props.onClose()

    const submitHandler = () => {
        console.log("on submit: ", item)
        mutateCustomer({variables: {
            data: clearTypeName( {...item} )
        }}).then( 
            r => {  props.onClose(r.data.contact)},
            err => {console.log("err:", err);props.onClose() }
        )
        
    }
    const changeHandler = ev => {
        console.log("cng item:", ev)
        setItem(ev)
    }

    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler} classes = {{paper: classes.dialogPaper}}>
           
            <DialogTitle>
            <Grid container justifyContent="space-between">
                    <strong>ADMINISTRARE PERSOANĂ</strong>
                    <IconButton onClick = {closeHandler}><FaTimesCircle/></IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
               
               {item && (!item.cui || typeof item.cui === "undefined")
                && <FormPf  data = {{...item}} fields = {FIELDS.filter( el =>el.tab !== "pj" )} onChange = {changeHandler} />}
                {item && item.cui 
                && <FormPj  data = {{...item }} fields = {FIELDS.filter( el =>el.tab === "pj" )} onChange = {changeHandler} />} 
                {/* {item && <pre>{JSON.stringify(item, null,4)}</pre>} */}
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "secondary" onClick = {closeHandler}>
                    RENUNȚĂ
                </Button>
                <Button variant = "contained" color = "primary" onClick ={submitHandler}>
                    CONFIRMĂ
                </Button>
            </DialogActions>

        </Dialog>
    )
}
