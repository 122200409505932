import { AppBar, Box, Button, Container, Fab, Grid, MenuItem, Toolbar } from '@material-ui/core'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Context } from "../../components/Store"
import storage from '../../lib/storage'
import { FaPowerOff, FaRegFolderOpen, FaRegThumbsUp } from "react-icons/fa"
import React from 'react'
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/client'
import { useHistory } from 'react-router'
import FunkyInput from "../../components/FunkyInput"
import { Alert } from '@material-ui/lab'
import { DADR_LISTA_PRIMARII, DADR_REGISTRU_PRIMARIE } from '../../apollo/query'
import moment from "moment"
import ReactTable7 from "../../components/ReactTable7"
import { deepClearTypeName } from '../../lib/graph-stuff'
import { endDate } from '../../lib/zileLucratoare'
import AvizModal from './AvizModal'
import DosarModal from './DosarModal'

export default function Dadr(props) {

  const [state, dispatch] = useContext(Context)
  const client = useApolloClient()
  const history = useHistory()
  const [filter, setFilter] = useState({companyId:0, year:+moment().format("YYYY")})
  const [dosare, setDosare] = useState([])
  const [dosarModal, setDosarModal] = useState()
  const [avizModal, setAvizModal] = useState()

  const {data, loading, error} = useQuery(DADR_LISTA_PRIMARII)

  const [getReg, dispatchReg] = useLazyQuery(DADR_REGISTRU_PRIMARIE) 

  const REGDATA = (dispatchReg && dispatchReg.data) || []

  useEffect(() => {
    if ( dispatchReg.data ) {
      let d = dispatchReg.data.dadrRegistruPrimarie
      d = deepClearTypeName(d)
      d = d.reduce( (acc,el) => {
        const date45 = moment(endDate(el.dt, 45))
        return [
          ...acc,
          {
            ...el,
            dtHuman: moment(el.dt).format("DD.MM.YYYY"),
            ofertantiHuman: el.ofertanti.reduce( (acc,e,i) =>{
              acc  += " " + e.titular.name
              if (i < el.ofertanti.length-1) {acc +=", "}
              return acc
            }, ""),
            preemptoriHuman: el.preemptori.length,
            anexe: JSON.parse(el.anexe),
            end45: date45.clone().format('DD.MM.YYYY'),
            sort45: date45.clone().format('DD.MM.YYYY'),
            end75: date45.clone().add(30, "days").format('DD.MM.YYYY'),
            sort75: date45.clone().add(30, "days").format('DD.MM.YYYY'),
            remainingDays: date45.clone().diff(moment(), "days"),
            remainingDays75: date45.clone().add(30, "days").clone().diff(moment(), "days"),
          }
        ]
      }, [])
      setDosare(d)
    }
  }, [REGDATA])

  const selectHandler = name => ev => setFilter({...filter, [name]: +ev.target.value})

  
  const submitFilterHandler = () => {
    getReg({variables: {
      data: {
        year: filter.year,
        companyId: filter.companyId
      },
      
    },
    fetchPolicy: "no-cache" 
  })
  }

  const avizModalCloseHandler = ev => {
    if (ev) {
      //do something...
    }
    setAvizModal(null)
  } 

  return (
    <Container maxWidth="lg">
      <AppBar>
        <Toolbar variant="dense">

          <Grid container alignItems="center" justifyContent='space-between'>
            <div>
            <strong>terenuri.agricole.ro</strong> - SECTIUNE PRIVATĂ DADR
            </div>
            <Button color="inherit"
              endIcon={<FaPowerOff />}
              onClick={
                () => {
                  storage.remove()
                  client.cache.reset()
                  dispatch({});
                  history.push("/login")
                }}
              style={{ marginRight: "8px" }}
            >
              {state && (
                <div style={{ lineHeight: 0.5, textAlign: "left" }}>
                  <small> {state.name} (utilizator: {state.username}) </small><br /><br />
                  <small>{state.company.name}</small>
                </div>
              )}

            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" style={{ marginTop: "50px" }}>
        <Alert severity="info">
        facilitate disponibilă exclusiv în relația cu abonații serviciului online 
        pentru procesarea dosarelor de vânzare terenuri extravilan - <span style={{color:"darkRed", fontWeight:800}}>terenuri.agricole.ro</span>
        </Alert>
        <br />
        <Grid container alignItems = "center" spacing={1}>
          <Grid item sm={1}>
            Primăria 
          </Grid>
          <Grid item sm = {5}>
            {data && (
            <FunkyInput value={filter.companyId} select placeholder ="alegeti o UAT" label = "UAT" onChange={selectHandler('companyId')}>
                {data.dadrListaPrimarii.map( (e,i) => (
                  <MenuItem key={i} value = {e.id}>{e.name}</MenuItem>
                ))}
            </FunkyInput>
            )}

          </Grid>
          <Grid item sm = {2}>
            <FunkyInput value={filter.year} select  label = "ANUL" onChange={selectHandler("year")}>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
            </FunkyInput>
          </Grid>
          <Grid item sm = {2}>
                <Button variant="contained" color="primary" onClick ={submitFilterHandler}>
                    <small>ARATĂ DOSARELE</small>
                </Button>
          </Grid>
          <Grid item sm ={true} align="right">
            {dosare.length > 0 && <Fab size="small" color="secondary">{dosare.length}</Fab>}
          </Grid>
        </Grid>
        <Box style = {{border: "1px solid lightgrey", margin:4, padding: 10, height: "77vh"}}>
        {/* {filter && <pre>{JSON.stringify(filter, null, 3)}</pre>} */}
           {dispatchReg && dispatchReg.data &&(
            <Fragment>
              <ReactTable7 
                  defaultPageSize = {10}
                  columns = {[
                    // {id: "id", Header: "#", Cell: ({row})=>row.index},
                    {accessor: "nr", Header: "NR", width: "7%", Cell: ({row})=><div align="center">{row.original.nr}</div>},
                    {accessor: "dtHuman", Header: "DIN DATA", width: "10%",  Cell: ({row})=><div align="center">{row.original.dtHuman}</div>},
                    {accessor: "ofertantiHuman", Header: "VANZATORI", width: "40%"},
                    // {id: "nrAcceptari", Header: "Nr.oferte", Cell: ({row})=>row.original.anexe.acceptari.length,  width: "5%"},
                    {
                      Header: <div align="center">45 zile<br />lucrătoare</div>,
                      accessor: "sort45",
                      width: "10%", align: "center",
                      Cell: ({row}) => <div align="center">{row.original.end45}</div>,
                  },
                  {
                      Header: <div align="center">Scadență<br />etapa I</div>,
                      accessor: "remainingDays",
                      align: "center",
                      width: "7%",
                      Cell: ({row}) => <div align="center">{row.original.remainingDays}</div>,
                  },
                  {
                    Header: <div>Se împlinesc<br />45 zl + 30 zc</div>,
                      accessor: "sort75",
                      Cell: ({row}) => <div align="center">{row.original.end75}</div>,
                      width: "5%", align: "center"
                  },
                  {
                    Header: <div>Zile<br />rămase</div>,
                      accessor: "remainingDays75",
                      Cell: ({row}) => <div align="center">{row.original.remainingDays75}</div>,
                      width: "7%", align: "center"
                  },
                  {
                    Header: <div style = {{color: "blue"}}>Consultare<br/>dosar<br/>electronic</div>,
                    id: "dosar",
                    Cell: ({row}) => <div align="center">
                      <Button 
                        size="small" startIcon={<FaRegFolderOpen/>}
                        onClick = {()=>setDosarModal(row.original)}
                      />
                      
                    </div>,
                    width: "10%"
                  },
                  {
                    Header: <div style = {{color: "red"}}>Consultare/Emitere<br/>aviz final<br/>DADR</div>,
                    id: "aviz",
                    Cell: ({row}) => <div align="center">
                      <Button 
                        size="small" 
                        startIcon={<FaRegThumbsUp color="grey"/>}
                        onClick = {()=>setAvizModal({index: row.index, item: row.original})}
                      />

                      
                    </div>,
                    width: "10%"
                  }

                  ]}
                  data = {dosare}
              />
              {/* {console.log("dosare:", dosare)} */}

               {/* <pre>{JSON.stringify(dosare, null, 4)}</pre> */}
               
             </Fragment>
          )}
        
        {/* {data && <pre>{JSON.stringify(data, null, 3)}</pre>} */}
        {dispatchReg && dispatchReg.error && <Alert severity='error'><pre>{JSON.stringify(dispatchReg.error,null,2)}</pre></Alert>}
        {dispatchReg && dispatchReg.loading && <Alert severity="warning" icon={false}><h3>vă rugăm să așteptați, se încarcă datele</h3></Alert>}
        {error}
        {avizModal && <AvizModal data = {avizModal} onClose = {avizModalCloseHandler}/>}
        {dosarModal && <DosarModal data = {dosarModal} onClose = {()=>setDosarModal(null)}/>}
        </Box>
      </Container>
    </Container>

  )
}
