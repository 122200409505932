import { Grid } from '@material-ui/core'
import React, {Fragment, useState, useEffect} from 'react'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'

export default function DateContract(props) {

    const item = props.data

    const inputHandler = name => ev => {
        
        let ni = {...item}
        
        props.onChange({...ni, [name]: ev.target.value})
    }

    const dtHandler = name => ev => props.onChange({...item, [name]: moment(ev.target.value).toDate() })

    return (
        <Fragment>
            {item && (
                <Grid container spacing = {1}>
            <Grid item xs={2}>
                <FunkyInput value={item.nr} label="NR." type = "number" step = "1"
                    onChange={inputHandler('nr')} />
            </Grid>
            <Grid item xs={3}>
                <FunkyInput value={moment(item.dt).format('YYYY-MM-DD')} label="DIN DATA" type="date"
                    onChange={dtHandler('dt')} />
            </Grid>
            <Grid item xs = {1}/>
            <Grid item xs={3}>
                <FunkyInput type="date" value={moment(item.from).format('YYYY-MM-DD')} label=" VALABIL DE LA"
                    onChange={dtHandler('from')} />
            </Grid>
            <Grid item xs={3}>
                <FunkyInput type="date" value={moment(item.to).format('YYYY-MM-DD')} label="PÂMĂ LA"
                    onChange={dtHandler('to')} />
            </Grid>
            <Grid item xs={10}>
                <FunkyInput value={item.price} label="PREȚ"
                    onChange={inputHandler('price')} />
            </Grid>
            <Grid item xs={2}>
                <FunkyInput value={item.penalty} label="PENALITATE"
                    onChange={inputHandler('penalty')} />
            </Grid>
            <Grid item xs={12}>
                <FunkyInput value={item.desc} label="OBSERVAȚII" multiline minRows={3}
                    onChange={inputHandler('desc')} />
            </Grid>
        </Grid>
            )}
        </Fragment>
        )
}
