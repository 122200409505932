import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from '@material-ui/core'
import { isTypeNode } from 'graphql'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { MdPrint } from 'react-icons/md'
import { useReactToPrint } from 'react-to-print'
import TplAvizEtapa30 from './TplAvizEtapa30'
import TplAvizEtapa45 from './TplAvizEtapa45'
import TplAvizNegativ from './TplAvizNegativ'


const useStyles = makeStyles( theme => ({
    dialogPaper: {
        minWidth: "70vw", minHeight: "95vh"
    }
}))

export default function AvizPrintModal({data, onClose}) {
    const clases = useStyles()
    const printRef = useRef()
    const [item, setItem] = useState()
    useEffect(()=>{
        setItem(data)
    }, [])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

  return (
    <Dialog open = {Boolean(item)} onClose = {()=>onClose()} classes = {{paper: clases.dialogPaper}}>
        <DialogTitle>
            <Grid container alignItems = "center">
                <Grid item sm = {true}>
            PREVIZUALIZARE TIPĂRIRE
            </Grid>
            <Grid item sm = {2} align="right">
                <IconButton onClick = {()=>onClose()}>
                    <FaTimesCircle size = "2em" color="lightgrey"/>
                </IconButton>

            </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <div ref = {printRef} style= {{margin: "5mm 5mm 5mm 20mm"}}>
                
            {item && (
                <Fragment>
                    {item.tip === "etapa45" && item.aviz && <TplAvizEtapa45 data = {item}/>}
                    {item.tip === "etapa30" && item.aviz && <TplAvizEtapa30 data = {item}/>}
                    {Boolean(item.tip)===true && !item.aviz && <TplAvizNegativ data = {item}/>}
                    {/* <pre>{JSON.stringify(item, null, 4)}</pre> */}
                </Fragment>
            )}
            </div>
           
        </DialogContent>
        <DialogActions>
            <Button 
                variant = "outlined"
                onClick = {printHandler}
                startIcon={<MdPrint size = "2em" color="green"/>}

            >
               TIPĂRIRE
            </Button>
        </DialogActions>
    </Dialog>
  )
}
