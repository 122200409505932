import React, { Fragment, useEffect, useState } from 'react'
import gql from 'graphql-tag'
import {useQuery, useMutation} from '@apollo/client'
import {SETTINGS_UPSERT} from '../../../apollo/mutation'
import RichCard from '../../../components/RichCard'
import { Box, Button, Switch, FormControlLabel, Grid, IconButton, Typography, makeStyles, Checkbox } from '@material-ui/core'
import FunkyInput from '../../../components/FunkyInput'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'
import { clearTypeName, deepClearTypeName } from '../../../lib/graph-stuff'
import {AddButton} from '../../../components/MyButtons'
import ImportContacts from './ImportContacts'
import Confirm from "../../../components/Confirm"
import { Alert } from '@material-ui/lab'
import { FaLaughWink } from "react-icons/fa";

const ALLOWED_OPTIONS = ["primar", "secretar", "operator", "atestate1", "atestate2", "licenta"]

const useStyles = makeStyles(theme => ({
    justify: {
        textAlign: "justify",
        textJustify: "inter-word"
    },
    printOnly: { "@media screen" : {display: "none"}},
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "0px solid lightgrey",
            fontSize: "0.7rem",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid white",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "crosshair",
            border: "2px solid navy",
        },
    },
    
}))

const SETTINGS = gql`query company{
    company { 
        id  
        settings {id prop value desc companyId}
    }
    
}`

const getSemnaturiProps = (settings) => settings.reduce( (acc,item) => {
    if ( ["primar", "viceprimar", "secretar", "operator"].includes(item.prop)) {
        acc.push(item)
    } 
    return acc
},[])


export default function SetariDiverse() {
    const classes = useStyles()
    const {data,loading,error} = useQuery(SETTINGS)
    const [settings, setSettings] = useState()
    const [mutateSetting, dispatchUpsert] = useMutation(SETTINGS_UPSERT)
    const [currentItem, setCurrentItem] = useState()
    const [confirm, setConfirm] = useState()
    const [adrese, setAdrese] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [zold, setZold] = useState(false)

    useEffect(()=>setLoaded(true), [])

    useEffect(() => {
        let adr = localStorage.getItem("adrese") ?? "false"
        adr = adr === "false" ? false : true
        setAdrese(adr)
    }, [loaded])

    const adreseHandler = ev => {
        setAdrese(ev.target.checked)
        localStorage.setItem("adrese", ev.target.checked )
    }

    useEffect(() => {
        if (data && data.company)  {
            const {company} = data 
            const s = company.settings || []

            let set = ALLOWED_OPTIONS.reduce( (acc,el, index) => {
                let isSetting = s.find( elem => elem.prop === el)
                
                if ( typeof isSetting !== "undefined" ) { 
                    acc = [
                        ...acc, 
                        isSetting
                    ]
                } else {
                    acc = [...acc, {companyId: company.id, prop: el, value:"", desc: ""}]
                }
                return acc
            }, [])
            setSettings(set)
        }
        
    }, [data])

    useEffect( () => {
        if (dispatchUpsert && dispatchUpsert.data && dispatchUpsert.data.settingsUpsert) {
            setSettings(deepClearTypeName(dispatchUpsert.data.settingsUpsert))
        }
    }, [dispatchUpsert && dispatchUpsert.data])


    const inputHandler = (name, index) => ev => {
        let ns = [...settings]
        ns = deepClearTypeName(ns)
        ns[index][name] = ev.target.value
        setSettings(ns)
    }

    const submitHandler = () => {
        console.log("settings:", settings)
        let res = deepClearTypeName(settings)
        mutateSetting({variables: {data: settings}})
    }



    const confirmRemoveCloseHandler = ev => {
        if (ev) {
            alert("sters!")
        } 
        setConfirm(null)
    }

    return (
        <div>
            {data && 
            <Fragment>
            <RichCard 
                title = "ADMINISTRARE SEMNATURI"
                style = {{background: "white"}}
                action = {
                    <Button size="small" color="primary" variant="contained" onClick={submitHandler}>
                        CONFIRMARE
                    </Button>
                }
               
            >
  
                <div style={{height: "70vh", overflow: "auto"}}>
                <table className={classes.table} cellSpacing={0} cellPadding={0} width="100%">
                    <thead>
                        <tr>
                            <th colSpan={1} align="center">PROPRIETATE</th>
                            <th colSpan={1}>VALOARE</th>
                            <th colSpan={1}>DESCRIERE</th>
                        </tr>
                    </thead>
                    <tbody>
                    {settings && settings.map((e,i) => (
                        <tr key={i}>
                            <td align="center" width = "15%">
                               {e.prop}
                            </td>
                            <td>
                                <FunkyInput 
                                    value={e.value} onChange = {inputHandler('value', i)}
                                    placeholder = "introduceti o valoare (obligatoriu)" required
                                    />
                            </td>
                            <td>
                                <FunkyInput 
                                    value={e.desc} onChange = {inputHandler('desc', i)}
                                    placeholder = "introduceti o descriere (optional)"
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <Box sx = {{m:1, p:1, border:"1px dashed grey"}}>
                    <Typography variant = "caption" color="primary" component="p">SETĂRI LOCALE</Typography>


                    <Alert severity='success'>
                        Setările locale au fost implementate pentru a satisface o serie de cerințe particulare ale unor clienți, care reprezintă "derogări" convenite de la modul standard de lucru recomandat de noi,
                        și decurg din interpretarea particulară a unor anumite aspecte legale și/sau a organizării interne diferite a respectivelor instituții.
                        Acestea NU sunt stocate în baza de date a aplicației ci în stocarea locală ("Local Storage") a browser-ului Dvs. 
                        <br/>
                        Dacă intenționați să utilizați aplicația și pe alt calculator, nu uitați să le re-configurați în prealabil.
                    </Alert>
                    <Box sx = {{p:1}}>
                    <FormControlLabel
                        label = {<Typography variant = "caption" color='primary' style={{fontWeight:800}}>Emitentul adreselor de înaintare este PRIMARUL</Typography>}
                        control={<Checkbox checked = {Boolean(adrese)} color="primary" onChange = {adreseHandler} inputProps={{ 'aria-label': 'controlled' }}/>}
                    />
                    <br/>
                    <FormControlLabel
                        label = {<Typography variant = "caption" style = {{color:"green", fontWeight:800}}>A fő szín zöld lesz</Typography>}
                        control={<Checkbox checked = {zold} style = {{color:"green"}} onChange = {ev=>setZold(ev.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}
                    />
                    <br/>
                    {zold && <Box><FaLaughWink size="4em" color="orange"/><br/>
                    <Typography variant = "subtitle2" color="primary">Just joking! The color stays Google Material Design <strong>BLUE</strong> !!!</Typography>
                    </Box>}
                    </Box>

                </Box>

            </div>
            </RichCard>

            {/* <RichCard title = "IMPORT CONTACTE" contentStyle = {{height: "30vh", overflow:"auto"}}>
                <ImportContacts />
            </RichCard> */}
            
            </Fragment>
            }
            
            { confirm && <Confirm open={confirm} onClose = {confirmRemoveCloseHandler} /> }
        </div>
    )
}
