import { Grid, Paper, makeStyles, Typography, ButtonGroup, Button } from '@material-ui/core';

import React, { useState, useEffect, Fragment } from 'react';
import { FaFileAlt, FaFilter } from 'react-icons/fa';
import Boxy from '../../../../components/Boxy';
import FunkyInput from '../../../../components/FunkyInput';
import moment from "moment"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    background: theme.palette.grey[200]
  }
}))

export default function Filters({ data, onApply, onReset }) {
  const classes = useStyles()
  const [items, setItems] = useState()

  useEffect(() => {
    setItems(data)
  }, [data])

    const dtChangeHandler = name => ev => {
      let p = {...items}
      p.between = {
        ...p.between,
        [name]: ev.target.value
      }
      setItems(p)
     
    }

  const orderHandler = dir => () => {
    const p = {...items}
    p.ordering[0]['value'] = dir
    setItems(p)
}

  const presetHandler = name => () => {
    const p = {...items}
    switch( name ) {
      case "currentYear": 
        p.between = {
            col:"dt",
            start: moment().startOf("year").format("YYYY-MM-DD"),
            end:  moment().endOf("year").format("YYYY-MM-DD")
        }
        break;
      case "prevYear" : 
        p.between = {
          col:"dt",
          start: moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
          end:  moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
        }
        break;
        case "curNonth" : 
        p.between = {
          col:"dt",
          start: moment().startOf("month").format("YYYY-MM-DD"),
          end:  moment().endOf("month").format("YYYY-MM-DD")
        }
        break;
    } 
    setItems(p)
  }

  return (
    <Fragment>
      {items && (
        <Paper className={classes.paper} icon={<FaFilter />} >
          
          <Typography variant="caption">FILTRARE REZULTATE</Typography>
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item  sm={5}>
              <Grid container spacing={1}>
                <Grid item xs = {4}>
                    <Typography variant="caption" component="p" color="primary">de la data</Typography>
                    <FunkyInput
                      value={items.between.start}
                      type="date"
                      onChange = {dtChangeHandler("start")}
                    />
                </Grid>
                <Grid item xs={4} >
                    <Typography variant="caption" component="p" color="primary">până la data</Typography>
                    <FunkyInput
                      value={items.between.end}
                      type="date"
                      onChange = {dtChangeHandler("end")}
                    />
              </Grid>
              <Grid item xs = {12}>
                <Button onClick = {presetHandler("prevYear")} variant = "contained" size ="small" variant="text">ANUL ANTERIOR</Button>
                <Button onClick = {presetHandler("currentYear")} variant = "contained" size ="small" variant="text">ANUL CURENT</Button>
                <Button onClick = {presetHandler("curNonth")} variant = "contained" size ="small" variant="text">LUNA ACEASTA</Button>
              </Grid>
            </Grid>
            </Grid>
           
            <Grid item xs={3} sm={3}>
              <Typography variant="caption" component="p" color="primary">Ordonare după dată atestat{items.ordering.value}</Typography>
              
              <ButtonGroup size="small">
                <Button onClick = {orderHandler("asc")}
                   variant = {items.ordering[0].value === "asc" ? "contained" : "outlined"}
                   color = {items.ordering[0].value === "asc" ? "primary" : "default"}
                >ASCENDENT</Button>
                <Button onClick = {orderHandler("desc")}
                  variant = {items.ordering[0].value === "desc" ? "contained" : "outlined"}
                  color = {items.ordering[0].value === "desc" ? "primary" : "default"}
                >DESCENDENT</Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={3} sm={3}>
              {/* <ButtonGroup size = "large"> */}
              <Button variant = "contained"  color = "secondary"  size="large" onClick = {()=>onReset(true)}>RESETEAZĂ</Button>
              {' '}
              <Button variant = "contained"  color = "primary"  size="large" onClick = {()=>onApply(items)}>FILTREAZĂ</Button>
              {/* </ButtonGroup> */}
            </Grid>
          </Grid>
        </Paper>

      )}
</Fragment>
      )
    
}
