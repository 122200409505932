import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Fragment } from 'react'
import ANTET_CERERE from '../../Common/ANTET_CERERE'
import PF_PJ from '../../Common/PF_PJ'
import Field from '../../Common/Field'
import inLitere from '../../../../lib/inLitere'
import moment from 'moment'
import Antet2022 from '../../Common/Antet2022'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },

}))

export default function Anexa1Etlp1E(props) {

    const data = props.data || {}
    const classes = useStyles()
    return (
        <div className={classes.text}>
            <div align="right">
                ANEXA Nr. 5 (Anexa nr. 1E la normele metodologice)
            </div>
            <br />
            <div align="center">
                <strong>COMUNICARE DE ACCEPTARE A OFERTEI DE VÂNZARE</strong>
            </div>
            <br />

            {data && (
                <Fragment>
                    <Antet2022 data={data.dosar} />
                    <br />
                    <div align="right">
                        Nr.înreg. {data.nr} / {moment(data.dt).format("DD.MM.YYYY")}
                    </div>

                    <p>Stimată doamnă/Stimate domnule primar,</p>
                    <PF_PJ data={[{ ...data }]} acceptare={data} />
                    <p>

                        prin prezenta îmi exprim intenţia fermă de cumpărare şi accept oferta de vânzare pentru terenul agricol extravilan
                        în suprafaţă de  <Field>{data.dosar.anexe.terenHa}</Field> ha, {' '}
                        reprezentând cota-parte <Field>{data.dosar.anexe.cotaParte}</Field>, {' '}
                        identificat cu număr cadastral <Field>{data.dosar.anexe.terenNrCadastral}</Field>,{' '},
                        înscris în cartea funciară nr. <Field>{data.dosar.anexe.terenCf}</Field>,{' '},
                        a localităţii  <Field>{data.dosar.anexe.terenCfLoc}</Field>,{' '},
                        afişată în data de  <Field>{moment(data.dosar.dt).format('DD.MM.YYYY')}</Field>,{' '}
                        la sediul Primăriei <Field>{data.company.loc}</Field>.
                    </p>
                    <p>
                        Preţul oferit pentru cumpărare este de <sup>(*)</sup>{' '}
                        <Field>{data.pret}</Field> <strong>lei.</strong> {'  '}
                        (<Field>{inLitere(data.pret || 0)}</Field>)
                        (Preţul se va scrie în cifre şi litere.)
                    </p>
                    <div>
                        În susţinerea comunicării de acceptare şi a calităţii de preemptor, depun următoarele acte doveditoare:
                        <pre style={{ fontFamily: "Arial" }}>{data.documente}</pre>
                    </div>
                    Îmi exprim acordul cu privire la utilizarea şi prelucrarea datelor cu caracter personal şi la introducerea datelor şi
                    informaţiilor din cerere şi documentele anexate în bazele de date care se organizează în temeiul Legii nr. 17/2014
                    privind unele măsuri de reglementare a vânzării terenurilor agricole situate în extravilan şi de modificare a Legii
                    nr. 268/2001 privind privatizarea societăţilor ce deţin în administrare terenuri proprietate publică şi privată a
                    statului cu destinaţie agricolă şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările şi completările
                    ulterioare, şi al legislaţiei subsecvente, cu respectarea prevederilor Regulamentului (UE) 2016/679 al
                    Parlamentului European şi al Consiliului din 27 aprilie 2016 privind protecţia persoanelor fizice în
                    ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date
                    şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor) şi ale Legii nr.
                    190/2018 privind măsuri de punere în aplicare a Regulamentului (UE) 2016/679 al Parlamentului European
                    şi al Consiliului din 27 aprilie 2016 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea
                    datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare a Directivei
                    95/46/CE (Regulamentul general privind protecţia datelor), cu modificările ulterioare.
                    <br />
                    Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu
                    modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                    <p>
                        Preemptor/Împuternicit,
                    </p>
                    <p>
                        <Field>{data.titular.name}</Field>
                        <br /><small>(numele şi prenumele în clar)</small>
                    </p>
                    {data.mandatar && data.mandatar.name && (
                        <p> prin <Field>{data.mandatar.name}</Field></p>
                    )}

                    <p>..........................................................</p>
                    <p> Data <Field>{moment(data.dt).format('DD.MM.YYYY')}</Field></p>

                    <p>

                        NOTE:<br />
                        - Câmpurile notate cu (*) sunt obligatoriu de completat.<br />
                        - Câmpurile notate cu (**) se completează de către cetăţenii unui stat membru al
                        Uniunii Europene, ai statelor care sunt parte la Acordul privind Spaţiul Economic
                        European (ASEE) sau ai Confederaţiei Elveţiene.<br />
                        - Câmpurile notate cu (***) se completează dacă este cazul.<br />

                    </p>
                </Fragment>

            )}


        </div>
    )
}
