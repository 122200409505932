import React, { useState, useEffect, Fragment } from 'react'
import {useHistory} from 'react-router-dom'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import FunkyInput from '../../components/FunkyInput'
import AUTOCOMPLETE_CONTACT from '../Dosar/Common/AUTCOMPLETE_CONTACT'
import RichCard from '../../components/RichCard'
import { MdHighlightOff } from 'react-icons/md'
import { FILTER_ARENDA } from '../../apollo/queryArenda'
import ReactTable7 from '../../components/ReactTable7'
import moment from 'moment'



const useStyles = makeStyles(theme=>({
    dialogPaper: {minWidth: "80vw", minHeight: "95vh"}
}))
export default function Filters(props) {
    const classes = useStyles()
    const [filters, setFilters] = useState({})
    const [res, setRes] = useState([])
    const [data, setData] = useState()
    const [results, setResults] = useState([])
    const history = useHistory()

    const  [queryFilter, dispatch] = useLazyQuery(FILTER_ARENDA)

    useEffect(() => {
        setData(props.data)
    }, [props])

    useEffect(() => {
        queryFilter({variables: {data: {
            parcela: filters.parcela !== "" ? filters.parcela : null,
            tarla: filters.tarla !== "" ? filters.tarla : null,
            nrCf: filters.nrCf !== "" ? filters.nrCf : null,
            nrCadastral: filters.nrCadastral !== "" ? filters.nrCadastral : null,
            arendatorId: filters.arendator && filters.arendator.id,
            arendasId: filters.arendas && filters.arendas.id,
            beginning: (filters.start && filters.end) ? {start: filters.start, end: filters.end} : null
        }}})
    }, [filters])

    const closeHandler = item => ev => {
        props.onClose(item||null)
    }

    const contactHandler = name => ev => {
        setFilters({ ...filters, [name]: ev })
    }

    const dtHandler = name => ev => setFilters({
        ...filters, 
        [name]: moment(ev.target.value).toDate()
    })

    const resetFiltersHandler = () => {
        setFilters({})
    }

    const rowClickHandler = ev => {
        props.onClose()
        history.push(`/registru-arendare/detalii/${ev.id}`)
    }

    const inputHandler = name => ev => setFilters({ ...filters, [name]: ev.target.value })
    return (
        <Dialog open={Boolean(data)} onClose={closeHandler()} classes = {{paper: classes.dialogPaper}}>

            <DialogTitle>
                <Grid container justifyContent="space-between">
                    <div>APLICĂ FILTRE</div>
                    <IconButton size="small" onClick={closeHandler()}><MdHighlightOff size = "2rem"/></IconButton>
                </Grid></DialogTitle>
            <DialogContent>
                {data && (
                    <Grid container spacing={1} alignItems = "center" justifyContent= "flex-start">
                        <Grid item xs = {12}>
                            <small>Afișează contractele conținând unul sau mai multe dintre următoarele criterii</small>
                        </Grid>
                        <Grid item xs={6}>
                            <AUTOCOMPLETE_CONTACT label="ARENDAȘ"
                                data={filters.arendas || ""}
                                onChange={contactHandler("arendas")} 
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AUTOCOMPLETE_CONTACT label="ARENDATOR"
                                data={filters.arendator || ""}
                                onChange={contactHandler("arendator")} 
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FunkyInput value={filters.tarla} label="TARLA" onChange={inputHandler("tarla")} />
                        </Grid>
                        <Grid item xs={3}>
                            <FunkyInput value={filters.parcela} label="PARCELA" onChange={inputHandler("parcela")} />
                        </Grid>
                        <Grid item xs={3}>
                            <FunkyInput value={filters.nrCf} label="NR.CF" onChange={inputHandler("nrCf")} />
                        </Grid>
                        <Grid item xs={3}>
                            <FunkyInput value={filters.nrCadastral} label="NR.CADASTRAL" onChange={inputHandler("nrCadastral")} />
                        </Grid>
                        <Grid item xs = {2}>
                            <small>încheiate în perioada </small>
                        </Grid>
                        <Grid item xs = {2}>
                            <FunkyInput 
                            value = {filters.start && moment(filters.start).format('YYYY-MM-DD') } 
                            type = "date" onChange = {dtHandler('start')}/>
                        </Grid>
                        <Grid item xs = {2}>
                            <FunkyInput 
                                value = {filters.end && moment(filters.end).format('YYYY-MM-DD') } 
                                type = "date" onChange = {dtHandler('end')}/>
                        </Grid>
                        
                        <Grid item xs={12}>
                           
                                <div style={{ height: "60vh", overflow: "visible", }}>
                                  
                                    {dispatch.data ? (
                                        <Fragment>
                                        <ReactTable7
                                            
                                            disablePagination = {true}
                                            clickedRow = {rowClickHandler}
                                            size = {5}
                                            data = {dispatch.data.arendaFilter.map( el => ({
                                                ...el,
                                                nr_data: el.nr + " / " + moment(el.dt).format('DD.MM.YYYY'),
                                                ardt: el.arendaArendator.reduce( (acc,item) => acc + item.contact.name + " ", ''),
                                                ards: el.arendaArendas.reduce( (acc,item) => acc + item.contact.name + " ", ''),
                                            }))}
                                            columns = {[
                                                { Header: "#", id: "id", Cell: ({row})=> row.id, width: 5 },
                                                { Header: "CONTRACT", accessor: "nr_data", width: 20},
                                                { Header: "ARENDAS", accessor: "ards"},
                                                { Header: "ARENDATORI", accessor: "ardt"},

                                            ]}
                                        />
                                        {/* {dispatch.data.arendaFilter.length} */}
                                       
                                        </Fragment>
                                    )
                                    : (<Typography variant = "caption" color = "secondary">
                                            NICI UN REZULTAT
                                    </Typography>)
                                    }
                                    {/* <pre>{JSON.stringify(filters, null,1)}</pre>`` */}
                                </div>
                                
                        
                        </Grid>
                    </Grid>
                )}

            </DialogContent>
            <DialogActions>
                <Grid container justifyContent= "space-between">
                    <Button onClick = {resetFiltersHandler}>RESETEAZĂ</Button>
                    <Grid item>
                    <Button onClick={closeHandler(filters)}> APLICĂ</Button>
                <Button onClick={closeHandler()}>ÎNCHIDE    </Button>
                    </Grid>
                </Grid>

                {/* <Button></Button> */}
            </DialogActions>

        </Dialog>
    )
}
