import { makeStyles, Grid, Button, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import RichCard from '../../../components/RichCard'
import { useReactToPrint } from 'react-to-print'
import { Fragment } from 'react'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import FunkyInput from '../../../components/FunkyInput'
import UAT from '../Common/UAT'
import PF_PJ from '../Common/PF_PJ'
import Field from '../Common/Field'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign: "center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem", fontWeight: 700,
            textAlign: "center",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        },
        "&& tbody>tr:hover" : {background: theme.palette.grey[400]}
    },
    selected: {
        background: "navy", color: "yellow",
        
    }
}))

const cod = "anexa1g"

export default function Anexa1GDecizieVanzator(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState(null)
    const [currentItem, setCurrentItem] = useState()


    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });
    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const acceptHandler = el =>  () => {
        let d = {...dosar}
        d.anexe.alegerePreemptor = d.anexe.alegerePreemptor && d.anexe.alegerePreemptor.id ===  el.id ? null : el
        props.onChange(d)
    }

    return (
        <Fragment>
            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
                    {/* {console.log("dosar: ", dosar)} */}
                    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                        <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                            <Grid item sm={2} xs={4}>
                                                <FunkyInput
                                                    value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                    onChange={anexaInputHandler('nr')} />
                                            </Grid>
                                            <Grid item sm={4} xs={6}>
                                                <FunkyInput
                                                    value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                    onChange={anexaInputHandler('dt')}
                                                />
                                            </Grid>

                                        </Grid>
                                    </RichCard>
                                <RichCard title="COMUNICĂRI DE ACCEPTARE"
                                >
                                    {dosar && dosar.anexe && (
                                        <Fragment>
                                            <Alert severity="info">
                                                <small>Faceți click pe comunicarea de acceptare aleasă de vânzător/ofertant</small>
                                            </Alert>
                                            <br />
                                            <table className={classes.table}>
                                                <thead>
                                                    <tr >
                                                        <th>Nr.crt</th>
                                                        <th> Preemptorul<br />acceptant </th>
                                                        <th> Nr și data<br />comunicării de acceptare </th>
                                                        <th> Prețul propus </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dosar.anexe.acceptari.map((e, i) => (
                                                        <tr 
                                                            key={i} 
                                                            onClick = {acceptHandler(e)}
                                                            className = {
                                                                (dosar.anexe.alegerePreemptor  && e.id === dosar.anexe.alegerePreemptor.id) ? classes.selected : null 
                                                            }

                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{e.titular.name}</td>
                                                            <td>{e.nr} / {moment(e.dt).format('DD.MM.YYYY')}</td>
                                                            <td>{e.pret} </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Fragment>
                                    )}

                                </RichCard>
                            </Grid>
                        </Grid>
                    </Grid>
{/* print                     */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE"
                            action={
                                <Fragment>
                                    {dosar && dosar.anexe && dosar.anexe.alegerePreemptor && (
                                        <Fragment>
                                            <Button variant="contained" color="primary" size="small" onClick={printHandler}>TIPĂRIRE</Button>
                                        </Fragment>
                                    )}

                                </Fragment>

                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar 
                                    && dosar.anexe 
                                    && dosar.anexe.alegerePreemptor 
                                    && dosar.anexe.alegerePreemptor.titular  
                                    && dosar.anexe.alegerePreemptor.titular.name 
                                    ? (
                                        <Grid
                                            container alignItems="flex-start"
                                            style={{ border: "0px solid red" }}
                                            className={classes.text}
                                            ref={printRef} >
                                            <Grid item xs={12} align="right">
                                                ANEXA Nr. 7 (Anexa nr. 1G la normele metodologice)
                                                <br /><br />
                                            </Grid>
                                            <Grid item xs = {12} align='center'>
                                                <strong>
                                                DECIZIA<br/>
                                                {dosar.tipDosar === "pf" 
                                                    ? "persoanei fizice"
                                                    : "persoanei juridice"
                                                } {' '}
                                                 privind alegerea preemptorului/potenţialului cumpărător
                                                </strong>
                                            </Grid>
                                            <Grid item xs = {12}>
                                                <br/><br/>
                                                <PF_PJ data = {dosar.ofertanti} />
                                                în calitate de vânzător al terenului agricol situat în extravilanul localității{' '}
                                                <Field>{dosar.anexe.terenLoc}</Field>,{' '}
                                                în suprafaţă de <Field>{dosar.anexe.terenHa}</Field>(ha),{' '}, 
                                                reprezentând cota-parte <Field>{dosar.anexe.cotaParte}</Field>(cotaParte),{' '}, 
                                                
                                                nr. cadastral <Field>{dosar.anexe.terenNrCadastral}</Field>{' '}, 
                                                la preţul de (*) <Field>{dosar.anexe.terenPret}</Field>{' '} (lei), declar pe propria răspundere că accept comunicarea de acceptare a ofertei de vânzare/oferta de cumpărare 
                                                nr. <Field>{dosar.anexe['alegerePreemptor'].nr}</Field> / {' '}
                                                <Field>{moment(dosar.anexe['alegerePreemptor']['dt']).format('DD.MM.YYYY')}</Field>, {' '}
                                                depusă de  <Field>{dosar.anexe.alegerePreemptor.titular.name}</Field>, {' '}
                                                 prin care a fost acceptată oferta de vânzare nr. 
                                                 <Field>{dosar.anexe['anexa1b'].nr}</Field> / 
                                                 <Field>{moment(dosar.anexe['anexa1b'].dt).format('DD.MM.YYYY')}</Field>.
                                                 <br/>
                                                Cunoscând că falsul în declaraţii se pedepseşte conform Legii 
                                                nr. 286/2009 privind Codul penal, cu modificările şi completările 
                                                ulterioare, declar că datele sunt reale, corecte şi complete.
                                                <br/><br/>
                                                <SEMNATURI_VANZATORI data = {dosar.ofertanti} />
                                                <br/>
                                                <br/>

                                                Data <Field>{moment(dosar.anexe[cod]['dt']).format('DD.MM.YYYY')}</Field>
                                                <br/>
                                                <br/>
                                                <small>
                                                NOTĂ:<br/>
                                                – Câmpurile notate cu (*) sunt obligatoriu de completat.
                                                </small>


                                            </Grid>
                                        </Grid>
                                    )
                                    : (<div align = "center">
                                        <Typography variant = "h2" style = {{color:"LightSteelBlue"}}>nu avem încă suficiente date pentru generarea documentului</Typography>
                                        </div>
                                    )}

                            </div>
                        </RichCard>

                    </Grid>
                </Grid>
            )}
        </Fragment>
    )
}
