export const menuItems = [
    {id: "anexa1a", title: "CERERE AFIȘARE (anx 1)", desc: "Cererea de afișare a ofertei de vânzare", stage:1, color: "red"},
    {id: "anexa1b", title: "OFERTĂ VÂNZARE (anx 2)", desc: "Ofertă de vânzare teren", stage:1, color: "red"},
    {id: "pv1", title: "P.V. OFERTĂ (1I, anx 9)", desc: "Proces-verbal de afișare a ofertei de vânzare", stage:1, color: "red"},
    {id: "anexa1c", title: "LISTA PREEMPTORILOR (anx 3)", desc: "Lista preemptorilor", stage:1, color: "red"},
    {id: "pv2", title: "P.V. AFIȘARE LISTÂ PRE.", desc: "Proces-verbal de afișare a listei preemptorilor", stage:1, color: "grey"},
    {id: "anexa1d", title: "NOTIFICARE PREEMPTORI (anx 4)", desc: "Notificări trasmise tuturor preemptorilor", stage:1, color: "red"},
    {id: "uat", title: "NOTIFICARE UAT VECINE ", 
    desc: "Notificări UAT vecine Legea 17/2014-art.6/pct.8 introdus prin Legea 175/2020-pct.5, art I", stage:1},
    
    {id: "da1", title: "ADRESA ÎNAINTARE D.A.D.R", desc: "Adresă de înaintare a dosarului către Dir.Agr.și Dezv.Rurală", stage:1},
    {id: "ads1", title: "ADRESA ÎNAINTARE A.D.S.", desc: "Adresă de înaintare a dosarului către Ag.Dom.Statului", stage:1},
    {id: "anexa3a", title: "ADRESA DIRECȚIA CULTURĂ", desc: "Adresă către Direcția pentru cultură", stage:1},
    {id: "anexa3b", title: "CERERE  AVIZ  CULTURĂ", desc: "Cerere către Dir.Cultură pentru eliberare a avizului specific", stage:1},
    {id: "anexa2c", title: "CERERE  AVIZ  MApN", desc: "Cerere eliberare aviz MApN pentru terenurile situate la mai putin de 30km de frontiera de stat", stage:1},
    {id: "anexa1e", title: "COM.ACCEPTARE (anx 5)", desc: "Comunicări de acceptare transmise de preemptori + cereri înregistrare(1f)", stage:2, color: "red"},
    {id: "anexa1g", title: "DECIZIE VÂNZĂTOR (anx 7)", desc: "Decizia vânzătorului de alegere a preemptorului potențial cumpărător", stage:2, color: "red"},
    {id: "pv45", title: "P.V.45 (fără oferte,1K, anx.11) ", desc: "Proces-verbal întocmit în cazul în care nu s-a prezentat nici un premptor potențial cumpărător în termenul de 45 de zile", color: "darkgreen", stage:2, color: "red"},
    {id: "pv46", title: "P.V.45 (cu oferte,K, anx.11) ", desc: "Proces-verbal întocmit în cazul au fost depuse oferte de preemptori în termenul de 45 de zile", color: "darkgreen", stage:2, color: "red"},
    {id: "da2", title: "ADR. ÎNAINTARE PPC + PV / DADR", desc: "Adresă de înaintare a procesului verbal încheiat la 45 de zile lucrătoare și a eventualei decizii de alegere a preemptorului potențial cumpărător către D.A.D.R.", stage:2},
    {id: "anexa1f", title: "Oferte non-preemptori (anx 6)", desc: "Oferte depuse după de potențialii cumpărători ce îndeplinesc condițiile art 4(alin.3) în cele 30 de zile și ulterior", color: "darkgreen", stage:3, color: "red"},
    {id: "anexa1g30", title: "DECIZIE VÂNZĂTOR 30 (anx 7)", desc: "Decizia vânzătorului de alegere a potențialului cumpărător, dintre cei ce au depus oferte în intervalul de 30 de zile ", color: "darkgreen", stage:3, color: "red"},
    {id: "pv30", title: "P.V.45+30 (fără oferte,1M,a.13)", desc: "Proces-verbal întocmit în cazul în care nu a fost depusă nicio ofertă în termenul de 30 de zile (după cele 45)", color: "darkgreen", stage:3, color: "red"},
    {id: "pv31", title: "P.V. > 45+30 ZILE (cu oferte)", desc: "Proces-verbal întocmit în cazul în care au fost depuse oferte în intervalul de 30 de zile (după cele 45) și vânzătorul a depus adresa 1G (alegere cumpărător potențial)", color: "red", stage:3},
    {id: "da3", title: "ADR. ÎNAINTARE VPC, PV / DADR", desc: "Adresă de înaintare a procesului verbal încheiat cu ocazia finalizării etapei de 30 de zile calendaristice și a eventualei decizii de alegere a V.P.C. către D.A.D.R.",  stage:3},


    {id: "pvr", title: "P.V. RETRAGERE", desc: "Procedura de retragere a ofertei de vânzare", color: "darkgreen", stage:4}

    // {id: "pv4", title: "PROCES-VERBAL RETRAGERE", desc: "Proces-verbal încheiat cu ocazia retragerii ofertei de către vânzător"},
    
   
]

export const ETAPE = [
    {id: 0, label: "TOATE ETAPELE PROCEDURALE", desc: "Anulează filtrarea etapelor și afișează în meniul vertical toate documentele"},
    {id:1, label: "ETAPA INIȚIALĂ", desc: "Etapa inițială a procedurii, incluzând depunerea cererii de afișare a ofertei de vânzare, oferta de vânzare precum și toate demersurile întreprinse de primărie privind identificarea și notificarea preemptorilor, comunicări cu diverse autorități prevăzute de lege (D.A.D.R., A.D.S., Direcția pentru Cultură, M.Ap.N. etc"},
    {id: 2, label: "ETAPA DE 45 DE ZILE", desc: "Etapa depunerii comunicărilor de acceptare de către preemptorii, comunicarea alegerii preemptorului potențial cumpărător de către vânzător și finalizată printr-un proces-verbal de constatatare a parcurgerii tuturor etapelor procedurale, atât în cazul în care au fost, cât și în cazul în care nu au fost depuse comunicări de acceptare"},
    {id: 3, label: "ETAPA DE 30 DE ZILE", desc: "Etapa depunerii comunicărilor de acceptare de către potențialii cumpărători ce întrunesc condițiile prevăzute de lege, comunicarea alegerii  potențialului cumpărător de către vânzător și finalizată printr-un proces-verbal de constatatare a parcurgerii tuturor etapelor procedurale, atât în cazul în care au fost, cât și în cazul în care nu au fost depuse comunicări de acceptare"},
    {id: 4, label: "PROCEDURA RETRAGERE", desc: "Procedura de retragere a ofertei de vânzare"}
]