import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Grid, Button, makeStyles, IconButton, Typography} from '@material-ui/core'
import RichCard from '../../components/RichCard'
import { useReactToPrint } from 'react-to-print'
import PF_PJ from '../Dosar/Common/PF_PJ'
import {FaCheck} from 'react-icons/fa'
import moment from 'moment'
import ADRESAPOSTALA from '../Dosar/Common/ADRESAPOSTALA'
import ANTET_PRE from '../Dosar/Common/ANTET_PRE'
import AUTOCOMPLETE_CONTACT from '../Dosar/Common/AUTCOMPLETE_CONTACT'
import Field from '../Dosar/Common/Field'
import { getSetting } from "../../lib/settings"
import SemnaturiPrimarSecretar2022 from '../Dosar/Common/SemnaturiPrimarSecretar2022'
import Antet2022 from '../Dosar/Common/Antet2022'
import { MdPrint} from "react-icons/md"


const cod = "anexa1c";

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        borderCollapse:"collapse",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export const PreemptorTemplate = props => {
    const classes = useStyles()
    // console.log("propsdata:", props.data)
    let data = props.data || []
    let rang = props.rang || 0
    data = [...data].filter( el => +el.rangPreemptorId === +rang)
    data = data.length !== 0 ? [...data] : []
   
     return   <table className={classes.table} cellSpacing = {0}>
            <tbody>
                <tr>
                    <td>Nr.crt</td>
                    <td width = "40%">
                        <div className = {classes.text} style = {{fontSize: "0.5rem", lineHeight:1}}>
                        Nume şi prenume persoană fizică/Denumire persoană/persoane 
                            juridică/juridice, asociaţii şi alte entităţi fără personalitate juridică, 
                            precum şi persoane juridice care nu se înregistrează în registrul comerţului
                        </div>

                       
                    </td>
                    <td><small>Adresă domiciliu/reşedinţă/sediu</small></td>
                </tr>
                {data.map( (e,i) => (
                    <tr key = {i}>
                        <td>{i+1}</td>
                        <td><Field>{e.titular && e.titular.name}</Field></td>
                        <td><ADRESAPOSTALA data = {e.titular} /></td>
                        {/* <td>{JSON.stringify(e.titular)}</td> */}
                    </tr>
                ))}
                {data.length === 0 && <tr><td> - </td><td>- - - </td><td>- - - </td></tr>}
                
            </tbody>
        </table>


}

export default function ListaPreTemplate(props) {
    const codAnexa = "1c"
    const printRef = useRef()
    const classes = useStyles()
    const item =  props.data || null

    const [dosar, setDosar] = useState()

    useEffect(() => {
        console.log("props: ", props)
        if (props && props.data) { setDosar( {...props.data, ...props.data.registru, company: props.data, anexe: props.data.registru.anexe, ofertanti :props.data.registru.ofertanti}) }
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });
    return (
        <RichCard
        title={<Typography variant="body2" color="primary" style = {{paddingLeft:"1em"}}>PREVIZUALIZARE TIPĂRIRE</Typography>}
        action={
            <IconButton onClick={printHandler}><MdPrint/> </IconButton>
        }
    >

        <div style={{ height: "90vh", padding: "5px", overflowY: "visible", zoom: 0.8 }} ref={printRef}>
                    {dosar && dosar.anexe && (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className={classes.text}
                                    ref={printRef} >
                                        {console.log("dosar:", dosar)}
                                     <Grid item xs={12} align="right">
                                     ANEXA Nr. 3 (Anexa nr. 1C la normele metodologice)
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD.MM.YYYY")}
                                    </Grid>
                                    <Grid item xs={12} align = "center">
                                        <strong>
                                        LISTA<br/>
                                        preemptorilor în vederea exercitării dreptului de preempţiune asupra 
                                        ofertei de vânzare în ordinea rangului de preferinţă
                                        </strong>
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Antet2022 data = {dosar}/>
                                    </Grid>
                                    <Grid item xs={12}  className = {classes.text}>
                                       <br/>
                                        
                                        Ca urmare a înregistrării Ofertei de vânzare nr.
                                         <Field>{dosar.nr}</Field> / <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>{' '}
                                        depuse de {dosar.ofertanti.map( (e,i) => (
                                            <Field key = {"field"+i}>
                                                {e.titular.name} 
                                                {dosar.ofertanti.length > i+1 ? ", ": " "}
                                            </Field>
                                        ))}
                                        în calitate de {dosar.ofertanti.length ===1 ? "vânzător" : "vânzători"}, 
                                        pe baza evidenţelor deţinute la nivelul primăriilor şi a informaţiilor cuprinse în oferta de vânzare au fost identificaţi următorii preemptori:
                                        
                                        {dosar.ranguri.map((e,i) => (
                                            <div className = {classes.text} key= {i}>
                                                {i+1}. {e.name}: {e.desc} <br/>
                                                <PreemptorTemplate data = {dosar.preemptori} rang = {e.id} />
                                                <br/>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}  align = "left"> 
                                        <SemnaturiPrimarSecretar2022 data = {dosar}/>
                                        <br/><br/>
                                        Data{' '}<Field>{moment(dosar.anexe.anexa1c.dt).format('DD.MM.YYYY')}</Field>
                                        <br/><br/>
                                        
                                    </Grid>   
                                    

                                    
                                    
                                    
                                
                            </Grid>
                             )}
</div>
        </RichCard>
    )
}
