import { Typography, makeStyles, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import PrintModalWrapper from './PrintModalWrapper';
import ANTET_INSTITUTIE from '../../Dosar/Common/ANTET_INSTITUTIE';
import { COMPANY } from '../../../apollo/query';
import { useLazyQuery } from '@apollo/client';
import Field from '../../Dosar/Common/Field';

const useStyles = makeStyles(theme => ({
    justify: {
        textAlign: "justify",
        textJustify: "inter-word"
    },
    printOnly: { "@media screen": { display: "none" } },
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "crosshair",
            border: "2px solid navy",
        },
    },

}))

export default function PrintPvCarnetActualizare({ data, context, onClose }) {

    const classes = useStyles()

    const { cerere, pv } = context || {}
    const { contact } = cerere || {}
    const [getCompany, company] = useLazyQuery(COMPANY)
    const [semnaturi, setSemnaturi] = useState({
        atestate1: { prop: "", value: "", desc: "" },
        atestate2: { prop: "", value: "", desc: "" },
    })
    const [ products, setProducts] = useState([])


    useEffect(() => {
        getCompany()
        if ( data && data.produse ) {
            const makeEmtyObj = tip => ({tip:tip, name: "", ha:"", ari:"", efective: "", estimat:""})
            let plainProds = data.produse.reduce( (acc,el) => {
                return [...acc, {
                    tip: el.atProdus.tip_produs ? "V" : "Z",
                    name: el.atProdus.den_produs,
                    ha: el.ha,
                    ari: el.ari,
                    efective: el.efective,
                    estimat: el.estimat 
                }]
            }, [])
            let v = plainProds.filter( el => el.tip === "V")
            let z = plainProds.filter( el => el.tip === "Z")
            let delta = v.length - z.length
            let dif
            switch (true) {
                case delta < 0 : 
                    dif = Math.abs(delta); 
                    for (let i = 0; i<dif; i++ ) {
                       v = [...v, makeEmtyObj("V")]
                    }
                    break;
                case delta > 0 : 
                    dif = Math.abs(delta); 
                    for (let i = 0; i<dif; i++ ) {
                        v = [...v, makeEmtyObj("Z")]
                    }
                    break;
                }
               let totalLength = v.length+z.length
               let res = []
               for (let i=0;i<totalLength/2;i++){
                   let row = [v[i], z[i]]
                    res = [...res, row]
               }
            console.log("Res prods:", res)
            setProducts(res)
        } 
    }, [data])

    

    useEffect(() => {
        if (company && company.data && company.data.company) {
            let s1, s2
            let settings = company.data.company.settings
            s1 = settings.filter(el => el.prop === "atestate1")
            s1 = s1.length > 0 ? s1[0] : null
            s2 = settings.filter(el => el.prop === "atestate2")
            s2 = s2.length > 0 ? s2[0] : null
            setSemnaturi({
                atestate1: s1 ? s1 : semnaturi.atestate1,
                atestate2: s2 ? s2 : semnaturi.atestate2,
                company: company.data.company
            })
        }
    }, [company && company.data])


    return <Fragment>
        {data && pv && company && company.data && company.data.company && semnaturi && products && (
            <PrintModalWrapper onClose={() => onClose(null)}>
                <div align="center">
                    <div className={classes.printOnly}>
                        <br /><br /><br /><br />
                    </div>
                    <ANTET_INSTITUTIE data={company.data.company} />
                    <br /><br />
                    <Typography variant="body1"><strong>PROCES-VERBAL </strong></Typography>
                    <Typography variant="body1"><strong>întocmit astăzi, {moment(data.dtElib).format("DD.MM.YYYY")}</strong></Typography>
                    <Typography variant="body1"><strong>întocmit cu ocazia actualizării unui carnet de producător </strong></Typography>
                </div>
                <br />
                <div className={classes.justify}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Subsemnatul, <Field>{semnaturi.atestate1.value}</Field>, {' '}
                    având funcția de <Field>{semnaturi.atestate1.desc}</Field>,
                    am vizat azi, <Field>{data.dtElib ? moment(data.dtElib).format("DD.MM.YYYY") : ".............."}</Field> {' '}
                    pentru <Field>{contact.name}</Field>, {' '}
                    cu domicliul în {' '}
                    localitatea {contact.domLoc}, {' '}
                    str.<Field>{contact.domStr}</Field>, {' '}
                    nr. <Field>{contact.domNr}</Field>, {' '}
                    bl. <Field>{contact.domBl}</Field>, {' '}
                    sc. <Field>{contact.domSc}</Field>, {' '}
                    et. <Field>{contact.domEt || " - - - "}</Field>, {' '}
                    ap. <Field>{contact.domAp || " - - - "}</Field>, {' '}
                    cnp. <Field>{contact.cnp || " - - - "}</Field>, {' '}
                    {contact.ciTip} seria <strong>{contact.ciSeria || "----"}</strong>, {' '}
                    numarul <strong>{contact.ciNr || "----"}</strong>,
                    eliberată de <strong>{contact.ciElibDe}</strong>,{' '}
                    <br />
                    <div align="center">
                        <strong>CARNETUL DE COMERCIALIZARE A PRODUSELOR AGRICOLE</strong>{' '}
                        seria <strong>{data.seria || "---"}</strong>{' '}
                        nr. <strong>{data.nr || "---"}</strong>{' '}
                        din data de <strong>{data.dt ? moment(data.dt).format("DD.MM.YYYY") : "---"}</strong>{' '}
                    </div>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Carnetul a fost eliberat pe baza atestatului de producător{' '}
                    seria <Field>{pv.atestatSeria}</Field> {' '}
                    nr. <Field>{pv.atestatNr}</Field> {' '}
                    / <Field>{pv.atestatDt ? moment(pv.atestatDt).format("DD.MM.YYYY") : "----"}</Field>,

                    pentru suprafețele cultivate/efectivele de animale/familiile de albine înscrise în Registrul Agricol al {' '}
                    {company.data.company.name}{' '}, județul {company.data.company.jud}, {' '}
                    vol. <Field>{pv.agricolVol}</Field>, {' '}
                    nr. <Field>{pv.agricolNr}</Field>, {' '}
                    și conține <Field>40</Field> de file, de la fila
                    <Field>{data.filaStart}</Field>, {' '}
                    până la <Field>{data.filaEnd}</Field>, {' '}, fiind utilizabil și de următorii membri ai gospodăriei:
                    <br /><br />
                    <table className={classes.table} width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody>
                            {data.gospodari.map((e, i) => (
                                <tr key={i}>
                                    <td> {i + 1}.  Numele și prenumele: <Field>{e.contact.name}</Field> </td>
                                    <td> CNP: <Field>{e.contact.cnp}</Field></td>
                                    <td>Adresa din BI/CI: {e.contact.domLoc}</td>
                                </tr>


                            ))}
                        </tbody>
                    </table>
                    <br />
                    și pentru comercializarea următoarelor produse:
                    <br />
   
                    <table className={classes.table} cellSpacing={0} cellPadding={0} width="100%">
                        <thead>
                            <tr>
                                <th rowSpan={2} align="center">Produsul</th>
                                <th colSpan={2} align="center">Suprafața cultivată</th>
                                <th rowSpan={2} align="center">Producția estimată a fi<br />destinată comercializării<br />(kg/buc)</th>
                                <th rowSpan={2} align="center">Produsul</th>
                            <th rowSpan={2} align="center">Număr efective de animale/<br />cutii viermi de mătase/<br />familii de albine</th>
                            <th rowSpan={2} align="center">Producția estimată a fi<br />destinată comercializării<br />(kg/buc)</th>

                            </tr>
                            <tr>
                                <th align="center">ha</th>
                                <th align="center">ari</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center">1</td>
                                <td align="center">2</td>
                                <td align="center">3</td>
                                <td align="center">4</td>
                                <td align="center">5</td>
                                <td align="center">6</td>
                                <td align="center">7</td>

                            </tr>
                                {products.map( (e,i) => (
                                    <tr key={i}>
                                        <td>{(e[0] && e[0].name) || <div>&nbsp;</div>}</td>
                                        <td>{e[0] && e[0]['ha']}</td>
                                        <td>{e[0] && e[0]['ari']}</td>
                                        <td>{e[0] && e[0]['estimat']}</td>
                                        <td>{(e[1] && e[1]['name']) || <div>&nbsp;</div>}</td>
                                        <td>{(e[1] && e[1]['efective'])} </td>
                                        <td>{(e[1] && e[1]['estimat'])}</td>

                                    </tr>
                                ))}

                            

                        </tbody>
                    </table>
                    <br/><br/>
                    <Grid container justifyContent="space-between" alignItems='flex-start'>
                    <Grid item xs = {8} align = "left">
                        {semnaturi.company && semnaturi.company.loc}, la {moment(data.dtElib).format("DD.MM.YYYY")}
                    </Grid>
                    <Grid item align="center" xs = {12} >
                        <br/><br/>
                    </Grid>
                    <Grid item xs = {6} align="center">
                        <i>Predat</i>,<br/><br/>
                        {semnaturi && semnaturi.atestate1.value}
                        <br/>
                        {semnaturi.atestate1.desc}<br/><br/>
                        . . . . . . . . . . . . . . . .
                    </Grid>
                    <Grid item xs = {6} align="center">
                        <i>Primit</i>,<br/><br/>
                        {contact.name}<br/><br/><br/>
                        . . . . . . . . . . . . . . . .
                    </Grid>
                </Grid>
                </div>

                {/* <pre>{context && JSON.stringify(context, null, 4)}</pre> */}
            </PrintModalWrapper>
        )}

    </Fragment>
}
