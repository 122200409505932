import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export default function ANTET_INSTITUTIE(props) {
    const  c = props.data || {}
    return (
        <Grid container style = {{borderBottom: "1px solid black"}}>
            <Grid item xs = {12} align = "center">
                <Typography variant = "h5">{c.name}</Typography>
                <Typography variant = "caption" style = {{lineHeight:1}}>
                    {c.loc}, 
                    {c.str ? "str. " + c.str + ",": ""}
                    {c.nr ? "nr. " + c.nr + ",": ""}<br/>
                    {c.phone ? "tel. " + c.phone + "," : ""}
                    {c.fax ? "fax. " + c.fax + "," : ""}
                    {c.email ? "email. " + c.email + ",": ""}<br/>
                    {c.cui ? "CIF. " + c.cui : ""}
                </Typography>
            </Grid>
        </Grid>
    )
}
