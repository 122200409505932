import { Card, Grid, makeStyles, Button, MenuItem, IconButton } from '@material-ui/core'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import moment from 'moment'
import ANTET_CERERE from '../Common/ANTET_CERERE'

import { useReactToPrint } from 'react-to-print'
import RichCard from '../../../components/RichCard'
import AUTOCOMPLETE_CONTACT from '../Common/AUTCOMPLETE_CONTACT'
import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import ContactModal from '../../Contacts/ContactModal'
import {useMutation} from "@apollo/client"
import {OFERTANT_RM} from '../../../apollo/mutation'
import FunkyInput from '../../../components/FunkyInput'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import {clearTypeName} from "../../../lib/graph-stuff"
import PF_PJ from '../Common/PF_PJ'
import { MdPrint } from 'react-icons/md'
import Antet2022 from '../Common/Antet2022'
const cod = "anexa1a"

const Field = props => {
    return (<strong>{props.children || ' - - - - -'}</strong>)
}

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none", 
        // "&& :hover": { background: theme.palette.grey[300] }
    },
    noPrint: {
        "@media print": { display: "none" }
    },
}))

export default function Anexa1ACererePJ(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState()
    const [anexaIndex, setAnexaIndex] = useState()
    const [] = useState(null)
    const [currentType, setCurrentType] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [currentItem, setCurrentItem] = useState()
    const [rmOfertant] = useMutation(OFERTANT_RM)

    useEffect(() => {
        if (props && props.data) { setDosar({...props.data}) }
    }, [props])



    const contactHandler = (type,index) => ev => {
        console.log("of: ",type,index)
        let newDosar = {...dosar, ofertanti: dosar.ofertanti.map( e =>({...e})) }
        newDosar.ofertanti[index][type] = ev
        newDosar.ofertanti[index][`${type}Id`] = ev.id
        props.onChange(newDosar)
    }
    const addOfertantHandler = () => {
        let newDosar = {...dosar, ofertanti: dosar.ofertanti.map( e =>({...e})) }
        newDosar.ofertanti.push({tip:1, titularId:0, mandatarId:0,titular:{}, mandatar:{}})
        props.onChange(newDosar)
    }

    const modContactHandler = (type, index, item) => () => {
        setCurrentItem(
        {
            ...item, 
            dNastere: moment(item.dNastere).format('YYYY-MM-DD'),
            ciElib: moment(item.ciElib).format('YYYY-MM-DD')
        })
        setCurrentIndex(index);setCurrentType(type)
    }
    const closeContactModalHandler = ev => {
        if (ev) {
            let newDosar = {...dosar, ofertanti: dosar.ofertanti.map( e =>({...e})) }
            newDosar.ofertanti[currentIndex][currentType] = ev
            if ( currentType === "titular") { newDosar.ofertanti[currentIndex].titularId = ev.id }
            if ( currentType === "mandatar") { newDosar.ofertanti[currentIndex].mandatarId = ev.id }
            props.onChange(newDosar)
        }
        setCurrentItem();
    }
    const addContactHandler = (pfpj, type, index) => () => {
        let tip;
        if (pfpj === "pf") { tip = {cnp: " "}}
        if (pfpj === "pj") {tip = {cui: " "}}
        setCurrentIndex(index); setCurrentType(type);
        setCurrentItem({...tip, dNastere:moment().format('YYYY-MM-DD'), ciElib:moment().format('YYYY-MM-DD'), ciElibDe:"SPCLEP"})
    }
    const removeOfertantHandler = (el, index) => () =>{
        if ( el.id ) { rmOfertant({variables: {id: el.id}}) }
        let newDosar = {...dosar, ofertanti: dosar.ofertanti.map( e =>({...e})) }
        newDosar.ofertanti.splice(index, 1)
        props.onChange(newDosar)
    }
    const addPreemptorHandler = () => {
        let newDosar = {...dosar}
        newDosar.anexe.preemptoriCerere.push({name: "", rangId: 0})
        props.onChange(newDosar)
    }
    
    const preemptorNameHandler = i => ev => {
        let newDosar = {...dosar}
        newDosar.anexe.preemptoriCerere[i]['name'] = ev.target.value
        props.onChange(newDosar)
    }

    const preemptorRangHandler = i => ev => {
        let newDosar = {...dosar}
        newDosar.anexe.preemptoriCerere[i]['rangId'] = ev.target.value
        props.onChange(newDosar)
    }

    const removePreemotor = i => () => {
        let newDosar = {...dosar}
        newDosar.anexe.preemptoriCerere.splice(i,1)
        props.onChange(newDosar)
    }

    const addDocHandler = () => {
        let newDosar = {...dosar}
        newDosar.anexe.documenteCerere.push("")
        props.onChange(newDosar)
    }
    const docChangeHandler = i => ev => {
        let newDosar = {...dosar}
        newDosar.anexe.documenteCerere[i] = ev.target.value
        props.onChange(newDosar)
    }
    const docRemoveHandler = i => () => {
        let newDosar = {...dosar}
        newDosar.anexe.documenteCerere.splice(i,1)
        props.onChange(newDosar)
    }

    const booleanHandler = (item) => () => {
        let newDosar = {...dosar}
        newDosar['anexe'][item] = !newDosar['anexe'][item]
        props.onChange(newDosar)
    }

    const anexaInputHandler = name => ev => {
        let newDosar ={...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const contentInput = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });



    const mdtHandler = (tip, index) => ev => {
        let nd = {...dosar, ofertanti: dosar.ofertanti.map( el => clearTypeName(el))}
        nd.ofertanti[index][tip] = ev.target.value
        props.onChange(nd)
    }

    const contractHandler = ev => props.onChange({
        ...dosar, 
        anexe : {
            ...dosar.anexe,
            contract: ev.target.value
    }})


    return (
        <Fragment>
            {/* {console.log("dosar:", dosar)} */}
            {dosar && (
                <Grid container justifyContent="flex-start" className={classes.text} alignItems="flex-start">
                
{/* forms /kitchen */}
 {/* ofertanti    */}
                    <Grid item sm={7} xs={12}>
                        <div style = {{maxHeight: "73vh", padding: "5px", paddingTop:0, overflow: "auto"}}>
                        {dosar && dosar.anexe && (
                                <RichCard title = " NR.INREGISRARE " >
                                    <Grid container justifyContent= "flex-start" spacing={1}>
                                    <Grid item xs ={2}>
                                        <FunkyInput value ={dosar.anexe[cod].nr} label = "NR" onChange ={anexaInputHandler('nr')}
                                        type="number" step ="1"/>
                                    </Grid>
                                    <Grid item xs = {4}>
                                    <FunkyInput value ={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} label = "NR.INREG"
                                    onChange ={anexaInputHandler('dt')} type = "date"/>
                                    </Grid>
                                    <Grid item xs = {2} />
                                    <Grid item xs = {4}>
                                        {dosar.status === "FINALIZAT" && (
                                            <FunkyInput 
                                                value = {dosar.anexe.contract || ""} 
                                                onChange = {contractHandler}
                                                label="CONTRACT NR/DATA"/>
                                        )}
                                        </Grid>
                                    </Grid>


                                </RichCard>
                            )}                            
                        <RichCard 
                            title = "OFERTANT/OFERTANȚI"
                            action = {
                                <IconButton size = "small" color = "primary" onClick = {addOfertantHandler}>
                                    <FaPlusCircle/>
                                </IconButton>
                            }
                        >
                        
                            {dosar.ofertanti.map( (e,i) => (
                                <Card key = {i} className = {classes.card}>
                                    <Grid container alignItems = "flex-start">
                                        <IconButton size = "small" onClick = {removeOfertantHandler(e,i)}>
                                            <FaTimesCircle style = {{color: "red"}} />
                                        </IconButton>
                                        <Grid item xs >
                                            <AUTOCOMPLETE_CONTACT data = {e.mandatar || {}} label = "MANDATAR" 
                                            onChange = {contactHandler("mandatar", i)} />
                                            {e.mandatar && e.mandatar.id && (
                                                <div style = {{display:"flex"}}>
                                                <FunkyInput value = {e.calitate || ""} label = "calitate" 
                                                    onChange = {mdtHandler("calitate", i)}/>
                                                <FunkyInput value = {e.prin || ""} label = "prin" 
                                                    onChange = {mdtHandler("prin", i)}/>
                                                <FunkyInput value = {e.conform || ""} label = "conform" 
                                                    onChange = {mdtHandler("conform", i)}/>

                                                </div>
                                            )}
                                        </Grid>
                                        {e.mandatar && e.mandatar.id 
                                            ? <IconButton size ="small"  onClick = {modContactHandler("mandatar", i, e.mandatar)}>
                                                <FaEdit style = {{color: "green"}}/>
                                            </IconButton>
                                            : <IconButton size ="small" onClick = {addContactHandler("pf","mandatar", i)}>
                                                <FaPlusCircle style = {{color: "navy"}} />
                                            </IconButton>
                                        }


                                        <Grid item xs >
                                            <AUTOCOMPLETE_CONTACT data = {e.titular} label = "PENTRU FIRMA" 
                                            onChange = {contactHandler("titular", i)} />
                                        </Grid>
                                        {e.titular && e.titular.id 
                                            ? <IconButton size ="small" onClick = {modContactHandler("titular",i,e.titular)}>
                                                    <FaEdit style = {{color: "green"}}/>
                                            </IconButton>
                                            : <IconButton size ="small" onClick = {addContactHandler("pj","titular", i)}>
                                                <FaPlusCircle style = {{color: "navy"}} />
                                            </IconButton>
                                        }
                                    </Grid>
                                </Card>
                            ))}
                        </RichCard>
 {/* preemptori    */}
                    <RichCard 
                        title = "PREEMPTORI"
                        action = {
                            <IconButton size = "small" color ="primary" onClick = {addPreemptorHandler}>    
                                <FaPlusCircle/>
                            </IconButton>
                        }
                    >
                        { dosar && dosar.anexe && dosar.anexe.preemptoriCerere
                            .map( (e,i) => (
                                <Grid container key = {i} alignItems="center" spacing={1}>
                                    {i+1} .
                                    <Grid item xs = {6}>
                                        <FunkyInput value = {e.name} onChange = {preemptorNameHandler(i)}/>
                                    </Grid>
                                    <Grid item xs ={5}>
                                        <FunkyInput value = {e.rangId} onChange = {preemptorRangHandler(i)} select>
                                            {dosar && dosar.ranguri.map( (el,ix) => (
                                                <MenuItem key = {ix} value = {el.id}>
                                                    {el.name}
                                                </MenuItem>
                                            ))}
                                        </FunkyInput>
                                    </Grid>
                                    <IconButton size = "small" color = "secondary" onClick = {removePreemotor(i)}>
                                            <FaTimesCircle/>
                                        </IconButton>
                                </Grid>
                            ))
                        }
                       
                    </RichCard>
{/* documente    */}
                    <RichCard 
                        title = "DOCUMENTE"
                        action = {
                            <IconButton size = "small" color ="primary" onClick = {addDocHandler}>    
                                <FaPlusCircle/>
                            </IconButton>
                        }
                    >
 
                        { dosar && dosar.anexe && dosar.anexe.documenteCerere
                            .map( (e,i) => (
                                <Grid container key = {i} alignItems="center" spacing={1}>
                                    {i+1} .
                                    <Grid item xs = {11}>
                                        <FunkyInput value = {e} onChange = {docChangeHandler(i)}/>
                                    </Grid>
                                    <IconButton size = "small" color = "secondary" onClick = {docRemoveHandler(i)}>
                                            <FaTimesCircle/>
                                        </IconButton>
                                </Grid>
                            ))
                        }
                       
                    </RichCard>  
{/* alte informatii */}
                    <RichCard title = "ALTE INFORMAȚII">
                        {dosar && dosar.anexe && (
                            <Fragment>
                            <Grid container alignItems = "center" spacing ={1} justifyContent="space-between">
                                    
                                Litigii, executare silită &nbsp; 
                                <div>
                                <Button 
                                    size = "small" color = "primary"
                                    variant = {dosar.anexe.litigii ? "contained":"outlined" }
                                    onClick = {booleanHandler('litigii')}
                                >DA</Button>
                                    &nbsp;&nbsp;
                                    <Button 
                                    size = "small" color = "primary"
                                    variant = {dosar.anexe.litigii ? "outlined":"contained" }
                                    onClick = {booleanHandler('litigii')}
                                >NU</Button>
                                </div>
                            </Grid>
                            <br/>
                            <Grid container alignItems = "center" spacing ={1} justifyContent="space-between">
                                    
                                    Terenul este grevat de sarcini &nbsp; 
                                    <div>
                                    <Button 
                                        size = "small" color = "primary"
                                        variant = {dosar.anexe.sarcini ? "contained":"outlined" }
                                        onClick = {booleanHandler('sarcini')}
                                    >DA</Button>
                                        &nbsp;&nbsp;
                                        <Button 
                                        size = "small" color = "primary"
                                        variant = {dosar.anexe.sarcini ? "outlined":"contained" }
                                        onClick = {booleanHandler('sarcini')}
                                    >NU</Button>
                                    </div>
                                </Grid>
                                <br/>
                                <Grid container alignItems = "center" spacing ={1} justifyContent="space-between">
                                    <Grid item xs = {3}>
                                        <FunkyInput 
                                            value = {dosar.anexe.terenHa} label = "Suprafața(ha)" 
                                            onChange ={contentInput('terenHa')}/>
                                    </Grid>
                                    <Grid item xs = {2}>
                                        <FunkyInput 
                                            value = {dosar.anexe.cotaParte} label = "Cota parte" 
                                            onChange ={contentInput('cotaParte')}/>
                                    </Grid>
                                    <Grid item xs = {7}>
                                        <FunkyInput 
                                            value = {dosar.anexe.terenLoc} label = "Localitatea" 
                                            onChange ={contentInput('terenLoc')}/>
                                    </Grid>
                                    <Grid item xs = {4}>
                                        <FunkyInput 
                                            value = {dosar.anexe.terenNrCadastral} label = "nr.cadastral" 
                                            onChange ={contentInput('terenNrCadastral')}/>
                                    </Grid>
                                    <Grid item xs = {4}>
                                        <FunkyInput 
                                            value = {dosar.anexe.terenCf} label = "nr. C.F." 
                                            onChange ={contentInput('terenCf')}/>
                                    </Grid>
                                    <Grid item xs = {4}>
                                        <FunkyInput 
                                            value = {dosar.anexe.terenCfLoc} label = "C.F. a localității" 
                                            onChange ={contentInput('terenCfLoc')} />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                    </RichCard>
                    </div>
                    </Grid>

{/* print */}
                    <Grid item sm={5} xs={12}>
                        <RichCard 
                            title = "Previzualizare tipărire" 
                            action = {
                                <Button variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                    <MdPrint size = "1.6em"/>
                                </Button>
                            }
                        >
                            <div style = {{height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8}}>
                            <Grid container
                                justifyContent="flex-start" className={classes.text} alignItems="flex-start"
                                // style={{ height: "70vh", border: "1px solid red", overflow: "auto" }}
                                ref={printRef}
                                // className={classes.text}
                            >

                                <Grid item xs={12} align="right">
                                    ANEXA Nr. 1 (Anexa nr. 1A la normele metodologice)
                                    <br/>
                                    - Formular pentru persoane juridice 
                                    şi entităţi fără personalitate juridică ce se înregistrează în registrul comerţului -
                                </Grid>
                                {dosar && dosar.anexe && (
                                        <Grid item xs={12} align = "right">
                                            <br/>
                                            Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD-MM-YYYY")}
                                        </Grid>
                                     )}                                
                                <Grid item xs={12} align="center">
                                    <br /><strong>CERERE</strong><br />pentru afişarea ofertei de vânzare a terenului
                                </Grid>
                                <Grid item xs={12}>
                                    <Antet2022 data={dosar} />
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                Stimată doamnă primar/Stimate domnule primar,
                                </Grid>
                                <Grid item xs={12}>
                                    {dosar.ofertanti.length === 0
                                        ? <div style ={{color: "red"}}>NU ATI DEFINIT NICI UN OFERTANT!!!</div>
                                        : <PF_PJ data = {dosar.ofertanti} />
                                    }
                                <Grid item xs={12} className = {classes.text}>
                                    având în vedere dispoziţiile Legii nr. 17/2014 privind unele măsuri de reglementare a vânzării terenurilor
                                    agricole situate în extravilan şi de modificare a Legii nr. 268/2001 privind privatizarea societăţilor
                                    ce deţin în administrare terenuri proprietate publică şi privată a statului cu destinaţie agricolă şi
                                    înfiinţarea Agenţiei Domeniilor Statului, cu modificările şi completările ulterioare, solicit prin prezenta
                                    cerere afişarea ofertei de vânzare anexată, în termenul prevăzut de Legea nr. 17/2014, cu modificările şi
                                    completările ulterioare.Am cunoştinţă despre existenţa următorilor preemptori pentru exercitarea dreptului
                                    de preempţiune asupra ofertei mele de vânzare:
                                </Grid>
                                <Grid item xs={12}>
                                        { dosar && dosar.anexe.preemptoriCerere
                                        && dosar.ranguri.map((rang, i) => (
                                            <div key={i}>
                                                [
                                                    {dosar.anexe.preemptoriCerere
                                                    .filter( el => el.rangId === rang.id).length > 0 ? "X": " "}
                                                 ] {rang.name}: {rang.desc} <br />
                                                <ul>
                                                {dosar.anexe.preemptoriCerere
                                                .filter( el => el.rangId === rang.id).map( (el,ix) => (
                                                    <li key ={ix}><strong>{el.name}</strong></li>
                                                )) }
                                                </ul>
                                            </div>
                                        ))}
                                </Grid>
                                <Grid item xs = {12}>
                                    {dosar && (
                                        <Fragment>
                                            Declar că sunt proprietar al terenului în suprafaţă de <Field>{dosar.anexe.terenHa}</Field> ha, {' '}
                                            reprezentând cota-parte <strong>{dosar.anexe.cotaParte || "---"} </strong>,{' '} 
                                            situat în extravilanul localităţii <Field>{dosar.anexe.terenLoc}</Field>,  
                                            identificat cu număr cadastral <Field>{dosar.anexe.terenNrCadastral}</Field>, 
                                            înscris în cartea funciară nr. <Field>{dosar.anexe.terenCf}</Field>,  
                                            a localităţii <Field>{dosar.anexe.terenCfLoc}</Field>,  care face obiectul ofertei de vânzare.
                                            <br/><br/>
                                            Declar că:<br/>

                                            – terenul face obiectul unor litigii sau a unei proceduri de executare silită:
                                             Da [<strong>{dosar.anexe.litigii ? "X": " "}</strong>] 
                                             Nu [<strong>{dosar.anexe.litigii ? " ": "X"}</strong>];<br/>

                                            – terenul este grevat de sarcini: 
                                            Da [<strong>{dosar.anexe.sarcini ? "X": " "}</strong>] 
                                             Nu [<strong>{dosar.anexe.sarcini ? " ": "X"}</strong>];<br/>

                                            – am cunoştinţă şi am respectat prevederile art. 4 alin. (6) din Legea nr. 17/2014, cu modificările şi completările ulterioare, cu privire la înstrăinarea terenurilor agricole situate în extravilan pe care sunt situri arheologice clasate.
                                        </Fragment>
                                    )}

                                </Grid>
                                <Grid item xs = {12}>
                                    În susţinerea cererii, depun următoarele acte doveditoare: 
                                    <ul>
                                    {dosar && dosar.anexe && dosar.anexe.documenteCerere
                                    .map( (e,i) => (
                                        <li key={i}><strong>{e}</strong></li>
                                    ))}
                                    </ul>
                                </Grid>
                                <Grid item xs ={12}>
                                Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                                </Grid>
                                <Grid item xs ={12}>
                                    <br/><i>
                                Prin completarea, semnarea și depunerea acestei cereri îmi/ne exprim(ăm) explicit consimțământul 
                                 pentru prelucrarea datelor cu caracter personal furnizate pe această cale, cu scopul exclusiv al soluționării prezentei cereri și declar(ăm) că sunt(em) de acord ca acestea să fie procesate, stocate și transmise, în baza unui temei legal justificat, către oricare dintre autoritățile nominalizate de legislația în vigoare ca având atribuții în soluționarea cererii mele/noastre. Înțeleg/înțelegem, de asemenea, faptul că, în baza Regulamentul (EU)679/2016 dispun(em) de drepturile de acces, intervenție și de opoziție asupra datelor personale transmise prin prezentul înscris.</i>
                                </Grid>                                
                                <Grid item xs ={12}>
                                    <br/>
                                    {dosar && <SEMNATURI_VANZATORI data = {dosar.ofertanti}/>}
                                    
                                </Grid>
                                <Grid item xs ={12}>
                                    <br/> <br/>
                                    Data: {dosar && moment(dosar.anexe[cod].dt).format('DD.MM.YYYY')}
                                   
                                </Grid>
                                <Grid item xs ={12}>
                                <br/> <br/>
                                Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                                </Grid>
                                
                                <strong>NOTE:</strong><br/>
                                <hr/>
                                – Câmpurile notate cu (*) sunt obligatoriu de completat.<br/>
                                – Câmpurile notate cu (**) se completează de către cetăţenii unui stat membru al Uniunii Europene, ai statelor care sunt parte la Acordul privind Spaţiul Economic European (ASEE) sau ai Confederaţiei Elveţiene.<br/>
                                – Câmpurile notate cu (*) se completează de către proprietar.<br/>
                                – Câmpurile notate cu (***) se completează de către împuternicit. <br/>În acest caz, prezintă documentele doveditoare a calităţii de împuternicit.
                                </Grid>
                            </Grid>
                            </div>
                        </RichCard>  
                        </Grid>
                      
                </Grid>

            )}
            <ContactModal data = {currentItem} onClose = {closeContactModalHandler} />
        </Fragment >

    )
}
