import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import FunkyInput from '../../../../components/FunkyInput'


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: "60vw", 
        height: "40vh"
    }
}))

const FIELDS = [
    {key:'terenTarla', label: "TARLAUA", xs:12, sm:2},
    {key:"terenParcela", label: "PARCELA", xs:12, sm:2},
    {key:"terenNrCadastral", label: "NR.CADASTRAL", xs:12, sm:2},
    {key: "terenCf", label: "NR.CF.", xs:12, sm:2},
    {key: "terenCfLoc", label: "LOC.TEREN", xs:12, sm:4},
    {key: "terenHa", label: "SUPR(HA)", xs:12, sm:1},
    {key:"terenAct", label: "ACT PROPRIETATE", xs:12, sm:9},
    {key:"terenCategorie", label: "CATEGORIE", xs:12, sm:2},
    {key:"coordonate", label: "COORDONATE/LOCALIZARE HARTĂ", xs:12, sm:12},

]



export default function TerenModal(props) {
    const classes = useStyles()
    const [item, setItem] = useState()


    useEffect(() => {
        setItem(props.data)
    }, [props])

    const closeHandler = name => ev => {
        if (!name) {
            props.onClose()
        } else {
            props.onClose(item)
        }
    }

    const inputHandler = name => ev => setItem({ ...item, [name]: ev.target.value })

    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler()} classes = {{paper: classes.dialogPaper}}>
            <DialogTitle>MODIFICARE PARCELĂ</DialogTitle>
            <DialogContent>
                <Grid container spacing = {1}>
                {item && FIELDS.map( (e,i) => (
                    <Grid item xs = {e.xs} sm = {e.sm} key = {i} >
                    <FunkyInput value = {item[e.key]} label = {e.label} onChange = {inputHandler(e.key)} />
                    </Grid>
                ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                    <Button color = "secondary" variant = "contained" onClick = {closeHandler()}>RENUNȚĂ</Button>
                    <Button color = "primary" variant = "contained" onClick = {closeHandler(item)}>CONFIRMĂ</Button>
            </DialogActions>
        </Dialog>
    )
}
