import React from 'react'
import moment from 'moment'
import { makeStyles, Grid, Button } from '@material-ui/core'
import { Fragment } from 'react'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import Field from '../../Common/Field'
import { getSetting } from '../../../../lib/settings'
import UAT from '../../Common/UAT'
import PF_PJ from '../../Common/PF_PJ'


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    }
}))

export default function TplADS(props) {
    const classes = useStyles()
    const dosar = props.data
    const cod = props.cod
    return (
        <div>
            {dosar && (
                <Grid
                    container alignItems="flex-start"
                    style={{ border: "0px solid red" }}
                    className={classes.text} >
                    <Grid item xs={12}>
                        <ANTET_INSTITUTIE data={dosar.company} />
                    </Grid>
                    <Grid item xs={12} align="right">
                        <br />
                        Nr.înreg. {dosar.anexe[cod]['ads'].nr} / {moment(dosar.anexe[cod]['ads'].dt).format("DD.MM.YYYY")}
                    </Grid>
                    <Grid item xs={12} align="left">
                        <br/>
                        Către: <Field> AGENȚIA DOMENIILOR STATULUI</Field>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <h4>ADRESĂ DE ÎNAINTARE<br/>
                            A PROCESULUI VERBAL DE ANULARE A PROCEDURII<br/>
                            AFERENTĂ OFERTEI DE VÂNZARE TEREN AGRICOL EXTRAVILAN<br/>
                            nr.{dosar.nr} din {' '}{moment(dosar.dt).format('DD.MM.YYYY')}
                        </h4>
                    </Grid>
                    <Grid item xs={12}>
                        Subscrisa <UAT data={dosar.company} />, {' '}
                         alăturat vă înaintăm procesul verbal de anulare a procedurii aferent 
                         OFERTEI DE VÂNZARE NR. <Field>{dosar.nr}/{moment(dosar.dt).format('DD.MM.YYYY')}</Field>, {' '}
                          depusă și înregistrată la sediul subscrisei de către 
                          <PF_PJ data = {dosar.ofertanti} />
                    </Grid>    
                    <Grid item xs={6} align ="left">
                        <br/><br/>
                        <Field>{dosar.company.loc}, {moment(dosar.anexe[cod]['ads'].dt).format("DD.MM.YYYY")}</Field>
                    </Grid>       
                    <Grid item xs = {6} align="center">
                        <br/><br/>
                        <br/>
                                        Întocmit, <br/>
                                        <Field>{ dosar.user.name}</Field>
                                        <br/><br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/><br/><br/>
                                        L.S. 
                    </Grid>             
                </Grid>
            )}
        </div>)
}
