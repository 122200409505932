import { Button, Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import Container from '../../components/Container';
import {Route, Switch, useHistory} from "react-router"
import ListCereri from './Cereri';
import CerereAtestat from './CerereAtestat';
import Produse from './Produse';
import Rapoarte from './Rapoarte';
import ServiceMenu from './ServiceMenu';

export default function RegistruAtestate() {
    const {push} = useHistory()
  return (
      <Container 
        title = {<Fragment><div 
                onContextMenu={ev=>{
                    ev.preventDefault();
                    push("/registru-atestate/service-menu")
                }} >
        GESTIUNE ATESTATE DE PRODUCĂTOR ȘI CARNETE DE COMERCIALIZARE</div></Fragment>}
        action ={<Grid container spacing={1} style = {{paddingRight: "10px"}}>


            <Grid item xs = {4}>
            <Button variant = "contained" color ="primary" size="small" fullWidth 
                onClick = {() => push("/registru-atestate/cereri")}
            >
                <small>ATESTATE</small>
            </Button>
            </Grid>
            {/* <Grid item xs = {3}>
            <Button variant = "outlined" color ="primary" size="small" fullWidth
                onClick={() => push("/registru-atestate/carnete")}
            >
                <small> CARNETE</small>
            </Button>
            </Grid>       */}
            <Grid item xs = {4}>
            <Button variant = "contained" color ="primary" size="small" fullWidth
                onClick={() => push("/registru-atestate/produse")}
            >
                <small> PRODUSE</small>
            </Button>
            </Grid> 
            <Grid item xs = {4}>
            <Button variant = "contained" color ="primary" size="small" fullWidth
                onClick={() => push("/registru-atestate/rapoarte")}
            >
                <small>RAPORTARI</small>
            </Button>

            </Grid>     
        </Grid>}
      >
         <Switch>
             <Route path = "/registru-atestate/cereri/:from?/:to?/:order?" exact component={ ListCereri }/>
             <Route path = "/registru-atestate/cerere/:id" component={ CerereAtestat }/>
             {/* <Route path = "/registru-atestate/carnete" component={ Carnete }/> */}
             <Route path = "/registru-atestate/produse" component={ Produse }/>
             <Route path = "/registru-atestate/rapoarte" component={ Rapoarte }/>
             <Route path = "/registru-atestate/service-menu" component = {ServiceMenu} />
                
         </Switch>
      </Container>
  )
}
