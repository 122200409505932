import React, {useState, useEffect, Fragment} from 'react'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, makeStyles, Paper, Menu, MenuItem, Typography} from '@material-ui/core'
import FunkyInput from '../../../../components/FunkyInput'
import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import moment from 'moment'
import AUTOCOMPLETE_CONTACT from '../../../../routes/Dosar/Common/AUTCOMPLETE_CONTACT'
import inLitere from '../../../../lib/inLitere'
import ContactModal from '../../../../routes/Contacts/ContactModal'

const FORMULA_STANDARD = `COPIE CI, DOVADA DOMICILIULUI PE TERITORIUL NATIONAL, DOVADA INREGISTRARII IN EVIDENTELE FISCALE, DOVADA CA DESFASOARA ACTIVITATI AGRICOLE`;

const useStyles = makeStyles(theme=>({
    dialogPaper: {minWidth: "80vw"},
    paper: {margin: theme.spacing(1), padding: theme.spacing(1), width: "100%", background: theme.palette.grey[200] }
}))

export default function OfertaModal(props) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [contact, setContact] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorEl2, setAnchorEl2] = useState(null)

    useEffect(()=> setItem(props.data), [props])

    const inputHandler = name=>ev=>{
        setItem({...item, [name]:ev.target.value})
    }
    const dtHandler = name=>ev=>setItem({...item, [name]:new Date(ev.target.value)})
    const titularHandler = ev => {
        let ni = {...item}
        item.ofertant[0]['titular'] = ev
        setItem(ni)
    }
    const mandatarHandler = ev => {
        let ni = {...item}
        item.ofertant[0]['mandatar'] = ev
        setItem(ni)
    }
    
    const contactModalHandler = (tip, elem) => () => {
        setContact({
            tip: tip,
            elem: elem
        })
        setAnchorEl(null)
    }

    const closeContactHandler = ev => {
        if (ev){
            let ni = {...item}
            ni.ofertant[0][contact.tip] = ev
            setItem(ni)
        }        
        setContact()
    }

    const addContact = (tip, jur) => () => {
        console.log("adding contact....", tip, jur)
        let isPfOrPj = jur==="pf" 
            ? {name:"", cnp:"1", dNastere:moment().format('YYYY-MM-DD'), ciElib: moment().format('YYYY-MM-DD')}
            : {name:"", cui:"1"}
        setContact({
            tip: tip, 
            elem: isPfOrPj
        })
        setAnchorEl(null)
    }

   

    const closeHandler = el => () => props.onClose(el)
    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler(null)} classes = {{paper: classes.dialogPaper}}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item><small>OFERTĂ</small></Grid>
                    <Grid item><FaTimesCircle  size = "2rem" color="lightgrey" onClick={closeHandler(null)}/></Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    {item && (
                        <Fragment>
                            <Grid item sm = {1}>
                                <FunkyInput value = {item.nr} type="number" step="1" label="NR" onChange={inputHandler('nr')} />
                            </Grid>
                            <Grid item sm = {3}>
                                <FunkyInput value = {moment(item.dt).format('YYYY-MM-DD')} 
                                type="date" step="1" label="DIN DATA" onChange={dtHandler('dt')} />
                            </Grid>
                            <Grid item sm={7} />
                            <Grid item sm = {5}>
                                <AUTOCOMPLETE_CONTACT data = {item.ofertant[0]['titular']} onChange = {titularHandler}
                                label="titular"/>
                                
                            </Grid>
                            <Grid item>
                                { item.ofertant[0]['titular'] && item.ofertant[0]['titular']['name']
                                    ? <FaEdit color="green" onClick={contactModalHandler("titular", item.ofertant[0]['titular'])}/>
                                    : <FaPlusCircle color = "red" onClick = {ev => setAnchorEl(ev.currentTarget)}/>
                                }
                                <Menu open = {Boolean(anchorEl)} anchorEl={anchorEl} onClose = {()=>setAnchorEl(null)}>
                                    <MenuItem button onClick = {addContact('titular','pf')}>ADAUGĂ TITULAR PERSOANĂ FIZICĂ</MenuItem>
                                    <MenuItem button onClick = {addContact('titular','pj')}>ADAUGĂ TITULAR PERSOANĂ JURIDICĂ</MenuItem>
                                </Menu>
                            </Grid>
                            <Grid item sm = {5}>
                                <AUTOCOMPLETE_CONTACT data = {item.ofertant[0]['mandatar']} onChange = {mandatarHandler}
                                label="mandatar"/>
                            </Grid>
                            <Grid item>
                                { item.ofertant[0]['mandatar'] && item.ofertant[0]['mandatar']['name']
                                    ? <FaEdit color="green" onClick={contactModalHandler("mandatar", item.ofertant[0]['mandatar'])}/>
                                    : <FaPlusCircle color = "red" onClick = {ev => setAnchorEl(ev.currentTarget)}/>
                                }
                                <Menu open = {Boolean(anchorEl2)} anchorEl={anchorEl2} onClose = {()=>setAnchorEl2(null)}>
                                    <MenuItem button onClick = {addContact('mandatar','pf')}>ADAUGĂ MANDATAR PERSOANĂ FIZICĂ</MenuItem>
                                    <MenuItem button onClick = {addContact('mandatar','pj')}>ADAUGĂ MANDATAR PERSOANĂ JURIDICĂ</MenuItem>
                                </Menu>
                            </Grid>
                            {item.ofertant[0]['mandatar'] && item.ofertant[0]['mandatar']['name'] && (
                                <Fragment>
                                    <Grid item sm = {5}/>
                                    <Grid item sm={7}>
                                        <Grid container spacing={2}>
                                            <Paper className = {classes.paper}>
                                                <Grid container spacing = {1}>
                                                    <Grid item xs={4}>
                                                <FunkyInput value={item.mdtMandat} label = "IN CALITATE DE" onChange={inputHandler('mdtMandat')}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                <FunkyInput value={item.mdtPrin} label = "PRIN" onChange={inputHandler('mdtPrin')}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                <FunkyInput value={item.mdtConform} label = "CONFORM" onChange={inputHandler('mdtConform')}/>
                                                </Grid>
                                                </Grid>

                                            </Paper>
                                        </Grid>

                                    </Grid>
                                </Fragment>
                            )}
                            <Grid item sm = {3}>
                                <FunkyInput value = {item.pret} label="PRET OFERIT (LEI)" onChange={inputHandler('pret')}/>
                            </Grid>
                            <Grid item sm = {9}>
                                {item.pret && <i>{inLitere(item.pret)}</i>}
                            </Grid>
                            <Grid item xs = {12} align="right">
                                <a href = "#" onClick = {ev => {ev.preventDefault();setItem({...item, acte: FORMULA_STANDARD})}}>
                                    <small>
                                    adaugă formulare standard
                                    </small>
                                </a>
                            </Grid>
                            <Grid item sm={12}>
                                <FunkyInput value={item.acte} label="DOCUMENTE DEPUSE ÎN SUSȚINEREA CERERII"
                                onChange={inputHandler('acte')} multiline minRows = {10}/>
                            </Grid>
                            {/* <Grid item sm = {12}>
                                <pre>{JSON.stringify(item,null,4)}</pre>
                            </Grid> */}
                        </Fragment>
                    )}
                </Grid>                
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color ="secondary" size ="small" onClick = {closeHandler(null)}>
                    RENUNȚĂ
                </Button>
                <Button variant="contained" color ="primary" size ="small" onClick = {closeHandler(item)}>
                    CONFIRMĂ
                </Button>
            </DialogActions>
            {contact && contact.elem && <ContactModal data = {contact.elem} onClose = {closeContactHandler} />}
        </Dialog>
    )
}
