import { Toolbar, makeStyles, Grid, List, ListItem, Divider,  Paper, ListItemSecondaryAction, Typography, ListItemAvatar, Checkbox } from '@material-ui/core'
import { NoSchemaIntrospectionCustomRule } from 'graphql'
import React, { Fragment, useState, useEffect } from 'react'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import PF_PJ from '../../Common/PF_PJ'
import Field from '../../Common/Field'

const useStyles = makeStyles(theme => ({
    paper: {
        background: theme.palette.grey[0],
        border:  `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(0.5),
        width: "100%",
        marginBottom: theme.spacing(2),
        "@media print": { display: "none" }
    }
}))

export default function Anexa1EtplAdresaVanzator(props) {
    const classes = useStyles()

    const [dosar, setDosar] = useState()
    const  [accIndex, setAccIndex] = useState(0)
    const [adrIndex, setAdrIndex] = useState()


    useEffect(() => {
          
           setDosar(props.dosar)
           setAccIndex(props.index)
         
    }, [props])

    let current = {}
    if ( dosar && adrIndex >= 0 ) {
        current = dosar.anexe.acceptari[accIndex].adreseVanzatori[adrIndex]
    }

    const nrHandler = i => ev => {
        let nd = {...dosar, anexe: dosar.anexe}
        nd.anexe.acceptari[props.index].adreseVanzatori[i].nr = ev.target.value
        props.onChange(nd)
    }
    const dtHandler = i => ev => {
        let nd = {...dosar, anexe: dosar.anexe}
        nd.anexe.acceptari[props.index].adreseVanzatori[i].dt = new Date(ev.target.value)
        props.onChange(nd)
    }

    const checkboxHandler = i => ev => {
        setAdrIndex(i)
    }
   

    return (
        <Fragment>
            {dosar && (
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                        <List component="nav" style = {{width: "100%"}}>
                            { dosar.anexe.acceptari[accIndex].adreseVanzatori.map( (e,i) => (
                                <Fragment key = {i}>
                                    
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Checkbox value = {i} checked = {i === adrIndex} onClick = {checkboxHandler(i)} />
                                            </ListItemAvatar>
                                            <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                                                <Grid item xs = {6}>
                                            <Typography variant="overline" color="primary">
                                                    <strong>{e.vanzator.titular.name}</strong>
                                                </Typography>
                                                </Grid>
                                                <Grid item xs = {2}>
                                                    <FunkyInput 
                                                        value = {e.nr} label = "NR."
                                                        onChange = {nrHandler(i)}
                                                    />
                                                </Grid>
                                                <Grid item xs = {4}>
                                                    <FunkyInput 
                                                        value = {moment(e.dt).format('YYYY-MM-DD')} type="date" label = "NR."
                                                        onChange = {dtHandler(i)}    
                                                    />
                                                </Grid>                                                
                                            </Grid>

                                        </ListItem>
                                       {i < dosar.anexe.acceptari[accIndex].adreseVanzatori.length-1 && <Divider/>}

                                           
                                    
                                </Fragment>
                            ))}
                        </List>

                                          
                         

                        </Paper>
                        <Grid item xs = {12}>
                            { adrIndex >= 0 ? (
                                <Fragment>
                                    <ANTET_INSTITUTIE data ={dosar.company} />
                                    <div align="right">
                                        Nr.înreg. {current.nr} / {moment(current.dt).format('DD.MM.YYYY')}
                                    </div>
                                    <br/>
                                    <div align="left">
                                        Către: <strong>
                                           {current.vanzator.titular.name}
                                        </strong>
                                     
                                    </div>
                                    <br/>
                                    <div align="center">
                                        <br/><br/>
                                        <Typography variant = "h6">
                                            ADRESĂ DE ÎNAINTARE<br/>
                                        </Typography>
                                    </div>
                                    <br/><br/>
                                    <div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        Referitor la oferta  de vânzare teren înregistrată 
                                        la sediul nostru sub nr. 
                                        <Field>{dosar.nr}</Field> / <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>,{' '}
                                        vă trasmitem alăturat <Field>COMUNICAREA DE ACCEPTARE A OFERTEI DVS DE VÂNZARE</Field>, {' '}
                                        depusă de preemptorul {' '}
                                        <Field>
                                        {dosar.anexe.acceptari[accIndex].titular.name}
                                        </Field>, {' '}
                                      
                                        la data de {' '}
                                        <Field>
                                            {moment(dosar.anexe.acceptari[accIndex].dt).format('DD.MM.YYYY')}
                                        </Field>, {' '}
                                        cu prețul oferit de  <Field> {dosar.anexe.acceptari[accIndex].pret} lei</Field>
                                    </div>
                                    <Grid container>
                                        <Grid item xs = {6}></Grid>
                                        <Grid item xs = {6} align="center">
                                            <br/><br/>
                                            <br/>
                                        Întocmit, <br/>
                                        <Field>{ dosar.user.name}</Field>
                                        <br/><br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/><br/><br/>
                                        L.S. 
                    </Grid>   

                                    </Grid>
                                </Fragment>
                            ) : (
                                <Typography variant = "h4" color = "secondary">
                                    selectati un vanzator
                                </Typography>
                            )}
                        </Grid>
                    </Grid>

                </Grid>
            )}

    
        </Fragment>
    )
}
