import { gql } from '@apollo/client'




export const CONTACT_MUTATION = gql`
mutation contact($data:ContactInput) {
  contact(data:$data){
    id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere  taraNastere cetatenie stCiv domLoc
    domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax  domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs 
  }
}
`
export const OFERTANT_MUTATION = gql`mutation ofertant($data: OfertantInput){
  ofertant(data:$data) { id titularId mandatarId 
    titular {id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
        taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail 
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs}
  mandatar {
    id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
        taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail 
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs
  }
  }
}`


export const OFERTANT_RM = gql`mutation ofertantRm($id: ID) {
  ofertantRm(id:$id)
}`

export const PREEMPTOR_MUTATION = gql`mutation preemptor($data: PreemptorInput){
  preemptor(data:$data) { id titularId mandatarId rangPreemptorId
    rang { id name desc }
    titular {id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
        taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail
        rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail 
        pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
        obs}
  mandatar {
    id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
    taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail 
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
    obs
  }
  
}}`

export const PREEMPTOR_RM = gql`mutation preemptorRm($id: ID) {
  preemptorRm(id:$id)
}  `

export const DOSAR_MUTATION = gql`mutation dosar($data:DosarInput){
  dosar(data:$data) {
    id 
  }
}`

export const DOSAR_RM = gql`mutation dosarRm($id: ID) {
  dosarRm(id:$id)
}  `

export const DOSAR_CLONE = gql`mutation cloneDosar($id: ID) {
  cloneDosar(id:$id) 
}`

export const COMPANY_MUTATION = gql`mutation company($data:CompanyInput) {
  company(data:$data){
    id name cui vat regcom loc str nr bl sc et ap cod jud tara phone fax email iban1 bank1 logo obs website
    users {id username email  name obs role companyId }
  }
}`

export const USER_MUTATION = gql`mutation user($data:UserInput) {
  user(data:$data){
    id username email  name obs role acl companyId 
  }
}`

export const USER_RM = gql`mutation userRm($id: ID) {
  userRm(id:$id)
}  `

export const SETTTING_MUTATION = gql`mutation setting($data:SettingInput) {
  setting(data:$data){
    id prop value desc companyId
  }
}`


export const SETTINGS_UPSERT = gql`mutation settingsUpsert($data: [SettingInput]) {
	settingsUpsert(data:$data) {
		id prop value desc companyId
	}
}`