import React, { useEffect, useState, useContext, useMemo, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router'
import { Context } from '../../components/Store'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { NEXTDOSARNUMBER, REGISTRU } from '../../apollo/query'
import { DOSAR_MUTATION, DOSAR_RM, DOSAR_CLONE } from '../../apollo/mutation'
import { SelectColumnFilter } from '../../components/ReactTable7/filters'
import { } from '../../apollo/mutation'
import {
    Button, LinearProgress, Menu, MenuItem, Typography, TextField, makeStyles, Grid, Fab,
    Dialog, DialogTitle, DialogContent, IconButton, Link, useTheme, useMediaQuery
} from '@material-ui/core'
import ReactTable from '../../components/ReactTable7'
import Container from '../../components/Container'
import moment from 'moment'
// import { SelectColumnFilter, DateFilter }  from '../../components/ReactTable7/filters'
import { MdAdd, MdClose } from "react-icons/md"
import { menuItems } from '../Dosar/menuItems'
import Confirm from '../../components/Confirm'
import Blinker from '../../components/Blinker'
import Updates from '../../components/Updates'
import { diff, endDate } from '../../lib/zileLucratoare'
import { FaDotCircle, FaInfoCircle } from 'react-icons/fa'
import MaterialTableInstance from '../../components/MaterialTableInstance'
import DadrModal from '../../components/DadrModal'
import InfoModal from './InfoModal'

const useStyles = makeStyles(theme => ({
    right: { textAlign: "right", paddingRight: theme.spacing(0.2) },
    total: { textAlign: "right", paddingRight: theme.spacing(0.2), fontWeight: 800 },
}))

const groupTerms = (data, name) => {
    let r = data.reduce((acc, el) => {
        if (!acc.contains(name)) { acc.push(el[name]) }
        return acc
    }, [])
    r = r.reduce((acc, el) => ({ ...acc, [el]: el }), {})
    return r
}

export default function Registru(props) {
    const classes = useStyles()
    const location = useLocation()
    const client = useApolloClient()
    const [registru, setRegistru] = useState([])
    const [state, setState] = useContext(Context)
    const [currentItem, setCurrentItem] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [year, setYear] = useState(+moment().format('YYYY'))
    const [anchorEl, setAnchorEl] = useState(null)
    const [newDosar, setNewDosar] = useState()
    const [next, setNext] = useState(0)
    const history = useHistory()
    const [confirm, setConfirm] = useState()
    const [release, setRelease] = useState()
    const [ infoModal, setInfoModal] = useState()
    const [dadrModal, setDadrModal] = useState(null)
    const theme = useTheme()
    const width = useMediaQuery(theme.breakpoints.up('sm'))


    const nextDosar = useQuery(NEXTDOSARNUMBER)
    const [mutateDosar] = useMutation(DOSAR_MUTATION)
    const [rmDosar] = useMutation(DOSAR_RM)
    const [cloneDosar] = useMutation(DOSAR_CLONE)

    const nxData = nextDosar.data

    const { data, loading, error, refetch } = useQuery(REGISTRU, {
        variables: {
            data: {
                // chunk: { skip: 0, take: 10 },
                ordering: [ {key:"dt", value: `desc`}, {key: "nr", value: "desc"} ]
            }

        },
        fetchPolicy: "cache-first"
    })
    // console.log("state:", state)
    useEffect(() => {
        refetch()
        nextDosar.refetch()
    }, [location.key])

    useEffect(() => {
        if (data && data.registru) {
            client.writeQuery({
                query: REGISTRU,
                data: data
            })
        }
    }, [data])

    useEffect(() => {
        if (nxData) setNext(nxData.nextDosarNumber)
    }, [nxData])

    useEffect(() => {
                // if (data && data.registru) setRegistru(data.registru.map(el => ({
                //        ...el,
                //        dt: moment(el.dt).format('DD.MM.YYYY'),
                //        dtSort: el.dt,
                //        tipDosar: el.tipDosar === "pf" ? "PERS.FIZICĂ" : "PERS.JURIDICĂ",
                //        titulari: el.ofertanti && el.ofertanti.reduce((a, e, i) => a + e.titular.name + (i + 1 < el.ofertanti.length ? ", " : ""), ""),
                //        client: el.company.id + " - " + el.company.name,
                //         end45: moment(endDate(el.dt, 45)).format('DD.MM.YYYY'),
                //        sort45: moment(endDate(el.dt, 45)).format('DD.MM.YYYY'),
                //        end75: moment(endDate(el.dt, 45)).add(30, "days").format('DD.MM.YYYY'),
                //        sort75: moment(endDate(el.dt, 45)).add(30, "days").format('DD.MM.YYYY'),
                //        remainingDays: moment(endDate(el.dt, 45)).diff(moment(), "days")
        
                //    })))
        if (data && data.registru) {
            let res;
            res = data.registru.reduce( (acc,el) => {
                const date45 = moment(endDate(el.dt, 45))
                let elem = {
                    ...el,
                    dt: moment(el.dt).format('DD.MM.YYYY'),
                    dtSort: el.dt,
                    tipDosar: el.tipDosar === "pf" ? "PERS.FIZICĂ" : "PERS.JURIDICĂ",
                    titulari: el.ofertanti && el.ofertanti.reduce((a, e, i) => a + e.titular.name + (i + 1 < el.ofertanti.length ? ", " : ""), ""),
                    client: el.company.id + " - " + el.company.name,
                    end45: date45.clone().format('DD.MM.YYYY'),
                    sort45: date45.clone().format('DD.MM.YYYY'),
                    end75: date45.clone().add(31, "days").format('DD.MM.YYYY'),
                    sort75: date45.clone().add(31, "days").format('DD.MM.YYYY'),
                    remainingDays: date45.clone().diff(moment(), "days")
                }
                return [...acc, elem]
            }, [])
            setRegistru(res)
        }
    }, [data])


    const dadrModalOpen = () => setDadrModal(data)

    const dadrModalClose = () =>setDadrModal(null)

    const columns = useMemo(() => {
        // console.log("State:", state)
        const userCols = [

            {
                title: "Nr.",
                field: "nr",
                width: "7%", align: "center",
                // defaultSort: "desc"
            },
            {
                title: "din data",
                field: "dtSort",
                // defaultSort: "desc",
                
                render: row => row.dt,
                width: "15%", align: "center"
            },
            {
                title: "Tip dosar",
                field: "tipDosar", width: "12%",
            },
            {
                title: "Titular",
                field: "titulari",
                width: "50%", align: "left"
            },
            {
                title: <div align="center">45 zile<br />lucrătoare</div>,
                field: "sort45",
                width: "5%", align: "center"
            },
            {
                title: <div align="center">Scadență<br />etapa I</div>,
                field: "remainingDays",
                align: "center",
                width: "5%"
            },
            {
                title: <div>Se împlinesc<br />45 zl + 30 zc</div>,
                field: "sort75",
                // render: (row) => <div align="center"><small>{row.end75}</small></div>, 
                width: "5%", align: "center"
            },
            {
                title: <div align="center" style={{ color: "red" }}>Stadiu<br />dosar</div>,
                field: "status",
                // render: (row) => <div align="center"><small>{row.end75}</small></div>, 
                width: "5%", align: "center",
                lookup: { OPERARE: "OPERARE", FINALIZAT: "FINALIZAT", RETRAS: "RETRAS", RESPINS:"RESPINS" }
            },
            // {
            //     title: "Acțiuni",
            //     id:"actiuni",
            //     render: row => {
            //         console.log(row);
            //         return <a href = "#" style = {{zIndex:99999}} onClick = {contextHandler(row, row.tableData.id)} >...</a>
            //     }
            // }
        ]

        const appCols = [
            {
                title: "Id",
                field: "id",
                width: "5%",
                align: "center",
                // render: (row) => +row.id + 1
            },
            {
                title: "CLIENT",
                field: "client",
                // render: row => row.client,
                // lookup: {1:"1 - UAT COMUNA DEMO", 2:"ZIMANDU NOU" }
            }]

        return state.role === "app" ? [...userCols, ...appCols] : userCols
    }, [state])


    const contextHandler = (ev, row) => {
        setCurrentItem(row)
        setCurrentIndex(row.tableData.id)
        setAnchorEl(ev.currentTarget)
    }

    const closeMenuHandler = () => {
        setCurrentItem(); setAnchorEl()
    }


    const addHandler = () => {
        const now = new Date()
        const menuItems2json = () => {
            let res = menuItems.reduce((acc, i) => {
                return {
                    ...acc,
                    [i.id]: {
                        name: i.title,
                        dt: now,
                        nr: next
                    }
                }
            }, {})
            res = {
                preemptoriCerere: [],
                documenteCerere: [],
                sarcini: false,
                litigii: false,
                terenHa: "",
                terenLoc: state.company.loc,
                terenJud: state.company.jud,
                terenNrCadastral: "",
                terenCf: "",
                terenCfLoc: state.company.loc,
                terenTarlaLot: "",
                terenParcela: "",
                terenCategorie: "ARABIL",
                terenObs: "",
                terenPret: "",
                terenConditii: "",
                notificari: [], // preemptorId, nr, dt, modalitate(posta, email, afisare)
                acceptari: [], // preemptorId, nr, dt, pret, documente:[]
                alegerePreemptor: {},
                ...res,

            }
            return JSON.stringify(res)
        }
        setNewDosar(
            {
                id: 0,
                nr: next,
                dt: now,
                tipDosar: "pf",
                anexe: menuItems2json(),
                // anexe: JSON.stringify(menuItems.reduce( (acc, el) => [...acc, {
                //     id: el.id,
                //     nr: 999,
                //     dt: new Date(),
                //     title: el.title,
                //     content: JSON.stringify({})
                // }], [])),
                status: "OPERARE",
                companyId: +state.companyId,
                userId: +state.id
            }
        )
    }
    const nrHandler = name => ev => setNewDosar({ ...newDosar, [name]: +ev.target.value })
    const dtHandler = name => ev => setNewDosar({ ...newDosar, dt: moment(ev.target.value).toDate() })

    const toggleTypeHandler = name => () => setNewDosar({ ...newDosar, tipDosar: name })

    const closeModalHandler = () => {
        let q = { ...newDosar }
        mutateDosar({ variables: { data: q } })
            .then(
                res => history.push(`/dosar/${res.data.dosar.id}`),
                err => console.log("err:", err))
        setNewDosar()

    }

    const confirmCloseHandler = ev => {
        if (ev) {
            rmDosar({ variables: { id: +currentItem.id } }).then(
                r => {
                    // console.log(r); alert("șters!");
                    let rg = [...registru]
                    rg.splice(currentIndex, 1)
                    setRegistru(rg)
                },
                err => { console.log("err: ", err); alert("eroare stergere!") }
            )
        }
        setCurrentItem(); setConfirm(); setAnchorEl();
    }

    const cloneHandler = () => {
        cloneDosar({ variables: { id: currentItem.id } })
            .then(
                r => {
                    if (r.data) { history.push('/dosar/' + r.data.cloneDosar) }
                },
                err => { console.log("error:", err); alert(err.toString()) }
            )
        setCurrentIndex(); setCurrentItem(); setAnchorEl();
    }

    return (
        <Container
            title={
                <Fragment>
                    {width ?
                        (
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Fragment></Fragment>
                                <small>&nbsp;&nbsp;&nbsp; REGISTRU DOSARE VÂNZĂRI TERENURI - LEGEA 17/2014 / LEGEA 145/2020 / ORD.311/2020 </small>
                                {/* <Blinker disabled={true} style={{ fontWeight: 800 }}> */}
                                <Button variant ='contained' color ="primary" onClick = {dadrModalOpen}>
                                        SITUATIE LUNARĂ DADR
                                        </Button>
                                <Button onClick={ev => { ev.preventDefault(); setRelease(true) }}>
                                    <Grid container alignItems="center" style={{ lineHeight: 1, fontSize: "0.6rem", fontWeight: 400 }}>

                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <FaInfoCircle color="royalblue" size="1.3rem" />
                                        &nbsp;&nbsp;
                                        <div align="center">
                                            ULTIMELE<br />ACTUALIZĂRI
                                        </div>
                                    </Grid>
                                </Button>
                                {/* </Blinker> */}

                            </Grid>
                        ) : (
                            <div>REGISTRU</div>
                        )}

                </Fragment>}
            action={
                <div>

                    <Fab color="primary" variant="circular" size="small" onClick={addHandler}>
                        <MdAdd size="2rem" />
                    </Fab>
                    &nbsp;&nbsp;

                </div>
            }

        >
            {loading && <LinearProgress />}
            {error && <div style={{ height: "80vh", overflow: "auto" }}><pre>{JSON.stringify(error, null, 4)}</pre></div>}
            {data && data.registru && (
                <Fragment>
                    <div style={{ height: "80vh", overflow: "auto" }}>
                        <MaterialTableInstance
                            title={<Grid container alignItems="center" spacing={0}>
                                <h3> LISTĂ DOSARE</h3>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button 
                                    size='small'
                                    style = {{height: "30px", padding: "0 20px 0 10px"}}
                                    variant='outlined' color="default"
                                    startIcon={<FaInfoCircle color="blue" size="1.2em"/>}
                                    onClick = {()=>setInfoModal(registru)}
                                >
                                    <strong style = {{color:"blue"}}>AVERTIZĂRI</strong>
                                </Button>
                            </Grid>}
                            options={{
                                
                                filtering: true,
                                selection: false,
                                // maxBodyHeight: "68vh",
                                headerStyle: {
                                    padding: "5px",
                                    // border: "1px solid black",
                                    lineHeight: 1.2,
                                    // minHeight: "30px",
                                    backgroundColor: 'aliceblue',
                                    color: 'navy',
                                    fontSize: "0.8rem"
                                },
                                cellStyle: { padding: 0, fontSize: "0.8rem", color: "navy" },
                                rowStyle: {
                                    // height: "40px"
                                },
                                pageSize: 7,
                                pageSizeOptions: [5, 7, 10, 12, 20, 50, 100, 1000],
                                grouping: true
                            }}

                            data={registru}
                            columns={columns}
                            onRowClick={(ev, row) => { ev.preventDefault(); props.history.push(`/dosar/${row.id}`) }}
                            // style={{ minHeight: "84vh", overflow: "auto" }}
                            actions={[
                                {
                                    tooltip: 'ACȚIUNI ',
                                    icon: () => <FaDotCircle color="grey" size="0.8rem" />,
                                    onClick: contextHandler
                                }
                            ]}
                        />
                    </div>
                </Fragment>
            )}

            <Dialog open={Boolean(newDosar)} onClose={() => setNewDosar()}>
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" color="primary">ADĂUGARE DOSAR</Typography>
                        <IconButton color="secondary"
                            onClick={() => setNewDosar()}><MdClose size="2rem" /></IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {newDosar && (
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={5}>
                                <TextField variant="outlined" fullWidth type="number" step="1"
                                    value={newDosar.nr} onChange={nrHandler('nr')} label="NUMĂRUL" />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField variant="outlined" fullWidth size="medium" type="date"
                                    value={moment(newDosar.dt).format('YYYY-MM-DD')}
                                    onChange={dtHandler('dt')} label="DIN DATA" />
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth size="medium"
                                    onClick={toggleTypeHandler('pf')}
                                    variant={newDosar.tipDosar === "pf" ? "contained" : "outlined"}
                                    color="primary">
                                    <div align="center" style={{ fontSize: "1.1rem" }}>PERSOANĂ <br />FIZICĂ</div>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth size="medium"
                                    onClick={toggleTypeHandler('pj')}
                                    variant={newDosar.tipDosar === "pj" ? "contained" : "outlined"}
                                    color="primary">
                                    <div align="center" style={{ fontSize: "1.1rem" }}>PERSOANĂ <br />JURIDICĂ</div>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained" color="secondary" size="medium" fullWidth
                                    onClick={closeModalHandler}
                                >
                                    <Typography variant="h5">CONFIRMĂ</Typography>
                                </Button>
                            </Grid>
                            {/* <pre>{JSON.stringify(newDosar, null, 4)}</pre> */}

                        </Grid>
                    )}

                </DialogContent>


            </Dialog>
            <Dialog open={Boolean(release)} onClose={() => setRelease()}>
                <DialogContent>
                    <Updates />
                </DialogContent>

            </Dialog>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenuHandler} >
                <MenuItem onClick={cloneHandler}>CLONEAZĂ</MenuItem>
                <MenuItem onClick={() => setConfirm(true)}>ȘTERGE</MenuItem>
            </Menu>
            <Confirm open={confirm} onClose={confirmCloseHandler} />
            {dadrModal && (
                <DadrModal data = {dadrModal} onClose={dadrModalClose} />
            )}
            {infoModal && <InfoModal data = {infoModal} onClose = {()=>setInfoModal(null)} />}
        </Container>
    )
}
