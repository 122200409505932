import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import {useQuery, useMutation} from '@apollo/client'
import {COMPANY_MUTATION} from '../../apollo/mutation'
import RichCard from '../../components/RichCard'
import FunkyInput from '../../components/FunkyInput'
import {fields} from './companyFields'
import { Grid } from '@material-ui/core'
import {FaCheckCircle} from 'react-icons/fa'
import { IconButton } from '@material-ui/core'
import {clearTypeName} from '../../lib/graph-stuff'

const COMPANY = gql`query company {
    company {id name cui vat regcom loc str nr bl sc et ap jud cod tara phone fax email bank1 iban1 logo obs website}
  }`


export default function SetariCompanie() {

    const [item,setItem] = useState()
    const {data,loading,error} = useQuery(COMPANY, {variables: {}})
    const [mutateCompany] = useMutation(COMPANY_MUTATION)

    useEffect(() => {
        if (data && data.company) {setItem({...data.company})}
    },[data])

    const inputHandler = name => ev => {
        console.log("name:", name, "ev:", ev.target.value)
        setItem({...item, [name]: ev.target.value})
    }
    
    const submitHandler = () => {
        mutateCompany({variables: {data: clearTypeName(item)}})
            .then( 
                r => console.log("r:", r),
                err => console.log("err:", err)
            )
    }
    return (
        <div>
            {item && 
                <RichCard 
                    title = "DATE COMPANIE" 
                    action = {
                        <IconButton size = "small" color = "primary" onClick = {submitHandler}>
                            <FaCheckCircle/>
                        </IconButton>
                    }
                    style = {{background: "floralwhite"}}
                >
                    <div  style = {{overflow:"visible", }}>
                        <Grid container justifyContent= "flex-start" alignItems ="center" spacing={1}> 
                            {fields.map( (e,i) => (
                                <Grid item key={i} xs = {e.xs} sm = {e.sm} lg = {e.lg}>
                                    {e.key &&
                                    <FunkyInput 
                                        multiline = {e.lines ? true: false}
                                        minRows = {e.lines || null}
                                        value = {item[e.key]} label = {e.label.toUpperCase()}
                                        onChange = {inputHandler(e.key)}
                                    />
                                    }
                                </Grid>
                            ))}
                        </Grid>
                      
                    </div>
                </RichCard>
            }
        </div>
    )
}
