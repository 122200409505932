import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles, Grid , Typography} from '@material-ui/core'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import Field from '../../Common/Field'
import UAT from '../../Common/UAT'


const useStyles = makeStyles(theme => ({
    noprint: {
        "@media print": { display: "none" },
        "@media screen": { zoom: 1.1 }
    }
}))

export default function AdresaDADRPrintTpl(props) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [dosar, setDosar] = useState()

    useEffect(() => {
        if (props.data && props.data.item) {
            setItem(props.data.item)
            setDosar(props.data.dosar)
        }
    }, [props])

    const nrHandler = ev => props.onChange({...item, dadr: {...item.dadr, nr: ev.target.value }})
    const dtHandler = ev => props.onChange({...item, dadr: {...item.dadr, dt: new Date(ev.target.value) }})

    return (
        <Fragment>
            {item && (
                <Fragment>
                    {console.log("cur iten:", item)}
                    <Grid container alignItems="center" spacing={2} className={classes.noprint}>
                        <Grid item>ADRESĂ D.A.D.R. </Grid>
                        <Grid item xs={3}>
                            <FunkyInput value={item.dadr.nr} label="NR." 
                              onChange = {nrHandler}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FunkyInput value={moment(item.dadr.dt).format('YYYY-MM-DD')} type="date" label="DIN DATA" 
                                onChange = {dtHandler}
                            />
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container>
                        <Grid item xs={12}>
                            <ANTET_INSTITUTIE data={dosar.company} />

                            <div align="right">
                                Nr.înreg. {item.dadr.nr} / {moment(item.dadr.dt).format("DD-MM-YYYY")}
                            </div>
                            <br/>
                            <div align="left">
                                Către <br/><strong>Direcția pentru agricultură și dezvoltare rurală a județului  {dosar.company.jud}</strong>
                            </div>
                            <div align="left">
                                Ref. la: {' '}
                                <strong>Ofertă vânzare teren nr.  {dosar.nr} / {moment(dosar.dt).format('DD.MM.YYYY')}</strong>
                            </div>                            
                            <br/><br/><br/>
                            <Typography variant = "body1" align="center">
                                ADRESĂ DE ÎNAINTARE <br/>
                                a ofertei de cumpărare a terenului agricol extravilan <br/>
                                din oferta de vânzare {' '}
                                nr. <Field>{dosar.nr}</Field> din <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>
                            </Typography>
                            <br/><br/><br/>
                            <div>
                                Subscrisa, <UAT data = {dosar.company} />, vă înaintăm alăturat {' '}
                                <Field>oferta de cumpărare teren (anexa 1F)</Field>, depusă la sediul nostru de către {' '}
                                <Field>{item.ofertant[0].titular.name}</Field>, înregistrată sub nr. {' '}
                                <Field>{item.nr}</Field> / <Field>{moment(item.dt).format('DD.MM.YYYY')}</Field>
                            </div>
                        </Grid>
                        <Grid item xs = {12} style ={{paddingLeft: "50%"}}>
                                        <br/><br/><br/>
                                        Întocmit, <br/>
                                        <Field>{ dosar.user.name}</Field>
                                        <br/><br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/><br/><br/>
                                        L.S. 
                                       
                        </Grid>                          
                    </Grid>
                    {/* <pre> 
                    {props && JSON.stringify(props,null,4)}
                </pre> */}
                </Fragment>
            )}



        </Fragment>
    )
}
