import React, { Fragment } from 'react'
import moment from 'moment'

const Field = props => {
    return (<strong>{props.children || ' - - - - -'}</strong>)
}

export default function Prin(props) {
    const item = props || {}
    return (
        <div>
            {item  && item.cnp && (
                <Fragment>
                   4. (***) Prin <Field>{item.name}</Field>, 
                        localitatea <Field>{item.domLoc}</Field>, str. <Field>{item.domStr}</Field>, 
                        nr. <Field>{item.domNr}</Field>, bl. <Field>{item.domBl}</Field>, 
                        sc. <Field>{item.domSc}</Field>, et. <Field>{item.domEt}</Field>, 
                        ap. <Field>{item.domAp}</Field>, judeţul/sectorul <Field>{item.domJud}</Field>, 
                        codul poştal <Field>{item.domCod}</Field>, telefon <Field>{item.domTel}</Field>, 
                        fax <Field>{item.domFax}</Field>, e-mail <Field>{item.domEmail}</Field>
                       
                </Fragment>
            )}

                {item  && item.cui && (
                <Fragment>
                   4. (***) Prin <Field>{item.name}</Field>, 
                     având număr de ordine în (**) <Field>{item.pjRegType}</Field>, <Field>{item.pjRegNr    }</Field>,  
                      CIF/CUI <Field>{item.cui}</Field>, 
                    3. (*) cu sediul în localitatea <Field>{item.pjLoc}</Field>,  
                    str. <Field>{item.pjStr}</Field>,  nr. <Field>{item.pjNr}</Field>,  
                    bl. <Field>{item.pjBl}</Field>,  sc. <Field>{item.pjSc}</Field>,  et.<Field>{item.pjEt}</Field>, 
                     ap. <Field>{item.ap}</Field>,  
                    judeţul/sectorul <Field>{item.pjJud}</Field>,  codul poştal <Field>{item.pjCod  }</Field>,  
                    telefon <Field>{item.pjTel}</Field>,  fax <Field>{item.pjFax}</Field>, 
                    e-mail <Field>{item.pjEmail}</Field>,  site <Field>{item.pjSite}</Field>, 
                    {props.mandat && (
                        <Fragment>
                            reprezentată legal de {' '}
                    
                    <Field>{props.mandat.prin}</Field>, 
                    în calitate de {' '} 
                    <Field>{props.mandat.calitate}</Field>{' '}
                    conform{' '}
                    <Field>{props.mandat.conform}</Field>{' '}
                    </Fragment>
                    )}

                       
                </Fragment>
            )}

        </div>
    )
}
