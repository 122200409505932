import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import RichCard from '../../../components/RichCard';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import { Button, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import Boxy from "../../../components/Boxy"
import { FaChevronCircleLeft, FaPlusCircle, FaTimesCircle } from "react-icons/fa"
import { MdPrint } from "react-icons/md"
import PrintCerere from '../PrintModals/PrintCerere';
import Tooltip from "../../../components/Tooltip"
import PrintPvAtestat from '../PrintModals/PrintPvAtestat';
import { deepClearTypeName } from '../../../lib/graph-stuff';
import Cerere from './Cerere';
import AvizConsultativ from './AvizConsultativ';
import PvAtestat from './PvAtestat';
import ListaCarnete from './ListaCarnete';
import { CERERE_ATESTAT_MUTATION } from "../../../apollo/mutationAtestate"
import NotifyBottom from '../../../components/NotifyBottom';



const LIST_QUERY = gql`
  query list($data: ComplexQuery) {
    atCereri(data:$data) {
		id nr dt contactId avizNr avizDt avizEmitent
		contact { id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
        taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara 
        }
		atProcesVerbal {
            companyId contactId atCerereId nr dt expiry 
            agricolVol agricolNr agricolArabil agricolLivezi agricolVii agricolAnimale agricolPasari agricolAlbine 
            atestatSeria atestatNr atestatDt
				atCarnete {
					id
                    contactId
					seria nr dt filaStart filaEnd dtElib dtPredare 
					produse {
						id 
                        ha ari efective estimat solicitat
						atProdus {
						    id tip_produs den_produs
					    }
					}
                    gospodari {
                        id 
                        contact { 
                            id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
                            taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara 
                        }
                    }
				}
		}
    }

	
}
`

export default function CerereAtestat() {

    const params = useParams()
    const history = useHistory()

    const { id } = params
    const [item, setItem] = useState()
    const [getCerere, { data, loading, error }] = useLazyQuery(LIST_QUERY)
    const [mutate, dispatch] = useMutation(CERERE_ATESTAT_MUTATION)
    const [notify, setNotify] = useState(null)
    
    const [printCerere, setPrintCerere] = useState(null)
    const [printPv, setPrintPv] = useState(null)

    const [memberModal, setMemberModal] = useState(null)

    useEffect(() => {
        if (id !== "noua") {
            getCerere({ variables: { data: { id: id } } })
        } else {
            setItem({
                nr: 1, dt: new Date(), avizNr: 0, avizDt: new Date(), avizEmitent: "",
                atCerereGospodari: []
            })
        }
    }, [id])

    useEffect(() => {
        if (id !== "noua") setItem(data && data.atCereri ? data.atCereri[0] : null)
    }, [data])

    const removePvHandler = () => setItem({
        ...item,
        atProcesVerbal: null
    })

    const addPvHandler = () => setItem({
        ...item,
        atProcesVerbal: {
            nr: item.nr,
            dt: new Date(),
            atCarnete: [],

        }
    })



    const childHandler = ev => setItem(ev)

    const submitHandler = () => {

        let ni = { ...item }
        ni = encodeURI(JSON.stringify(deepClearTypeName(ni)))
        mutate({variables: {data: ni}}).then( 
            r => {
                let id = r.data.atUpdateCerere.id
                if ( !item.id ) {
                    history.push("/registru-atestate/cerere/" + id)
                } else {
                    setNotify("DATE ACTUALIZATE CU SUCCES!")
                }
            },
            err => {
                console.log("Err: ", err)
                setNotify(err.toString())
            }
        
        )
        
    }

    return (
        <RichCard
            style={{ height: "85vh" }}
            title={
                <Grid container alignItems="center">
                    <IconButton size="small" onClick={() => history.push("/registru-atestate/cereri")}>
                        <FaChevronCircleLeft color="grey" />
                    </IconButton>
                    <Typography variant="subtitle2" style={{ marginLeft: "10px" }}>DOSAR ATESTAT</Typography>
                </Grid>
            }
            action={
                <Button
                    size="small" color="secondary" variant="contained"
                    disabled={!item || (item && !(item.contactId && item.nr && item.dt && item.avizNr && item.avizEmitent))}
                    onClick={submitHandler}
                >
                    CONFIRMĂ</Button>
            }

        >
            {(item && !loading)
                ? (<Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7}>
                            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <Boxy
                                        title="CERERE ELIBERARE ATESTAT DE PRODUCĂTOR"
                                        style={{ background: "white" }}
                                        contentStyle={{ height: "63vh", background: "white" }}
                                        action={item.id
                                            && <IconButton size="small" color="primary" onClick={() => setPrintCerere(item)}>
                                                <MdPrint />
                                            </IconButton>}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Cerere data={item} onChange={childHandler} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AvizConsultativ data={item} onChange={childHandler} />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Boxy
                                                    title="PROCES-VERBAL EMITERE ATESTAT"
                                                    style={{ background: "white" }}
                                                    contentStyle={{ background: "white" }}
                                                    footer={!item.atProcesVerbal && <small>
                                                        &nbsp;&nbsp;pentru a crea p.v., folosește butonul albastru cu plus
                                                        din partea dreaptă sus a acestui box</small>}
                                                    action={
                                                        !item.atProcesVerbal
                                                            ? <IconButton color="primary" size="small" onClick={addPvHandler}>
                                                                <FaPlusCircle />
                                                            </IconButton>
                                                            : <Fragment>
                                                                <Tooltip title={<div>elimină pv.<br />atentie!<br />
                                                                    VOR FI ELIMINATE SI EVENTUALELE CARNETE!!!
                                                                </div>}>
                                                                    <IconButton color="secondary" size="small" onClick={removePvHandler}>
                                                                        <FaTimesCircle />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                &nbsp;
                                                                <IconButton size="small" color="primary" onClick={() => setPrintPv(item)}>
                                                                    <MdPrint />
                                                                </IconButton>
                                                            </Fragment>
                                                    }
                                                >
                                                    {item.atProcesVerbal
                                                        ? <PvAtestat data={item} onChange={childHandler} />
                                                        : <Alert severity='info' align="center">
                                                            NU A FOST ÎNCĂ ÎNTOCMIT PROCESUL VERBAL<br />
                                                        </Alert>
                                                    }
                                                </Boxy>
                                            </Grid>
                                        </Grid>
                                    </Boxy>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={5}>

                            <Grid item xs={12}>
                                <Boxy
                                    title="CARNETE DE COMERCIALIZARE"
                                    style={{ background: "white" }}
                                    contentStyle={{ height: "65.5vh", overflow: "auto", background: "white" }}

                                    footer={<small>&nbsp;&nbsp;&nbsp;faceți click pe un rând pentru a edita un carnet și
                                        click dreapta pentru stergerea unui carnet</small>}
                                >
                                    <ListaCarnete data = {item} onChange={childHandler} />
                                    <div style = {{width: "100%", display:"block", overflowWrap:"break-word", wordWrap: "break-word" }}>
                                    {/* <small>{encodeURI(JSON.stringify(item))}</small>*/}
                                    {/* <small><pre>{(JSON.stringify(item, null,2))}</pre> </small>  */}
                                    </div>
                                </Boxy>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fragment>)
                : <LinearProgress/>
            }
            
            {printCerere && <PrintCerere data={printCerere} onClose={() => setPrintCerere(null)} />}
            {printPv && <PrintPvAtestat data={printPv} onClose={() => setPrintPv(null)} />}
            {notify && <NotifyBottom show = {notify} onClose = {()=>setNotify()} />}

        
        </RichCard>
    )
}
