import { Grid, makeStyles, Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import { FaCheck } from 'react-icons/fa'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'
import UAT from '../Common/UAT'
import { getSetting } from '../../../lib/settings'
import ANTET_CERERE from '../Common/ANTET_CERERE'
import { Alert } from '@material-ui/lab'
import { MdPrint } from 'react-icons/md'

const cod = "anexa3a";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function AdresaADS1(props) {
    const codAnexa = "ads1"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()

   
    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const inputAnexeHandler = name => ev => {
        let nd = {...dosar}
        nd['anexe'][name] = ev.target.value
        props.onChange(nd)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                        <Grid item sm={2} xs={4}>
                                            <FunkyInput
                                                value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                onChange={anexaInputHandler('nr')} />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <FunkyInput
                                                value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                onChange={anexaInputHandler('dt')}
                                            />
                                        </Grid>
                                    </Grid>
                                </RichCard>
                               {dosar && dosar.anexe && (
                                <RichCard title = "SITURI, MONUMENTE">
                                <Alert severity = "warning">
                                    Dacă există situri sau monumente pe terenul agricol care face obiectul ofertei de
                                    vânzare, vă rugăm să completați mai jos. <br/>
                                    <div align ="center">
                                    <strong style = {{color:"red"}}>ATENȚIE!!!</strong><br/>
                                    </div>
                                    Lăsați câmpurile goale, nu treceți linuție sau icsuri sau orice alt caracter!!!! 
                                    Orice caracter înscris în câmpurile de mai jos va conduce la valoarea <strong>"DA"</strong>
                                     în formularul generat iar vânzarea-cumpărarea se supune dispoziţiilor Legii nr. 422/2001 privind 
                                     protejarea monumentelor istorice, republicată, cu modificările ulterioare!!!
                                </Alert>
                                <br/>
                                <FunkyInput 
                                    value = {dosar.anexe.situri || ""} multiline minRows = {2}
                                    onChange = {inputAnexeHandler('situri')} 
                                    label = "pe terenul agricol situat în extravilan se află situri arheologice,
                                     au fost instituite zone cu patrimoniu arheologic reperat sau zone cu potenţial 
                                     arheologic evidenţiat întâmplător"
                                />
                                 <br/> <br/>
                                <FunkyInput 
                                    value = {dosar.anexe.monumente || ""} multiline minRows = {2}
                                    onChange = {inputAnexeHandler('monumente')} 
                                    label = "pe terenul agricol situat în extravilan se află situri arheologice clasate ca monument istoric"
                                />
                                </RichCard>
                               )}

                               
                            </Grid>
                          
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action = {
                                <Button variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                    <MdPrint size = "1.6em"/>
                                </Button>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className="printable"
                                    ref={printRef} >
                                     <Grid item xs={12}>
                                        <ANTET_INSTITUTIE data = {dosar.company}/>
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD-MM-YYYY")}
                                    </Grid>
                                    <Grid item xs={12} align = "left">
                                        <ANTET_CERERE data = {dosar} />
                                    </Grid>
                                    <Grid item xs={12} align = "center">
                                        <br/>
                                        <strong>ADRESĂ </strong>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={12}  className = {classes.text}>
                                        În baza cererii depuse de
                                        <PF_PJ data = {dosar.ofertanti} noPrefix/>
                                        și înregistrată la <Field>{dosar.company.name}</Field>,{' '}
                                        cu numărul {' '}
                                        <Field>{dosar.nr}</Field> din <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>, {' '}
                                        ţinând cont de prevederile art. 3 alin. (4) din Legea nr. 17/2014 privind unele măsuri de 
                                        reglementare a vânzării-cumpărării terenurilor agricole situate în extravilan şi de modificare
                                        a Legii nr. 268/2001 privind privatizarea societăţilor comerciale ce deţin în administrare 
                                        terenuri proprietate publică şi privată a statului cu destinaţie agricolă şi înfiinţarea 
                                        Agenţiei Domeniilor Statului, cu modificările ulterioare, pentru terenul în suprafaţă de{' '}
                                        <Field>{dosar.anexe.terenHa}</Field> ha, {' '}
                                        identificat cu număr cadastral{' '}
                                        <Field>{dosar.anexe.terenNrCadastral}</Field>, {' '}
                                        înscris în cartea funciară nr.{' '}
                                        <Field>{dosar.anexe.terenCf}</Field>, {' '}
                                        a localităţii.{' '}
                                        <Field>{dosar.anexe.terenCfLoc}</Field>, {' '}
                                        , prin prezenta adresă se certifică următoarele:
                                        <br/><br/>
                                        
                                        - pe terenul agricol situat în extravilan se află situri arheologice, au fost instituite zone cu patrimoniu arheologic reperat sau zone cu potenţial arheologic evidenţiat întâmplător<br/>
                                        <small><i>
                                        *) Dacă această opţiune este bifată, avizul specific al Ministerului Culturii este necesar.
                                        </i></small>
                                        <br/>
                                        <div align = "center">
                                            [
                                                {dosar.anexe.situri 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] DA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                            [
                                                {!dosar.anexe.situri 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] NU
                                        </div>
                                        <br/>
                                        - pe terenul agricol situat în extravilan se află situri arheologice clasate ca monument istoric
                                        <br/>
                                        <div align = "center">
                                            [
                                                {dosar.anexe.monumente 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] DA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                            [
                                                {!dosar.anexe.monumente 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] NU
                                        </div>
                                        
                                        {/* - terenul mentionat mai sus, nu figureaza in evidentele noaste,ca fiind arendat. */}
                                       
                                        Declarăm că prin preluarea cererii de afișare a ofertei de vânzare teren extravilan am obținut acordul explicit al vânzătorului/vânzătorilor pentru prelucrarea și trasnmiterea datelor persoanale către instituția Dvs. în scopul păstrării și prelucrării datelor, în limitele prevâzute de legea 17/2014, iar vânzătorul/vânzătorii au fost informat(i) cu privire la drepturile ce îi/le revin pe baza Regulamentului (EU)679/2016 privind protecția datelor cu caracter personal (GDPR).
                                    </Grid>
                                    <Grid item xs = {5} align = "center">
                                            Primar, <br/>
                                            <Field>{ getSetting(dosar.settings, "primar")}</Field>
                                            <br/><br/>
                                            .......................................................<br/>
                                            <small>(numele şi prenumele/semnătura)</small> 
                                            </Grid>
                                            <Grid item xs = {1}>
                                            L.S.
                                            </Grid>
                                            <Grid item xs = {5} align = "center">
                                            Secretar general U.A.T., <br/>
                                            <Field>{ getSetting(dosar.settings, "secretar")}</Field>
                                            <br/><br/>
                                            .......................................................<br/>
                                            <small>(numele şi prenumele/semnătura)</small>
                                        </Grid>                              
                                    
                                
                            </Grid>
                             )}
                            </div>
                            </RichCard>
                        
                    </Grid>
                </Grid>
            )}
        </Fragment>

    )
}

