import React, {Fragment, useMemo, useState} from 'react';
import { PROCESEVERBALE } from '../../../apollo/queryAtestate';
import { UPDATE_ATPROCESVERBAL } from "../../../apollo/mutationAtestate"
import { useQuery, useMutation} from '@apollo/client';
import Boxy from "../../../components/Boxy"
import ReactTable from "../../../components/ReactTable7"
import {deepClearTypeName} from "../../../lib/graph-stuff"
import moment from 'moment';
import { Grid, IconButton, makeStyles, TextField } from '@material-ui/core';

import { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';


const useStyles = makeStyles(theme => ({
  table: {
      '&& thead>tr>th, tfoot>tr>td': {
          border: "1px solid lightgrey",
          background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
      },
      '&& tbody>tr>td, tfoot>tr>td': {
          border: "1px solid lightgrey",
          fontSize: "0.7rem",
          padding: theme.spacing(0.5)
      },
      '&& tbody>tr:hover': {
          background: theme.palette.grey[300],
          cursor: "zoom-in",
          border: "2px solid navy",
      },
  }
}))

const RenderValues = ({data, index, onSubmit}) => {

  const [item, setItem] = useState()
  useEffect(() => setItem(data), [data])

  const changeHandler = name => ev => setItem({...item, [name]: +ev.target.value, modified: true })

  const submitHandler = () => onSubmit(item, index)

  return <Fragment>
    {item && (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs> <TextField size="small" fullWidth variant="outlined"  type="number" value={item.agricolVol} label="vol" onChange = {changeHandler("agricolVol")}/></Grid>
        <Grid item xs><TextField size="small" fullWidth variant="outlined" type="number" value={item.agricolNr} label="nr" onChange = {changeHandler("agricolNr")}/> </Grid>
        <Grid item xs><TextField size="small" fullWidth variant="outlined" type="number" value={item.agricolArabil} label="arabil" onChange = {changeHandler("agricolArabil")} /> </Grid>
        <Grid item xs><TextField size="small" fullWidth variant="outlined" type="number" value={item.agricolLivezi} label="liv."  onChange = {changeHandler("agricolLivezi")}/> </Grid>
        <Grid item xs><TextField size="small" fullWidth variant="outlined" type="number" value={item.agricolVii} label="vii"  onChange = {changeHandler("agricolVii")}/> </Grid>
        <Grid item xs><TextField size="small" fullWidth variant="outlined" type="number" value={item.agricolAnimale} label="anim."  onChange = {changeHandler("agricolAnimale")}/> </Grid>
        <Grid item xs><TextField size="small" fullWidth variant="outlined" type="number" value={item.agricolPasari} label="pasari"  onChange = {changeHandler("agricolPasari")}/> </Grid>
        <Grid item xs><TextField size="small" fullWidth variant="outlined" type="number" value={item.agricolAlbine} label="albine"  onChange = {changeHandler("agricolAlbine")}/> </Grid>
        <Grid item  >
          {item.modified && (<IconButton size="small" color="secondary" onClick = {submitHandler}><FaCheckCircle/></IconButton>) }
          </Grid>
      </Grid>
    )}
  </Fragment>
}

export default function ServiceMenu() {

  const [filters, setFilters] = useState({
    ordering: [{key: "dt", value: "asc"}]
  })
  const classes = useStyles()
  const [items, setItems] = useState([])
  const {data, loading, error} = useQuery(PROCESEVERBALE, {variables: {data: filters}})
  const [update, dispatch] = useMutation(UPDATE_ATPROCESVERBAL)

  
  useEffect( () => {
    if ( data && data.atProceseVerbale) {
      setItems(deepClearTypeName(data.atProceseVerbale))
    }
  }, [data])



 const updateHandler = async (item,index) => {

   let payload = {...item}
   delete payload.contact
   delete payload.modified
   update( {variables:{data: payload }} ).then( 
     r => {
      console.log("r:", r)
     },
     err => console.log("err:", err)
   )
   let ni = [...items]
   ni[index] = {...ni[index], modified: false}
   setItems(ni)
 }


  return <Fragment>
  
  <br/>
  <Boxy title ="PROCESE VERBALE" contentStyle={{overflow: "auto", height: "65vh"}}>
      {data && <Fragment>
        
            <table className = {classes.table}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>TITULAR</th>
                    <th>NR</th>
                    <th>DATA</th>
                    <th>REG.AGRICOL</th>
                  </tr>
                </thead>
                <tbody>
                  {items && items.map( ( e,i ) => (
                    <tr key = {i}>
                      <td>{i+1}</td>
                      <td>{e.contact.name}</td>
                      <td>{e.nr}</td>
                      <td>{moment(e.dt).format("DD.MM.YYYY")}</td>
                      <td>
                        <RenderValues data= {e} index={i}  onSubmit={updateHandler} />
                      </td>
                    </tr>
                  )) }
                </tbody>
            </table>
          
        </Fragment>}
  </Boxy>
  </Fragment>
}
