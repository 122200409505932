import React, {Fragment} from 'react'
import { makeStyles,  } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Courier", fontSize: "0.8rem", fontWeight: "400" },
    table: {
        width: "100%",
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5),
            verticalAlign: "top",
            height: "100%"
        },
    }
}))

export default function ANTET_CERERE(props) {
    const classes = useStyles()
    return (
        <Fragment>
            {props && props.data && (
                <table className={classes.table} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td width="50%">
                                Județul/localitatea<br />
                                <strong>{props.data.company.jud} /  {props.data.company.loc} </strong>
                            </td>
                            <td rowSpan="2">
                                Nr. unic de înregistrare al ofertei de vânzare<br /> din Registrul de evidenţă
                         <br />
                                <div align="center">
                                    <strong>{props.data.nr} / {moment(props.data.dt).format('DD-MM-YYYY')}</strong>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td>Primăria <br /><strong>{props.data.company.loc}</strong><br /></td>
                        </tr>
                        <tr>
                            <td>Numele şi prenumele funcţionarului primăriei care primeşte cererea<br /><strong>{props.data.user.name}</strong></td>
                            <td>
                                Semnătura funcţionarului care primeşte oferta de vânzare (*)
                            </td>
                        </tr>
                    </tbody>

                </table>
            )}

        </Fragment>
    )
}
