import { Button, Card, CardContent, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core'
import React, {Fragment, useRef, useState, useEffect} from 'react'
import ANTET_INSTITUTIE from '../routes/Dosar/Common/ANTET_INSTITUTIE'
import {useReactToPrint} from 'react-to-print'
import FunkyInput from './FunkyInput'
import moment from 'moment'
import { FaPrint, FaTimesCircle } from 'react-icons/fa'
import styled from "styled-components"
import ToolTip from './Tooltip'
import RichCard from "../components/RichCard"
import { useLazyQuery } from '@apollo/client'
import { COMPANY } from '../apollo/query'
import {get} from '../lib/storage'

const initialFilter = {       
    start    : moment().startOf("year").format("YYYY-MM-DD"),
    end      : moment().subtract(1, "M").endOf("M").format("YYYY-MM-DD"),
    status   : "TOATE",
    contract: true
}

const filterFunction = (arr, filter) => {
    let res = []
    
    res =  arr.reduce( (a,e) => {
        let dosarDt = moment(e.dt)
        let start   = moment(filter.start)
        let end     = moment(filter.end)
    
        if ( dosarDt.isBetween(start, end, null, '[]')) {
            return [...a, e]
        } else { return a }
    }, [])

    res =  res.reduce( (acc,el) => {
        let r;
        if  (el.status === filter.status) {
            r =  [...acc, el]
        } else {
            if ( filter.status === "TOATE") {
                r = [...acc, el]
            } else { 
                r = acc
            }
        }
        return r
    }, [])
    res = res.reduce((acc,el) => {
        let r;
        if ( !filter.contract ) {
            r = [...acc, el]
        } else {
            if ( el.anexe.contract ) {
                r = [...acc, el]
            } else {
                r = acc
            }

        }
        return r
    }, [])
    return res
}

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: "99vh", minWidth: "75vw"
    },
    printOnly: {
        "@media screen" : {display: "none"}
    },
    table: {
        borderSpacing:0,
        borderCollapse: 0,
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.8rem", fontWeight: 500,
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            spacing:0,
            height: "100%",
            
        },
    },
    print: {
        "@media print": {
            // margin:"10mm 10mm 10mm 20mm"
        }
    }
}))

export default function DadrModal({data, onClose}) {
    const printRef = useRef()
    const closeHandler = () => onClose(true)
    const classes = useStyles()

    const [items, setItems] = useState(null)
    const [init, setInit] = useState()
    const [filter, setFilter] = useState({...initialFilter})
    const [antet, setAntet] = useState()
    const [user, setUser] = useState()

    const [getCompany, company ] = useLazyQuery(COMPANY)

    useEffect(() => {
        getCompany()
        let {registru} = data || []
        if ( registru && registru.length > 0 ) { 
            registru = registru.reduce((acc,el) => {
                    return [...acc, {...el, anexe: JSON.parse(el.anexe)}]
             }, []) 
        }
        setUser(get())
        setInit(registru)
        setItems(filterFunction(registru,filter))
       
    }, [data])

    useEffect(() => {
        if (items) {
            let ni = [...init]
            
            setItems(filterFunction(ni, filter))
        }


    }, [filter])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });



    const filterHandler = name => ev => setFilter({...filter, [name]: ev.target.value})

    const resetHandler = () => setFilter({...initialFilter})


    return (
        <Dialog open = {Boolean(items)} onClose = {closeHandler} classes = {{paper: classes.dialogPaper}}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems ="center">
                    <Typography variant = "h6">
                SITUAȚIE LUNARĂ - DIRECȚIA PENTRU AGRICULTURĂ ȘI DEZVOLTARE RURALĂ
                </Typography>
                <IconButton onClick = {closeHandler}><FaTimesCircle size="2em" color="lightgrey"/></IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Card style = {{background: "aliceblue"}}>
                    <CardContent>
                    <Typography variant = "overline" color="primary">
                            FILTREAZĂ REZULTATELE ({items && items.length ? items.length : 0})
                    </Typography>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item  align="left">
                            <ToolTip title = "RESETEAZĂ FILTRUL">
                            <IconButton color="secondary" size="small" onClick = {resetHandler}>
                                <FaTimesCircle/>
                            </IconButton>
                            </ToolTip>
                        </Grid>
                        <Grid item sm={2} xs={4}>
                            <FunkyInput type="date" value={filter.start} label="DE LA" onChange = {filterHandler("start")}/>
                        </Grid>
                        <Grid item sm={2} xs={4}>
                            <FunkyInput type="date" value={filter.end} label="PÂNĂ LA" onChange = {filterHandler("end")}/>
                        </Grid>
                        <Grid item sm = {2} xs = {4}>
                            <FunkyInput select value = {filter.status} label="STADIU DOSAR" onChange = {filterHandler("status")}>
                                {["TOATE", "FINALIZAT", "RETRAS", "OPERARE" ].map((e,i) => (
                                    <MenuItem value = {e} key={i}>{e}</MenuItem>
                                ))}
                            </FunkyInput>
                        </Grid>
                        <Grid item align="left">
                            <FormControlLabel 
                                label = "CONTRACT"
                                labelPlacement = "start"
                                control = {<Checkbox 
                                    color="primary" 
                                    size="small" 
                                    value = {filter.contract}
                                    checked = {filter.contract}
                                    onChange = {ev => setFilter({...filter, contract: ev.target.checked ? true: false})}
                                />}
                            />
                        </Grid>

                        <Grid item sm = {3} align="right">
                            <Button variant = "contained" color="primary" onClick= {printHandler}>
                                <FaPrint/> &nbsp; TIPĂRIRE
                            </Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                <RichCard contentStyle = {{height: "60vh", overflow: "auto", background: "white"}}>
                <div ref = {printRef} >
                    <Style>
                {items && (
                    <Fragment>
                        <div className = {classes.printOnly}>
                       {company && company.data && company.data.company && (
                            <ANTET_INSTITUTIE data = {company.data.company} />
                       )}
                       <div align="right">
                           <br/>
                       nr........... din .........................
                           </div>
                        <br/><br/><br/>
                        </div>
                        <Typography variant="body2" align ="center">
                                <strong>
                                Comunicare situație proceduri de vânzare finalizate,<br/>
                                conform prevederilor Legii 17/2014, modificată și completată prin Legea 175/2020
                                
                                <br/>
                                
                                în perioada {moment(filter.start).format("DD.MM.YYYY")} - {moment(filter.end).format("DD.MM.YYYY")}
                                </strong>
                            </Typography>
                            <br/>
                            <table className={classes.table} width="100%">
                                <thead>
                                    <tr>
                                        <th>Nr.crt</th>
                                        <th>Nume, prenume / Denumire vânzător</th>
                                        <th>Suprafața<br/>- ha -</th>
                                        <th>Nume, prenume preemptor/<br/>
                                            potențial cumpărător
                                        </th>
                                        <th>Categorie<br/>preemptor</th>
                                        <th>Nr.și data<br/>contract <br/>de vânzare</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map( (e,i) => (
                                        <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>
                                                {e.ofertanti && e.ofertanti.map( (el,ix) =>(
                                                    <Fragment key={'of-'+ix}>
                                                    <Typography variant="caption"  >
                                                        {el.titular && el.titular.name}
                                                        {ix+1 < e.ofertanti.length && ", "}
                                                    </Typography>
                                                    {/* <br/> */}

                                                    </Fragment>
                                                ))}
                                                <br/>
                                                   <Typography variant ="caption">
                                                        <small> (oferta nr.{e.nr} / {moment(e.dt).format("DD.MM.YYYY")})</small>
                                                    </Typography>
                                            </td>
                                            <td align="center">
                                                {e.anexe && e.anexe.terenHa ? e.anexe.terenHa : "---"}
                                            </td>
                                            <td align="center">
                                                {(e.anexe 
                                                    && e.anexe.alegerePreemptor
                                                    && e.anexe.alegerePreemptor.titular
                                                    && e.anexe.alegerePreemptor.titular.name
                                                    )
                                                      ? e.anexe.alegerePreemptor.titular.name 
                                                      : "---"}
                                            </td>
                                            <td align="center">
                                                {((e.anexe 
                                                    && e.anexe.alegerePreemptor
                                                    && e.anexe.alegerePreemptor.rang
                                                    && e.anexe.alegerePreemptor.rang.name
                                                    )
                                                      ? e.anexe.alegerePreemptor.rang.name.replace("preemptori de rang", "")
                                                      : "")}

                                                {((e.anexe 
                                                    && e.anexe.anexa1g30
                                                    && e.anexe.anexa1g30.oferta
                                                    && e.anexe.anexa1g30.oferta.ofertant
                                                    && e.anexe.anexa1g30.oferta.ofertant.titular
                                                    )
                                                      ? e.anexe.anexa1g30.oferta.ofertant.titular.name 
                                                      : "")}
                                            </td>
                                            <td align="center">
                                                {(e.anexe && e.anexe.contract) || "---"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        {/* <pre>{JSON.stringify(items, null, 4)}</pre> */}
                        <br/><br/>
                        <Grid container className = {classes.printOnly}>
                            <Grid item xs = {6} align="left"> </Grid>
                            <Grid item xs = {6} align="right">
                                <div style  = {{display: "block", textAlign:"center"}}>
                            Întocmit,<br/><br/>
                {user && user.name}
                </div>

                            </Grid>
                        </Grid>
                        
                    </Fragment>
                )}
                </Style>
                </div>
                </RichCard>
            </DialogContent>
        </Dialog>
    )
}

const Style = styled.div`
@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 15mm 10mm 15mm 20mm;
  }

`
