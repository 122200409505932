import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { LIST_QUERY } from '../../../../apollo/queryAtestate';
import ANTET_INSTITUTIE from '../../../Dosar/Common/ANTET_INSTITUTIE';
import { COMPANY } from '../../../../apollo/query';

import moment from 'moment';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { MdPrint } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import Filters from './Filters';
import { useHistory } from 'react-router';


const useStyles = makeStyles(theme => ({
    justify: {
        textAlign: "justify",
        textJustify: "inter-word"
    },
    screenOnly: { "@media print": { display: "none" } },
    printOnly: { "@media screen": { display: "none" } },
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            textAlign: "center",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "crosshair",
            border: "2px solid navy",
        },
    },

}))

export default function RegAtestate({ match }) {
    const classes = useStyles()
    const company = useQuery(COMPANY)
    const [items, setItems] = useState([])
    const printRef = useRef()
    const history = useHistory()

    const [queryParams, setQueryParams] = useState({
        between: {
            col: "dt",
            start: moment().startOf("year").format("YYYY-MM-DD"),
            end: moment().format("YYYY-MM-DD")
        },
        ordering: [
            { key: "nr", value: "desc" }
        ]
    })

    const [getData, dispatchData] = useLazyQuery(LIST_QUERY)


    useEffect(() => {
        let { query } = match.params
        if (typeof query !== 'undefined') {
            query = decodeURIComponent(query)
            query = JSON.parse(query)
            setQueryParams(query)
        } else {
            query = queryParams
        }
        getData({ variables: { data: queryParams } })
    }, [match.params])


    useEffect(() => {
        if (dispatchData.data && dispatchData.data.atCereri) {
            let elems = dispatchData.data.atCereri
            // console.log("elems:", elems)
            elems = elems.reduce((acc, elem) => {
                if (elem.atProcesVerbal) {
                    let newElem = {
                        id: elem.id,
                        contact: elem.contact,
                        cerereNr: elem.nr,
                        cerereDt: moment(elem.dt).format("DD.MM.YYYY"),
                        pvNr: elem.atProcesVerbal.nr,
                        pvDt: moment(elem.atProcesVerbal.dt).format("DD.MM.YYYY"),
                        atestatSeria: elem.atProcesVerbal.atestatSeria,
                        atestatNr: elem.atProcesVerbal.atestatNr,
                        atestatDt: moment(elem.atProcesVerbal.atestatDt).format("DD.MM.YYYY"),
                        atestatExp: moment(elem.atProcesVerbal.atestatDt).add(7, "years").format("DD.MM.YYYY"),
                        agricol: {
                            vol: elem.atProcesVerbal.agricolVol,
                            nr: elem.atProcesVerbal.agricolNr,
                            albine: elem.atProcesVerbal.agricolAlbine,
                            animale: elem.atProcesVerbal.agricolAnimale,
                            arabil: elem.atProcesVerbal.agricolArabil,
                            livezi: elem.atProcesVerbal.agricolLivezi,
                            pasari: elem.atProcesVerbal.agricolPasari,
                            vii: elem.atProcesVerbal.agricolVii

                        }
                    }
                    acc = [...acc, newElem]
                }
                return acc
            }, [])
            setItems(elems)
        }

    }, [dispatchData && dispatchData.data])

    const filtersApplyHandler = ev => {
        history.push("/registru-atestate/rapoarte/registru-atestate/"+encodeURIComponent(JSON.stringify(ev)))
        setQueryParams(ev)
    }

    const resetHandler = () => {
        const intialParams = {
            between: {
                col: "dt",
                start: moment().startOf("year").format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD")
            },
            ordering: [
                { key: "nr", value: "desc" }
            ]
        }
        history.push("/registru-atestate/rapoarte/registru-atestate/"+encodeURIComponent(JSON.stringify(intialParams)))
        setQueryParams(intialParams)
        
    }

    const printHandler = useReactToPrint({content: ()=>printRef.current})

    return <Fragment>
        <Grid container justifyContent= "flex-start" alignItems = "center">
            <Grid item xs = {11}>
                {items && queryParams && <Filters data = {queryParams} 
                    onApply={filtersApplyHandler} 
                    onReset = {resetHandler}    
                />}
            </Grid>
            <Grid item xs={1} align="center">
            <IconButton onClick = {printHandler}><MdPrint size = "2.5em" color="grey"/></IconButton>
            </Grid>
        </Grid>
        <div className={classes.screenOnly}>
            <br/><br/>
        </div>
        <Styles>
        <div ref = {printRef}>
        <div className = {classes.printOnly}>
        {company && company.data && company.data.company 
            && <ANTET_INSTITUTIE data = {company.data.company}  />
        }
        </div>
        <div className={classes.printOnly}>
            <br/><br/>
        </div>
        <div align="center">
            <Typography variant = "body1">  Registrul atestatelor de producător </Typography>
            <Typography variant = "body1">  eliberate în perioada {' '}
            {moment(queryParams.between.start).format("DD.MM.YYYY")}  -
            {moment(queryParams.between.end).format("DD.MM.YYYY")}
             </Typography>
        </div>
        
        <div className={classes.printOnly}>
            <br/>
        </div>
        <table className={classes.table} width="100%" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th rowSpan="2">Nr.crt</th>
                    <th rowSpan="2">Titular</th>
                    <th colSpan="2">Cerere atestat</th>
                    <th colSpan="2">PV. eliberare atestat</th>
                    <th colSpan="4">Atestat de  producator</th>
                    <th colSpan="8">Reg.agricol</th>
                </tr>
                <tr>
                    <th>nr</th>
                    <th>din data</th>
                    <th>nr</th>
                    <th>din data</th>
                    <th>Seria</th>
                    <th>Nr</th>
                    <th>Emis la</th>
                    <th>Expira la</th>
                    <th>VOL</th>
                    <th>NR.MAT.</th>
                    <th>ARABIL</th>
                    <th>LIVEZI</th>
                    <th>VII</th>
                    <th>ANIMALE</th>
                    <th>PASARI</th>
                    <th>ALBINE</th>
                </tr>
            </thead>
            <tbody>
                <tr>{Array.from(Array(18).keys()).map(e => <td align="center" key={e}><small>{e + 1}</small></td>)}</tr>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{e.contact.name}</td>
                        <td>{e.cerereNr}</td>
                        <td>{e.cerereDt}</td>
                        <td>{e.pvNr}</td>
                        <td>{e.pvDt}</td>
                        <td>{e.atestatSeria}</td>
                        <td>{e.atestatNr}</td>
                        <td>{e.atestatDt}</td>
                        <td>{e.atestatExp}</td>
                        <td>{e.agricol.vol}</td>
                        <td>{e.agricol.nr}</td>
                        <td>{e.agricol.arabil}</td>
                        <td>{e.agricol.livezi}</td>
                        <td>{e.agricol.vii}</td>
                        <td>{e.agricol.animale}</td>
                        <td>{e.agricol.pasari}</td>
                        <td>{e.agricol.albine}</td>
                    </tr>
                ))}

            </tbody>
        </table>

        {/* {queryParams && <small><pre>{JSON.stringify(queryParams, null, 4)}</pre></small>} */}
        </div>
        </Styles>
    </Fragment>;
}


export const Styles = styled.div`

@media print {
        @page { 
            size:  A4 landscape;
            margin: 10mm 10mm 10mm 30mm; 
        };
        div.printable{
            div {
                page-break-inside: avoid!important;
                break-inside: avoid!important;
            }
        }
}
`