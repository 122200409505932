import React from 'react'
import { Fragment } from 'react'

const Field = props => {
    return (<strong>{props.children || ' - - - - -'}</strong>)
}

export default function PENTRU(props) {
    const item = props || {}
    return (
        <Fragment>
              2. (*) pentru^(2): <Field>{item.name}</Field>, 
                     având număr de ordine în (**) <Field>{item.pjRegType}</Field>, <Field>{item.pjRegNr    }</Field>,  
                      CIF/CUI <Field>{item.cui}</Field>, 
                    3. (*) cu sediul în localitatea <Field>{item.pjLoc}</Field>,  
                    str. <Field>{item.pjStr}</Field>,  nr. <Field>{item.pjNr}</Field>,  
                    bl. <Field>{item.pjBl}</Field>,  sc. <Field>{item.pjSc}</Field>,  et.<Field>{item.pjEt}</Field>, 
                     ap. <Field>{item.ap}</Field>,  
                    judeţul/sectorul <Field>{item.pjJud}</Field>,  codul poştal <Field>{item.pjCod  }</Field>,  
                    telefon <Field>{item.pjTel}</Field>,  fax <Field>{item.pjFax}</Field>, 
                    e-mail <Field>{item.pjEmail}</Field>,  site <Field>{item.pjSite}</Field>, 
        </Fragment>
    )
}
