import { gql } from '@apollo/client'

export const REG = gql`query registruArenda($data:ComplexQuery) {
  registruArenda(data:$data) {
    id nr dt tip 
    from to price penalty desc parentId   
    arendaArendas { id  contact { id name  } }
    arendaArendator  { id  contact { id name  } }
    parent {id arendaArendas{id contact{id name}} arendaArendator{id contact{id name}} }
  }
}`

export const REGISTRU = gql`query registruArenda($data:ComplexQuery) {
  registruArenda(data:$data) {
    id nr dt tip 
    from to price penalty desc
    companyId userId parentId
    company {id name}
    
    parent { 
      id nr dt tip  from to   
      arendaArendas {
        id contactId contact { id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu obs }
      }
        arendaArendator {
        id contactId contact { id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu obs }
      }
    }
    children{ id tip nr dt }
    arendaArendas {
      id contactId contact { id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu obs }
    }
    arendaArendator {
      id contactId 
      contact { id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu obs }
    }
    arendaTeren {
      id 
      terenTarla terenParcela terenNrCadastral terenCf terenCfLoc
      terenHa terenAct terenCategorie coordonate
      companyId userId arendaRegistruId
      arendaVecin { 
         id  directia coordonate nrCadastral
         contact { id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu obs }
      }
     }
  }
}`

export const NEXT_ARENDA_DOSAR_NUMBER = gql`query nextArendaDosarNumner { nextArendaDosarNumber }`

export const FILTER_ARENDA = gql`query filterQuery($data:ArendaFilterInput) {
  arendaFilter(data:$data) {
    id nr dt
    arendaTeren {id terenTarla}
    arendaArendator {contact{name}}
    arendaArendas {contact{name}}
    from to 
    children {id}
  }
}  `