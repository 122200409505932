import { Drawer, Paper, makeStyles, Grid, Chip } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { Fragment } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import {diff, endDate} from '../../lib/zileLucratoare'

const useStyles = makeStyles(theme=>({
    paper: { padding: theme.spacing(1), margin: theme.spacing(0.5), height: "20vh"},
    chip: {padding: theme.spacing(1), height: "60px"}
}))



export default function InfoDrawer(props) {
    const classes = useStyles()
    const dosar = props && props.data ? props.data : null

    const LargeChip = props => <Chip label = {<h1>{props.children}</h1>} className = {classes.chip} />

    return (
        <Drawer open = {Boolean(dosar)} anchor="bottom" onClose = {()=>props.onClose(true)}>
            <Paper className={classes.paper}>
                {dosar && (
                    <Fragment>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs = {2} align="center">
                                CERERE A FOST DEPUSĂ<br/>
                                 DE VÂNZĂTOR<br/>
                                ÎN DATA DE<br/>
                                <LargeChip>{moment(dosar.dt).format('DD.MM.YYYY')}</LargeChip>
                                
                                
                            </Grid>
                            <FaLongArrowAltRight size = "5rem" color="lightblue"/>
                            <Grid item xs = {2} align="center">
                                TERMENUL DE 45 DE ZILE<br/>
                                <span style = {{color: "red", fontWeight:700}}>LUCRĂTOARE</span><br/>
                                (ETAPA PREEMPTORILOR)<br/>
                                <LargeChip>{moment(endDate(dosar.dt, 45)).format('DD.MM.YYYY')}</LargeChip>
                                
                            </Grid>
                            <FaLongArrowAltRight size = "5rem" color="lightblue"/>
                            <Grid item xs = {2} align="center">
                                TERMENUL DE 30 DE ZILE<br/>
                                <span style = {{color: "red", fontWeight:700}}>CALENDARISTICE</span><br/>
                                (DUPA CELE 45 DE ZILE)<br/>
                                <LargeChip>{moment(endDate(dosar.dt, 45)).add(31, "days").format('DD.MM.YYYY')}</LargeChip>
                                
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
            </Paper>
        </Drawer>
    )
}
