import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import Boxy from '../../../components/Boxy';
import FunkyInput from '../../../components/FunkyInput';
import moment from "moment"

const AGR_FIELDS = [
    { key: "agricolVol", label: "VOLUM" },
    { key: "agricolNr", label: "NR.REG.", float: true },
    { key: "agricolArabil", label: "ARABIL", float: true },
    { key: "agricolLivezi", label: "LIVEZI", float: true },
    { key: "agricolVii", label: "VII", float: true },
    { key: "agricolAnimale", label: "ANIMALE", float: true },
    { key: "agricolPasari", label: "PASARI", float: true },
    { key: "agricolAlbine", label: "ALBINE", float: true }
]

export default function PvAtestat({ data, onChange }) {

    const item = data || {}

    const pvHandler = name => ev => onChange({ ...item, atProcesVerbal: { ...item.atProcesVerbal, [name]: ev.target.value } })
    const pvDtHandler = name => ev => onChange({ ...item, atProcesVerbal: { ...item.atProcesVerbal, [name]: moment(ev.target.value).toDate() } })
    const pvInputHandler = name => ev => {
        console.log(name, ev.target.value)
        onChange({
        ...item,
        atProcesVerbal: {
            ...item.atProcesVerbal,
            [name]: ev.target.value.replace(",", ".")
        }
    })}

    return (
        <Fragment>
            {item && (
                <Fragment>
                    <Grid container spacing={1}>
                        <Grid item sm={2}>
                            <FunkyInput
                                value={item.atProcesVerbal.nr} label="NR.PV"
                                onChange={pvHandler("nr")}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FunkyInput
                                type="date"
                                value={moment(item.atProcesVerbal.dt).format("YYYY-MM-DD")} label="DIN DATA"
                                onChange={pvDtHandler("dt")}
                            />
                        </Grid>
                        {/* <Grid item xs={1} /> */}
                        <Grid item sm={2}>
                            <FunkyInput
                                value={item.atProcesVerbal.atestatSeria} label="SERIA"
                                onChange={pvHandler("atestatSeria")}
                            />

                        </Grid>
                        <Grid item sm={2}>
                            <FunkyInput
                                value={item.atProcesVerbal.atestatNr} label="NR.ATESTAT"
                                onChange={pvHandler("atestatNr")}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FunkyInput
                                type="date"
                                value={moment(item.atProcesVerbal.atestatDt).format("YYYY-MM-DD")} label="DIN DATA"
                                onChange={pvDtHandler("atestatDt")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Boxy
                                title="DATE DIN REGISTRUL AGRICOL"
                                style={{ background: "aliceblue" }}
                                contentStyle={{ padding: "5px", margin: 0, background: "aliceblue" }}
                            >
                                <div style={{ display: "flex" }}>
                                    {AGR_FIELDS.map((e, i) => (
                                        <FunkyInput
                                            value={item.atProcesVerbal[e.key]} label={e.label} key={i}
                                            type="number"
                                            step={e.float ? "0.1" : "1"}
                                            onChange={pvInputHandler(e.key)}
                                        />
                                    ))}
                                </div>



                            </Boxy>


                        </Grid>

                    </Grid>
                </Fragment>
            )}
        </Fragment>
    )
}
