import React, { Fragment } from 'react'
import moment from 'moment'

const Field = props => <strong>{ (props.children )|| ' - - - - -'}</strong>


export default function CONTACTOFERTA(props) {
    let item = props.item || {}
    let mandat = props.mandat || null
    
    return (
        <div>
            {item && (item.cnp || !item.cui) && (
                <Fragment>
                    Subsemnatul/Subsemnata^1), <Field>{item.name}</Field>,{' '}
                     CNP <Field>{item.cnp}</Field>,{' '}
                      având adresa de comunicare în: localitatea <Field>{item.domLoc}</Field>,{' '}
                       str. <Field>{item.domStr}</Field>,{' '}
                        nr. <Field>{item.domNr}</Field>,{' '} 
                        bl. <Field>{item.domBl}</Field>,{' '}
                        sc. <Field>{item.domSc}</Field>,{' '}
                        et. <Field>{item.domEt}</Field>,{' '} 
                        ap. <Field>{item.domAp}</Field>,{' '}
                        judeţul/sectorul <Field>{item.domJud}</Field>,{' '}
                        codul poştal <Field>{item.domCod}</Field>,{' '}
                        e-mail <Field>{item.domEmail}</Field>,{' '}
                        tel. <Field>{item.domTel}</Field>,{' '}
                </Fragment>
            )}
            {item && item.cui && (
                <Fragment>
                    Subscrisa^1), <Field>{item.name}</Field>,{' '} 
                    CIF/CUI <Field>{item.cui}</Field>,{' '} 
                    având adresa de comunicare în localitatea <Field>{item.sedLoc}</Field>,{' '} 
                    str.  <Field>{item.pjStr}</Field>,{' '} 
                    nr.  <Field>{item.pjNr}</Field>, {' '}
                    bl.  <Field>{item.pjBl}</Field>, {' '}
                    sc.  <Field>{item.pjSc}</Field>, {' '}
                    et.  <Field>{item.pjEt}</Field>, {' '}
                    ap.  <Field>{item.pjAp}</Field>, {' '}
                    judeţul/sectorul  <Field>{item.pjJud}</Field>, {' '}
                    codul poştal <Field>{item.pjCod}</Field>,{' '}
                    e-mail <Field>{item.pjEmail}</Field>{' '}



                  
                </Fragment>)}

                 
        </div>
    )
}
