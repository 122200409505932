import { Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState} from 'react';
import FunkyInput from '../../../../components/FunkyInput';
import { FaTimesCircle } from 'react-icons/fa';
import Boxy from '../../../../components/Boxy';
import { clearTypeName, deepClearTypeName } from '../../../../lib/graph-stuff';
import PRODUS from '../PRODUS';
import ProuctModal from '../../Produse/ProductModal';
import ProductModal from '../../Produse/ProductModal';
import { useMutation } from '@apollo/client';
import {  PRODUS_MUTATION } from '../../../../apollo/mutationAtestate';

const useStyles = makeStyles(theme => ({
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey", fontSize: "0.75em", fontWeight: 500,
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "crosshair",
            border: "2px solid navy",
        },
    }
}))

export default function CarnetProduse({data, title, tip, onChange}) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [ newProductModal, setNewProductModal] = useState()

    const [mutateProduct, mutateProductData] = useMutation(PRODUS_MUTATION)

    useEffect(()=>setItem(data), [data])


    const changeProductHandler = elem => ev => {
        let ni ={...item}
        ni = deepClearTypeName(ni)
        let curIndex = item.produse.findIndex( el => el.id === elem.id )
        if (ev) {
            ni.produse[curIndex].atProdus = clearTypeName(ev)
            onChange(ni)
        } else {
            removeHandler(elem)()
        }
        

    }

    const removeHandler = elem => () => {
        console.log("time:", item)
        const ni ={...item}
        ni.produse = ni.produse.map(el=>({...el}))
        let curIndex = ni.produse.findIndex( el => el.id === elem.id )
        ni.produse.splice(curIndex,1)
        onChange(ni)

    }

    const prodHandler = (name, id) => ev => {
        let ni ={...item}
        ni = deepClearTypeName(ni)
        let curIndex = ni.produse.findIndex( el => el.id === id)
        ni.produse[curIndex][name] = ev.target.value
        onChange(ni)
    }
    const openProductNodalHandler = (e,i) => () => setNewProductModal({elem:e, index: i})
    
    const closeNewProductModal = async ev => {
        let res;
        console.log("new prod modal close ev:", ev)
        if (ev) {
            res = await mutateProduct({variables: {den_produs: ev.den_produs, tip_produs: ev.tip_produs}})
            console.log("Res after mutation: ", res)
            if (res.data && res.data.atProdus) {
                const newProdus = deepClearTypeName(res.data.atProdus)
                let ni ={...item}
                ni = deepClearTypeName(ni)
                console.log("atttt", res.data.atProdus)
                ni.produse[newProductModal.index].id = newProdus.id
                ni.produse[newProductModal.index]['atProdus'] = deepClearTypeName(newProdus)
                onChange(ni)
            }
        }
        setNewProductModal(null)
    }

    return (
        <Fragment>
            {/* {console.log("I T E M : ", item)} */}
            {item && (
                   <Boxy title = {title}>
                   <table className={classes.table}>
                       <thead>
                           <tr>
                               <th width="10%">Nr.crt</th>
                               <th width="30%">Denumire</th>
                               <th width="12%">Ha</th>
                               <th width="12%">Ari</th>
                               <th width="12%">Efective</th>
                               <th width="12%">Prod. estimată</th>
                               <th width="12%">Cantit. aprobată</th>
                               {/* <th width="10%"></th> */}
                           </tr>
                       </thead>
                       <tbody>
                   { item.produse
                       .map( (e,i) => {
                           return e.atProdus && e.atProdus['tip_produs'] === Boolean(tip)
                           ? (
                               <tr key = {i}>
                                   <td align="center">{i+1}</td>
                                   <td align="center"  style = {{textAlign:"center"}}>
                                   <PRODUS 
                                      
                                       data ={e.atProdus} 
                                       tip ={e.atProdus['tip_produs']} 
                                       onChange = {changeProductHandler(e)}
                                       onNew = {openProductNodalHandler(e,i)}
                                       />
                                   </td>
                                   <td><FunkyInput value = {e.ha} type="number" step="0.1" onChange = {prodHandler("ha", e.id)}/></td>
                                   <td><FunkyInput value = {e.ari}  type="number" step="0.1" onChange = {prodHandler("ari", e.id)}/></td>
                                   <td><FunkyInput value = {e.efective}  type="number" step="0.1" onChange = {prodHandler("efective", e.id)}/></td>
                                   <td><FunkyInput value = {e.estimat}  type="text" step="0.1" onChange = {prodHandler("estimat", e.id)}/></td>
                                   <td><FunkyInput value = {e.solicitat}  type="text" step="0.1" onChange = {prodHandler("solicitat", e.id)}/></td>
                                   {/* <td align="center">
                                       <IconButton color ="primary" size="small" onClick ={removeHandler(e)}>
                                           <FaTimesCircle color="red"/>
                                       </IconButton>
                                   </td> */}
                               </tr>
                           ) : null
                          
                       
                           })}
                   </tbody>
                   </table>
                   
               </Boxy>
            )}

            {newProductModal && <ProductModal 
                data ={newProductModal.elem.atProdus}
                onClose = {closeNewProductModal} />
            }
        </Fragment>
    )
}