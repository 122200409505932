import { Grid } from '@material-ui/core'
import React from 'react'

export default function SEMNATURI_VANZATORI(props) {
    return (
        <Grid container>
            { props 
                && props.data 
                && props.data.map(( e,i) => (
                    <Grid item xs key = {i}>
                        Vânzător/împuternicit<br/>
                        
                        { e.titular.name }<br/>
                        <small>(numele şi prenumele în clar)</small><br/>
                        { e.mandatar 
                            && e.mandatar.name 
                            ? <span>prin<br/> {e.mandatar.name}</span> : <br/>
                        }
                        <br/><br/>
                        . . . . . . . . . . . . . . . . . .

                    </Grid>
                ))
            }
        </Grid>
    )
}
