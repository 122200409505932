import { Dialog, DialogTitle, IconButton, makeStyles, Grid, DialogContent, Paper, Button, Divider } from '@material-ui/core'
import { IndeterminateCheckBoxSharp } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import { FaInfoCircle, FaTimesCircle } from 'react-icons/fa'
import FunkyInput from '../../components/FunkyInput'
import RichCard from '../../components/RichCard'


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: "80vw", 
        minHeight: "70vh"
    }
}))

const get30Items = (items, start, end) => {
    const s = moment(start).subtract(1, "day"), e = moment(end).add(1, "day")
    return items.reduce( (acc, el) => {
        let m = moment(el.end75)
        if ( m.isAfter(s) && m.isBefore(e) ) {
            acc = [...acc, el]
        }
        return acc
    }, [])

}
const get45Items = (items, start, end) => {
    const s = moment(start), e = moment(end)
    return items.reduce( (acc, el) => {
        let m = moment(el.end45)
        if ( m.isAfter(s) && m.isBefore(e) ) {
            acc = [...acc, el]
        }
        return acc
    }, [])
}

const Titulari = ({ofertanti}) => (
    <Fragment>
        {ofertanti.map((e,i) =>(
            <Fragment key={i}>
                {e.titular.name} {ofertanti.length > i+1 && ", "}
            </Fragment>
        ))}
    </Fragment>
)

export default function InfoModal({data, onClose}) {

    const classes = useStyles()

    const [items, setItems] = useState()
    const [filter45, setFilter45] = useState([])
    const [filter30, setFilter30] = useState([])
    const [start, setStart] = useState(moment().startOf("week").format("YYYY-MM-DD"))
    const [end, setEnd] = useState(moment().endOf("week").format("YYYY-MM-DD"))

    const closehandler = name => () => onClose(name)

    useEffect(() => { setItems(data)}, [data])

    useEffect(()=>{
        if (items) {
            
            setFilter45(get45Items(items, start, end))
            setFilter30(get30Items(items, start, end))
        }
    },[items, start, end])

    const filterHandler = name => ev => {
        let dt = moment(ev.target.value).format("YYYY-MM-DD")
        if ( name === "start") { setStart(dt)}
        if ( name === "end") { setEnd(dt)}
    }
    const curWeekHandler = () => {
        setStart(moment().format("YYYY-MM-DD"))
        setEnd(moment().add(1, "week").format("YYYY-MM-DD"))
    }
    const curMonthHandler = () => {
        setStart(moment().format("YYYY-MM-DD"))
        setEnd(moment().add(1, "month").format("YYYY-MM-DD"))
    }

    const next3daysHandler = () => {
        setStart(moment().format("YYYY-MM-DD"))
        setEnd(moment().add(3, "days").format("YYYY-MM-DD"))
    }
    

  return (
    <Dialog open = {Boolean(IndeterminateCheckBoxSharp)} onClose = {closehandler(null)} classes = {{paper: classes.dialogPaper}}>
        <DialogTitle>
            <Grid container justifyContent='space-between' alignItems="center" spacing={1}>
                <Grid item sm = {11}>
                <Alert severity = 'info' icon={<FaInfoCircle size = "2em" color="red"/>}>
                <strong style = {{color: "navy"}}>
                DOSARE CARE NECESITĂ ATENȚIA DVS. IMEDIATĂ 
                </strong>
                <br/><small style = {{fontWeight: 400, color:"darkred", fontSize:"0.9em"}}>
                    (experimental, această opțiune este în curs de dezvoltare, nu luați încă "de bune" informațiile prezentate aici)</small>
                </Alert>
                </Grid>
            <IconButton onClick={closehandler()}><FaTimesCircle color="lightgrey" size="2em"/></IconButton>
                <Grid item sm = {11}>
                    <div>
                        <Grid container spacing={1} justifyContent="space-between" alignItems="center" 
                            style = {{background: "rgb(232, 244, 253)", fontSize: "0.9em", paddingLeft: "10px", paddingRight: "10px"}}>
                            <Grid item sm = {true}>
                            FILTRARE
                            </Grid>
                            <small>de la data</small>
                            <Grid item sm = {true}>
                            <FunkyInput type="date" value ={start} onChange = {filterHandler("start")} />
                            </Grid>
                            <small>până la data </small>
                            <Grid item sm = {true}>
                            <FunkyInput type="date" value = {end} onChange = {filterHandler("end")} />
                            </Grid>
                            <Grid item sm = {true}>
                                <Button size="small" color="primary" variant="contained" fullWidth onClick = {next3daysHandler}>
                                    <small style = {{lineHeight:1.1}}>
                                        următoarele <br/>3 zile
                                    </small>
                                </Button>
                            </Grid>
                            <Grid item sm = {true}>
                                <Button size="small" color="primary" variant="contained" fullWidth onClick = {curWeekHandler}>
                                    <small style = {{lineHeight:1.1}}>
                                        de azi, o<br/>săptămână
                                    </small>
                                </Button>
                            </Grid>
                            <Grid item sm = {true}>
                                <Button size="small" color="primary" variant="contained" fullWidth onClick = {curMonthHandler}>
                                    <small style = {{lineHeight:1.1}}>
                                         de azi,<br/>o lună
                                    </small>
                                </Button>
                                
                            </Grid>


                        </Grid>
                       
                    </div>
                    
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Grid container alignItmes = "flex-start" spacing={2}>
                <Grid item sm ={6}>
                    {/* <Paper style={{padding:10, border: "1px solid grey", boxShadow: "none", height: "60vh", overflow: "auto"}}>
                        DOSARE CARE AJUNG LA TERMENUL DE 45 DE ZILE ÎN PERIOADA ALEASĂ
                    </Paper> */}
                        <RichCard 
                            title = "DOSARE CARE AJUNG LA TERMENUL DE 45 DE ZILE ÎN PERIOADA ALEASĂ"
                            contentStyle = {{height: "50vh"}}    
                        >
                        {filter45.map( (e,i) => (
                            <Fragment key={i}>
                                <li >
                                    <a href = {"./dosar/"+e.id}>
                                    {e.nr} / {e.dt} 
                                    - <Titulari ofertanti = {e.ofertanti} />
                                 
                                    </a>
                                    <small> <i> ({moment(e.end45).format("DD.MM.YYYY")})</i></small>
                                </li>
                                <Divider style={{margin: "10px"}} />
                                </Fragment>
                            ))}
                           
                            {/* {filter45 && <pre>{JSON.stringify(filter45, null, 4)}</pre>} */}
                           
                        </RichCard>
                </Grid>
                <Grid item sm ={6}>
                    {/* <Paper style={{padding:10, border: "1px solid grey", boxShadow: "none", height: "60vh", overflow: "auto"}}>
                    DOSARE CARE AJUNG LA 45+30 DE ZILE ÎN PERIOADA ALEASĂ
                    </Paper> */}
                    <RichCard 
                        title = "DOSARE CARE AJUNG LA 45+30 DE ZILE ÎN PERIOADA ALEASĂ"
                        contentStyle = {{height: "50vh"}}   
                    >

                        {filter30.map( (e,i) => (
                            <Fragment key = {i}>
                                <li key={i}>
                                    <a href = {"./dosar/"+e.id}>
                                    {e.nr} / {e.dt} 
                                    - <Titulari ofertanti = {e.ofertanti} />
                                    
                                    </a>
                                    <small> <i> ({moment(e.end75).format("DD.MM.YYYY")})</i></small>
                                </li>
                                <Divider style={{margin: "10px"}} />
                             </Fragment>
                            ))}

                    </RichCard>
                </Grid>
                <Grid item sm = {12}>
                    
                </Grid>

            </Grid>
        </DialogContent>
    </Dialog>
  )
}
