
import React, { Fragment, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, ButtonGroup, Grid, IconButton, Fab, Typography } from '@material-ui/core';
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import FunkyInput from "../../../components/FunkyInput"
import { useEffect } from 'react';


export default function ProductModal({ data, onClose }) {

    const [item, setItem] = useState()

    useEffect(() => setItem(data), [data])

    const closeModalHandler = () => onClose(item)

    const toggleTipHandler = () => {
        setItem({ ...item, tip_produs: !item.tip_produs })
    }

    return <Dialog open={Boolean(item)} onClose={() => setItem(null)}>
        <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
                {item && item.id ? "EDITARE" : "ADĂUGARE"} PRODUS
                <IconButton size="small" color="default" onClick={() => setItem(null)}><FaTimesCircle size="2em" /></IconButton>
            </Grid>

        </DialogTitle>
        <DialogContent align="center">
            {item && (
                <Fragment>
                    <ButtonGroup variant="contained">
                        <Button fullWidth
                            onClick={toggleTipHandler}
                            color={item.tip_produs ? "secondary" : "default"}
                            variant={item.tip_produs ? "contained" : "text"}
                        >
                            VEGETAL
                        </Button>
                        <Button fullWidth
                            onClick={toggleTipHandler}
                            color={!item.tip_produs ? "secondary" : "default"}
                            variant={!item.tip_produs ? "contained" : "text"}
                        >
                            ANIMAL
                        </Button>
                    </ButtonGroup>
                    <br /><br />

                    <Typography variant="caption">DENUMIRE PRODUS</Typography>
                    <FunkyInput value={item.den_produs}
                        onChange={ev => setItem({ ...item, den_produs: ev.target.value.toUpperCase() })} />
                    <br /><br />


                    <hr />
                    <br />
                    <Fab variant="extended" color="primary" onClick={closeModalHandler}><FaCheckCircle size="1.5em" />
                        &nbsp;&nbsp;&nbsp;CONFIRMĂ
                    </Fab>
                </Fragment>
            )}


        </DialogContent>

    </Dialog>
}
