import React, {useState, useEffect, Fragment, useMemo, useContext} from 'react'
import {useLocation} from 'react-router'
import {useQuery} from '@apollo/client'
import {Context} from '../../components/Store'
import {CONTACTS} from '../../apollo/query'
import { IconButton, LinearProgress, Button, Grid, Typography } from '@material-ui/core'
import Container from '../../components/Container'
import { FaPlus } from 'react-icons/fa'
import ReactTable7 from '../../components/ReactTable7'
import ContactModal from './ContactModal'
import moment from 'moment'
import {getInputFieldsProps} from './getFields'

export default function Customers(props) {
    const [state] = useContext(Context)
    const [customers, setCustomers] = useState([])
    const [currentItem, setCurrentItem] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const location = useLocation()
    
    const COLUMNS = useMemo(() => [
        {Header: "#", accessor: "id", width: "50"},
        {Header: "NUME/DENUMIRE", accessor: "name", width: 300},
        {Header: "CUI/CIF", accessor: "cui", width: 100},
        {Header: "Nr.Reg.Com", accessor: "regcom", width: 100},
        {Header: "Telefon", accessor: "phone", width: 100},
        {Header: "Fax", accessor: "fax", width: 100},
        {Header: "Email", accessor: "email", width: 200},

    ], [])
    
    const {loading, data, error, refetch, networkStatus } = useQuery(
        CONTACTS, {
            variables: {ordering: [{key: "name", value: "asc"}]},
            fetchPolicy: "cache-first"
    }) 

    useEffect(()=>{
        if ( data && data.contacts ) setCustomers(data.contacts.map( el => ({
            ...el, 
            ciElib: moment(el.ciElib||new Date()).format('YYYY-MM-DD'),
            dNastere: moment(el.dNastere || new Date()).format('YYYY-MM-DD'),
        })))
    }, [data, networkStatus])

    useEffect(() => {
        refetch()
    }, [location.key])

    const closeModalHandler = ev => {
        console.log("Ev:", ev)
        setCurrentItem()
        if ( typeof ev !== "undefined") { 
            // let newItems = [...customers].map( el => ({...el}))
            // let isItem = newItems.filter( el => el.id === ev.id )
            // isItem = isItem.length > 0 ? isItem[0] : null
            // if (  isItem ) {
            //     newItems[newItems.indexOf(isItem)] = ev
            // } else {
            //     newItems = [...newItems, ev]
            // }
            // setCustomers(newItems)
            refetch()
         }
        
    }

    const clickRowHandler = (row,ev) => {
        ev.preventDefault()
        setCurrentItem(row)

    }

    const addNewContactHandler = tip => () => {
        let modelFromField =getInputFieldsProps.reduce( (acc,item) => ({...acc, [item.key]: "" }), {})
        let cItem = {
            ...modelFromField,
            ciElib: moment().format('YYYY-MM-DD'),
            dNastere: moment().format('YYYY-MM-DD'),
            cui: tip === "pj" ? " ": null,
            cnp: tip === "pf" ?  " ": null
        }
        setCurrentItem(cItem)
      
    }
    





    return (
        <Fragment>
            {error && <pre>{error.toString()}</pre>}
            {loading && <LinearProgress/>}
            { customers && (
                <Fragment>
                    <Container 
                        title = "CONTACTE"
                        action = {
                            <Grid container alignItems="center">
                                <Button 
                                    variant ="contained" color="primary" size = "small"
                                    onClick = {addNewContactHandler('pf')}><FaPlus/> &nbsp;
                                    <Typography variant = "caption"  style={{paddingTop:"3px"}}> P.F.</Typography>
                                </Button>    
                                &nbsp;&nbsp;&nbsp;                            
                                <Button 
                                    variant ="contained" color="primary" size = "small"
                                    onClick = {addNewContactHandler('pj')}><FaPlus/> &nbsp;
                                    <Typography variant = "caption" style={{paddingTop:"3px"}}> P.J.</Typography>
                                </Button>
                                &nbsp;&nbsp;
                        </Grid>
                        }
                    >
                        <ReactTable7 
                            columns = {COLUMNS}
                            data = {customers}
                            clickedRow = {clickRowHandler}
                            style={{ height: "70vh" , overflow: "auto"}}
                        />
                        {/* <pre>{JSON.stringify(customers, null, 4)}</pre> */}
                    </Container>
                    <ContactModal data = {currentItem} onClose = {closeModalHandler} />
                </Fragment>
            )}
        </Fragment>
    )
}
