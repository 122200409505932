import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

export default function NoLicence({app}) {
  return <Alert severity = "error">
    <Typography variant = "h5">
      Accesul Dvs. la acest modul nu este permis! (nu v-ați abonat încă la acest modul)
      <br/>
      Vă rugăm să contactați furnizorul serviciului pentru a obține un cont demonstrativ sau o ofertă de preț.
      <br/> <br/>
     Totodată vă punem la dispoziți și facilitatea de a genera automat o ofertă personalizată 
     pentru a o prezenta factorilor de decizie din instituția Dvs. 
     <br/><br/>
     Vă rugăm să accesați 
      configuratorul nostru de oferte, la adresa {' '}
      <strong><a href = "https://gmaftei.ro/pricing"  rel="noreferrer" target="_blank">https://gmaftei.ro/pricing</a></strong>
      </Typography>
  </Alert>
}

