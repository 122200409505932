import { Typography, makeStyles, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import PrintModalWrapper from './PrintModalWrapper';
import { COMPANY } from '../../../apollo/query';
import { useLazyQuery } from '@apollo/client';
import ANTET_INSTITUTIE from '../../Dosar/Common/ANTET_INSTITUTIE';
import Field from '../../Dosar/Common/Field';

const useStyles = makeStyles(theme => ({
    justify: {
        textAlign: "justify",
        textJustify: "inter-word"
    },
    printOnly: { "@media screen" : {display: "none"}},
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "crosshair",
            border: "2px solid navy",
        },
    },
    
}))

export default function PrintPvAtestat({ data, onClose }) {

    const classes = useStyles()

    const {atProcesVerbal} = data || {}
    const pv = atProcesVerbal
    const [getCompany, company] = useLazyQuery(COMPANY)
    const [semnaturi, setSemnaturi] = useState({
        atestate1: {prop:"", value: "", desc: ""}, 
        atestate2: {prop:"", value: "", desc: ""}, 
    })

    useEffect(() => {
        getCompany()
    }, [data])

    useEffect( () => {
        if (company && company.data && company.data.company) {
            let s1, s2
            let settings = company.data.company.settings
            s1 = settings.filter(el => el.prop === "atestate1")
            s1 = s1.length > 0 ? s1[0] : null
            s2 = settings.filter(el => el.prop === "atestate2")
            s2 = s2.length > 0 ?  s2[0] : null
            setSemnaturi({
                atestate1: s1 ? s1 : semnaturi.atestate1,
                atestate2: s2 ? s2 : semnaturi.atestate2,
                company:  company.data.company
            })
        }
    }, [company && company.data])

    return <Fragment>
        {pv && company && company.data && company.data.company &&  (
            <PrintModalWrapper onClose={() => onClose(null)}>
                {console.log("company:", company.data)}
                <ANTET_INSTITUTIE data = {company.data.company} />
                <div align="center">
                    {/* <div className = {classes.printOnly}> */}
                            <br/><br/>
                    {/* </div> */}
                   
                <Typography variant ="body1"><strong>Proces verbal</strong></Typography>
                <Typography variant ="body1"><strong>nr. {pv.nr} din {moment(pv.dt).format("DD.MM.YYYY")}</strong></Typography>
                </div>
                <br/>
                <div className={classes.justify}>
                    Subsemnații, <br/>
                    1. <Field>{semnaturi.atestate1.value}</Field>, 
                    având funcția de <Field>{semnaturi.atestate1.desc}</Field> având competenţă în completarea Registrului Agricol,
                    <br/>
                    2. <Field>{semnaturi.atestate2.value}</Field>, 
                    având funcția de <Field>{semnaturi.atestate2.desc}</Field>,  funcționar desemnat în vederea verificării 
                    deţinerii folosinţei suprafeţelor de teren, precum şi a existenţei produselor, producţiilor
                     estimate şi cantităţilor destinate comercializării,
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    în urma cererii nr. {data && data.nr} / { data && moment(data.dt).format("DD.MM.YYYY")} depuse de {' '}
                    
                    <Field>{data.contact.name}</Field>, {' '}
                    având domiciliul în localitatea <Field>{data.contact.domLoc}</Field>, {' '}
                    str. <Field>{data.contact.domStr}</Field>, {' '}
                    nr. <Field>{data.contact.domNr}</Field>, {' '}
                    bl. <Field>{data.contact.domBl}</Field>, {' '}
                    sc. <Field>{data.contact.domSc}</Field>, {' '}
                    et. <Field>{data.contact.domEt}</Field>, {' '}
                    ap. <Field>{data.contact.domAp}</Field>, {' '}
                    CNP:  <Field>{data.contact.cnp}</Field>, {' '} 
                    insoțită de avizul consultativ nr. <Field>{data && data.avizNr}</Field>, {' '}
                    din data de <Field>{data && moment(data.avizDt).format("DD.MM.YYYY")}</Field>, {' '}
                    emis de  <Field>{data && data.avizEmitent}</Field>, {' '}

                     am întreprins toate măsurile prevăzute de legislația în vigoare în scopul verificării deţinerii folosinţei suprafeţelor de teren, precum şi a culturilor și efectivelor deținute de petent, în evidențele Registrului Agricol al  {company && company.data && company.data.company.name}, constatând că acesta figureză înscris(ă)
                     în vol. <Field>{pv.agricolVol}</Field>,{' '}
                     nr. matricol  <Field>{pv.agricolNr}</Field>,{' '}
                     cu teren arabil în suprafață de <Field>{pv.agricolArabil || 0}</Field> ha,{' '}
                     livezi în suprafață de <Field>{pv.agricolLivezi || 0}</Field> ha,{' '}
                     vii în suprafață de <Field>{pv.agricolVii || 0}</Field> ha,{' '}
                     animale <strong>{pv.agricolAnimale || 0}</strong> capete,{' '}
                     păsări <strong>{pv.agricolPasari || 0}</strong> capete,{' '}
                     albine <strong>{pv.agricolAlbine || 0}</strong> familii.{' '}
                     <br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Constatând că sunt îndeplinite condițiile legale în materie, am procedat la eliberarea {' '}
                        <strong>atestatului de producător nr. {pv.atestatSeria || "--"}</strong>, {' '}
                        <strong>seria {pv.atestatNr || "--"}</strong> {' '}
                        <strong>din data de {pv.atestatDt ? moment(pv.atestatDt).format("DD.MM.YYYY") : "---"}</strong> {' '}
                     <br/>
                   </div>
              <br/><br/>
                <Grid container justifyContent="space-between" alignItems='flex-start'>
                    <Grid item xs = {8} align = "left">
                        {semnaturi && semnaturi.company && semnaturi.company.loc}, la {moment(pv.dt).format("DD.MM.YYYY")}
                    </Grid>
                    <Grid item align="center" xs = {12} >
                        <br/><br/>
                    </Grid>
                    <Grid item xs = {6} align="center">
                        {semnaturi && semnaturi.atestate1.value}
                        <br/>
                        {semnaturi.atestate1.desc}
                    </Grid>
                    <Grid item xs = {6} align="center">
                        {semnaturi.atestate2.value}<br/>
                        {semnaturi.atestate2.desc}
                    </Grid>
                </Grid>

                {/* <pre>{data && JSON.stringify(data, null, 4)}</pre> */}
            </PrintModalWrapper>
        )}

    </Fragment>
}
