import { Button, Grid, Typography } from '@material-ui/core'
import {Route, Switch} from 'react-router'
import React, {useContext} from 'react'
import {Context} from '../../components/Store'
import { FaWrench } from 'react-icons/fa'
import Container from '../../components/Container'
import SetariCompanie from './SetariCompanie'
import SetariUtilizatori from './SetariUtilizatori'
import SetariDiverse from './SetariDiverse'
import SetariLanding from './SetariLanding'
import SuperAdmin from './SuperAdmin'

export default function Settings(props) {

    const [state] = useContext(Context)

    return (
        <Container title={
            <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                <Grid item style={{ display: "flex", alignItems: "center" }}
                onClick = {()=>props.history.push('/setari')}> 
                    <FaWrench color="navy" size="1.2rem" />
                    <Typography variant="h6" > &nbsp;&nbsp;CONFIGURĂRI APLICAȚIE  </Typography>
                </Grid>
                {state.role === "app" && (
                    <Grid item> 
                        <Button variant="text" color="primary" onClick = {()=>props.history.push('/setari/superadmin')}> 
                            SUPERADMIN
                        </Button>
                    </Grid>
                )}

                <Grid item> 
                    <Button variant="text" color="primary" onClick = {()=>props.history.push('/setari/companie')}> 
                        COMPANIE
                    </Button>
                </Grid>
                <Grid item> 
                    <Button variant="text" color="primary" onClick = {()=>props.history.push('/setari/utilizatori')}> 
                        UTLIZATORI
                    </Button>
                </Grid>
                <Grid item> 
                    <Button variant="text" color="primary" onClick = {()=>props.history.push('/setari/setari-diverse')}> 
                        SETĂRI DIVERSE 
                    </Button> 
                </Grid>
            </Grid>
        }>
            <Switch>
                <Route path ="/setari" exact component ={SetariLanding} />
                <Route path = "/setari/companie" component = {SetariCompanie} />
                <Route path = "/setari/utilizatori" component = {SetariUtilizatori}  />
                <Route path = "/setari/setari-diverse" component = {SetariDiverse} />
                <Route path = "/setari/superadmin" component = {SuperAdmin} />
            </Switch>
           
        </Container>
    )
}
