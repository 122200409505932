import { makeStyles, Grid, Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import RichCard from '../../../components/RichCard'
import {useReactToPrint} from 'react-to-print'
import { Fragment } from 'react'
import Field from '../Common/Field'
import UAT from '../Common/UAT'
import PF_PJ from '../Common/PF_PJ'
import moment from 'moment'
import FunkyInput from '../../../components/FunkyInput'
import { getSetting } from '../../../lib/settings'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        borderCollapse:"collapse",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem", fontWeight: 700, textAlign: "center",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

const cod = "pv3"

export default function Pv3Final(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState(null)
    const [currentItem, setCurrentItem] = useState()

    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const anexaInputHandler = name => ev => {
        let newDosar ={...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    return (
        <Fragment>
            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
{/* kitchen             */}
                    <Grid item xs={7}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs = {12}>
                                <RichCard title = " NR.INREGISRARE " >
                                    <Grid container justifyContent= "flex-start" spacing={1}>
                                    <Grid item xs ={2}>
                                        <FunkyInput value ={dosar.anexe[cod].nr} label = "NR" onChange ={anexaInputHandler('nr')}
                                        type="number" step ="1"/>
                                    </Grid>
                                    <Grid item xs = {4}>
                                    <FunkyInput value ={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} label = "NR.INREG"
                                    onChange ={anexaInputHandler('dt')} type = "date"/>
                                    </Grid></Grid>


                                </RichCard>
                                </Grid>
                        </Grid>
                    </Grid>
{/* print                     */}
                    <Grid item xs={5}>

                        <RichCard
                            title="PREVIZUALIZARE"
                            action={
                                <Fragment>
                                    <Button variant="contained" color="primary" size="small" onClick={printHandler}>TIPĂRIRE</Button>
                                </Fragment>
                            }
                            >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }} >
                                {dosar && dosar.anexe 
                                    ? (
                                        <Fragment>
                                        <Grid
                                            container alignItems="flex-start"
                                            style={{ border: "0px solid red" }}
                                            className="printable"
                                            ref={printRef} >
                                    <Grid item xs={12} align = "center">
                                        <ANTET_INSTITUTIE data = {dosar.company} />
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD-MM-YYYY")}
                                    </Grid>
                                    <Grid item xs={12} align = "center"></Grid>
                                            <Grid item xs={12} align = "center" >
                                                <strong>
                                                PROCES-VERBAL FINAL 
                                                </strong>
                                                <br/>
                                                de constatare a derulării fiecărei etape procedurale<br/>
                                                prevăzute la art.7 din legea nr.17/2014 modificată,<br/>
                                                întocmit pentru cererea de publicare și oferta de vânzare
                                                nr. <Field>{dosar.nr}</Field> / <Field>{moment(dosar.anexe[cod].dt).format('DD.MM.YYYY')}</Field>
                                                <br/><br/>
                                            </Grid>
                                            <Grid item xs={12}   className = {classes.text}>
                                                Subscrisa, <strong>UAT</strong> <UAT data = {dosar.company} />, {' '}
                                                în temeiul art. 9 si 10 din Legea nr. 17/2014, privind unele masuri de
                                                reglementare a vânzări-cumpărării terenurilor agricole situate in 
                                                extravilan și de modificare a Legii nr.268/2001 privind privatizarea 
                                                societăţilor comerciale ce deţin în administraţie terenuri proprietate
                                                    publică și privată a statului cu destinaţie agricolă și înfiinţarea 
                                                    Agenţiei Domeniului Statului, cu modificările ulterioare, având în 
                                                    vedere oferta de vânzare depusă și înregistrată la sediul subscrisei 
                                                    sub nr. <Field>{dosar.nr}</Field> 
                                                    / <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field> de către:
                                                    <PF_PJ data = {dosar.ofertanti}  noPrefix={true} />
                                                    am încheiat astăzi, <Field>{moment(dosar.anexe[cod].dt).format('DD.MM.YYYY')}</Field>, 
                                                    cu ocazia finalizării procedurilor prevăzute de Legea 17/2014 cu
                                                    completările ulterioare, prezentul <Field>PROCES-VERBAL FINAL</Field>,{' '} 
                                                    fiind constatate următoarele:
                                                    <br/>

                                                    - Ca urmare a verificării modului de respectare a procedurii prevăzuta 
                                                    de lege privind exercitarea dreptului de preempțiune în vederea încheierii 
                                                    contractului de vânzare - cumpărare în formă autentică de către notarul
                                                    public, în urma publicității, au depus cereri pentru înregistrarea ofertei de
                                                     cumpărare, respectiv comunicări de acceptare a ofertei de vânzare, următorii preemptori:
                                                    <table className = {classes.table}>
                                                        <thead>
                                                            <tr>
                                                                <th>Nr.crt.</th>
                                                                <th>Nume/denumire<br/>preemptor /<br/> rang preemptor</th>

                                                                <th>Nr/Data depunerii <br/>comunicării <br/>de acceptare</th>
                                                                <th>Prețul oferit<br/>-lei-</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dosar.anexe.acceptari.map( (e,i) => (
                                                                <tr key={i}>
                                                                    <td>{i+1}</td>
                                                                    <td>
                                                                        {e.titular.name}<br/>
                                                                        <i>{e.rang.name}</i>

                                                                    </td>
                                                                    <td>
                                                                        {e.nr} {' '} / {' '}
                                                                        {moment(e.dt).format('DD.MM.YYYY')}
                                                                    </td>
                                                                    <td>{e.pret}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <br/>
                                                    - Preemptorul potențial cumpărător ales de către vânzător este {' '}
                                                    {dosar.anexe.alegerePreemptor 
                                                    && dosar.anexe.alegerePreemptor.titular
                                                    && dosar.anexe.alegerePreemptor.titular.name 
                                                    ? (
                                                        <Fragment>
                                                            <Field>{dosar.anexe.alegerePreemptor.titular.name}</Field>, {' '}
                                                            <Field>{dosar.anexe.alegerePreemptor.rang.name}</Field> {' '}
                                                        </Fragment>
                                                    )
                                                    : <Field> - - - - - - - - - - -</Field>
                                                    }

                                                    al terenului agricol având categoria 
                                                    de folosință <Field>{dosar.anexe.terenCategorie}</Field>, 
                                                    situat în extravilanul localității <Field>{dosar.anexe.terenLoc}</Field>,{' '}
                                                    judeţul  <Field>{dosar.anexe.terenJud}</Field>,{' '}
                                                    nr. tarla  <Field>{dosar.anexe.terenTarlaLot}</Field>,{' '}
                                                    parcela  <Field>{dosar.anexe.terenParcela}</Field>,{' '}
                                                    dentificat cu nr. cadastral  <Field>{dosar.anexe.terenNrCadastral}</Field>,{' '}
                                                    înscris in cartea funciară nr.  <Field>{dosar.anexe.terenCf}</Field>,{' '}
                                                    a localității  <Field>{dosar.anexe.terenCfLoc}</Field>,{' '}
                                                    în suprafaţa de.  <Field>{dosar.anexe.terenHa}</Field>,{' '} <strong>ha</strong>,
                                                        la preţul de vânzare de {' '}
                                                        <Field>{dosar.anexe.terenPret}</Field>{' '} <strong>lei</strong>.
                                                        <br/><br/>
                                                    - Precizăm pe această cale și faptul că au fost parcurse absolut toate
                                                    etapele procedurale impuse de prevederile Legii nr. 17/2014 
                                                    privind unele masuri de reglementare a vânzării-cumpărării 
                                                    terenurilor agricole situate în extravilan, modificată și completată prin 
                                                    Legea 175/2020 și Ordinul Comun nr. 311/2020, 
                                                    a Ordinului M.A.D.R. nr. 719/12.05.2014, a Ordinului M.Ap.N. nr. 57 / 13.05.2014, 
                                                    a Ordinului M.D.R.A.P. nr. 740/13.05.2014, 
                                                    precum și prevederile Ordinului Ministerului Culturii nr. 2333 / 13.05.2014
                                                    <br/> <br/>
                                                    <Field>{dosar.company.loc}</Field>, {' '}la{' '}
                                                    <Field>{moment(dosar.anexe[cod].dt).format('DD.MM.YYYY')}</Field>
                                                    <br/>
                                                    <br/>
                                                    Primar,<br/>
                                                    <strong>{ dosar &&getSetting(dosar.settings, 'primar') }</strong>
                                                    <br/><br/>
                                                    ..................................................................<br/>
                                                    (numele şi prenumele/semnătura)
                                                    <br/>
                                                    <br/>
                                                    L.S. Secretar general primărie,<br/>
                                                    <strong>{ dosar && getSetting(dosar.settings, 'secretar') }</strong>
                                                    <br/><br/>
                                                    ..................................................................<br/>
                                                    (numele şi prenumele/semnătura)
                                                    <br/><br/>
                                            </Grid>
                                        </Grid>
                                        </Fragment>
                                          
                                    
                                    ) 
                                    : (
                                        <div>alegeti o anexa</div>
                                    )}
           
           </div>
                        </RichCard>

                    </Grid>
                </Grid>
            )}
        </Fragment>
    )
}
