import React, { Fragment, useEffect } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
// import { Table, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { TextField, Button, makeStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { Filter, DefaultColumnFilter } from './filters';
import { FaBackward, FaCaretLeft, FaCaretRight, FaFastBackward, FaFastForward, FaForward, FaPlay } from 'react-icons/fa';
import { FastRewind } from '@material-ui/icons';
import FunkyInput from '../FunkyInput';


const useStyles = makeStyles(theme => ({
  table: {
    overflow: "auto",
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
    "&& thead tr th": {
        position: "sticky",
        top: -10
      },
      "&& tfoot tr th": {
        position: "sticky",
        bottom: 0
      },
    "&& th": {
            // padding: "16px",
            // paddingLeft: "15px",
            borderLeft: "1px dotted rgba(200, 209, 224, 0.6)",
            borderBottom: "1px solid #e8e8e8",
            background:theme.palette.grey[100],
            textAlign: "left",
            boxShadow: "0px 0px 0 1px #e8e8e8"
          
    },
    "&& thead tr:nth-child(2) th": { padding: 0, fontSize: "0.5rem", fontWeight:"normal", textAlign: "center" },
    "&& thead>tr>th,td, tbody>tr>td": {
        padding: theme.spacing(0.4), 
        border: "none", 
        fontSize: "0.80rem", fontWeight: 400, margin: 0,color: "black", fontFamily: "Roboto",
    },
    "&& thead>tr>th": {color: "navy", textAlign: "center"},
    "&& tbody>tr:nth-child(odd)": {background: theme.palette.grey[300]},
    "&& tbody>tr:nth-child(even)": {background: theme.palette.grey[0]},
    "&& tbody>tr:hover": {background: "#EBF5FB"},
    "&& tfoot>tr": {paddingTop: "20px"}
    
}
}))

const ReactTable7 = ({ columns, defaultPageSize, height,  data, renderRowSubComponent, clickedRow, contextRow, style,
disablePagination, ...rest  }) => {

  const classes = useStyles()
  const width = useMediaQuery(useTheme().breakpoints.up('sm'))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    getRowsProps,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const onRowClick = row => ev => { 
    ev.preventDefault();
    clickedRow && clickedRow(row, ev) 
  }

  const onRowContext = (row, index) => ev => { 
    ev.preventDefault();
    contextRow && contextRow(row, index, ev) 
  }


  useEffect(()=>{
    if ( defaultPageSize ) { setPageSize(defaultPageSize)}
  }, [])
  
  return (
    <div style={{height: "78vh"}}>
      <div  style = { style } >
        <table 
          // bordered="true" hover="true"
          {...getTableProps()}
          // classes={{ root: classes.fixedHeader }}
          className={classes.table}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  // {console.log(column)}
                  return (
                  <th {...column.getHeaderProps()} style={column.style} width = {column.width}>
                    <div {...column.getSortByToggleProps()}>
                      <small>{column.render('Header')}</small>
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                )})}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={`rw-${i}`} >
                  <tr onClick={onRowClick(row.original)} onContextMenu = {onRowContext(row.original, row.index)}>
                    {row.cells.map((cell) => {
                      
                      return (
                        <td {...cell.getCellProps()} style={cell.column.style}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length} style = {{background: "white"}}>
                        <div>{renderRowSubComponent({row})}</div>
                      </td>
                    </tr>
                  ):null}
                </Fragment>
              );
            })}
          </tbody>
          <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
        </table>
      </div>
          { !disablePagination && (
             <div className={classes.footer} >

             {/* <TableRow style = {{border: "1px solid red"}} > */}
               
             <Grid container justifyContent="space-between" alignItems="center" style={{ width: "100%", marginTop: "10px" }}>
               <Grid item xs={5} sm={5} align="center" style={{ display: "flex" }}>
                 {width && (
                   <Button
                     color='primary'
                     onClick={() => gotoPage(0)}
                     disabled={!canPreviousPage}
                   >
                     <FaFastBackward />
                   </Button>
                 )}
     
                   &nbsp;&nbsp;&nbsp;
                   <Button
                   color='primary'
                   onClick={previousPage}
                   disabled={!canPreviousPage}
                 >
                   <FaCaretLeft style={{ fontSize: "2rem" }} />
     
                 </Button>
                 {width && (
                 <FunkyInput
                   variant="outlined"
                   label=" pagina"
                   type='number'
                   min={1}
                   style={{ width: 70, background: 'white' }}
                   size="small"
                   max={pageOptions.length}
                   value={pageIndex + 1}
                   onChange={onChangeInInput}
                 />
                 )}
                 <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                   <FaCaretRight style={{ fontSize: "2rem" }} />
                 </Button>
                 {width && (
                   <Button
                     color='primary'
                     onClick={() => gotoPage(pageCount - 1)}
                     disabled={!canNextPage}
                   >
                     <FaFastForward />
                   </Button>
                 )}
               </Grid>
     
               {width && (
                 <Grid item xs={5} style={{ display: "flex", alignItems: "center", justify: "right" }}>
                   afișează &nbsp;
                   <FunkyInput
                     select
                     id="opts-select"
                     fullWidth={false}
                     // label="pe pag."
                     value={pageSize}
                     onChange={onChangeInSelect}
                     variant="outlined"
                     size="small"
                     style={{ background: "white" }}
                     SelectProps={{
                       native: true,
                     }}
                   >
     
                     {[5, 12, 10, 15, 20, 30, 40, 50, 100, 1000].map((pageSize) => (
                       <option key={pageSize} value={pageSize}>
                         {pageSize}
                       </option>
                     ))}
                   </FunkyInput>
                   &nbsp;
                   pe pagină
                 </Grid>
               )}
     
     
             
                 <Grid item xs={5} sm={2} align="right">
                   <>
                     Pagina {' '}
     
                     {pageIndex + 1} din {pageOptions.length}
                   </>
                 </Grid>
               
     
             </Grid>
           </div>
          )}
     



    </div>
  )
};

export default ReactTable7;