import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, makeStyles } from '@material-ui/core'
import React, {useState, useEffect } from 'react'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import { useMutation, useApolloClient } from "@apollo/client"
import { ARENDA_REGISTRU_MUTATION } from '../../../apollo/mutationArenda'
import { REGISTRU } from '../../../apollo/queryArenda'
import { MdClose } from 'react-icons/md'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        // width: "60vw", 
        height: "auto"
    }
}))

export default function NewConex(props) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [mutateRegistru] = useMutation(ARENDA_REGISTRU_MUTATION)
    const client = useApolloClient()

    useEffect(() => {
        setItem(props.data)
    }, [props])

    const closeHandler = item => ev => {
        if ( !item ) {props.onClose()}
        else {
            mutateRegistru({variables: {
                data: item
            }}).then( 
                
                r => {
                    let d  = client.readQuery({query:REGISTRU})
                    client.writeQuery({
                        query:REGISTRU,
                        variables: {
                            data: {
                                chunk: {
                                    skip: 0, take: 20
                                },
                                ordering: [{ key: "nr", value: "asc" }]
                            }
                        },
                        data: {
                            registruArenda:  r.data.arendaRegistru
                        }
                    })
                    props.onClose({id:r.data.arendaRegistru.id})
                },
                err => {
                    console.log("err: ", err)
                    props.onClose(err)
                }
            )
            
        }
    }

    const inputHandler = name => ev => setItem({...item, [name]: name === "dt" 
        ? moment(ev.target.value).toDate()
        : parseInt(ev.target.value)
     })

    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler()} classes = {{paper:classes.dialogPaper}}>
            <DialogTitle>ADAUGĂ DOCUMENT CONEX</DialogTitle>
            <DialogContent>
                {item && item.parentId && (
                    <Grid container justifyContent = "flex-start">
                        <Grid item xs = {4}>
                        <FunkyInput value = {item.nr} label = "NR" onChange = {inputHandler('nr')}/>
                        </Grid>
                        <Grid item xs = {8}>
                        <FunkyInput 
                            value = {moment(item.dt).format('YYYY-MM-DD')} label = "DIN DATA" type = "date"
                            onChange = {inputHandler('dt')}
                        />
                        </Grid>
                    </Grid>
                )}
                {item && !item.parentId && (
                <Alert severity='info'>
                <Typography variant='body1' color="secondary" style = {{textAlign:"justify", justifyContent: "inter-word"}}>
                    Procedura pentru adăugarea unui act adițional care presupunea crearea actului adițional urmată de alegerea contractului la care se referă a fost eliminată din cadrul aplicației, deoarece practica ne-a demonstrat că duce la rezultate imprevizibile. (în cazul în care operatorul, dintr-un motiv sau altul, NU mai continuă procedura prin alegerea contractului, iar actul a fost deja creat în baza de date)
                </Typography>
                <Typography variant='body1' color="primary" style = {{textAlign:"center", justifyContent: "inter-word", marginTop: "0.5em"}}>
                    <strong>MODUL RECOMANDAT  DE ADĂUGARE A DOCUMENTELOR CONEXE UNUI CONTRACT </strong> <br/><br/>
                </Typography>
                <Typography variant='body1' color="primary" style = {{textAlign:"left", justifyContent: "inter-word"}} component="div">
                    <ul style={{marginTop: "-20px"}}>
                        <li>Căutați în registru (lista principală) contractul la care se referă actul conex și accesați-l </li>
                        <li>Din pagina de administrare a contractului, în secținea <strong>ISTORIC/ACTE CONEXE</strong> (în dreapta, sub arendași și arendatori)
                        folosiți butonul "plus" (în cerc albastru) pentru a adăuga un document conex. Acestuia i se va aloca implicit următorul număr disponibil din registrul general și data curentă
                        </li>
                        <li> continuați editarea detaliilor documentului conex (tip, explicație etc) în modul deja cunoscut</li>
                    </ul>
                </Typography>
            </Alert>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick = {closeHandler()} color = "secondary" variant = "contained" startIcon={<MdClose/>}> INCHIDE</Button>
                {item && item.parentId && (<Button onClick = {closeHandler(item)} color = "primary" variant = "contained"> CONFIRMĂ</Button>)}
            </DialogActions>
        </Dialog>
    )
}
