import React from 'react'
import { Chip, Grid } from "@material-ui/core"

export default function LuckyChip(props) {

    const item = props.data || "enter text prop"
    const icon = props.icon || "enter icon prop"
    return (
        <Grid item>
            <Chip 
                color={props.disabled ? "default":"primary" }
                style={{ paddding: "10px" }}
                onClick={()=>props.onClick(item)}
                label={<small>{item.name}</small>}
                deleteIcon={<div style={{ 
                    paddingTop: "2px", width: "20px", height: "20px", 
                    background: "white", 
                    color: props.disabled ? "grey":"navy", 
                    borderRadius: "10px", textAlign: "center", fontWeight: 800 
                }}>
                    { icon }
                </div>}
                onDelete={() => { }}
                disabled = {props.disabled}
            />
          
        </Grid>
    )
}

