import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from "@apollo/client"
import { COMPANIES } from '../../apollo/query'
import { COMPANY_MUTATION, USER_MUTATION, USER_RM } from '../../apollo/mutation'
import { Divider, Grid, LinearProgress, MenuItem, IconButton, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Fragment } from 'react'
import RichCard from '../../components/RichCard'
import { FaCheck, FaCheckCircle, FaPlus, FaTimesCircle, FaUserLock } from 'react-icons/fa'
import FunkyInput from '../../components/FunkyInput'
import Confirm from '../../components/Confirm'
import { clearTypeName, deepClearTypeName } from '../../lib/graph-stuff'
import AclModal from './AclModal'


const PlusButton = props => <IconButton size="small" color="primary" {...props}><FaPlus /></IconButton>
const CheckButton = props => <IconButton size="small" color="primary" {...props}><FaCheck /></IconButton>
const RemoveButton = props => <IconButton size="small" color="secondary" {...props}><FaTimesCircle /></IconButton>
const Gput = props => <Grid item xs={props.xs}><FunkyInput {...props} /></Grid>




export default function SuperAdmin() {

    const { data, loading, error } = useQuery(COMPANIES)
    const [companies, setCompanies] = useState()
    const [cIndex, setCIndex] = useState()
    const [uIndex, setUIndex] = useState()
    const [companyMutation] = useMutation(COMPANY_MUTATION)
    const [userMutation] = useMutation(USER_MUTATION)
    const [userRm] = useMutation(USER_RM)
    const [confirm, setConfirm] = useState()
    const [ acl, setAcl ] = useState()

    useEffect(() => { if (data && data.companies) setCompanies(data.companies) }, [data])

    const selectCompanyHandler = (_e, i) => () => {
        setCIndex(i); setUIndex()

    }
    const addCompanyHandler = () => {
        const nc = { ap: "", bank1: "", bl: "", cod: "", cui: "", email: "", et: "", fax: "", iban1: "", jud: "ARAD", loc: "", logo: "", name: "", nr: "", obs: "", phone: "", regcom: "", sc: "", str: "", tara: "ROMÂNIA", users: [] }
        let c = [...companies]
        c.push(nc)
        setCompanies(c)
        setCIndex(companies.length)

    }

    const companyInput = name => ev => {
        let c = companies.map(e => ({ ...e }))
        c[cIndex][name] = ev.target.value
        setCompanies(c)

    }
    const userInput = (name, i) => ev => {
        let c = companies.map(e => ({ ...e, users: e.users.map(e => ({ ...e })) }))
        c[cIndex]['users'][i][name] = ev.target.value
        setCompanies(c)
    }

    const submitCompany = async () => {
        let c = deepClearTypeName([...companies])
        let qdata = c[cIndex]
        delete qdata.users
        let res = await companyMutation({ variables: { data: deepClearTypeName(qdata) } })
        console.log("res:", res)
        if (res && res.data) {
            c[cIndex] = res.data.company;
            setCompanies(c)
        }
    }

    const addNewUser = () => {
        let c = companies.map(cmp => ({ ...cmp, users: cmp.users.map(e => ({ ...e })) }))
        c[cIndex]['users'].push({ companyId: c[cIndex]['id'] })
        console.log(c[cIndex])
        setCompanies(c)
    }

    const submitUser = async () => {
        let c = [...companies]
        let cUser = c[cIndex]['users'][uIndex]
        cUser = clearTypeName(cUser)
        let res = await userMutation({ variables: { data: cUser } })
        console.log("res user:", res)
        c[cIndex]['users'][uIndex] = res.data.user
        setCompanies(c)
    }

    const confirmCloseHandler = async () => { //remove user,if possible
        let c = companies.map(cmp => ({ ...cmp, users: cmp.users.map(e => ({ ...e })) }))
        let user = c[cIndex]['users'][uIndex]
        let res = await userRm({ variables: { id: +user.id } })
        console.log("res", res)
        if (res && res.data && res.data.userRm) {
            c[cIndex]['users'].splice(uIndex, 1)
            setCompanies(c); setConfirm(); setUIndex()
        }

    }

    const aclHandler = (item, index) => () => setAcl({ item: item, index: index })

    const aclModalCloseHandler = ev => {
        if (ev) {
            console.log("ev:", ev, cIndex, uIndex)
            let ni = deepClearTypeName(companies)
            ni[cIndex]['users'][uIndex] = ev
            setCompanies(ni)
        }
        setAcl(null)
    }

    return (
        <div>
            {loading && <LinearProgress />}
            {error && <Alert severity="error">{JSON.stringify(error, null, 1)}</Alert>}
            {companies && (

                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12} sm={4} lg={3}>
                        {/* lista firme    */}
                        <RichCard
                            title="CLIENȚI"
                            action={<PlusButton onClick={addCompanyHandler} />}
                        >
                            <div style={{ height: "70vh", overflow: "auto" }}>
                                {companies.map((e, i) => (
                                    <Fragment key={i}>
                                        <MenuItem onClick={selectCompanyHandler(e, i)} dense
                                            style={{
                                                background: i === cIndex ? "navy" : null,
                                                color: i === cIndex ? "white" : null
                                            }}>
                                            <small>{e.name || "---"}</small>
                                        </MenuItem>
                                        <Divider />
                                    </Fragment>
                                ))}
                            </div>
                        </RichCard>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                {/* firma    */}
                                <RichCard
                                    title={(cIndex || (cIndex === 0 && companies[cIndex])) ? companies[cIndex]['name'] : ""} style={{ background: "#C6E3F4" }}
                                    action={<CheckButton onClick={submitCompany} />}
                                >
                                    <div style={{ height: "32vh", overflow: "auto", paddingTop: "10px", }}>
                                        {(cIndex || cIndex === 0)
                                            ? (
                                                <Grid container spacing={1}>
                                                    <Gput xs={12} value={companies[cIndex].name} label="NUME"
                                                        onChange={companyInput('name')} />
                                                    <Gput xs={2} value={companies[cIndex].cui} label="CUI/CIF"
                                                        onChange={companyInput('cui')} />

                                                    <Gput xs={2} value={companies[cIndex].regcom} label="REG.COM"
                                                        onChange={companyInput('regcom')} />
                                                    <Grid item xs={12} />
                                                    <Gput xs={2} value={companies[cIndex].cod} label="COD POȘTAL"
                                                        onChange={companyInput('cod')} />
                                                    <Gput xs={3} value={companies[cIndex].loc} label="LOC."
                                                        onChange={companyInput('loc')} />
                                                    <Gput xs={3} value={companies[cIndex].jud} label="JUD"
                                                        onChange={companyInput('jud')} />
                                                    <Gput xs={3} value={companies[cIndex].tara} label="ȚARA"
                                                        onChange={companyInput('tara')} />

                                                    <Gput xs={7} value={companies[cIndex].str} label="STR."
                                                        onChange={companyInput('str')} />
                                                    <Gput xs={1} value={companies[cIndex].nr} label="NR."
                                                        onChange={companyInput('nr')} />
                                                    <Gput xs={1} value={companies[cIndex].bl} label="BL."
                                                        onChange={companyInput('bl')} />
                                                    <Gput xs={1} value={companies[cIndex].sc} label="SC."
                                                        onChange={companyInput('sc')} />
                                                    <Gput xs={1} value={companies[cIndex].et} label="ET."
                                                        onChange={companyInput('et')} />
                                                    <Gput xs={1} value={companies[cIndex].ap} label="AP."
                                                        onChange={companyInput('ap')} />
                                                    <Gput xs={2} value={companies[cIndex].tel} label="TEL."
                                                        onChange={companyInput('tel')} />
                                                    <Gput xs={2} value={companies[cIndex].fax} label="FAX"
                                                        onChange={companyInput('fax')} />
                                                    <Gput xs={3} value={companies[cIndex].email} label="EMAL"
                                                        onChange={companyInput('email')} />
                                                    <Gput xs={5} value={companies[cIndex].obs} label="OBSERVAȚII"
                                                        onChange={companyInput('obs')} />
                                                </Grid>
                                            )
                                            : <div align="center"><Typography variant="h5" color="primary">
                                                ALEGETI O COMPANIE
                                        </Typography>
                                            </div>
                                        }
                                    </div>
                                </RichCard>
                            </Grid>
                            {(cIndex || cIndex === 0) && (
                                <Grid item xs={12}>
                                    <RichCard
                                        title="UTILIZATORI"
                                        action={<PlusButton disabled={!companies[cIndex]['id']} onClick={addNewUser} />}
                                    >
                                        {/* user   */}
                                        <div style={{ height: "27.5vh", overflow: "auto" }}>
                                            {companies[cIndex]
                                                && companies[cIndex].users
                                                && companies[cIndex].users.map((e, i) => (

                                                    <div
                                                        key={i}
                                                        style={{
                                                            padding: "10px",
                                                            background: uIndex === i ? "pink" : "white"
                                                        }}>
                                                        <Grid container spacing={2} onMouseOver={() => { setUIndex(i); }}
                                                        >
                                                            <RemoveButton onClick={() => setConfirm(true)} />

                                                            <Gput xs={2} value={e.name} label="NUME ȘI PRENUME"
                                                                onChange={userInput('name', i)} />
                                                            <Gput xs={3} value={e.email} label="EMAIL"
                                                                onChange={userInput('email', i)} />
                                                            <Gput xs={2} value={e.username} label="NUME UTILIZATOR"
                                                                onChange={userInput('username', i)} />
                                                            <Gput xs={2} value={e.password} label="PAROLA" type="password"
                                                                onChange={userInput('password', i)} />
                                                            <Gput xs={2} value={e.role} label="ROL ACCES"
                                                                onChange={userInput('role', i)} />
                                                            <IconButton 
                                                                size="small" color="primary"
                                                                onClick = {aclHandler(e,i)}>
                                                                <FaUserLock/>
                                                            </IconButton>
                                                            &nbsp;
                                                            <IconButton
                                                                size="small"
                                                                disabled={uIndex !== i}
                                                                color={uIndex === i ? "secondary" : "inherit"}
                                                                onClick={submitUser}
                                                            ><FaCheckCircle /></IconButton>
                                                        </Grid>
                                                    </div>
                                                ))}
                                        </div>
                                    </RichCard>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Confirm open={confirm} onClose={confirmCloseHandler} />
            {acl && <AclModal data = {acl.item} onClose={aclModalCloseHandler}/>}
        </div>
    )
}