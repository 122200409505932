import { makeStyles, Grid, Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import RichCard from '../../../components/RichCard'
import {useReactToPrint} from 'react-to-print'
import { Fragment } from 'react'
import moment from 'moment'
import FunkyInput from '../../../components/FunkyInput'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'
import Field from '../Common/Field'
import PF_PJ from '../Common/PF_PJ'
import { getSetting } from '../../../lib/settings'
import inLitere from '../../../lib/inLitere'
import Antet2022 from '../Common/Antet2022'
import SemnaturiPrimarSecretarConsilier from '../Common/SemnaturiPrimarSecretarConsilier'
import { endDate } from '../../../lib/zileLucratoare'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    }
}))

const cod = "pv30"

export default function Pv30(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState(null)
    const [currentItem, setCurrentItem] = useState()

    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const anexaInputHandler = name => ev => {
        let newDosar ={...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    return (
        <Fragment>

            { dosar && dosar.anexe && dosar.anexe.anexa1g30 && Boolean(dosar.anexe.anexa1g30.oferta) === true 
            ? (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
{/* kitchen             */}
                    <Grid item sm={7}  xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard title = " NR.INREGISRARE " >
                                        <Grid container justifyContent= "flex-start" spacing={1}>
                                        <Grid item xs ={2}>
                                            <FunkyInput value ={dosar.anexe[cod].nr} label = "NR" onChange ={anexaInputHandler('nr')}
                                            type="number" step ="1"/>
                                        </Grid>
                                        <Grid item xs = {4}>
                                        <FunkyInput value ={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} label = "NR.INREG"
                                        onChange ={anexaInputHandler('dt')} type = "date"/>
                                        </Grid></Grid>


                                    </RichCard>
                            </Grid>
                        </Grid>
                    </Grid>
{/* print                     */}
                    <Grid item sm={5} xs={12} >

                        <RichCard
                            title="PREVIZUALIZARE"
                            action={
                                <Fragment>
                                   <Button variant="contained" color="primary" size="small" onClick={printHandler}>TIPĂRIRE</Button>
                                </Fragment>

                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe 
                                    ? (
                                        <Grid
                                            container alignItems="flex-start"
                                            style={{ border: "0px solid red" }}
                                            className={classes.text}
                                            ref={printRef} >
                                             <Grid item xs={12} align = "center">
                                        <ANTET_INSTITUTIE data = {dosar.company} />
                                    </Grid>
                                    
                                        <Grid item xs = {12} align="right">
                                            ANEXA Nr. 13 (Anexa nr. 1M la normele metodologice)
                                            <br/>
                                        </Grid>
                                        <Grid item xs={12} align = "right">
                                        <br/>
                                            Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD-MM-YYYY")}
                                        </Grid>
                                        <Grid item xs={12} align = "center" >
                                            <h4>
                                           
                                            PROCES-VERBAL<br/>
                                            de finalizare a procedurii
                                            </h4>
                                        </Grid>

                                        <Grid item sm = {12}>
                                            <Antet2022 data = {dosar}/>
                                            <br/>
                                        </Grid>
                                        <Grid item sm = {12}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            Încheiat astăzi, <Field>{moment(dosar.anexe[cod]['dt']).format("DD.MM.YYYY")}</Field>, {' '}
                                            cu ocazia finalizării procedurii privind vânzarea 
                                            terenului agricol situat în extravilanul 
                                            localităţii <Field>{dosar.company.loc}</Field>, {' '}
                                            judeţul <Field>{dosar.company.jud}</Field>, {' '}
                                            în conformitate cu prevederile art. 10 alin. (5) din Legea nr. 17/2014 privind unele 
                                            măsuri de reglementare a vânzării terenurilor agricole situate în extravilan şi de modificare 
                                            a Legii nr. 268/2001 privind privatizarea societăţilor ce deţin în administrare terenuri 
                                            proprietate publică şi privată a statului cu destinaţie agricolă şi înfiinţarea Agenţiei 
                                            Domeniilor Statului, cu modificările şi completările ulterioare, şi cu dispoziţiile 
                                            art. 3 lit. s) din Normele metodologice privind exercitarea de către Ministerul Agriculturii 
                                            şi Dezvoltării Rurale a atribuţiilor ce îi revin pentru aplicarea titlului I din Legea nr. 
                                            17/2014 privind unele măsuri de reglementare a vânzării terenurilor agricole situate în 
                                            extravilan şi de modificare a Legii nr. 268/2001 privind privatizarea societăţilor ce deţin 
                                            în administrare terenuri proprietate publică şi privată a statului cu destinaţie agricolă şi 
                                            înfiinţarea Agenţiei Domeniilor Statului, aprobate prin Ordinul viceprim-ministrului, 
                                            ministrul agriculturii şi dezvoltării rurale, al viceprim-ministrului, ministrul dezvoltării 
                                            regionale şi administraţiei publice, al ministrului apărării naţionale şi al viceprim-ministrului, 
                                            ministrul culturii, nr. 719/740/M.57/2.333/2014, cu modificările şi completările ulterioare.
                                            <br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            Ca urmare a faptului că în termenul legal, respectiv 45 de zile lucrătoare/30 de zile de la data înregistrării 
                                            ofertei de vânzare nr. <Field>{dosar.anexe["anexa1b"]["nr"]}</Field>{' '}
                                            din data de <Field>{moment(dosar.anexe["anexa1b"]["dt"]).format("DD.MM.YYYY")}</Field>
                                            /de la data expirării termenului de 45
                                            de zile lucrătoare, 
                                            respectiv data de <Field>{moment(endDate(dosar.dt, 45)).add(30, "days").format('DD.MM.YYYY')}</Field>,
                                             afişată în data de <Field>{moment(dosar.anexe["pv1"]["dt"]).format("DD.MM.YYYY")}</Field>, conform 
                                            Procesului-verbal de afişare a ofertei de vânzare 
                                            nr.<Field>{dosar.anexe["pv1"]["nr"]}</Field> {' '}
                                            din data de <Field>{moment(dosar.anexe["pv1"]["dt"]).format("DD.MM.YYYY")}</Field>,{' '}
                                            <u><strong>au fost înregistrate</strong></u> oferte de cumpărare (Anexa1E) din partea următorilor potenţialilor cumpărători

                                            
                                            <ul style = {{marginTop:0, marginBottom:0}}>
                                                {dosar.anexe.anexa1f.oferte.map((e,i) => (
                                                    <li key={i} > 
                                                        oferta nr. <Field>{e.nr}</Field>{' '}
                                                        din data de <Field>{moment(e.dt).format("DD.MM.YYYY")}</Field>{' '}
                                                        depusă de {' '}
                                                        <strong>
                                                        {e.ofertant[0].titular.name}
                                                        </strong>   
                                                        {e.ofertant[0].mandatar 
                                                            &&  " prin " + e.ofertant[0].mandatar.name 
                                                            + " ("+ e.mdtMandat + ")"
                                                        } la prețul de <Field>{e.pret}</Field> lei
                                                    </li>
                                                ))}
                                                </ul>

                                              {console.log("dosar", dosar)}
                                              
                                              {dosar.anexe.anexa1g30 && (
                                                  <Fragment>
                                                        In data de <Field>{moment(dosar.anexe.anexa1g30.dt).format('DD.MM.YYYY')}</Field>, {' '} 
                                                        vanzatorul a depus la sediul nostru 
                                                        sub nr. {' '}
                                                        <Field>{dosar.anexe.anexa1g30.nr}</Field> {' '}/ {' '}
                                                        <Field>{moment(dosar.anexe.anexa1g30.dt).format('DD.MM.YYYY')}</Field>{' '}
                                                         decizia sa de alegere a preemptorului/potențial cumpărător (Anexa 1G), 
                                                         alegând oferta depusă de către {' '}
                                                         <Field>{dosar.anexe.anexa1g30.oferta.ofertant[0].titular.name}</Field>,{' '}
                                                         
                                                  </Fragment>
                                              )}
                                            
                                            pentru terenul agricol situat în extravilanul 
                                            localităţii <Field>{dosar.company.loc}</Field>,{' '}
                                            tarlaua nr. <Field>{dosar.anexe.terenTarlaLot}</Field>,{' '}
                                            parcela nr. <Field>{dosar.anexe.terenParcela}</Field>,{' '}
                                            categoria de folosinţă <Field>{dosar.anexe.terenCategorie}</Field>,{' '} 
                                            identificat prin nr. cadastral <Field>{dosar.anexe.terenNrCadastral}</Field>,{' '} 
                                            şi număr carte funciară <Field>{dosar.anexe.terenCf}</Field>,{' '} 
                                            în suprafaţă de <Field>{dosar.anexe.terenHa} </Field> ha, {' '}
                                            reprezentând cota-parte <Field>{dosar.anexe.cotaParte} </Field>,{' '}
                                            judeţul <Field>{dosar.anexe.terenJud} </Field>,{' '}
                                            oferit spre vânzare de către {' '}
                                            {dosar.ofertanti.map( (e,i) => (
                                                <Fragment key={i}>
                                                    <strong>{e.titular.name}</strong>,{' '}
                                                    {dosar.ofertanti.length === 1 && "în calitate de proprietar, "}
                                                    {dosar.ofertanti.length > 1 && "în calitate de coproprietar, "}

                                                </Fragment>
                                            ))}
                                            ,{' '}
                                            la preţul de <Field>{dosar.anexe.terenPret}</Field> lei
                                            prin prezentul proces-verbal s-a constatat parcurgerea tuturor etapelor procedurale privind 
                                            oferta de vânzare nr.{' '}
                                            <Field>{dosar.anexe["anexa1b"]["nr"]}</Field>{' '}
                                            din data de <Field>{moment(dosar.anexe["anexa1b"]["dt"]).format("DD.MM.YYYY")}</Field>,{' '}
                                            pentru terenul agricol situat 
                                            în extravilanul localităţii  <Field>{dosar.anexe.terenLoc} </Field>,{' '}
                                            judeţul  <Field>{dosar.anexe.terenJud} </Field>.{' '}
                                            Prezentul proces-verbal se eliberează, în original, vânzătorului şi se comunică, în copie, 
                                            <del>structurii centrale</del>/structurii teritoriale DADR {dosar.company.jud}
                                        </Grid>

                                        <Grid item sm = {12}>
                                            <br/>
                                            <SemnaturiPrimarSecretarConsilier data = {dosar} />
                                        </Grid>



                                      
                                    </Grid>

                                    ) 
                                    : (
                                        <div>alegeti o anexa</div>
                                    )}
           
                            </div>
                        </RichCard>

                    </Grid>
                </Grid>
            ): <div>nu există oferte depuse, generați vă rog PV30</div>}
        </Fragment>
    )
}
