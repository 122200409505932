import moment from 'moment'
import freeDays from "./freeDays"

let legalDays = freeDays.map( el => moment(el.dt).format("DD.MM.YYYY") )

const isLegalDay = (day) => {
    let ld = legalDays.filter( el => el === day.format("DD.MM.YYYY"))
    return ld.length > 0 ? true : false
}

export function endDate(from, days) {
    
    const initalDay = from ? moment(from) : moment()

    let r = []
    for ( let i = 0; r.length < days+1; i++) {
        let day =  i === 0 ? initalDay.clone() : initalDay.clone().add(i, "day") 
        let weekday = parseInt(day.clone().format("e"))
        if ( ![5, 6].includes(weekday) &&  !isLegalDay(day)) {
            r.push(day.clone())
        }
    }

    return   r[r.length-1].toDate()
}

export function diff(from, to) {
    
    const initalDay = from ? moment(from) : moment()
    const days = moment(to).diff(moment(initalDay), "days")
    let r = []
    for ( let i = 0; r.length < days+1; i++) {
        let day =  i === 0 ? initalDay.clone() : initalDay.clone().add(i, "day") 
        let weekday = day.clone().format("dd")
        if ( !["Su", "Sa"].includes(weekday) &&  !isLegalDay(day)) {
            r.push(day.clone())
        }
    }

    return   r.length-1
}

export function dateAgoFromNow(days) {
    
    const initalDay = moment()

    let r = []
    for ( let i = 0; r.length < days; i++) {
        let day =  i === 0 ? initalDay.clone() : initalDay.clone().subtract(i, "day") 
        let weekday = day.clone().format("dd")
        if ( !["Su", "Sa"].includes(weekday) &&  !isLegalDay(day)) {
            r.push(day.clone())
        }
    }

    // console.log("r",r)
    return   r[r.length-1].toDate()
}


