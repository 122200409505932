import { Button, Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import React, { useRef } from 'react'
import { Fragment } from 'react'
import ANTET_CERERE from '../Dosar/Common/ANTET_CERERE'
import PF_PJ from '../Dosar/Common/PF_PJ'
import Field from '../Dosar/Common/Field'
import inLitere from '../../lib/inLitere'
import moment from 'moment'
import RichCard from '../../components/RichCard'
import { useReactToPrint } from 'react-to-print'
import Antet2022 from '../Dosar/Common/Antet2022'
import { MdPrint } from 'react-icons/md' 


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },

}))

export default function AcceptareTemplate(props) {
    const classes = useStyles()
    const { data } = props
    const printRef = useRef()
    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });
    return (
        <Grid container justifyContent="space-around">
            <Grid item xs={8}>
                {data && data.anexe && (
                    <RichCard
                        title={<Typography variant="body2" color="primary" style = {{paddingLeft:"1em"}}>PREVIZUALIZARE TIPĂRIRE</Typography>}
                        action={
                            <IconButton onClick={printHandler}><MdPrint/> </IconButton>
                        }
                    >

                        <div style={{ height: "90vh", padding: "5px", overflowY: "visible", zoom: 0.8 }} ref={printRef}>
                            <Antet2022 data={data} />
                            <br />
                            <div align="right">
                                Nr.înreg. {data.acc.nr} / {moment(data.acc.dt).format("DD.MM.YYYY")}
                            </div>

                            <p>Stimată doamnă/Stimate domnule primar,</p>
                            <PF_PJ data={[{ ...data.acc }]} acceptare={data.acc} />
                            <p>

                                prin prezenta îmi exprim intenţia fermă de cumpărare şi accept oferta de vânzare pentru terenul agricol extravilan
                                în suprafaţă de  <Field>{data.dosar.anexe.terenHa}</Field> ha, {' '}
                                reprezentând cota-parte <Field>{data.dosar.anexe.cotaParte}</Field>, {' '}
                                identificat cu număr cadastral <Field>{data.dosar.anexe.terenNrCadastral}</Field>,{' '},
                                înscris în cartea funciară nr. <Field>{data.dosar.anexe.terenCf}</Field>,{' '},
                                a localităţii  <Field>{data.dosar.anexe.terenCfLoc}</Field>,{' '},
                                afişată în data de  <Field>{moment(data.dosar.dt).format('DD.MM.YYYY')}</Field>,{' '}
                                la sediul Primăriei <Field>{data.company.loc}</Field>.
                            </p>
                            <p>
                                Preţul oferit pentru cumpărare este de <sup>(*)</sup>{' '}
                                <Field>{data.acc.pret}</Field> <strong>lei.</strong> {'  '}
                                (<Field>{inLitere(data.acc.pret || 0)}</Field>)
                                (Preţul se va scrie în cifre şi litere.)
                            </p>
                            <div>
                                În susţinerea comunicării de acceptare şi a calităţii de preemptor, depun următoarele acte doveditoare:
                                <div style={{ fontFamily: "Arial", width: "100%", overflowWrap: "break-word" }}>{data.acc.documente}</div>
                            </div>
                            <br />
                            <div style={{ textAlign: "justify", textJustify: "inter-word" }}>
                                Îmi exprim acordul cu privire la utilizarea şi prelucrarea datelor cu caracter personal şi la introducerea datelor şi
                                informaţiilor din cerere şi documentele anexate în bazele de date care se organizează în temeiul Legii nr. 17/2014
                                privind unele măsuri de reglementare a vânzării terenurilor agricole situate în extravilan şi de modificare a Legii
                                nr. 268/2001 privind privatizarea societăţilor ce deţin în administrare terenuri proprietate publică şi privată a
                                statului cu destinaţie agricolă şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările şi completările
                                ulterioare, şi al legislaţiei subsecvente, cu respectarea prevederilor Regulamentului (UE) 2016/679 al
                                Parlamentului European şi al Consiliului din 27 aprilie 2016 privind protecţia persoanelor fizice în
                                ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date
                                şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor) şi ale Legii nr.
                                190/2018 privind măsuri de punere în aplicare a Regulamentului (UE) 2016/679 al Parlamentului European
                                şi al Consiliului din 27 aprilie 2016 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea
                                datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare a Directivei
                                95/46/CE (Regulamentul general privind protecţia datelor), cu modificările ulterioare.
                                <br />
                                Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu
                                modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                            </div>
                            <p>
                                Preemptor/Împuternicit,
                            </p>
                            <p>
                                <Field>{data.acc.titular.name}</Field>
                                <br /><small>(numele şi prenumele în clar)</small>
                            </p>
                            {data.mandatar && data.mandatar.name && (
                                <p> prin <Field>{data.mandatar.name}</Field></p>
                            )}

                            <p>..........................................................</p>
                            <p> Data <Field>{moment(data.acc.dt).format('DD.MM.YYYY')}</Field></p>

                            <p>

                                NOTE:<br />
                                - Câmpurile notate cu (*) sunt obligatoriu de completat.<br />
                                - Câmpurile notate cu (**) se completează de către cetăţenii unui stat membru al
                                Uniunii Europene, ai statelor care sunt parte la Acordul privind Spaţiul Economic
                                European (ASEE) sau ai Confederaţiei Elveţiene.<br />
                                - Câmpurile notate cu (***) se completează dacă este cazul.<br />

                            </p>
                        </div>
                    </RichCard>

                )}
            </Grid>
        </Grid>
    )
}
