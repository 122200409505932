import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        maxHeight: "95vh", maxWidth: "90vw"
    }
}))

export default function DosarModal({ data, onClose }) {
    const classes = useStyles()
    const closeHandler = elem => () => onClose(elem)
    const [item, setItem] = useState()

    useEffect(() => setItem(data), [data])

    return (
        <Dialog open={Boolean(item)} onClose={closeHandler(null)} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container alignItems="center" justifyContent="space-between">
                    CONSULTARE DOSAR
                    <IconButton onClick={closeHandler(null)}>
                        <FaTimesCircle size="2em" color="lightgrey" />
                    </IconButton>
                </Grid>


            </DialogTitle>
            <DialogContent>
                {item && <pre>{JSON.stringify(item, null, 4)}</pre>}
            </DialogContent>

        </Dialog>
    )
}
