import React from "react"

import { makeStyles, Toolbar, Typography, 
    // useMediaQuery, 
    // useTheme, 
    Card, CardContent, Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow:1, width: "100%",

    },
    toolbar: {
        background: theme.palette.grey[300],
        display: "flex", alignItems: "center", justifyContent: "space-between",
        paddingRight: 0, paddingLeft: theme.spacing(1)
    },
    content: {
        height: "80vh", padding: theme.spacing(0), 
        overflowY: "hidden"
    }
}))

const Container = ({title, action, children, style}) => {
    const classes = useStyles()
    // const theme = useTheme()
    // const width = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Card className = {classes.root} style = {style}>
            <Toolbar variant = "dense" className = {classes.toolbar}>
                <Grid item xs={10}>
                <Typography variant = "body1" color = "primary" component = "div"><strong>{title}</strong></Typography>
                </Grid>
                <Grid item>{action}</Grid>
            </Toolbar>
            <CardContent className = {classes.content}>
                {children}
            </CardContent>
        </Card>
    )
}
export default Container