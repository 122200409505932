import React from "react"
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { persistCache } from "apollo3-cache-persist"
import { HttpLink } from 'apollo-link-http'
import config from '../config'
import { ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';

const { API_URL } = config



const ApolloClientProvider = ({token, children}) => {

    const link = new HttpLink({uri: API_URL})
    // console.log("client token:", token)
    const setAuthLink= setContext( (request, prevContext) => ({
        headers: {
          ...prevContext.headers,
          'Authorization': "Bearer " + token
        }
    }))

    const cache = new InMemoryCache()
    persistCache({
        cache,
        storage: localStorage,
      });

    const client = new ApolloClient({
        link: setAuthLink.concat(link),
        cache: cache,
        defaultOptions: {
            watchQuery: {
              fetchPolicy: "network-only",
            },
          }
        // fetchPolicy: "cache-first",
        // nextPolicy: "network-only"
    })

    return (
        <ApolloProvider client = {client}>
            {children}
        </ApolloProvider>
    )
}

export default ApolloClientProvider