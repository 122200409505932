import { Grid, makeStyles, Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import { FaCheck } from 'react-icons/fa'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'
import UAT from '../Common/UAT'
import { getSetting } from '../../../lib/settings'
import { MdPrint } from 'react-icons/md'
import Antet2022 from "../Common/Antet2022"
import SemnaturiPrimarSecretarConsilier from '../Common/SemnaturiPrimarSecretarConsilier'

const cod = "pv1";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function Pv1AfisareOfertaPF(props) {
    const codAnexa = "1b"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()

   
    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                        <Grid item sm={2} xs={4}>
                                            <FunkyInput
                                                value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                onChange={anexaInputHandler('nr')} />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <FunkyInput
                                                value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                onChange={anexaInputHandler('dt')}
                                            />
                                        </Grid>

                                    </Grid>
                                </RichCard>
                            </Grid>
                          
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action = {
                                <Button variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                    <MdPrint size = "1.6em"/>
                                </Button>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className={classes.text}
                                    ref={printRef} >
                                     <Grid item xs={12}>
                                        <ANTET_INSTITUTIE data = {dosar.company} textVersion={false}/>
                                    </Grid>
                                    <Grid item xs = {12} align="right">
                                    ANEXA Nr. 9 (Anexa nr. 1I la normele metodologice)
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD.MM.YYYY")}
                                    </Grid>
                                    <Grid item xs={12} align = "center">
                                        <strong>
                                        PROCES-VERBAL<br/>
                                        de afişare a ofertei de vânzare
                                        </strong>
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={12} align = "center">
                                        <Antet2022 data = {dosar}/>
                                    </Grid>
                                    <Grid item xs={12}  className = {classes.text}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <br/>  <br/>
                                    Încheiat astăzi,{' '}
                                    <Field>{moment(dosar.anexe[cod].dt).format('DD.MM.YYYY')}</Field>,{' '}
                                     în temeiul art. 6 alin. (2) din Legea nr. 17/2014 privind unele măsuri de
                                    reglementare a vânzării terenurilor agricole situate în extravilan şi de modificare a Legii nr. 268/
                                    2001 privind privatizarea societăţilor ce deţin în administrare terenuri proprietate publică şi privată
                                     a statului cu destinaţie agricolă şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările şi 
                                     completările ulterioare.
                                     <br/>
                                     &nbsp;&nbsp;&nbsp;
                                     În vederea îndeplinirii formalităţilor prevăzute de lege şi aducerii la cunoştinţa preemptorilor a  
                                     ofertei de vânzare nr. <Field>{dosar.nr}</Field>{' '}
                                      din data de <Field>{moment(dosar.dt).format("DD.MM.YYYY")}</Field>, depusă de 
                                     către {' '}
                                     {dosar.ofertanti.map( (e,i) => (
                                        <Fragment key={i}>
                                            <strong>{e.titular.name}</strong>,{' '}
                                            {dosar.ofertanti.length === 1 && "în calitate de proprietar, "}
                                            {dosar.ofertanti.length > 1 && "în calitate de coproprietar, "}

                                        </Fragment>
                                     ))}
                                     
                                     al terenului agricol situat în extravilanul localităţii <Field>{dosar.company.loc} </Field>, {' '}
                                     judeţul <Field>{dosar.company.jud} </Field>,  s-a constatat afişarea ofertei de vânzare la sediul instituţiei şi pe pagina de internet <Field>{dosar.company.website || "---------------"}</Field>, în data de{' '}
                                      <Field>{moment(dosar.anexe["pv1"]['dt']).format("DD.MM.YYYY")}</Field>
                                     <br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                     Oferta de vânzare nr.{' '}
                                     <Field>{dosar.anexe["anexa1b"]['nr']}</Field>{' '}
                                      din data de <Field>{moment(dosar.anexe["anexa1b"]['dt']).format("DD.MM.YYYY")}</Field> {' '}
                                      se afişează pentru un termen de 45 de zile lucrătoare şi conţine informaţii privind proprietarul terenului agricol, date privind identificarea terenului, precum şi condiţiile de vânzare.
                                      <br/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    În acest sens s-a întocmit proces-verbal de afişare a ofertei de vânzare nr.{' '}
                                    <Field>{dosar.anexe["pv1"]['nr']}</Field>{' '}
                                      din data de{' '}
                                      <Field>{moment(dosar.anexe["pv1"]['dt']).format("DD.MM.YYYY")}</Field>{' '}
                                        a terenului agricol situat în extravilan.
                                     
                                   </Grid>
                                   <Grid item xs = {12} align="center">
                                        <br/><br/>
                                       <SemnaturiPrimarSecretarConsilier data = {dosar}/>
                                       {console.log("dosar:", dosar)}
                                    </Grid>                                  
                                                                    
                                    
                                
                            </Grid>
                             )}
                            </div>
                            </RichCard>
                        
                    </Grid>
                </Grid>
            )}
        </Fragment>

    )
}

