import React from 'react'
import moment from 'moment'
import { makeStyles, Grid, Button } from '@material-ui/core'
import { Fragment } from 'react'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import Field from '../../Common/Field'
import { getSetting } from '../../../../lib/settings'
import PF_PJ from '../../Common/PF_PJ'
import SEMNATURI_VANZATORI from '../../Common/SEMNATURI_VANZATORI'


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    }
}))

export default function TplCerere(props) {
    const classes = useStyles()
    const dosar = props.data
    const cod = props.cod
    return (
        <div>
            {dosar && (
                <Grid
                    container alignItems="flex-start"
                    style={{ border: "0px solid red" }}
                    className={classes.text} >
                    <Grid item xs={12}>
                        <ANTET_INSTITUTIE data={dosar.company} />
                    </Grid>
                    <Grid item xs={12} align="right">
                        <br />
                        Nr.înreg. {dosar.anexe[cod]['cerere'].nr}  / {moment(dosar.anexe[cod]['cerere'].dt).format("DD.MM.YYYY")}
                    </Grid>
                    <Grid item xs={12} align="center">
                        <h4>CERERE DE RETRAGERE</h4>

                        <h4>a  ofertei de vânzare terenuri extravilan nr.{dosar.nr} din {' '}
                            {moment(dosar.dt).format('DD.MM.YYYY')}<br />
                                                         prevăzute de legea 17/2014 modificată
                                                    </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <PF_PJ data = {dosar.ofertanti}/>
                        având în vedere prevederile art. 71 din Legea nr. 17/2014 privind unele măsuri de reglementare a 
                        vânzării terenurilor agricole situate în extravilan și de modificare a Legii nr. 268/2001 privind
                         privatizarea societăților ce dețin în administrare terenuri proprietate publică și privată a 
                         statului cu destinație agricolă și înființarea Agenției Domeniilor Statului, cu modificările 
                         și completările ulterioare, solicit/solicităm prin prezenta cerere retragerea ofertei de vânzare 
                         înregistrată la <Field>U.A.T. {dosar.company.name}</Field>, sub numărul {' '}
                         {dosar.nr}/{moment(dosar.dt).format('DD.MM.YYYY')} {' '}
                         și pe cale de consecință să dispuneți <Field>ANULAREA PROCEDURII</Field> prevăzută de actul normativ invocat.
                                               
                    </Grid>
                    <Grid item xs = {12}>
                        <br/>
                        <br/>
                        <SEMNATURI_VANZATORI data ={dosar.ofertanti}/>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}
