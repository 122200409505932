import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import React, {useState, useEffect } from 'react'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import {useLazyQuery} from '@apollo/client'
import { useMutation, useApolloClient } from "@apollo/client"
import { ARENDA_REGISTRU_MUTATION } from '../../../apollo/mutationArenda'
import { REGISTRU, NEXT_ARENDA_DOSAR_NUMBER } from '../../../apollo/queryArenda'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        // width: "60vw", 
        height: "auto"
    }
}))

export default function NewContract(props) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [mutateRegistru] = useMutation(ARENDA_REGISTRU_MUTATION)
    const client = useApolloClient()


    useEffect(() => {
        setItem(props.data)
    }, [props])



    const closeHandler = item => ev => {
       
        if ( !item ) {props.onClose()}
        else {
            mutateRegistru({variables: {
                data: item
            }}).then( 
                r => {
                    let d  = client.readQuery({query:REGISTRU})
                    console.log("D:", d)
                    client.writeQuery({
                        query:REGISTRU,
                        variables: {
                            data: {
                                chunk: {
                                    skip: 0, take: 20
                                },
                                ordering: [{ key: "nr", value: "asc" }]
                            }
                        },
                        data: {
                            registruArenda: r.data.arendaRegistru
                        }
                    })
                    props.onClose({id:r.data.arendaRegistru.id})
                   
                },
                err => {
                    console.log("err: ", err)
                    props.onClose(err)
                }
            )
            
        }
    }

    const inputHandler = ev => setItem({...item, nr: parseInt(ev.target.value)})

    const dtHandler = ev => setItem({...item, dt: moment(ev.target.value).toDate()})

    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler()} classes = {{paper:classes.dialogPaper}}>
            <DialogTitle>ADAUGĂ CONTRACT NOU</DialogTitle>
            <DialogContent>
                {item && (
                    <Grid container justifyContent = "flex-start">
                        <Grid item xs = {4}>
                        <FunkyInput value = {item.nr} label = "NR" onChange = {inputHandler} />
                        </Grid>
                        <Grid item xs = {8}>
                        <FunkyInput value = {moment(item.dt).format('YYYY-MM-DD')} label = "DIN DATA" type = "date"
                            onChange={dtHandler}
                        />
                        </Grid>
                        <Grid item xs = {12}>
                        {/* {item && <pre>{JSON.stringify(item, null, 3)}</pre>} */}
                        </Grid>
                    </Grid>
                )}
               
            </DialogContent>
            <DialogActions>
                <Button onClick = {closeHandler()} color = "secondary" variant = "contained"> RENUNȚĂ</Button>
                <Button onClick = {closeHandler(item)} color = "primary" variant = "contained"> CONFIRMĂ</Button>
            </DialogActions>
        </Dialog>
    )
}
