import { Grid, Tabs, Tab, Paper, makeStyles, Typography } from '@material-ui/core'
import {TabPanel} from '@material-ui/lab'
import React, {Fragment, useState} from 'react'
import FunkyInput from '../../components/FunkyInput'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1), marginBottom: theme.spacing(2), border:"0px solid grey", background: theme.palette.grey[200]
    }
}))

export default function FormPf(props) {
    const classes = useStyles()
    const {data, fields} = props
    const [tabIndex, setTabIndex] = useState(0)

    const switchTabHandler = (ev, newValue) => setTabIndex(newValue)

    const changeHandler = name => ev => {
        props.onChange({...props.data, [name]:ev.target.value})
    }
    
    return (
        <Fragment>
            
                { data && fields && (
                    <Fragment>
                       <Paper className = {classes.paper}>
                            <Grid container spacing = {1} style = {{padding: "10px"}}>
                                <Grid item xs = {12}>
                                    <Typography variant = "caption" color = "primary" style ={{paddingLeft: "5px", fontWeight: 800}}>
                                        DATE PERSONALE
                                    </Typography>
                                </Grid>
                                {fields.filter(el =>el.tab === "pf")
                                    .map( (e,i) =>(
                                        <Grid item xs = {e.xs} sm ={e.sm} lg = {e.lg} key = {i}>
                                        <FunkyInput 
                                            value = {data[e.key]} 
                                            onChange = {changeHandler(e.key)}
                                            label = {e.label} 
                                            placeholder = {e.label} 
                                            type = {e.type} />
                                    </Grid>
                                    ))
                                }
                            </Grid>
                            </Paper>
                            <Paper className = {classes.paper}>
                            <Grid container spacing = {1}>
                                <Grid item xs = {12}>
                                <Typography variant = "caption" color = "primary" style ={{paddingLeft: "5px", fontWeight: 800}}>
                                    DOMICILIUL
                                </Typography>
                                </Grid>
                                {fields.filter(el =>el.tab === "dom")
                                   .map( (e,i) =>(
                                    <Grid item xs = {e.xs} sm ={e.sm} lg = {e.lg} key = {i}>
                                    <FunkyInput 
                                        value = {data[e.key]} 
                                        onChange = {changeHandler(e.key)}
                                        label = {e.label} 
                                        placeholder = {e.label} 
                                        type = {e.type} />
                                </Grid>
                                ))
                                }
                            </Grid>
                            </Paper>
                            <Paper className = {classes.paper}>
                            <Grid container spacing = {1}>
                                <Grid item xs = {12}>
                                    <Typography variant = "caption" color = "primary" style ={{paddingLeft: "5px", fontWeight: 800}}>
                                        REȘEDINȚA
                                    </Typography>
                                </Grid>                                
                                {fields.filter(el =>el.tab === "rsd")
                                    .map( (e,i) =>(
                                        <Grid item xs = {e.xs} sm ={e.sm} lg = {e.lg} key = {i}>
                                        <FunkyInput 
                                            value = {data[e.key]} 
                                            onChange = {changeHandler(e.key)}
                                            label = {e.label} 
                                            placeholder = {e.label} 
                                            type = {e.type} />
                                    </Grid>
                                    ))
                                }
                            </Grid>
                            </Paper>
                                            

                    </Fragment>
                )}
           


                    
  
          

        </Fragment>
    )
}
