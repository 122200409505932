import { Container, Dialog, DialogContent, DialogActions, IconButton, makeStyles } from '@material-ui/core';
import React, {Fragment, useRef} from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { MdPrint } from 'react-icons/md';
import {useReactToPrint} from "react-to-print"
import styled from 'styled-components';


const useStyles = makeStyles(theme => ({
    dialogPaper: {minHeight: "100vh", minWidth: "60vw"}
}))

export default function PrintModalWrapper({title, children, onClose, ...rest}) {
    const printRef = useRef()
    const classes = useStyles()
    const printHandler = useReactToPrint({content: ()=>printRef.current})

    
  return (
    
      <Dialog open ={Boolean(children)} onClose = {()=>onClose(null)} classes = {{paper:classes.dialogPaper}}>
          
          <DialogContent>
                <div align="right">
                    <IconButton onClick ={()=>onClose(null)}><FaTimesCircle size="2em" color="lightgrey"/></IconButton>
                </div>
              {children && (
                <Container ref = {printRef} {...rest}>
                    <Styles>
                    {children}
                    </Styles>
                </Container> 
              )}

          </DialogContent>
          <DialogActions>
                <IconButton onClick ={(printHandler)}><MdPrint size ="2em"/></IconButton>
          </DialogActions>

      </Dialog>
      
  )
}

export const Styles = styled.div`

@media print {
        @page { 
            size:  A4 portrait;
            margin: 10mm 10mm 10mm 30mm; 
        };
        div.printable{
            div {
                page-break-inside: avoid!important;
                break-inside: avoid!important;
            }
        }
}
`
