import React from 'react'
import { Fragment } from 'react'
import {makeStyles, Grid} from '@material-ui/core'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import moment from 'moment'
import UAT from '../../Common/UAT'
import Field from '../../Common/Field'
import PF_PJ from '../../Common/PF_PJ'
import inLitere from '../../../../lib/inLitere'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
}))

export default function Anexa1EtplPV(props) {
    // console.log("props pv 1e", props)
    const classes = useStyles()
    let data = props.data || {}
    return (
<Fragment>
    { data && (
        <Grid container>
                <Grid item xs = {12}>
                    <ANTET_INSTITUTIE data = {data.company}/>
                </Grid>
                <Grid item xs={12} align = "right">
                    <br/>
                    Nr.înreg. {data.nr} / {moment(data.dt).format("DD-MM-YYYY")}
                </Grid>
                <Grid item xs={12} align = "center">
                    <strong>
                    PROCES-VERBAL<br/>
                    DE AFIȘARE A COMUNICĂRII DE ACCEPTARE<br/>PENTRU OFERTA DE VÂNZARE TEREN AGRICOL EXTRAVILAN<br/>
                    nr. {data.dosar.nr} din {moment(data.dosar.dt).format('DD.MM.YYYY')}
                    </strong>
                    <br/><br/><br/><br/>
                </Grid>
                <Grid item xs={12}  className = {classes.text}>
                    Subscrisa, <UAT data = {data.company} />, 
                    am procedat astăzi, <Field>{moment(data.dt).format('DD.MM.YYYY')}</Field>,
                    la afișarea la avizierul instituției și pe site-ul web oficial al acesteia a 
                    <strong>comunicării de acceptare a ofertei de vânzare</strong>{' '}
                     depusă la sediul nostru și înregistrată 
                     sub nr. <Field>{data.nr} / {moment(data.dt).format('DD.MM.YYYY')} </Field> de către 
                    <PF_PJ 
                        data = {[{titular:data.titular, mandatar: data.mandatar}]} 
                        noPrefix ={true}
                        acceptare ={true}
                    />{' '}
                    cu prețul oferit de {data.pret} lei {data.pret && "("+inLitere(data.pret)+")"  } 
                    
                </Grid>
                <Grid item xs={12}  align = "left"> 
                <br/><br/>
                    <Field>{data.company.loc}, la {moment(data.dt).format('DD.MM.YYYY')}</Field>
                </Grid>      
                <Grid item xs = {12} style ={{paddingLeft: "50%"}}>
                                        <br/>
                                        Întocmit, <br/>
                                        <Field>{ data.user.name}</Field>
                                        <br/><br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/><br/><br/>
                                        L.S. 
                                       
                </Grid>                                 
                
                
                
            
        </Grid>
         )}
         </Fragment>
    
    )
}
