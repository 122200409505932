import { gql } from '@apollo/client'

export const ARENDA_REGISTRU_MUTATION = gql`mutation arendRegistru($data:ArendaRegistruInput) {
    arendaRegistru(data:$data) {
      id nr dt tip 
      from to price penalty desc
      companyId userId 
    }
  }  ` 

  export const ARENDA_TEREN_MUTATION = gql`mutation arendaTeren($data:ArendaTerenInput) {
    arendaTeren(data:$data) {
      id terenTarla terenParcela terenNrCadastral terenCf terenCfLoc terenBlocFizic
       terenHa terenHa terenAct terenCategorie companyId userId arendaRegistruId coordonate
    }
  }  `

  export const ARENDA_ARENDAS_MUTATION = gql`
    mutation arendaArendas($data:ArendaArendasInput) {
      arendaArendas(data:$data) {
        id desc contactId arendaRegistruId
      }
  }`

export const ARENDA_ARENDATOR_MUTATION = gql`
mutation arendaArendator($data:ArendaArendatorInput) {
  arendaArendator(data:$data) {
    id desc contactId arendaRegistruId
  }
}`

export const RM_ARENDATOR = gql`mutation rmArendator($id:Int) { rmArendator(id:$id)}`
export const RM_ARENDAS = gql`mutation rmArendass($id:Int) { rmArendas(id:$id)}`
export const RM_TEREN = gql`mutation rmTeren($id:Int) { rmTeren(id:$id)}`

export const RM_REGISTRU = gql`mutation arendaRmRegistru($id:Int) {
  arendaRmRegistru(id:$id)
}`