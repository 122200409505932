export const fields = [
    {
        "key": "name",
        "label": "name",
        "xs": 12,
        "sm": 12,
        "lg": 12
    },

    {
        "key": "cui",
        "label": "cui",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    {
        "key": "regcom",
        "label": "regcom",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    { xs: true, sm: 6, lg: 6 },
    {
        "key": "loc",
        "label": "loc",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    {
        "key": "str",
        "label": "str",
        "xs": 12,
        "sm": 4,
        "lg": 4
    },
    {
        "key": "nr",
        "label": "nr",
        "xs": 12,
        "sm": 1,
        "lg": 1
    },
    {
        "key": "bl",
        "label": "bl",
        "xs": 12,
        "sm": 1,
        "lg": 1
    },
    {
        "key": "sc",
        "label": "sc",
        "xs": 12,
        "sm": 1,
        "lg": 1
    },
    {
        "key": "et",
        "label": "et",
        "xs": 12,
        "sm": 1,
        "lg": 1
    },
    {
        "key": "ap",
        "label": "ap",
        "xs": 12,
        "sm": 1,
        "lg": 1
    },
    {
        "key": "cod",
        "label": "cod",
        "xs": 12,
        "sm": 2,
        "lg": 2
    },
    {
        "key": "jud",
        "label": "jud",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },

    {
        "key": "tara",
        "label": "tara",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    { xs: 12, sm: 4, lg: 4 },
    {
        "key": "phone",
        "label": "phone",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    {
        "key": "fax",
        "label": "fax",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    {
        "key": "email",
        "label": "email",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    {
        "key": "website",
        "label": "website",
        "xs": 12,
        "sm": 3,
        "lg": 3
    },
    {
        "key": "bank1",
        "label": "bank1",
        "xs": 12,
        "sm": 6,
        "lg": 6
    },
    {
        "key": "iban1",
        "label": "iban1",
        "xs": 12,
        "sm": 6,
        "lg": 6
    },
    {
        "key": "logo",
        "label": "logo url",
        "xs": 12,
        "sm": 12,
        "lg": 12,
        "lines": 5
    },
    {
        "key": "obs",
        "label": "obs",
        "xs": 12,
        "sm": 12,
        "lg": 12
    }
]