import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './components/Store'
import "./index.css"
import {  makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles'
import { blue , green, grey, red} from "@material-ui/core/colors"
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {  MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/ro";

moment.locale("ro");

const newTheme = createTheme({
  palette: {
    info: {
      main: blue[50],
      contrastText: "#000"
    },
    primary: {
      main: blue[900]
    },
    secondary: {
      main: red[900]
    },
    success: {
      main: green[800]
    }
  }


});

ReactDOM.render(
  // <React.StrictMode  thirdParty={false}>
  <Store>
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='ro'>
    <ThemeProvider theme={newTheme}>
    <App />
    </ThemeProvider>
    </MuiPickersUtilsProvider>
    </Store>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
