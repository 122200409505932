import React, { Fragment } from 'react'
import { makeStyles, } from '@material-ui/core'
import moment from 'moment'
import Field from './Field'

const useStyles = makeStyles(tdeme => ({
    text: { fontFamily: "Courier", fontSize: "0.8rem", fontWeight: "400" },
    table: {
        width: "100%",
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.7rem",
            padding: tdeme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        },
    }
}))

export default function ANTET_PRE(props) {
    const classes = useStyles()
    const dosar = props && props.data 
    return (
        <Fragment>
            {dosar && (
                <table className={classes.table} cellSpacing={0} >
                    <tbody>
                        <tr>
                            <td width = "50%">
                                Județul <sup>*</sup><br/>
                                <Field>{dosar.company.jud}</Field>
                            </td>
                            <td rowSpan="2" width = "50%" align ="center">
                                Registrul de evidență <br />
                                Nr. <Field>{dosar.nr} </Field> din <Field>{moment(dosar.dt).format('DD.MM.YYYY')} <sup>* </sup></Field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Unitatea administrativ-teritorială <sup>*</sup><br/>
                                <Field>{dosar.company.name}</Field>
                            </td>
                        </tr>
                    </tbody>

                </table>
            )}

        </Fragment>
    )
}
