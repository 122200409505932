import { Grid, makeStyles, Button, IconButton } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import { FaCheck } from 'react-icons/fa'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'
import UAT from '../Common/UAT'
import { getSetting } from '../../../lib/settings'
import { Alert } from '@material-ui/lab'
import PlicB5 from '../Common/PlicB5'
import ConfirmarePrimire from '../Common/ConfirmarePrimire'
import { MdMail, MdPrint, MdReply } from 'react-icons/md'


const cod = "ads1";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign: "center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function AdresaADS1(props) {
    const codAnexa = "ads1"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()
    const [pcp, setPcp] = useState()
    const [plic, setPlic] = useState()
    const [loaded, setLoaded] = useState(false)
    const [adrese, setAdrese] = useState(false)

    useEffect(() => setLoaded(true), [])

    useEffect(() => {
        let adr = localStorage.getItem("adrese")
        adr = adr === "true" ? true : false
        setAdrese(adr)
    }, [loaded])

    const ADRESA_ADS = {
        name: "AGENȚIA DOMENIILOR STATULUI",
        pjStr: "Știrbei Vodă", pjNr: "43", pjJud: "Sector 1", pjLoc: "București", pjCod: "010103"
    }

    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = { ...dosar }
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
                    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                        <Grid item sm={2} xs={4}>
                                            <FunkyInput
                                                value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                onChange={anexaInputHandler('nr')} />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <FunkyInput
                                                value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                onChange={anexaInputHandler('dt')}
                                            />
                                        </Grid>

                                    </Grid>
                                </RichCard>

                            </Grid>

                        </Grid>
                    </Grid>
                    {/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action={
                                <Fragment>
                                    <Button size="small" variant="contained" color="primary"
                                        onClick={() => setPlic({ company: dosar.company, data: { titular: ADRESA_ADS } })}>
                                        <MdMail size="1.6em" />
                                    </Button>&nbsp;
                                    <Button size="small" variant="contained" color="primary" onClick={() => setPcp({ company: dosar.company, data: { titular: ADRESA_ADS } })}>
                                        <MdReply size="1.6em" />
                                    </Button>&nbsp;
                                    <Button size="small" variant="contained" color="primary" onClick={printHandler}>
                                        <MdPrint size="1.6em" />
                                    </Button>
                                </Fragment>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && (
                                    <Grid
                                        container alignItems="flex-start"
                                        style={{ border: "0px solid red" }}
                                        className="printable"
                                        ref={printRef} >
                                        <Grid item xs={12}>
                                            <ANTET_INSTITUTIE data={dosar.company} />
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                            <br />
                                            Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD-MM-YYYY")}
                                        </Grid>
                                        <Grid item xs={12} align="left">
                                            <br />
                                            Către:
                                            <strong>AGENȚIA DOMENIILOR STATULUI</strong>
                                            <br />
                                            <small>Str. Știrbei Vodă, nr.43, Sector 1, Bucuresti</small>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <br /><br />
                                            <strong>
                                                ADRESĂ DE ÎNAINTARE A DOSARULUI<br />
                                                nr. {dosar.nr} din {moment(dosar.dt).format('DD.MM.YYYY')}
                                            </strong>
                                            <br /><br />
                                        </Grid>
                                        <Grid item xs={12} className={classes.text}>
                                            Subscrisa, <UAT data={dosar.company} />,
                                            alăturat vă înaintăm dosarul cu documentele aferente{' '}
                                            <strong>OFERTEI DE VÂNZARE </strong>
                                                {/* NR.</strong>{' '} */}
                                            {/* <Field>{moment(dosar.anexe.pv2.dt).format('DD.MM.YYYY')}</Field>, */}
                                            depusă și înregitrată la sediul subscrisei
                                            sub nr. <Field>{dosar.nr} / {moment(dosar.dt).format('DD.MM.YYYY')} </Field> de către <br />
                                            <PF_PJ data={dosar.ofertanti} noPrefix={true} />
                                            conform prevederilor legale în vigoare, în vederea demarării procedurii pentru emiterii AVIZULUI FINAL, după cum urmează:
                                            <ul>
                                                <li>Cererea de afișare a ofertei de vânzare teren nr. {' '}
                                                    <Field>{dosar.anexe.anexa1a.nr}</Field> /
                                                    <Field>{moment(dosar.anexe.anexa1a.dt).format('DD.MM.YYYY')}</Field>
                                                </li>
                                                <li>Documentele prevăzute de art. 6 din Anexa la OAP 719 / 2014.</li>
                                                <li>Procesele verbale - în copie întocmite la cerinţele legale în materie.</li>
                                            </ul>
                                        </Grid>
                                        <Grid item xs={12} align="left">

                                            <Field>{dosar.company.loc}, la {moment(dosar.anexe[cod].dt).format('DD.MM.YYYY')}</Field>
                                            <br />
                                        </Grid>

                                        {!adrese && (<Fragment>
                                            <Grid item sm={6} style={{ paddingTop: "10px" }} />
                                            <Grid item sm={6}>
                                                Întocmit, <br />
                                                <Field>{getSetting(dosar.settings, "operator")}</Field>
                                                <br />
                                                .......................................................<br />
                                                <small>(numele şi prenumele/semnătura)</small><br />
                                                L.S.
                                            </Grid>
                                        </Fragment>


                                        )}
                                        {adrese && (
                                            <Grid item sm={12} style={{ paddingTop: "2cm" }}>
                                                <Grid container alignItems="flex-start" justifyContent='space-between' >
                                                    <Grid item sm={6} align="center">
                                                        Primar,<br />
                                                        <Field>{getSetting(dosar.settings, "primar")}</Field>
                                                        <br />
                                                        <strong>{getSetting(dosar.settings, "primar", "name")}</strong>

                                                        .......................................................<br />
                                                        <small>(numele şi prenumele/semnătura)</small><br />
                                                        L.S.
                                                    </Grid>
                                                    <Grid item sm={6} align="center">
                                                        Întocmit, <br />
                                                        <Field>{getSetting(dosar.settings, "operator")}</Field>
                                                        <br />
                                                        .......................................................<br />
                                                        <small>(numele şi prenumele/semnătura)</small><br />
                                                        L.S.
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </div>
                        </RichCard>

                    </Grid>
                </Grid>
            )}
            <ConfirmarePrimire data={pcp} onClose={() => setPcp()} />
            <PlicB5 data={plic} onClose={() => setPlic()} />
        </Fragment>

    )
}

