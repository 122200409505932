import { Grid, makeStyles, Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import { FaCheck } from 'react-icons/fa'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import { MdPrint } from 'react-icons/md'
import inLitere from '../../../lib/inLitere'
import { Alert } from '@material-ui/lab'

const cod = "anexa1b";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function Anexa1BOfertaPJ(props) {
    const codAnexa = "1b"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()

   
    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar.anexe.anexa1b[name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const contentInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar.anexe[name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12} >
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                    <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={2}>
                                        <Grid item sm={2} xs = {4}>
                                            <FunkyInput
                                                value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                onChange={anexaInputHandler('nr')} />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <FunkyInput
                                                value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                onChange={anexaInputHandler('dt')}
                                            />
                                        </Grid>

                                    </Grid>
                                </RichCard>
                            </Grid>
                            <Grid item xs={12}>
{/* conditii de vanzare                                 */}
                                <RichCard title="CONDIȚII OFERTĂ" >
                                    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                        <Grid item xs = {3}>
                                            <FunkyInput 
                                                value = {dosar.anexe.terenHa} label = "SUPRAFAȚA (HA)" 
                                                onChange = {contentInputHandler("terenHa")} />
                                        </Grid>
                                        <Grid item xs = {2}>
                                            <FunkyInput 
                                                value = {dosar.anexe.cotaParte} label = "COTA PARTE" 
                                                onChange = {contentInputHandler("cotaParte")} />
                                        </Grid>
                                        <Grid item xs = {7}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenConditii} label = "CONDIȚII VÂNZARE" 
                                                onChange={contentInputHandler('terenConditii')} />
                                        </Grid>
                                        <Grid item xs = {3}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenPret} label = "PREȚ (LEI)" 
                                                onChange={contentInputHandler('terenPret')} />
                                        </Grid>
                                        <Grid item xs = {5}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenLoc} label = "LOCALIT. TEREN" 
                                                onChange={contentInputHandler('terenLoc')} />
                                        </Grid>
                                        <Grid item xs = {4}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenJud} label = "JUDEȚ TEREN" 
                                                onChange={contentInputHandler('terenJud')} />
                                        </Grid>
                                        <Grid item xs = {3}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenCf} label = "Nr.CF" 
                                                onChange={contentInputHandler('terenCf')} />
                                        </Grid>
                                        <Grid item xs = {3}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenNrCadastral} label = "NR.CADASTRAL" 
                                                onChange={contentInputHandler('terenNrCadastral')} />
                                        </Grid>
                                        <Grid item xs = {3}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenTarlaLot} label = "TARLA/LOT" 
                                                onChange={contentInputHandler('terenTarlaLot')} />
                                        </Grid>
                                        <Grid item xs = {3}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenParcela} label = "PARCELA" 
                                                onChange={contentInputHandler('terenParcela')} />
                                        </Grid>
                                        <Grid item xs = {3}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenCategorie} label = "CATEGORIE" 
                                                onChange={contentInputHandler('terenCategorie')} />
                                        </Grid>
                                        <Grid item xs = {9}>
                                            <FunkyInput  
                                                value = {dosar.anexe.terenObs} label = "OBSERVAȚII" 
                                                onChange={contentInputHandler('terenObs')} />
                                        </Grid>
                                    </Grid>
                                </RichCard>
                                <Alert severity='warning'>
                                    Cota parte se exprimă în fracție și hectare
                                </Alert>
                            </Grid>
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action = {
                                <Button variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                    <MdPrint size = "1.6em"/>
                                </Button>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className={classes.text}
                                    ref={printRef}
                                >
                                    <Grid item xs={12} align="right">
                                        ANEXA Nr. 2 (Anexa nr. 1B la normele metodologice)
                                        <br/>
                                        - Formular pentru persoane juridice şi entităţi fără personalitate juridică care se înregistrează în registrul comerţului
                                    </Grid>
                                    {dosar && dosar.anexe && (
                                        <Grid item xs={12} align = "right">
                                            <br/>
                                            Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD.MM.YYYY")}
                                        </Grid>
                                     )}                                    
                                    <Grid item xs={12} align="center">
                                        <br />
                                        <strong>OFERTĂ DE VÂNZARE TEREN</strong>
                                        <br /><br />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {dosar.ofertanti.length === 0
                                            ? <div style={{ color: "red" }}>NU ATI DEFINIT NICI UN OFERTANT!!!</div>
                                            : <PF_PJ data={dosar.ofertanti} isOferta={true}/>
                                        }
                                    </Grid>
                                    <Grid item xs = {12}>
                                        <small>
                                    <sup>1</sup>) <i>Se completează cu denumirea formei de organizare, conform reglementărilor legale, proprietar al terenului: societate comercială (S.R.L., S.A., S.N.C., S.C.S., S.C.A.)/cooperativă de credit/societate cooperativă/cooperativă agricolă/grup european de interes economic/societate europeană/societate cooperativă europeană sucursală/persoană fizică autorizată/întreprindere familială/întreprindere individuală etc.</i>
                                    </small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        vând teren agricol situat în extravilan,
                                    în suprafaţă de <Field>{dosar.anexe.terenHa}</Field> (ha),{' '}
                                    reprezentând cota parte <Field>{dosar.anexe.cotaParte}</Field>,{' '}
                                    la preţul de (*) <strong>{dosar.anexe.terenPret} lei</strong> (
                                        <Field>{inLitere(dosar.anexe.terenPret||0)}</Field>).<br />
                                    Condiţiile de vânzare^2) sunt următoarele: <Field>{dosar.anexe.terenConditii}</Field>
                                    <br/>
                                        Date privind identificarea terenului
                                        <br /><br />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <table className={classes.table} cellSpacing={0}>
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan="3">
                                                            <p align="center">Specificare</p>
                                                        </td>
                                                        <td colspan="7">
                                                            <p align="center">Informaţii privind amplasamentul terenului</p>
                                                        </td>
                                                        <td rowSpan="3">
                                                            <p  align="center">Categoria de folosinţă<sup>3)</sup> (**)</p>
                                                        </td>
                                                        <td rowSpan="3">
                                                            <p>Observaţii</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Oraşul/<br/>Comuna/<br/>Judeţul (*)</p>
                                                        </td>
                                                        <td colspan="2"  align="center">
                                                            <p>Suprafaţă (ha)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr cadastral (**)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr de carte funciară (**)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr tarla/lot (**)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr parcelă (**)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>Suprafaţa totală (*)</p>
                                                        </td>
                                                        <td>
                                                            <p>Cotă-parte<sup>5)</sup></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p align="center">Se completează de către vânzător.</p>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenLoc}</Field>
                                                            <br /> jud. <br/>
                                                            <Field>{dosar.anexe.terenJud}</Field>

                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenHa}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.cotaParte}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenNrCadastral}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenCf}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenTarlaLot}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenParcela}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenCategorie}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{dosar.anexe.terenObs}</Field>
                                                        </td>                                                                                                                                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p align="center">Verificat primărie<sup>4)</sup></p>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.terenLoc && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.terenHa && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.cotaParte && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.terenNrCadastral && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.terenCf && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.terenTarlaLot && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.terenParcela && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{dosar.anexe.terenCategorie && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                        <sup>3</sup>) Se completează categoria de folosinţă a terenurilor agricole situate în extravilan 
                                        conform art. 2 din Legea fondului funciar nr. 18/1991, republicată, cu modificările şi 
                                        completările ulterioare.
                                        <br/>
                                        <sup>4</sup>) Se completează cu "X" rubricile în care informaţiile pot fi comparate cu datele din 
                                        Registrul agricol, evidenţele fiscale, evidenţele de stare civilă, altele asemenea. 
                                        <br/>
                                        5) Cota-parte din suprafaţă se exprimă în fracţie şi în hectare.
                                        <br/>
                                        (*) Declar că terenul agricol situat în extravilan face obiectul acţiunii pentru pronunţarea unei hotărâri judecătoreşti care să ţină loc de contract de vânzare:
                                        <br/>
                                         {dosar.anexe.litigii 
                                            ? <strong> DA &nbsp; <del> NU </del> </strong>
                                            : <strong><del> DA </del> &nbsp; NU </strong>
                                        }
                                        <br/>
                                        Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                                        <br/><br/>

                                    </Grid>
                                    <Grid item xs={12} align="center">
                                        <SEMNATURI_VANZATORI data = {dosar.ofertanti} />
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={12} align="left">
                                        Data: <Field>{dosar && dosar.anexe && dosar.anexe[cod] 
                                        && moment(dosar.anexe[cod].dt).format('DD.MM.YYYY')}</Field>
                                    </Grid>
                                    <Grid item xs = {12}>
                                        <br/>
                                        <strong>NOTE:</strong><br/>
                                        Câmpurile notate cu (*) sunt obligatoriu de completat.<br/>
                                        Câmpurile notate cu (**) se completează în cazul în care sunt cunoscute informaţiile.
                                    </Grid>
                                
                            </Grid>
                            </div>
                            </RichCard>
                        
                    </Grid>
                </Grid>
            )}
        </Fragment>

    )
}

