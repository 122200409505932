
import { Dialog, DialogTitle, DialogContent, Grid, IconButton, Typography , makeStyles} from '@material-ui/core'
import React, {useState, useEffect, Fragment} from 'react'
import { MdClose } from 'react-icons/md'
import moment from 'moment'
import OfertaTemplate from './OfertaTemplate'
import { FaTimesCircle } from 'react-icons/fa'

const useStyles = makeStyles(theme => ({
    dialogPaper: { minWidth: "70vw", minHeight: "100vh"}
}))

export default function OfertaModal({data, onClose}) {
    const classes = useStyles()
    const [item, setItem] = useState()

    useEffect(() => {
        setItem(data)
    }, [data])
    
    const closeHandler = () =>onClose(true)

    return (
        <Dialog open = {Boolean(item)}  onClose = {closeHandler} classes = {{paper:classes.dialogPaper}}>
            <DialogTitle style = {{padding:"5 10px 0 30px"}}>
                <Grid container justifyContent= "space-between" alignItems = "center">
                    <Typography variant = "caption" color = "primary">
                        <strong>
                        OFERTA DE VÂNZARE TEREN nr. {item  ? item.registru.nr: "---"}
                        {' '} / {' '}
                        {item  ? moment(item.registru.dt).format('DD.MM.YYYY') : "---"}
                        </strong>
                    </Typography>
                    <IconButton size="small" onClick = {closeHandler}>
                        <FaTimesCircle color = "lightgrey" size = "2em"/>
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {item && (
                   <Fragment>
                        <OfertaTemplate data = {item} />
                   </Fragment> 
                )}
           
            </DialogContent>
        </Dialog>
    )
}
