import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom'
import {Context} from '../components/Store'

import storage from '../lib/storage'
import Layout from '../components/Layout'
import Registru from './Registru'
import Contacts from './Contacts'
import Notification from '../components/Notification'
import Settings from './Settings'
import Dosar from './Dosar'
import Rapoarte from './Rapoarte'
import RegistruArendare from './RegistruArendare'
import ArendarePreview from './RegistruArendare'
import ArendaItem from './RegistruArendare/ArendaItem'
import Landing from './Landing'
import Producator from './Producator'
import RegistruAtestate from './RegistruAtestate'
import NoLicence from "../components/NoLicence"
import Dadr from './Dadr'


const Main = props => {
    const History = useHistory()
    const [state, dispatch] = useContext(Context)
    const [ acl, setAcl ] = useState()

    // console.log("main/props:", props)
    useEffect(() => {
        const ls = storage.get()
       
        if (!ls.token) { History.push("/login") } else {
            // dispatch(JSON.parse(JSON.stringify(ls)))
            dispatch(ls)
        }
    }, [])
    useEffect(() => {
        const ls = storage.get()
        if (!ls.token) { History.push("/login") } else {
            dispatch(ls)
            
        }
    }, [state.token])

    useEffect( () => {
        let aclist = {}
        if (state && state.acl) { aclist = (JSON.parse(state.acl))}
        aclist = {...aclist, legea17:true}
        setAcl(aclist)
    }, [state])

    return (
        <Fragment>
        { state && state.role !== "dadr" && (
            <Layout title = "user role">
            {/* {console.log("context in main:", acl)} */}
            {acl && (
                <Fragment>
            <Notification show = {state.notify} onClose = {()=>dispatch({...state, notify: false})}/>
            <Switch>
            <Route path = "/"  exact component = {Landing} />
            <Route path = "/registru-vanzari"  exact component = {acl.legea17 ? Registru: NoLicence} />
            <Route path = "/registru-arendare"  exact component = {acl.arenda ? RegistruArendare: NoLicence} />
            {/* <Route path = "/registru-arendare-preview"  exact component = {ArendarePreview} /> */}
            <Route path = "/registru-arendare/detalii/:id" component = {acl.arenda ? ArendaItem : NoLicence} />

            <Route path = "/agenda" component = {Contacts}/>
            <Route path = "/setari" component = {Settings}/>
            <Route path = "/dosar/:id" component = {acl.legea17 ? Dosar : NoLicence}/>
            <Route path = "/rapoarte" component = {Rapoarte} />
            {/* <Route path = "/producator" component = {Producator} /> */}

            <Route path = "/registru-atestate" component = {acl.atestate ? RegistruAtestate: NoLicence}/>
            </Switch>
                </Fragment>
            )}

            
        </Layout>
        )} 

        {state && state.role === "dadr" && <Dadr/>}

        {!state || (!!state && !state.role) && <h1>ACCES NEAUTORIZAT</h1>}
           
   
   </Fragment>
    )
    
}

export default Main