import React, { Fragment, useState } from 'react'
import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core'
import { MdAddCircle, MdEdit } from 'react-icons/md'
import AUTOCOMPLETE_CONTACT from '../../../Dosar/Common/AUTCOMPLETE_CONTACT'
import { FaTimesCircle } from 'react-icons/fa'
import ContactModal from '../../../Contacts/ContactModal'
import {useMutation} from "@apollo/client"
import {RM_ARENDAS} from '../../../../apollo/mutationArenda'

export default function Arendasi(props) {

    const item = props.data
    const [anchorEl, setAnchorEl] = useState()
    const [curItem, setCurItem] = useState()
    const [curIndex, setCurIndex] = useState()
    const [rmArendas] = useMutation(RM_ARENDAS)

    const arendasHandler = i => ev => {
        let ni = { ...item, arendaArendas: item.arendaArendas.map(el => ({ ...el })) }
        ni.arendaArendas[i].contact = ev
        ni.arendaArendas[i]['contactId'] = ev.id
        props.onChange(ni)
    }
    const contactHandler = (e, i) => () => {
        setCurItem(e); setCurIndex(i)
    }
    const closeContactModalHandler = ev => {
        if (ev) {
            let ni = {
                ...item,
                arendaArendas: item.arendaArendas.map(e => ({ ...e })),
                arendaArendas: item.arendaArendas.map(e => ({ ...e }))
            }
            if (ev) {
                ni.arendaArendas[curIndex] = {
                    ...ni.arendaArendas[curIndex],
                    contact: ev,
                    contactId: ev.id
                }
            }
            props.onChange(ni)
        }

        setCurItem(); setCurIndex()
    }


    const menuOpen = (e, i) => ev => {
        setAnchorEl(ev.currentTarget)
        setCurIndex(i)
    }

    const addContactModal = (type) => ev => {
        if (type === "pf") {
            setCurItem({ name: "", cnp: " " });
        }
        if (type === "pj") {
            setCurItem({ name: "", cui: " " });
        }
        setAnchorEl()
    }

    const closeMenuHandler = () => {
        setAnchorEl()
    }

    const removeHandler = (elem, index) => async () => {
        console.log(elem)
        let ni = {
            ...item,
            arendaArendas: item.arendaArendas.map(e => ({ ...e }))
        }
        if ( elem.id ) {
            let res = await rmArendas({variables: {id: +elem.id}})
            console.log("RES ", res)
            if (res.data && res.data.rmArendas ) {
                ni.arendaArendas.splice(index, 1)
                props.onChange(ni)
            }
        } else {
            ni.arendaArendas.splice(index, 1)
            props.onChange(ni)
        }

    }

    return (
        <Fragment>
            {item && item.arendaArendas && (
                <Grid container spacing={1}>
                    {item.arendaArendas.map((e, i) => (
                        <Fragment key={i}>
                            <Grid item xs={12}>
                                <Grid container spacing = {1}>
                                <IconButton size="small"
                                    onClick={removeHandler(e,i)}>
                                    <FaTimesCircle color="red" />
                                </IconButton>
                                <Grid item xs={10}>
                                    <AUTOCOMPLETE_CONTACT label="ARENDAȘ"
                                        data={e.contact}
                                        onChange={arendasHandler(i)} />
                                </Grid>
                                {e.contactId
                                    ? (
                                        <IconButton size="small" onClick={contactHandler(e.contact, i)}>
                                            <MdEdit color = "green"/>
                                        </IconButton>
                                    )
                                    : (
                                        <IconButton size="small"
                                            onClick={menuOpen(e, i)}
                                        >
                                            <MdAddCircle color = "purple" />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            )}
            <ContactModal data={curItem} onClose={closeContactModalHandler} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenuHandler}>
                <MenuItem onClick={addContactModal("pf")}>PF</MenuItem>
                <MenuItem onClick={addContactModal("pj")}>PJ</MenuItem>
            </Menu>
        </Fragment>
    )
}
