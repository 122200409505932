import { Grid } from '@material-ui/core'
import {TabPanel} from '@material-ui/lab'
import React, {Fragment, useState} from 'react'
import FunkyInput from '../../components/FunkyInput'

export default function FormPj(props) {

    const {data, fields} = props
    const [tabIndex, setTabIndex] = useState(0)

    const switchTabHandler = (ev, newValue) => setTabIndex(newValue)

    const changeHandler = name => ev => {
        props.onChange({...props.data, [name]:ev.target.value})
    }
    
    return (
        <Fragment>
            
                { data && fields && (
                    <Fragment>
 

                       
                            <Grid container spacing = {1} justifyContent= "flex-start" style = {{padding: "10px"}}>
                                <Grid item xs = {6}>
                                    <FunkyInput 
                                            value = {data.name} 
                                            onChange = {changeHandler('name')}
                                            label = 'DENUMIRE PJ'
                                            placeholder = 'DENUMIRE PJ'
                                            type = "text" />
                                </Grid>
                                <Grid item xs = {2}>
                                    <FunkyInput 
                                            value = {data.cui} 
                                            onChange = {changeHandler('cui')}
                                            label = 'CIF/CUI'
                                            type = "text" />
                                </Grid>
                                {fields.filter(el =>el.tab === "pj")
                                    .map( (e,i) =>(
                                        <Fragment key = {i}>
                                        <Grid item xs = {e.xs} sm ={e.sm} lg = {e.lg} key = {i}>
                                            <FunkyInput 
                                                value = {data[e.key]} 
                                                onChange = {changeHandler(e.key)}
                                                label = {e.label} 
                                                placeholder = {e.label} 
                                                type = {e.type} />
                                        </Grid>
                                        {i === 12 && <Grid item xs ={4} />}
                                        </Fragment>
                                    ))
                                }
                            </Grid>
                                              

                    </Fragment>
                )}
           


                    
  
          

        </Fragment>
    )
}
