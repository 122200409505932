import { Button, Grid, makeStyles, Tooltip, MenuItem } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import FunkyInput from '../../components/FunkyInput'



const useStyles = makeStyles(theme => ({
    button: { padding: 0, border: "none", borderBottom: "0.05em dotted grey", height: "27px", borderRadius: 0 },
    id: { fontSize: "0.7rem", fontWeight: "800", textAlign: "left", paddingLeft: theme.spacing(0.5) },
    title: { fontSize: "0.6rem", textAlign: "left", paddingLeft: theme.spacing(1) },
    subtitle: { fontSize: "0.5rem" },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: 'white', color: "blue", padding: theme.spacing(1),
        fontSize: "0.9rem", textAlign: "center", border: "1px solid lightgrey",
        boxShadow: "1px 1px 3px grey"
    },
    customArrow: {
        color: 'navy',
    },
}))

export default function Menu(props) {
    const classes = useStyles()
    const [isSelected, setIsSelected] = useState(0)
    const { width } = props


    const clickHandler = (e, i) => () => {
        setIsSelected(i)
        props.onChange(e)
    }
    return (
        <Fragment>
            {props && props.data && (
                <Fragment>
                    {width ? (
                        <Grid container direction="column">
                            {props.data.map((e, i) => (
                                <Fragment key={i}>
                                    {(e.stage === props.stage || props.stage === 0) && (
                                        <Tooltip
                                            key={i} title={e.desc}
                                            arrow={true}
                                            placement="right"
                                            classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.customArrow
                                            }}

                                        >
                                            <Button

                                                className={classes.button}
                                                variant={i == isSelected ? "contained" : "outlined"}
                                                color={e.color ? "inherit" : "primary"}
                                                style={e.color ? { color: e.color, background: e.color=="red"?"yellow":null } : null}
                                                onClick={clickHandler(e, i)}
                                            >
                                                <Grid container alignItems="center">
                                                    <Grid item xs={2}>
                                                        <div className={classes.id}>{e.id.replace("anexa", " ")}</div>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Grid container alignItems="flex-start" justifyContent="space-around">
                                                            <Grid item xs={12}>
                                                                <div className={classes.title}>
                                                                    {e.title}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Button>
                                        </Tooltip>
                                    )}

                                </Fragment>
                            ))}

                        </Grid>
                    )
                    : (
                        <FunkyInput value = {isSelected} select>
                            {props.data.map((e, i) => (
                                <MenuItem key={i} value = {i}  onClick={clickHandler(e, i)}>
                                    {e.title}
                                </MenuItem>
                            ))}
                        </FunkyInput>
                    )
                }

                </Fragment>
            )}
        </Fragment>
    )
}
