import { Dialog, DialogTitle, DialogContent, Grid, IconButton, makeStyles, Button, DialogActions, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { MdClose, MdPrint } from 'react-icons/md'
import styled, { createGlobalStyle } from 'styled-components'
import { useReactToPrint } from 'react-to-print'
import svg from '../../../assets/confirmare.svg'

const useStyles = makeStyles(theme => ({
    dialog: { height: "90vh", minWidth: "90vw" }

}))



const Styles = styled.div`


@media print {
    @page {
        size:  A4;
        margin: 0;
        margin-top: 20mm;
    }

    .containerlaser {
        width: 100%;
        height:100%;
        position:absolute;
        top:0px;
        bottom:0px;
        margin: auto;
        margin-top: 0px !important;
        // border: 1px solid magenta; 
        // display:flex; align-items:center; justify-content:flex-start;
    }
    .containerjet {
        width: 100%;
        height:100%;
        position:absolute;
        top:0px;
        bottom:0px;
        margin: auto;
        margin-top: 0px !important;
        // border: 1px solid magenta; 
        // display:flex; align-items:flex-start; justify-content:flex-end;
    }
    .confirmare {
        box-shadow: 0;
        border: none;
        // background: lightgrey;
        width: 220mm;
        height: 110mm;
        position:relative;
    }

};

@media screen {
    .container {
        border: 2px dashed red;
        height:100%;width:100%;
        margin: 10px;
    }
    .confirmare {
        position:relative;
        background: white;
        width: 220mm;
        height: 110mm;
        display: block;
        margin: 0 auto;
        margin-bottom: 0cm;
        box-shadow: 0 0 0.2cm rgba(0,0,0,0.5);
    }

};


.title {  background:lightgrey; padding: 5px 5px 5px 10px; font-size:1.2em; font-weight:700; }
.content { padding: 10px; display:block; }
  
.bkgTxt {
    position:absolute; 
    z-index:1;
    margin-top:0mm;
    margin-left:0mm;
    background-image: url(${svg});
    width: 220mm;
    height: 110mm;
    background-repeat: no-repeat;
   
} 
`




const Item = props => (<Styles>
    <Typography variant="caption">{props.title ? props.title + ": " : ""} <strong>{props.text || "- - - - - "}</strong></Typography>
</Styles>)

const ItemP = props => (<Styles>
    <Typography 
        style = {{position:"absolute", top: props.pos[0]+"px", left: props.pos[1]+"px"}}
        variant="caption">{props.title ? props.title + ": " : ""} <strong>{props.text || "- - - - - "}</strong></Typography>
</Styles>)

const ConfirmareText = props => {
    let data = props.data 
    console.log("DATA:", data)
    return (
        <div style = {{position: "relative"}}>
{/* destinatar    */}
            <ItemP pos = {[150,50]} text = {data.data.titular.name}/>
            <ItemP pos = {[185,80]} text = {data.data.titular.domStr||data.data.titular.pjStr}/>
            <ItemP pos = {[185,360]} text = {data.data.titular.domNr||data.data.titular.pjNr}/>
            <ItemP pos = {[210,70]} text = {data.data.titular.domBl||data.data.titular.pjBl}/>
            <ItemP pos = {[210,130]} text = {data.data.titular.domEt||data.data.titular.pjEt}/>
            <ItemP pos = {[210,185]} text = {data.data.titular.domSc||data.data.titular.pjSc}/>
            <ItemP pos = {[210 ,240]} text = {data.data.titular.domAp||data.data.titular.pjAp}/>
            <ItemP pos = {[210 ,340]} text = {data.data.titular.domCod||data.data.titular.pjCod}/>
            <ItemP pos = {[235 ,65]} text = {data.data.titular.domLoc||data.data.titular.pjLoc}/>
            <ItemP pos = {[235 ,340]} text = {data.data.titular.domJud||data.data.titular.pjJud}/>
{/* expeditor    */}
            <ItemP pos = {[150 ,440]} text = {data.company.name}/>
            <ItemP pos = {[185 ,465]} text = {data.company.str}/>
            <ItemP pos = {[185, 785]} text = {data.company.nr}/>
            <ItemP pos = {[210, 740]} text = {data.company.cod}/>
            <ItemP pos = {[233, 480]} text = {data.company.loc}/>
            <ItemP pos = {[233, 740]} text = {data.company.jud}/>
        </div>
    )
}

export default function ConfirmarePrimire(props) {
    const classes = useStyles()
    const closeHandler = () => props.onClose()
    const printRef = useRef()
    const data = props.data

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });


    return (

        <Dialog open={Boolean(data)} onClose={closeHandler} classes={{ paper: classes.dialog }} >
            <DialogTitle>
                <Grid container justifyContent="space-between">
                    <div>TIPĂRIRE CONFIRMARE DE PRIMIRE</div>


                    <IconButton onClick={closeHandler}><MdClose color="grey" /></IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent ref={printRef}>
                {data && (
                    <Styles>
                        <div className={`containerjet`}>
                            {/* <hr style = {{border: "1px dashed black"}} /> */}
                            <div className="confirmare">
                                <div className = 'bkgTxt'/>
                                <ConfirmareText data = {data}/>
                            </div>
                            {/* <hr style = {{border: "1px dashed black"}} />
                            <div className="confirmare">
                                <div className = 'bkgTxt'/>
                                <ConfirmareText data = {data}/>
                            </div>
                            <hr style = {{border: "1px dashed black"}} /> */}

                        </div>

                    </Styles>
                )}

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" size="large" onClick={printHandler}>
                    <MdPrint size="2rem" /> &nbsp; TIPĂREȘTE
                </Button>
            </DialogActions>
        </Dialog>

    )
}
