import React, { useState, useEffect, useContext, Fragment } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import { COMPANY_MUTATION, USER_MUTATION } from '../../apollo/mutation'
import RichCard from '../../components/RichCard'
import { Card, Grid, IconButton, MenuItem } from '@material-ui/core'
import { FaCheckCircle, FaTimesCircle, FaPlusCircle } from 'react-icons/fa'
import { clearTypeName } from '../../lib/graph-stuff'
import FunkyInput from '../../components/FunkyInput'
import { Context } from '../../components/Store'
import { deepClearTypeName } from "../../lib/graph-stuff"

const USERS = gql`query company{ company {id users {id name username email obs role acl companyId}}}`

const UserForm = (props) => {
    let data = props.data
    const [state] = useContext(Context)
    const changeHandler = name => ev => props.onChange({ ...data, [name]: ev.target.value })
    return (
        <div>
            {data && (
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    {['name', 'username', 'email', 'password'].map((e, i) => (
                        <Fragment key={i}>
                            <Grid item xs={12} sm={3} lg={2}>
                                
                                <FunkyInput
                                    disabled = {e === "username" && state.role !== "app"}
                                    value={data[e]} onChange={changeHandler(e)} label={e}
                                    type={e === 'password' ? "password" : "text"}
                                />
                            </Grid>
                        </Fragment>
                    ))}

                    <Grid item xs={12} sm={2} lg={2}>
                        <FunkyInput value={data.role} onChange={changeHandler('role')} label="ROL" select
                            disabled={state.role !== "app"}
                        >
                            <MenuItem value="admin">ADMIN</MenuItem>
                            <MenuItem value="user">USER</MenuItem>
                        </FunkyInput>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

export default function SetariUtilizatori() {
    const [current, setCurrent] = useState()
    const { data, loading, error } = useQuery(USERS)
    const [mutateUser] = useMutation(USER_MUTATION)
    const [state] = useContext(Context)

    const [company, setCompany] = useState()
    useEffect(() => {
        let c = data && data.company
        if (c) {
            c = deepClearTypeName(c)
            if ( state.role === "user") { c.users = c.users.filter( el => el.username === state.username )}
            setCompany(c)
        }
    }, [data])

    const changeHandler = i => ev => {
        let c = c = deepClearTypeName(c)
        c['users'][i] = ev
        setCompany(c)
    }
    const addUserHandler = () => {
        let c = { ...company, users: company.users.map(e => ({ ...e })) }
        c.users = [
            ...c.users,
            { id: 0, name: "", username: "", password: "", role: "user", companyId: company.id, obs: "" }
        ]
        const lastIndex = c.users.length - 1
        // setCurrent({data:c.users[lastIndex], index:lastIndex})
        setCompany(c)
    }
    const removeHandler = (item, index) => () => {
        if (item.id) {
            //mutation userRm
        }
        let c = { ...company, users: [...company.users] }
        c.users.splice(index, 1)
        setCurrent()
        setCompany(c)
    }

    const submitHandler = (item, index) => async () => {
        let d = deepClearTypeName(current.data)
        let res = await mutateUser({ variables: { data: { ...d, companyId: +d.companyId, id: +d.id } } })
        if (res && res.data) {
            let c = { ...company }
            c.users[index] = res.data.user
        }
        setCurrent()


    }

    return (
        <div>
            {data &&
                <RichCard
                    style={{ background: "none", overflow: "auto" }}
                    title="ADMINISTRARE UTILZATORI"
                    action={state.role !== "user" && (
                        <IconButton onClick={addUserHandler} size="small" color="primary">
                            <FaPlusCircle />
                        </IconButton>
                    )}
                >
                    {company && company.users.map((e, i) => {
                       
                        return (
                        <Card
                            key={i}
                            style={{ padding: "1em" }}>
                                <Grid
                                    container justifyContent="space-between" alignItems="center" spacing={1}
                                    style={{ background: current && current.index === i ? "linen" : "White" }}
                                    onMouseOver={() => setCurrent({ data: e, index: i })} >
                                    <Grid item>
                                        <IconButton color="secondary" onClick={removeHandler(e, i)}>
                                            <FaTimesCircle />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <UserForm data={e} onChange={changeHandler(i)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            onClick={submitHandler(e)}
                                            color={current && current.index !== i ? 'default' : 'secondary'}
                                            disabled={current && current.index !== i} >
                                            <FaCheckCircle />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                        </Card>
                    )}
                    )}
                    
                </RichCard>
            }


        </div>
    )
}
