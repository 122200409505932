import { Paper, makeStyles, Grid, Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import Field from '../../Common/Field'

const useStyles = makeStyles(theme => ({
    paper: {
        background:"rgb(235, 245, 251)",
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: "100%",
        "@media print": { display: "none" }
    }
}))



export default function Anexa1EtplAdresaDADR(props) {

    const dosar = props.dosar
    let adresa;

    adresa = props.dosar && props.index && dosar.anexe.acceptari[props.index]
    const classes = useStyles()

    const nrHandler = ev => {
        let nd = { ...dosar }
        nd.anexe.acceptari[props.index].adresaDADR.nr = ev.target.value
        props.onChange(nd)

    }

    const dtHandler = ev => {
        let nd = { ...dosar }
        nd.anexe.acceptari[props.index].adresaDADR.dt = new Date(ev.target.value)
        props.onChange(nd)

    }

    return (
        <Fragment>
            {dosar && (
                <Fragment>
                    <Paper className={classes.paper}>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={5}>
                                Nr. de inregistrare
                        </Grid>
                            <Grid item xs={3}>
                                <FunkyInput
                                    value={dosar.anexe.acceptari[props.index].adresaDADR.nr}
                                    label="NR."
                                    onChange={nrHandler}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FunkyInput
                                    value={moment(dosar.anexe.acceptari[props.index].adresaDADR.dt).format('YYYY-MM-DD')}
                                    type="date" label="DATA"
                                    onChange={dtHandler}
                                />
                            </Grid>


                        </Grid>
                    </Paper>

                    <Grid container>
                        <Grid item xs={12}>
                            {dosar && (
                                <Fragment>
                                    <ANTET_INSTITUTIE data={dosar.company} />
                                    <div align="right">
                                        Nr.înreg. 
                                        {/* {adresa.nr}  */}
                                        {dosar.anexe &&
                                        dosar.anexe.acceptari &&
                                        dosar.anexe.acceptari[props.index] &&
                                        dosar.anexe.acceptari[props.index].adresaDADR &&
                                        dosar.anexe.acceptari[props.index].adresaDADR.nr}
                                        
                                        / 
                                        {/* {moment(adresa.dt).format('DD.MM.YYYY')} */}
                                        {dosar.anexe &&
                                        dosar.anexe.acceptari &&
                                        dosar.anexe.acceptari[props.index] &&
                                        dosar.anexe.acceptari[props.index].adresaDADR &&
                                        moment(dosar.anexe.acceptari[props.index].adresaDADR.dt).format("DD.MM.YYYY")}
                                    </div>
                                    <div align="left">
                                        <br/><br/><br/>
                                        Către: <strong>
                                           Direcția pentru Agricultură și dezvoltare rurală a județului {dosar.company.jud}
                                        </strong>
                                     
                                    </div>
                                    <div align="center">
                                        <br/><br/>
                                        <Typography variant = "h6">
                                            ADRESĂ DE ÎNAINTARE<br/>
                                        </Typography>
                                        <br/><br/>
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        Referitor la oferta  de vânzare teren agricol extravilan înregistrată 
                                        la sediul nostru sub nr. {' '}
                                        <Field>{dosar.nr}</Field> / <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>,{' '}
                                        vă trasmitem alăturat <Field>COMUNICAREA DE ACCEPTARE A OFERTEI DE VÂNZARE</Field>, {' '}
                                        depusă de preemptorul {' '}
                                        <Field>
                                        {dosar.anexe.acceptari[[props.index]].titular.name}
                                        </Field>, {' '} 
                                      
                                        la data de {' '}
                                        <Field>
                                            {moment(dosar.anexe.acceptari[props.index].dt).format('DD.MM.YYYY')}
                                        </Field>, {' '}
                                        cu prețul oferit de  <Field> {dosar.anexe.acceptari[props.index].pret} lei</Field>, {' '}
                                        precum și documentele depuse de sus-numitul preemptor conform reglementărilor legale în vigoare.
                                    </div>
                                    <Grid container>
                                        <Grid item xs = {6}></Grid>
                                        <Grid item xs = {6} align="center">
                                            <br/><br/>
                                            <br/>
                                        Întocmit, <br/>
                                        <Field>{ dosar.user.name}</Field>
                                        <br/><br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/><br/><br/>
                                        L.S. 
                    </Grid>   

                                    </Grid>
                                </Fragment>)}
                        </Grid>
                    </Grid>

                </Fragment>
            )}
        </Fragment>
    )
}
