import React from 'react'
import { Fragment } from 'react'

const Field = props => {
    return (<strong>{props.children || ' - - - - -'}</strong>)
}

export default function UAT(props) {
    const item = props.data || {}
    return (
        <Fragment>
              <Field>{item.name}</Field>, {' '}
                    CIF <Field>{item.cui}</Field>,{' '}
                     având  sediul în județul <Field>{item.jud}</Field>,  {' '}
                     localitatea <Field>{item.loc}</Field>,  {' '}
                    str. <Field>{item.str}</Field>,  nr. <Field>{item.nr}</Field>, {' '}
                    telefon <Field>{item.phone}</Field>,  fax <Field>{item.fax}</Field>, {' '}
                    e-mail <Field>{item.email}</Field>
        </Fragment>
    )
}
