import { makeStyles, Grid, Button, IconButton } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import RichCard from '../../../../components/RichCard'
import Confirm from '../../../../components/Confirm'
import {useReactToPrint} from 'react-to-print'
import { Fragment } from 'react'
import {FaChevronCircleRight, FaEdit, FaPlusCircle, FaTimesCircle} from 'react-icons/fa'
import moment from 'moment'
import OfertaModal from './OfertaModal'
import Anexa1FPrintTpl from './Anexa1FPrintTpl'
import PvAfisare1FPrintTpl from './PvAfisare1FPrintTpl'
import ComVanzatori1FPrintTpl from './ComVanzatori1FPrintTpl'
import AdresaDADRPrintTpl from './AdresaDADRPrintTpl'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        },
        // "&& tr:hover": {background: theme.palette.grey[200]}
    }
}))

const cod = "anexa1f"

export default function Anexa1Fnonpreemptori(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState(null)
    const [currentItem, setCurrentItem] = useState()
    const [currentPrintTemplate, setCurrentPrintTemplate] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [modal, setModal] = useState()
    const [confirm, setConfirm] = useState()

    useEffect(() => {
        if (props && props.data) { setDosar({ 
            ...props.data, 
            anexe: {
                ...props.data.anexe,
                [cod]: {
                    ...props.data.anexe[cod],
                    oferte: props.data.anexe[cod].oferte ?  props.data.anexe[cod].oferte : [],
                    pv: props.data.anexe[cod].pv ?  props.data.anexe[cod].pv : {nr:1, dt: new Date()},
                    dadr: props.data.anexe[cod].dadr ?  props.data.anexe[cod].dadr : {nr:1, dt: new Date()},
                    comVanzatori: props.data.anexe[cod].comVanzatori 
                        ?  props.data.anexe[cod].comVanzatori 
                        : []

                }
            }    
        }) }
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const openOfertaModalHandler = (e={},i) => ev => {
        setCurrentIndex(i)
        const item = {
            nr: 1,
            dt: new Date(),
            ofertant: [
                {
                    titlarCalitate: "",
                    mdtCalitate: "",
                    mdtMandat: "",
                    mdtPrin: "",
                    mdtConform: "",
                    titular: {name: ""}, 
                    madatar: {},
                    acte: "",
                    nrCom: 1, 
                    dtCom: new Date()
                }],
            pret: "123",
            pv: e.pv || {nr: dosar.nr, dt: new Date()},
            dadr: e.dadr || {nr: dosar.nr, dt: new Date()},
            comVanzatori: e.comVanzatori || dosar.ofertanti.map( e => ({id: e.id, titular: e.titular, nr: 1, dt: new Date() })),
            ...e
        }
        setModal({item:item, index: i})
    }

    const closeOfertaModalHandler = ev => {
        if (ev) {
            let nd = {...dosar}
           
            if (modal.index || modal.index === 0 ) {
                nd.anexe[cod]['oferte'][modal.index]=ev
            } else {
                nd.anexe[cod]['oferte'].push(ev)
            }
            props.onChange(nd)
        }

        setModal()
    }

    const closeRemoveConfirmHandler = ev => {
        if (ev) {
            let nd = {...dosar}
            nd.anexe[cod].oferte.splice(confirm.index,1)
            setConfirm()
            props.onChange(nd)
        }
        else { setConfirm() }
    }

    const templateHandler = (e,i,tpl) => () => {
        setCurrentItem(e)
        setCurrentIndex(i)
        setCurrentPrintTemplate(tpl)
    }

    const changeHandler = ev => {
        console.log("change handler: ", ev)
        
        let nd = {...dosar}
        
        nd.anexe[cod]['oferte'][currentIndex] = ev
        props.onChange(nd)
        setCurrentItem(ev)
    }

    return (
        <Fragment>
            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
{/* kitchen */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}  >

                            <Grid item xs={12}>
                                <RichCard 
                                    title = "OFERTE"
                                    action = {<IconButton 
                                        size="small"
                                        color="primary"
                                        onClick = {openOfertaModalHandler()}
                                    >
                                        <FaPlusCircle/>
                                    </IconButton> }
                                >
                                    <table className = {classes.table} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th>Nr.</th>
                                                <th>Data</th>
                                                <th>Titular</th>
                                                <th>Mandatar</th>
                                                <th>Preț propus</th>
                                                <th>Acțiuni</th>
                                                <th>Tipărire</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                {  dosar.anexe[cod]['oferte'] && dosar.anexe[cod]['oferte'].map( (e,i) => (
                                    <tr key={i} style = {i === currentIndex ? {background:"rgb(232, 244, 253)"}: null}>
                                        <td>{e.nr}</td>
                                        <td align="center">{moment(e.dt).format('DD.MM.YYYY')}</td>
                                        <td  align="center">
                                            {e.ofertant[0] 
                                                ? (e.ofertant[0]['titular'] ? e.ofertant[0]['titular']['name']: "---")
                                                : "---" }
                                        </td>
                                        <td   align="center">
                                            {e.ofertant[0] 
                                                ? (e.ofertant[0]['mandatar'] ? e.ofertant[0]['mandatar']['name']: "---")
                                                : "---" }
                                        </td>
                                        <td align="center">{e.pret}</td>
                                        <td align="center">
                                            <Grid container justifyContent="space-around" alignItems="center">
                                                <IconButton size="small" onClick = {()=>setConfirm({index:i})} >
                                                    <FaTimesCircle color ="red" size="0.8rem" />
                                                </IconButton>
                                                <IconButton size="small" onClick = {openOfertaModalHandler(e,i)} >
                                                    <FaEdit color ="green" size="0.8rem" />
                                                </IconButton>
                                                </Grid>
                                            </td>
                                            <td>
                                                <Grid item xs = {12}>
                                                <a href="#"  onClick = {templateHandler(e,i,"anexa")} style = {{fontSize:"1.3em"}}>
                                                    Anexa 1F
                                                </a>
                                                <br/>
                                               
                                                <a href="#"  onClick = {templateHandler(e,i,"pv")} style = {{fontSize:"1.3em"}}>
                                                    Proces-verbal afișare
                                                </a>
                                                <br/>
                                                <a href="#"  onClick = {templateHandler(e,i,"comunicare")} style = {{fontSize:"1.3em"}}>
                                                   Comunicare vânzător(i)
                                               </a>
                                               <br/>
                                               <a href="#"  onClick = {templateHandler(e,i,"dadr")} style = {{fontSize:"1.3em"}}>
                                                   Adresă înaintare D.A.D.R.
                                               </a> 



                                                </Grid>
                                           
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                </table>
                                </RichCard>
                            </Grid>                                
{/* 
                            <Grid item xs={12}>
                                <pre>{JSON.stringify(dosar.anexe[cod], null,4)}</pre>
                            </Grid> */}
                        </Grid>
                    </Grid>
{/* print */}
                    <Grid item sm={5}  xs={12}>
                        <RichCard
                            title="PREVIZUALIZARE"
                            action={
                                <Fragment>
                                    {currentItem && (
                                        <Fragment>
                                            <Button variant="contained" color="primary" size="small" onClick={printHandler}>TIPĂRIRE</Button>
                                        </Fragment>
                                    )}
                                </Fragment>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && currentItem
                                    ? (
                                        <Grid
                                            container alignItems="flex-start"
                                            style={{ border: "0px solid red" }}
                                            className={classes.text}
                                            ref={printRef} >
                                            {
                                                currentPrintTemplate  
                                                && currentPrintTemplate === "anexa"
                                                && <Anexa1FPrintTpl data = {{item:currentItem, dosar:dosar}}/>
                                            }
                                            {
                                                currentPrintTemplate  
                                                && currentPrintTemplate === "pv"
                                                && <PvAfisare1FPrintTpl 
                                                    data = {{ item:currentItem, dosar:dosar }}
                                                    onChange = {changeHandler}
                                                />
                                            }
                                            {
                                                currentPrintTemplate  
                                                && currentPrintTemplate === "comunicare"
                                                && <ComVanzatori1FPrintTpl data = {{item:currentItem, dosar:dosar}} onChange = {changeHandler}/>
                                            }                                            
                                            {
                                                currentPrintTemplate  
                                                && currentPrintTemplate === "dadr"
                                                && <AdresaDADRPrintTpl data = {{ item:currentItem, dosar:dosar }} onChange = {changeHandler}/>
                                            }

                                        </Grid>
                                    ) 
                                    : (
                                        <div>alegeti o anexa</div>
                                    )}
           
                            </div>
                        </RichCard>

                    </Grid>
                </Grid>
            )}
            {modal && <OfertaModal data = {modal.item} onClose = {closeOfertaModalHandler} />}
            {confirm && <Confirm open = {confirm} onClose = {closeRemoveConfirmHandler}/>}
        </Fragment>
    )
}
