import React, {Fragment, useRef} from 'react'
import {Grid, Button,IconButton,makeStyles, Typography} from '@material-ui/core'
import RichCard from '../../components/RichCard'
import { useReactToPrint } from 'react-to-print'
import PF_PJ from '../Dosar/Common/PF_PJ'
import SEMNATURI_VANZATORI from '../Dosar/Common/SEMNATURI_VANZATORI'
import Field from '../Dosar/Common/Field'
import {FaCheck} from 'react-icons/fa'
import moment from 'moment'
import {MdPrint} from "react-icons/md"

import inLitere from '../../lib/inLitere'
const cod = "anexa1b";
const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function OfertaTemplate(props) {
    const printRef = useRef()
    const classes = useStyles()
    const item =  props.data || null

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });
    return (
        <Fragment>
            {item && item.registru && item.registru.anexe && (
                <Fragment>

                <RichCard
                    title={<Typography variant="body2" color="primary" style = {{paddingLeft:"1em"}}>PREVIZUALIZARE TIPĂRIRE</Typography>}
                    action={
                        <IconButton onClick={printHandler}><MdPrint/> </IconButton>
                    }
                >
                    { item && item.registru && item.registru.anexe && (
                   <div style={{ height: "90vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className={classes.text}
                                    ref={printRef}
                                >
                                    <Grid item xs={12} align="right">
                                        ANEXA Nr. 2 (Anexa nr. 1B la normele metodologice)
                                        <br />
                                       
                                    </Grid>
                                    {item.registru && item.registru.anexe && (
                                        <Grid item xs={12} align="right">
                                            <br />
                                            Nr.înreg. {item.registru.anexe[cod].nr} / {moment(item.registru.anexe[cod].dt).format("DD.MM.YYYY")}
                                        </Grid>
                                    )}
                                    <Grid item xs={12} align="center">
                                        <br />
                                        <strong>OFERTĂ DE VÂNZARE TEREN</strong>
                                        <br /><br />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {item.registru.ofertanti.length === 0
                                            ? <div style={{ color: "red" }}>NU ATI DEFINIT NICI UN OFERTANT!!!</div>
                                            : <PF_PJ data={item.registru.ofertanti} isOferta={true} />
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        vând teren agricol situat în extravilan,
                                        în suprafaţă de <strong>{item.registru.anexe.terenHa}</strong> (ha),{' '}
                                        reprezentând cota parte <strong>{item.registru.anexe.cotaParte || "----"}</strong>{' '}
                                        la preţul de (*) <strong>{item.registru.anexe.terenPret} lei</strong> (
                                        <Field>{inLitere(item.registru.anexe.terenPret || 0)}</Field>).<br />
                                        Condiţiile de vânzare^2) sunt următoarele: <Field>{item.registru.anexe.terenConditii}</Field>
                                        <br />
                                        Date privind identificarea terenului
                                        <br /><br />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div className="avoid">
                                            <table className={classes.table} cellSpacing={0}>
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan="3">
                                                            <p align="center">Specificare</p>
                                                        </td>
                                                        <td colSpan="7">
                                                            <p align="center">Informaţii privind amplasamentul terenului</p>
                                                        </td>
                                                        <td rowSpan="3">
                                                            <p  align="center">Categoria de folosinţă<sup>3)</sup> (**)</p>
                                                        </td>
                                                        <td rowSpan="3">
                                                            <p>Observaţii</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Oraşul/<br/>Comuna/<br/>Judeţul (*)</p>
                                                        </td>
                                                        <td colSpan="2"  align="center">
                                                            <p>Suprafaţă (ha)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr cadastral (**)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr de carte funciară (**)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr tarla/lot (**)</p>
                                                        </td>
                                                        <td rowSpan="2"  align="center">
                                                            <p>Număr parcelă (**)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>Suprafaţa totală (*)</p>
                                                        </td>
                                                        <td>
                                                            <p>Cotă-parte<sup>5)</sup></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p align="center">Se completează de către vânzător.</p>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenLoc}</Field>
                                                            <br /> jud. <br/>
                                                            <Field>{item.registru.anexe.terenJud}</Field>

                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenHa}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.cotaParte}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenNrCadastral}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenCf}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenTarlaLot}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenParcela}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenCategorie}</Field>
                                                        </td>
                                                        <td align="center">
                                                            <Field>{item.registru.anexe.terenObs}</Field>
                                                        </td>                                                                                                                                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p align="center">Verificat primărie<sup>4)</sup></p>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.terenLoc && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.terenHa && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.cotaParte && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.terenNrCadastral && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.terenCf && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.terenTarlaLot && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.terenParcela && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div style={{fontSize:"1.5em"}}>{item.registru.anexe.terenCategorie && "X"}</div>
                                                        </td>
                                                        <td align="center">
                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <br />
                                        <sup>3</sup>) Se completează categoria de folosinţă a terenurilor agricole situate în extravilan
                                        conform art. 2 din Legea fondului funciar nr. 18/1991, republicată, cu modificările şi
                                        completările ulterioare.
                                        <br />
                                        <sup>4</sup>) Se completează cu "X" rubricile în care informaţiile pot fi comparate cu datele din
                                        Registrul agricol, evidenţele fiscale, evidenţele de stare civilă, altele asemenea.
                                        <br />
                                        5) Cota-parte din suprafaţă se exprimă în fracţie şi în hectare.
                                        <br />
                                        (*) Declar că terenul agricol situat în extravilan face obiectul acţiunii pentru pronunţarea unei hotărâri judecătoreşti care să ţină loc de contract de vânzare:
                                        <br />
                                        {item.registru.anexe.litigii 
                                            ? <strong> DA &nbsp; <del> NU </del> </strong>
                                            : <strong><del> DA </del> &nbsp; NU </strong>
                                        }
                                       
                                        <br />
                                        Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                                        <br /><br />

                                    </Grid>

                                    <Grid item xs={12} align="center">
                                        <SEMNATURI_VANZATORI data={item.registru.ofertanti} />
                                        <br /><br />
                                    </Grid>
                                    <Grid item xs={12} align="left">
                                        Data: <Field>{item.registru && item.registru.anexe && item.registru.anexe[cod]
                                            && moment(item.registru.anexe[cod].dt).format('DD.MM.YYYY')}</Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br /><br /><br />
                                        <strong>NOTE:</strong><br />
                                        Câmpurile notate cu (*) sunt obligatoriu de completat.<br />
                                        Câmpurile notate cu (**) se completează în cazul în care sunt cunoscute informaţiile.
                                    </Grid>

                                </Grid>
               </div>
                    )}

 
                    </RichCard>
                    {/* <pre>{JSON.stringify(item, null,1)}</pre> */}
                
            </Fragment>
            )}

        </Fragment>

    )
}
