const legalDays = [
    {dt:"2021-01-01" , desc: "Anul Nou"},
    {dt:"2021-01-02" , desc: "Anul Nou"},
    {dt:"2021-04-30" , desc: "Vinerea Mare"},
    {dt:"2021-05-01" , desc: "Ziua Muncii"},
    {dt:"2021-05-02" , desc: "Paștele Ortodox"},
    {dt:"2021-05-03" , desc: "Paștele Ortodox"},
    {dt:"2021-06-20" , desc: "Duminica Rusaliilor"},
    {dt:"2021-06-21" , desc: "A doua zi de Rusalii"},
    {dt:"2021-08-15" , desc: "Adormirea Maicii Domnului"},
    {dt:"2021-11-30" , desc: "Sf.Andrei"},
    {dt:"2021-12-01" , desc: "Ziua Națională a Romnâniei"},
    {dt:"2021-12-25" , desc: "Crăciunul"},
    {dt:"2021-12-26" , desc: "Crăciunul"},

    {dt:"2022-01-01" , desc: "Anul Nou"},
    {dt:"2022-01-02" , desc: "Anul Nou"},
    {dt:"2022-01-02" , desc: "Anul Nou"},
    {dt:"2022-01-24" , desc: "Unirea Principatelor Române"},
    {dt:"2022-04-22" , desc: "Vinerea Mare"},
    {dt:"2022-04-24" , desc: "Paștele Ortodox"},
    {dt:"2022-04-25" , desc: "Paștele Ortodox"},
    {dt:"2022-05-01" , desc: "Ziua Muncii"},
    {dt:"2022-06-01" , desc: "Ziua Copilului"},
    {dt:"2022-06-12" , desc: "Duminica Rusaliilor"},
    {dt:"2022-06-13" , desc: "A doua zi de Rusalii"},
    {dt:"2022-08-15" , desc: "Adormirea Maicii Domnului"},
    {dt:"2022-11-30" , desc: "Sf.Andrei"},
    {dt:"2022-12-01" , desc: "Ziua Națională a Romnâniei"},
    {dt:"2022-12-25" , desc: "Crăciunul"},
    {dt:"2022-12-26" , desc: "Crăciunul"},

    {dt: "2023-01-01", desc: "Anul Nou"},
    {dt: "2023-01-02", desc: "Anul Nou"},
    {dt: "2023-01-24", desc: "Ziua Unirii Principatelor Române"},
    {dt: "2023-04-14", desc: "Vinerea Mare"},
    {dt: "2023-04-16", desc: "Paște ortodox 2023"},
    {dt: "2023-04-17", desc: "Paște ortodox 2023"},
    {dt: "2023-06-01", desc: "Paște ortodox 2023"},
    {dt: "2023-06-04", desc: "(duminică) - Rusalii"},
    {dt: "2023-06-05", desc: "A doua zi de Rusalii"},
    {dt: "2023-08-15", desc: "Adormirea Maicii Domnului"},
    {dt: "2023-11-30", desc: "Sfântul Andrei"},
    {dt: "2023-11-01", desc: "Ziua Națională a României"},
    {dt: "2023-12-25", desc: "Crăciunul"},
    {dt: "2023-12-26", desc: "Crăciunul"},

    {dt: "2024-01-01", desc: "Anul Nou"},
    {dt: "2024-01-02", desc: "Anul Nou"},
    {dt: "2024-01-06", desc: "Boboteaza"},
    {dt: "2024-01-07", desc: "Sf. Ioan Botezătorul"},
    {dt: "2024-01-24", desc: "Ziua Unirii Principatelor Române"},
    {dt: "2024-05-01", desc: "Ziua Muncii"},
    {dt: "2024-05-02", desc: "Ziua Muncii - punte la weekend"},
    {dt: "2024-05-03", desc: "Vinerea Mare"},
    {dt: "2024-05-05", desc: "Paște Ortodox"},
    {dt: "2024-05-04", desc: "Paște Ortodox"},
    {dt: "2024-05-05", desc: "Paște Ortodox"},
    {dt: "2024-05-06", desc: "Paște Ortodox"},
    {dt: "2024-06-01", desc: "Ziua Copilului"},
    {dt: "2024-06-23", desc: "A doua zi de Rusalii"},
    {dt: "2024-06-24", desc: "A doua zi de Rusalii"},
    {dt: "2024-08-15", desc: "Adormirea Maicii Domnului"},
    {dt: "2024-08-16", desc: "Adormirea Maicii Domnului-punte"},
    {dt: "2024-11-30", desc: "Sfântul Andrei"},
    {dt: "2024-12-01", desc: "Ziua Națională a României"},
    {dt: "2024-12-25", desc: "Crăciunul"},
    {dt: "2024-12-26", desc: "Crăciunul"}
]


export default legalDays