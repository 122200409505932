import { Button, Grid, IconButton, makeStyles } from '@material-ui/core'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '../../../../components/Container'
import moment from 'moment'
import RichCard from '../../../../components/RichCard'
import { MdAddCircle } from 'react-icons/md'

import DateContract from './DateContract'
import Arendatori from './Arendatori'
import Arendasi from './Arendasi'
import Terenuri from './Terenuri'
import { Alert } from '@material-ui/lab'
import { 
    ARENDA_TEREN_MUTATION , ARENDA_REGISTRU_MUTATION, ARENDA_ARENDAS_MUTATION, ARENDA_ARENDATOR_MUTATION 
} from '../../../../apollo/mutationArenda'
import {NEXT_ARENDA_DOSAR_NUMBER} from '../../../../apollo/queryArenda'
import {useMutation, useQuery} from '@apollo/client'
import { clearTypeName } from '../../../../lib/graph-stuff'
import ConexeList from './ConexeList'
import { FaChevronCircleLeft } from 'react-icons/fa'
import NewConex from '../../NewItem/NewConex'

const useStyles = makeStyles(theme => ({
    container: { padding: theme.spacing(1) },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`,
        padding: theme.spacing(1),
        boxShadow: 'none'
    },
}))

const  columnTotal = (arr, col) => arr.reduce( (acc,el) => {
            acc = acc +parseFloat(el[col])
        return acc
    }, 0)



export default function Contract(props) {
    const classes = useStyles()
    const history = useHistory()
    const [item, setItem] = useState()
    const [newConex, setNewConex] =useState()
    const [next, setNext] = useState(0)
    const [mutateTeren] = useMutation(ARENDA_TEREN_MUTATION)
    const [mutateRegistru] = useMutation(ARENDA_REGISTRU_MUTATION)
    const [mutateArendas] = useMutation(ARENDA_ARENDAS_MUTATION)
    const [mutateArendator] = useMutation(ARENDA_ARENDATOR_MUTATION )
    const nextNumber = useQuery(NEXT_ARENDA_DOSAR_NUMBER)

    const nextNr = nextNumber.data

    useEffect(() => {
        setItem(props)
    }, [props])

    useEffect(() =>{
        if (nextNr) setNext(nextNr.nextArendaDosarNumber)
    }, [nextNr])

    const childHandler = ev => setItem(ev)


    const addArendatorHandler = () => {
        let ni = { ...item, arendaArendator: item.arendaArendator.map(e => ({ ...e })) }
        ni.arendaArendator.push({ contact: { name: "" } })
        setItem(ni)
    }
    const addArendasHandler = () => {
        let ni = { ...item, arendaArendas: item.arendaArendas.map(e => ({ ...e })) }
        ni.arendaArendas.push({ contactId: null, contact: { name: "" } })
        setItem(ni)
    }

    const addTerenHandler = async () => {
        let ni = { ...item, arendaTeren: item.arendaTeren.map(e => ({ ...e })) }
        let res = await mutateTeren({variables: {data: {companyId:+item.companyId, userId:+item.userId, arendaRegistruId:+item.id}}})
        if ( res.data && res.data.arendaTeren ) {
            ni.arendaTeren.push(res.data.arendaTeren)
        }
        setItem(ni)
    }

    const newConexOpenHandler = () => setNewConex({
        nr: +next,
        dt: new Date(),
        tip: "conex",
        parentId: +item.id
    })

    const newConexCloseHandler = ev => {
        setNewConex()
        if ( ev && ev.id ) { history.push("/registru-arendare/detalii/"+ev.id) }
    }

    const confirmHandler = async() => {
        console.log("obs: ", item.obs)
        const mRegistru = await mutateRegistru({variables: {data: {
            id: item.id,
            tip: item.tip,
            parentId: item.parentId,
            nr: parseInt(item.nr),
            dt: item.dt,
            from: item.from, to: item.to,
            price: item.price, penalty: item.penalty, 
            desc: item.desc, 
            companyId:+item.companyId, userId:+item.userId,
        }}})
        const mTeren = await item.arendaTeren.map( async e => {
            let el = clearTypeName({...e})
            delete(el.arendaVecin)
            el.terenHa = el.terenHa ? parseFloat(el.terenHa): null
            return await mutateTeren({variables:{data: el}})}
        )
        const mAredasi = await item.arendaArendas.map( async e => {
            let el = {...e}
            return await mutateArendas({variables: {data: {
                id:+el.id,  arendaRegistruId:+item.id, contactId:+el.contactId, companyId:+item.companyId
            }}})
        })
        const mAredatori = await item.arendaArendator.map( async e => {
            let el = {...e}
            return await mutateArendator({variables: {data: {
                id:+el.id,  arendaRegistruId:+item.id, contactId:+el.contactId, companyId:+item.companyId
            }}})
        })

        Promise.all([...mTeren, mRegistru, mAredasi, mAredatori]).then(
            r => {console.log("r:", r); props.onChange(true)},
            err => console.log("err:", err)
        )
    }
    

    const terenHaTotal =  (item && item.arendaTeren) ? Math.round(columnTotal(item && item.arendaTeren, "terenHa") * 1000) / 1000 : 0
    return (
        <Fragment>
            {item && (
                <Container
                    title={<div>
                        <IconButton size = "small" onClick = {()=>history.push('/registru-arendare')}>
                            <FaChevronCircleLeft color ="navy"/> &nbsp;&nbsp;&nbsp;
                        </IconButton>
                        CONTRACT NR. {item.nr} / {moment(item.dt).format('DD.MM.YYYY')}
                    </div>}
                    action={<Button
                        onClick = {confirmHandler}
                        size="small" color="primary" variant="contained" style={{ marginRight: "10px" }}>
                        CONFIRMĂ
                        </Button>}
                    >
                    <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.container} spacing={0}
                        style={{ overflow: "auto", maxHeight: "85vh" }}>

                        <Grid item xs={8}>
                            
                            {/* contract - date generale */}
                            <RichCard title="DATE GENERALE CONTRACT">
                                <DateContract data={item} onChange={childHandler} />
                            </RichCard>

                            {/* terenuri */}
                            <RichCard
                                title={<>
                                    {item && item.arendaTeren && (
                                       <>
                                        TERENURI ({terenHaTotal} ha)
                                        </>
                                    )}
                                   
                                </>}
                                action={
                                    <IconButton size="small" color="primary" onClick={addTerenHandler}>
                                        <MdAddCircle />
                                    </IconButton>
                                }
                                footer = {
                                    <Alert severity = "info" style = {{marginTop: "-20px"}}>
                                        <small>Faceți click pe nr.crt pentru detalii și vecinătăți</small>
                                    </Alert>
                                }
                            >
                                <div style={{ overflow: "visible", height: "35vh", padding: 0, margin: 0 }}>
                                    <Terenuri data={item} onChange={childHandler} />
                                </div>
                            </RichCard>
                        </Grid>


                        <Grid item xs={4}>

                            {/* arendas */}
                            <RichCard
                                style={{ height: "auto" }}
                                title="ARENDAȘI"
                                action={
                                    <IconButton size="small" color="primary" onClick={addArendasHandler}>
                                        <MdAddCircle />
                                    </IconButton>
                                }
                            >
                                <Arendasi data={item} onChange={childHandler} />
                            </RichCard>

                            {/* arendator */}
                            <RichCard
                                style={{ height: "auto" }}
                                title="ARENDATORI"
                                action={
                                    <IconButton size="small" color="primary" onClick={addArendatorHandler}>
                                        <MdAddCircle />
                                    </IconButton>
                                }
                            >
                                <Arendatori data={item} onChange={childHandler} />
                            </RichCard>

                            <RichCard 
                                title = "ISTORIC / ACTE CONEXE"
                                action = {
                                    <IconButton size = "small" onClick = {newConexOpenHandler}>
                                        <MdAddCircle color = "navy" />
                                    </IconButton>
                                }
                               
                            >
                                 <ConexeList data = {item} />
                            </RichCard>                            
                            {/* {item && <pre>{JSON.stringify(item, null, 4)}</pre>} */}
                        </Grid>


                       
                    </Grid>
                    <NewConex data = {newConex} onClose = {newConexCloseHandler} />
                </Container>
            )}


        </Fragment>

    )
}
