import React, { Fragment, useEffect, useState } from 'react';
import RichCard from '../../../components/RichCard';
import { Avatar, Button, Chip, Grid, IconButton, InputAdornment } from "@material-ui/core"
import { useMutation } from "@apollo/client"
import { useQuery } from '@apollo/client';
import { PRODUSE } from '../../../apollo/queryAtestate';
import Boxy from '../../../components/Boxy';
import FunkyInput from '../../../components/FunkyInput';
import { deepClearTypeName } from '../../../lib/graph-stuff';
import { FaDog, FaLeaf, FaPlus, FaTimes } from 'react-icons/fa';
import { PRODUS_MUTATION, PRODUS_RM } from '../../../apollo/mutationAtestate';
import ProductModal from './ProductModal';

export default function Produse() {

  const { data, loading, error, refetch } = useQuery(PRODUSE, {fetchPolicy:"network-only"})

  const [items, setItems] = useState()

  
  const [srcString, setSrcString] = useState("")
  const [editModal, setEditModal] = useState(null)

  const [rmProdus, rmProdusData] =  useMutation(PRODUS_RM)
  const [upsertProdus, upsertProdusData] = useMutation(PRODUS_MUTATION)


  useEffect(() => {
    if (data && data.atProduse) { setItems(data.atProduse); }
  }, [data, srcString.length < 1])

  useEffect(() => {
    if ( srcString < 1 ) refetch()
  }, [...srcString])



  const deleteHandler = ev => async () => {
    if ( ev.id ) {
      rmProdus({variables: {id: +ev.id}}).then( 
        r => refetch(), 
        err => alert("NU SE POATE STERGE UN PRODUS CARE APARE DEJA IN CARNETE")
      )
    } else {
      let ni = [...items]
      ni = deepClearTypeName(ni)
      let cIndex = ni.findIndex(el => el.id === ev.id)
      ni.splice(cIndex, 1)
      setItems(ni)

    }
    

  }

  const filterHandler =  ev => {
    const key = ev.target.value || ""
    console.log("jey:", key)
    setSrcString(key)

    setItems(items.filter( el => el.den_produs.toUpperCase().includes(key.toUpperCase())))
    
  }



  const closeModalHandler =  ev => {
    if (ev) {
        upsertProdus({variables: {id: ev.id, den_produs: ev.den_produs, tip_produs: ev.tip_produs}}).then(
        r => refetch(), 
        err => {console.log("upsert product error", err)}
      )
    }
    setEditModal(null)
  }

  const resetHandler = () => {
    setSrcString(""); refetch()
  }

  return (
    <Fragment>
      <RichCard
        title="CONFIGURARE PRODUSE"

      >

        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12} sm={12}>
            <Boxy
              title="PRODUSE VEGETALE"
              style={{ height: "70vh", overflow: "auto" }}
            >

              <Grid container spacing={2} justifyContent='flex-start' >

                <Grid item xs ={6} sm ={10}>
                    <FunkyInput 
                      value = {srcString} onChange = {filterHandler}
                      label ="filtrare" placeholder="filtrare" 
                      InputProps={{
                        endAdornment: <InputAdornment position="start">
                          <IconButton disabled = {srcString.length === 0 } onClick ={resetHandler}>
                          <FaTimes/>
                          </IconButton>
                          
                        </InputAdornment>,
                      }}
                    />
                </Grid>
                <Grid item xs = {6} sm = {2} align="right">
                    <Button variant="outlined" size="small" color="primary"
                        onClick={() => setEditModal({ den_produs: "", tip_produs: true })}>
                            <FaPlus size="2em" color ="green"/>
                              &nbsp;&nbsp;
                            <FaLeaf size ="2em" color ="green" />
                      </Button>
                      <Button variant="outlined" size="small" color="primary"
                        onClick={() => setEditModal({ den_produs: "", tip_produs: false })}>
                            <FaPlus size="2em" color ="brown"/>
                              &nbsp;&nbsp;
                            <FaDog size ="2em" color ="brown" />
                      </Button>
                </Grid>
                {items && items.map((e, i) => (
                  <Grid item key={i}>
                    <Chip
                      style = {{border: e.tip_produs ? "1px solid green" : "1px solid red"}}
                      variant="outlined" color="primary"
                      avatar={<Avatar style={{ background: "white" }}>
                        {!e.tip_produs
                          ? <FaDog size="2em" color="brown" />
                          : <FaLeaf size="2em" color="green" />
                        }
                      </Avatar>}
                      size="medium"
                      label={e.den_produs}
                      deleteIcon={<FaTimes color="red" size="1.5em" />}
                      onDelete={deleteHandler(e)}
                      onClick={() => e.id ? setEditModal(e) : null}
                    />
                    &nbsp;
                  </Grid>
                ))}
              </Grid>

              {/* {editModal && <pre>{JSON.stringify(editModal, null, 2)}</pre>} */}
            </Boxy>
          </Grid>
        </Grid>
       
      </RichCard>
      
      {editModal && <ProductModal data = {editModal} onClose = {closeModalHandler} />}
     
    </Fragment>
  )
}
