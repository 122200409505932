import React from "react"
import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => (
    {
        '@keyframes blinker': {
            from: {opacity: 1, color: "red", fontSize: "1rem", fontWeight: 800},
            to: {opacity: 0, fontWeight: 800, color: "navy", fontSize: "1rem",}
        },
        headerGT: {
                
                animationName: '$blinker',
                animationDuration: '1.2s',
                animationTimingFunction: 'linear',
                animationIterationCount:'infinite',
        },
    })
    )

export default function Blinker(props) {
    const classes = styles()

        return <div className = {classes.headerGT}> 
            {props.children}
        </div>
    
}