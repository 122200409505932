import { Grid, makeStyles, Button, IconButton, Card, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import { FaArrowAltCircleRight, FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'
import UAT from '../Common/UAT'
import { getSetting } from '../../../lib/settings'
import AUTOCOMPLETE_CONTACT from '../Common/AUTCOMPLETE_CONTACT'
import { Alert } from '@material-ui/lab'
import PlicB5 from '../Common/PlicB5'
import ConfirmarePrimire from '../Common/ConfirmarePrimire'
import { MdMail, MdPrint, MdReply } from 'react-icons/md'

const cod = "uat";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function AdresaNotificareUatVecine(props) {

    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const [plic, setPlic] = useState()
    const [confirmare, setConfirmare] = useState()
    const [ currentItem, setCurrentItem ]= useState()
    const printRef = useRef()

   
    useEffect(() => {
        // console.log("cod:", cod)
        if (props && props.data ) { 
            let d = {...props.data}
            // console.log("uat ::", d.anexe[cod])
            d.anexe[cod]['notificariUAT'] = d.anexe[cod]['notificariUAT'] || []
            setDosar(d) 
        }
    }, [props])

    const inputHandler = (name, index) => ev => {
        let d = {...dosar}
        d.anexe[cod]['notificariUAT'][index] = {...d.anexe[cod]['notificariUAT'][index], [name]: ev.target.value}
        props.onChange(d)
    }

    const addHandler = () => {
        let d = {...dosar}
        d.anexe[cod]['notificariUAT'].push({
            nr: d.anexe[cod]['notificariUAT'].length+1,
            dt: new Date()
        })
        props.onChange(d)
    }

    const chooseHandler = i => ev  =>{
        if (ev) {
            let d = {...dosar}
            d.anexe[cod]['notificariUAT'][i] = {
                ...d.anexe[cod]['notificariUAT'][i],
                uatId: ev.id,
                uat: ev
            }
            props.onChange(d)
        }
    }

    const removeHandler = i => () => {
        let d = {...dosar}
        d.anexe[cod]['notificariUAT'].splice(i,1)
        props.onChange(d)
    }


    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>
            {/* {console.log("cur:", currentItem)} */}
            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard 
                                    title="NOTIFICĂRI TRANSMISE" 
                                    action = {
                                        <IconButton size ="small" color="primary" onClick = {addHandler}>
                                            <FaPlusCircle/>
                                        </IconButton>
                                    }    
                                >
                                    <Alert severity = "warning" className = {classes.text}>
                                        <small>
                                        Primăriile vecine vor fi alese din agenda aplicației, așadar, în prealabil, 
                                        o primărie "vecină" trebuie să fie adăugată la "CONTACTE", ca persoană juridică<br/>
                                        Pentru a apărea în lista inițială de selecție aferentă unei notificări de mai jos, contactul 
                                        astfel creat în prealabil trebuie să conțină în nume caracterele "UAT", după care aplicația
                                        va filtra opțiunile prezentate.</small>
                                    </Alert>
                                    <br/>
                                    <Card style = {{padding: "10px 10px 10px 20px"}}>
                                    {   dosar 
                                        && dosar.anexe 
                                        && dosar.anexe[cod]
                                        && dosar.anexe[cod]['notificariUAT']
                                        && dosar.anexe[cod]['notificariUAT'].map( (e,i) => (
                                            <Grid 
                                                container key = {i} alignItems="center" justifyContent="flex-start" spacing={1}
                                                style = {
                                                    currentItem && currentItem.index === i 
                                                ? { background: "lightblue"}
                                                :  null
                                                }  
                                            >
                                                <FaTimesCircle color = "red" onClick = {removeHandler(i)}/>
                                            <Grid item xs={2}>
                                                <FunkyInput
                                                    value={e.nr } type="number" step="1" label="NR.INREG"
                                                    onChange={inputHandler('nr',i)} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FunkyInput
                                                    value={moment(e.dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                    onChange={inputHandler('dt',i)}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <AUTOCOMPLETE_CONTACT 
                                                    data = {e.uat || "ALEGEȚI UAT VECINĂ"}
                                                    src = {e.uat ? null : "UAT" }
                                                    onChange = {chooseHandler(i)} />
                                            </Grid>
                                            <IconButton onClick = {()=>{
                                                     currentItem && currentItem.index === i
                                                        ? setCurrentItem() 
                                                        : setCurrentItem({data:e, index:i})}}>
                                                    <FaArrowAltCircleRight color = "navy" />
                                                </IconButton>   
                                           
    
                                        </Grid>
                                        ))
                                    }
                                    </Card>

                                </RichCard>
                            </Grid>
                          
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action={
                                <Fragment>
                                    <Button size = "small" variant = "contained" color = "primary"
                                        onClick = {()=>setPlic({ company: dosar.company,data: {titular: currentItem.data.uat} })}
                                        disabled = {!currentItem} >
                                        <MdMail size = "1.6em"/>
                                    </Button>&nbsp;              
                                    <Button size = "small" variant = "contained"  color = "primary" 
                                    onClick = {()=>setConfirmare({ company: dosar.company,data: {titular: currentItem.data.uat} })}
                                    disabled = {!currentItem} >
                                        <MdReply size = "1.6em"/>
                                    </Button>&nbsp;
                                    <Button size = "small" variant = "contained" color = "primary" 
                                        onClick={printHandler}
                                        disabled = {!currentItem} >
                                        <MdPrint size = "1.6em" />
                                    </Button>
                                </Fragment>
                            }
                            
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {currentItem && dosar && dosar.anexe ? (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className='printable'
                                    ref={printRef} >
                                     <Grid item xs={12}>
                                        <ANTET_INSTITUTIE data = {dosar.company}/>
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        <br/>
                                        Nr.înreg. {currentItem.data.nr} / {moment(currentItem.data.dt).format("DD-MM-YYYY")}
                                    </Grid>
                                    <Grid item xs={12} align = "left">
                                        Către: <Field>{currentItem.data.uat.name}</Field>
                                        <br/><br/><br/>
                                    </Grid>
                                    <Grid item xs={12} align = "center">
                                        <strong>
                                        NOTIFICARE PRIVIND OFERTA DE VÂNZARE  <br/>
                                        nr. {dosar.nr} din {moment(dosar.dt).format('DD.MM.YYYY')}
                                        </strong>
                                        <br/><br/><br/><br/>
                                    </Grid>
                                    <Grid item xs={12}  className = {classes.text}>
                                        Subscrisa, <UAT data = {dosar.company} />, 
                                        în conformitate cu obligațiile ce ne revin conform Art.6(pct.8) din Legea 17/2014, modificat prin Pct.5(art.1) din Legea 175/2020, vă transmitem alăturat oferta privind vânzarea terenului  agricol extravilan 
                                        nr. <Field>{dosar.nr} / {moment(dosar.dt).format('DD.MM.YYYY')} </Field>
                                       depusă la sediul subscrisei de către <br/>
                                        <PF_PJ data = {dosar.ofertanti} noPrefix ={true}/>
                                       întrucât din evidențele noastre reiese că terenul 
                                       în suprafaţă de {' '}
                                        <Field>{dosar.anexe.terenHa}</Field> ha, {' '}
                                        identificat cu număr cadastral{' '}
                                        <Field>{dosar.anexe.terenNrCadastral}</Field>, {' '}
                                        înscris în cartea funciară nr.{' '}
                                        <Field>{dosar.anexe.terenCf}</Field>, {' '}
                                        a localităţii.{' '}
                                        <Field>{dosar.anexe.terenCfLoc}</Field>, {' '}
                                       se află la vecintătea subscrisei cu  <Field>{currentItem.data.uat.name}</Field>, {' '}
                                       astfel încât Dvs. să dispuneți notificarea titularilor drepturilor de preempţiune, cu respectarea prevederilor alin. (6) din Legea 17/2014.
                                    </Grid>
                                    <Grid item xs={12}  align = "left"> 
                                    <br/><br/>
                                        <Field>{dosar.company.loc}, la {moment(dosar.anexe.pv2.dt).format('DD.MM.YYYY')}</Field>
                                    </Grid>     
                          
                                    
                                    <Grid item xs = {12} style ={{paddingLeft: "50%"}}>
                                        
                                        Întocmit, <br/>
                                        <Field>{ getSetting(dosar.settings, "operator")}</Field>
                                        <br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/>
                                        L.S. 
                                       
                                    </Grid>                                    
                                    
                                
                            </Grid>
                             )
                            : <Typography variant = "h2" style={{color:"LightSteelBlue", textAlign:"center"}}>
                            ALEGETI <br/>O <br/>NOTIFICARE <br/>DIN <br/>LISTĂ
                        </Typography>
                            }
                            </div>
                            </RichCard>
                        
                    </Grid>
                     <PlicB5 data = {plic} onClose = {() => setPlic()}/>
                     <ConfirmarePrimire data = {confirmare} onClose = {() => setConfirmare()}/>

                </Grid>
            )}
        </Fragment>

    )
}

