import { Grid, makeStyles, Button, IconButton } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import { MdAdd, MdClose, MdMail, MdPrint, MdReply } from 'react-icons/md'
import PlicB5 from '../Common/PlicB5'
import ConfirmarePrimire from '../Common/ConfirmarePrimire'

const cod = "anexa2c";

const ADRESA_MAPN = {
    name: "M.Ap.N - UM. 02700 București",
    pjStr: "Bd.Ion Mihalache", pjNr: "124-126", pjJud: "Sector 1", pjLoc: "București", pjCod: "011203" 
}


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function Anexa2cMapn(props) {
    const codAnexa = "ads1"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()
    const [plic, setPlic] = useState()
    const [ar, setAr] = useState()

   
    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const inputAnexeHandler = name => ev => {
        let nd = {...dosar}
        nd['anexe'][name] = ev.target.value
        props.onChange(nd)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const preiaHandler = () => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod]["acte"] = [...dosar.anexe.documenteCerere || []]
        props.onChange(newDosar)
    }

    const addActHandler = () => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod]["acte"] = newDosar['anexe'][cod]["acte"] 
            ? [...newDosar['anexe'][cod]["acte"], "" ]
            : [""]
        props.onChange(newDosar)
    }
    
    const rmActHanler = (e,i) => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod]["acte"].splice(i,1)
        props.onChange(newDosar)
    }

    const acteTextHandler = (e,i) => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod]["acte"][i] = ev.target.value
        props.onChange(newDosar)
    }
    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            { dosar && dosar.anexe && (
                                <Fragment>
                            <Grid item xs={12}>
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                        <Grid item sm={2} xs={4}>
                                            <FunkyInput
                                                value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                onChange={anexaInputHandler('nr')} />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <FunkyInput
                                                value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                onChange={anexaInputHandler('dt')}
                                            />
                                        </Grid>
                                    </Grid>
                               </RichCard>
                            </Grid>
                            <Grid item sm = {12}>
                            <RichCard 
                                title="ACTE DOVEDITOARE" 
                                action = {<>
                                    <Button 
                                    size="small" 
                                    color="primary" 
                                    variant='contained'
                                    onClick = {preiaHandler}
                                ><small>PREIA DIN ANEXA 1A</small>
                                    </Button>
                                    {' '}
                                    <Button 
                                    size="small" 
                                    color="secondary" 
                                    variant='contained'
                                    startIcon={<MdAdd/>}
                                    onClick = {addActHandler}
                                ><small>NOU</small>
                                    </Button>
                                    </>
                                }    
                            >
                                {dosar.anexe 
                                    && dosar.anexe[cod] 
                                    && dosar.anexe[cod]["acte"] 
                                    &&  dosar.anexe[cod]["acte"].map( (e,i) => (
                                        <Grid container alignItems="center">
                                            <Grid item sm={1} align="center">{i+1}.</Grid>
                                            <Grid item sm = {true}>
                                                <FunkyInput value = {e} key={i} onChange = {acteTextHandler(e,i)}/>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" color="secondary" onClick = {rmActHanler(e,i)}>
                                                    <MdClose/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        
                                    )) }
                                </RichCard>
                            </Grid>
                            </Fragment>
                            )}

                               
                           
                          
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action = {

                                <Fragment>
                                    <Button 
                                        variant = "contained" size = "small" color = "primary"
                                        onClick = {()=>setPlic({company: dosar.company, data: {titular: ADRESA_MAPN}})}>
                                            <MdMail size = "1.6rem"/>
                                    </Button>
                                    &nbsp;
                                    <Button 
                                variant = "contained" size = "small" color = "primary"
                                onClick = {()=>setAr({company: dosar.company, data: {titular: ADRESA_MAPN}})}>
                                    <MdReply size = "1.6rem"/>
                                </Button>
                                &nbsp;
                                    <Button 
                                        variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                        <MdPrint size = "1.6em"/>
                                    </Button>
                                </Fragment>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className="printable"
                                    ref={printRef} >
                                     {/* <Grid item xs={12}>
                                        <ANTET_INSTITUTIE data = {dosar.company}/>
                                    </Grid> */}
                                    <Grid item sm = {12} align="right">
                                    Anexa nr. 3  
                                    <br/>         
                                    (Anexa nr. 2C la normele metodologice)
                                    </Grid>
                                    <Grid item sm = {12} align="center">
                                        <br/><br/>
                                    - Formular pentru persoane fizice -
                                    </Grid>
                                    {/* <Grid item xs={12} align = "right">
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD.MM.YYYY")}
                                        <br/><br/>
                                    </Grid> */}
                                    <Grid item xs={12} align = "center">
                                        <br/><br/>
                                        <strong>C E R E R E  </strong>
                                        <br/>
                                        pentru solicitarea avizului specific 
                                        al Ministerului Apărării Naţionale
                                    </Grid>

                                    <Grid item xs = {12} align="left">
                                        <br/>
                                        Către: <br/>
                                        <strong> Ministerul Apărării Naționale</strong><br/>
                                        <strong>Unitatea Militară 02700 București</strong><br/>
                                       <br/>
                                    </Grid>

                                    
                                    <Grid item xs={12}  className = {classes.text}>
                                        
                                        <PF_PJ data = {dosar.ofertanti}/>
                                        Declar că sunt proprietar al terenului
                                        extravilan în suprafaţă de {' '}
                                        <Field>{dosar.anexe.terenHa}</Field> ha, {' '}
                                        identificat cu număr cadastral{' '}
                                        <Field>{dosar.anexe.terenNrCadastral}</Field>, {' '}
                                        înscris în cartea funciară nr.{' '}
                                        <Field>{dosar.anexe.terenCf}</Field>, {' '}
                                        a localităţii.{' '}
                                        <Field>{dosar.anexe.terenCfLoc}</Field>, {' '}
                                        care urmează să facă obiectul vânzării către {' '}
                                        {dosar.anexe.anexa1g30 
                                        &&  dosar.anexe.anexa1g30['oferta']
                                        && dosar.anexe.anexa1g30['oferta']['ofertant']
                                        && dosar.anexe.anexa1g30['oferta']['ofertant'][0]
                                        && dosar.anexe.anexa1g30['oferta']['ofertant'][0].titular
                                        && <Field>{dosar.anexe.anexa1g30['oferta']['ofertant'][0].titular.name}</Field>
                                        }
                                        {dosar.anexe
                                        && dosar.anexe.alegerePreemptor
                                        && dosar.anexe.alegerePreemptor.titular
                                        && <Field>{dosar.anexe.alegerePreemptor.titular.name}</Field>
                                        }
                                        {(
                                            !(
                                                dosar.anexe.anexa1g30 
                                                &&  dosar.anexe.anexa1g30['oferta']
                                                && dosar.anexe.anexa1g30['oferta']['ofertant']
                                                && dosar.anexe.anexa1g30['oferta']['ofertant'][0]
                                                && dosar.anexe.anexa1g30['oferta']['ofertant'][0].titular
                                                && dosar.anexe.anexa1g30['oferta']['ofertant'][0].titular.name
                                            
                                            ) &&
                                             !(dosar.anexe.alegerePreemptor && dosar.anexe.alegerePreemptor.titular)
                                        ) && "- - - - - - - - - " }
                                        
                                        <br/>
                                        {/* {console.log("1g30:", dosar.anexe.anexa1g30['oferta']['ofertant'][0].titular.name)} */}
                                        <small>
                                            <i>
                                                (Se înscrie numele cumpărătorului, dacă acesta este cunoscut la data depunerii cererii.)
                                            </i>
                                        </small>
                                        <br/>
                                        <br/>
                                        Declar că:<br/>
                                        <Grid container justifyContent= "space-between" alignItems = "flex-start">
                                        <Grid item xs={9}>
                                        - terenul face obiectul unor litigii sau a unei proceduri de executare silită: 
                                        </Grid>
                                        <Grid item align = "right">
                                        [
                                                {dosar.anexe.litigii 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] DA &nbsp;&nbsp;
                                            [
                                                {!dosar.anexe.litigii 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] NU
                                            </Grid>
                                        </Grid>

                                        
                                        <Grid container justifyContent= "space-between" alignItems = "flex-start">
                                        <Grid item xs={9}>
                                        - terenul este grevat de sarcini:
                                        </Grid>
                                        <Grid item align = "right">
                                        [
                                                {dosar.anexe.sarcini 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] DA &nbsp;&nbsp;
                                            [
                                                {!dosar.anexe.sarcini 
                                                    ? <strong>{' '}X{' '}</strong> 
                                                    : <span>&nbsp;&nbsp;&nbsp;</span>}
                                            ] NU
                                            </Grid>
                                        </Grid>
                                         <br/>
                                         Având în vedere dispoziţiile art. 3 din Legea nr. 17/2014 privind unele măsuri de reglementare a vânzării terenurilor agricole situate în extravilan şi de modificare a Legii nr. 268/2001 privind privatizarea societăţilor ce deţin în administrare terenuri proprietate publică şi privată a statului cu destinaţie agricolă şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările și completările ulterioare, solicit prin prezenta cerere emiterea avizului specific al Ministerului Apărării Naţionale, în termenul prevăzut de Legea nr. 17/2014, cu modificările și completările ulterioare.

                                        În susţinerea cererii, depun următoarele acte doveditoare*1)<br/>
                                        <ul>
                                        {dosar 
                                        && dosar.anexe 
                                        && dosar.anexe[cod] 
                                        && dosar.anexe[cod]["acte"] 
                                        &&  dosar.anexe[cod]["acte"].map( (e,i)=>(
                                            <li key = {i}>{e}</li>
                                        ))}
                                        </ul>
                                        Îmi exprim acordul cu privire la utilizarea și prelucrarea datelor cu caracter personal şi la introducerea datelor și informațiilor din cerere și documentele anexate în bazele de date care se organizează în temeiul Legii nr. 17/2014, cu modificările şi completările ulterioare, și a legislației subsecvente, cu respectarea prevederilor Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor) și a Legii nr. 190/2018 privind măsuri de punere în aplicare a Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor), cu modificările ulterioare.
                                        <br/><br/>

                                        Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, cu modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                                        <br/><br/>

                                    </Grid>
   
                                    <Grid item xs={12}  align = "left">     
                                        <br/>
                                        <SEMNATURI_VANZATORI data = {dosar.ofertanti} />

                                    </Grid>                           
                                    
                                                                    
                                    
                                
                            </Grid>
                             )}
                            </div>
                            </RichCard>
                        
                    </Grid>
                </Grid>
            )}
            <PlicB5 data = {plic} onClose = {() => setPlic()} />
            <ConfirmarePrimire data = {ar} onClose = {() => setAr()} />
        </Fragment>

    )
}

