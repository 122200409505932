import React from 'react'
import { FaCogs } from 'react-icons/fa'

export default function SetariLanding() {
    return (
        <div align = "center" style = {{height: "60vh", display:"flex", flexDirection: "column",alignItems: "center", justifyContent:"center"}}>
           
            
            
            <div style = {{marginTop:"0em"}}>
            
            <FaCogs size = "20em" color = "#dadada" />
            <div style = {{marginTop:"-2.4em", fontWeight: 800, fontSize: "6rem", color: "white", textShadow: "1px 1px 5px black"}}>
                CONFIGURĂRI APLICAȚIE</div>
            
            </div>
        </div>
    )
}
