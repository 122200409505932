import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery, gql } from "@apollo/client"
import Container from '../../../components/Container';
import RichCard from '../../../components/RichCard';
import MaterialTableInstance from '../../../components/MaterialTableInstance';
import { FaDotCircle } from 'react-icons/fa';
import moment from "moment"
import { Button, Chip } from '@material-ui/core';
import { PieChart } from '@material-ui/icons';


const LIST_QUERY = gql`
query cereri($data:ComplexQuery) {
	atCereri(data:$data) {
		id nr dt
		contact { id name }

		atProcesVerbal {id nr dt atestatNr atestatSeria atestatDt
				atCarnete {
					id
					nr dt filaStart filaEnd dtElib dtPredare
					gospodari {
							id
							contact { id name cnp}
					}
					produse {
						id 
					# 	atProdus {
					# 	id tip_produs den_produs
					# }
					}
				}
		}
}
}
`

export default function ListCereri() {

  const { from, to, order } = useParams()
  const { data, loading, error } = useQuery(LIST_QUERY, { 
    variables: { data: {} },
    fetchPolicy:"cache-and-network"
  })
  const [items, setItems] = useState()
  const history = useHistory()

  useEffect(() => {
    if (data && data.atCereri) {
      setItems(data.atCereri.map(e => ({ 
        ...e, 
        dt: moment(e.dt).format("DD.MM.YYYY"),
        dtt: moment(e.dt).format("YYYY-MM-DD"),
        atestat: (e.atProcesVerbal && e.atProcesVerbal.atestatSeria && e.atProcesVerbal.atestatNr) ? 
        e.atProcesVerbal.atestatSeria + " " + e.atProcesVerbal.atestatNr+" / "
        + moment(e.atProcesVerbal.atestatDt).format("DD.MM.YYYY")
        : "---",
        carnete: e.atProcesVerbal 
          && e.atProcesVerbal.atCarnete 
          && e.atProcesVerbal.atCarnete.map( (e,i) => (<Chip style ={{marginRight: "3px"}} variant="outlined"
            size="small"  color="primary" key={i} label = {<small>{e.nr} / {moment(e.dt).format("DD.MM.YYYY")} ({e.produse.length})</small>}/>))
      })))
    }

  }, [data])


  const columns = [
    {
      title: "Nr.",
      field: "nr",
      filtering: false, 
       align: "center",
        width: "5%"
    },
    {
      title: "din data",
      field: "dtt",
      filtering: false, 
      defaultSort: "desc",
      render: row => row.dt,
      // width: "10%", 
      align: "center"
    },
    {
      title: "titular",
      field: "contact.name",
      // align: "center",
      // width: "15%", 
      // align: "left"
    },

    {
      title: "ATESTAT",
      field: "atestat",
        // width: "15%", 
        align: "left"
    },
    {
      title: "CARNETE",
      field: "carnete",
      filtering: false, 
        // width: "55%", 
        align: "left"
    }
  ]

  const contextHandler = (ev, row) => {

  }

  return (
    <RichCard 
      title = "CERERI ATESTAT"
      style = {{height: "79vh"}} contentStyle={{height:"74vh", overflow:"auto"}}
      action = {<Button variant="contained" size="small" color ="primary" 
      onClick = {()=>history.push("/registru-atestate/cerere/noua")}>
        ADAUGA CERERE</Button>}  
    >

        {items && (
          // <div style={{ height: "70vh", overflow: "auto" }}>
            <MaterialTableInstance
              title="REGISTRU CERERI ATESTATE"
              options={{
                                
                filtering: true,
                selection: false,
                // maxBodyHeight: "68vh",
                headerStyle: {
                    padding: "5px",
                    // border: "1px solid black",
                    lineHeight: 1.2,
                    // minHeight: "30px",
                    backgroundColor: 'aliceblue',
                    color: 'navy',
                    fontSize: "0.8rem"
                },
                cellStyle: { padding: 0, fontSize: "0.8rem", color: "navy", lineHeight:2 },
                rowStyle: {
                    // height: "40px"
                  
                },
                pageSize: 10,
                pageSizeOptions: [5, 7, 10, 12, 20, 50, 100, 1000],
                grouping: true
            }}

              data={items}
              columns={columns}
              onRowClick={(ev, row) => { ev.preventDefault(); history.push(`/registru-atestate/cerere/${row.id}`) }}
              // style={{ minHeight: "84vh", overflow: "auto" }}
              // actions={[
              //   {
              //     tooltip: 'ACȚIUNI ',
              //     icon: () => <FaDotCircle color="grey" size="0.8rem" />,
              //     onClick: contextHandler
              //   }
              // ]}
            />
          
        )}
        


    </RichCard>
  )


}
