import React from 'react'
import moment from 'moment'
import { makeStyles, Grid, Button } from '@material-ui/core'
import { Fragment } from 'react'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import Field from '../../Common/Field'
import { getSetting } from '../../../../lib/settings'


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    }
}))

export default function TplPv(props) {
    const classes = useStyles()
    const dosar = props.data
    const cod = props.cod
    return (
        <div>
            {dosar && (
                <Grid
                    container alignItems="flex-start"
                    style={{ border: "0px solid red" }}
                    className={classes.text} >
                    <Grid item xs={12}>
                        <ANTET_INSTITUTIE data={dosar.company} />
                    </Grid>
                    <Grid item xs={12} align="right">
                        <br />
                        Nr.înreg. {dosar.anexe[cod]['pv'].nr} / {moment(dosar.anexe[cod]['pv'].dt).format("DD.MM.YYYY")}
                    </Grid>
                    <Grid item xs={12} align="center">
                        <h4>PROCES-VERBAL </h4>

                        <h4>încheiat cu ocazia îndeplinirii procedurii de anulare <br />
                                                    a cererii de publicare și ofertei de vânzare nr.{dosar.nr} din {' '}
                            {moment(dosar.dt).format('DD.MM.YYYY')}<br />
                                                         prevăzute de legea 17/2014 modificată
                                                    </h4>
                    </Grid>
                    <Grid item xs={12}>
                        Subscrisa <Field>UAT {dosar.company.name} </Field>,
                                                CIF <Field>{dosar.company.cui}</Field>, având sediul în
                                                județul <Field>{dosar.company.jud}</Field>,{' '}
                                                localitatea <Field>{dosar.company.loc}</Field>,{' '}
                                                strada <Field>{dosar.company.str}</Field>,{' '}
                                                nr. <Field>{dosar.company.nr}</Field>,{' '}
                                                 în temeiul art. 71  alin.(1) din legea nr.17/2014, privind unele măsuri de reglementare a vânzării- cumpărării terenurilor agricole situate în extravilan și de modificare a Legii nr.268/2001, privind privatizarea societăților comerciale care dețin în administrație terenuri proprietate publică și privată a statului cu destinație agricolă și înființarea Agenției Domeniilor Statului, cu modificările ulterioare, având în vedere cererea de retragere a ofertei de vânzare depusă și înregistrată la sediul subscrisei sub numărul {' '}
                        <Field>{dosar.nr}/{moment(dosar.dt).format('DD.MM.YYYY')}</Field>, {' '}
                                                 de către {dosar.ofertanti.map((e, i) => (
                            <Fragment key={i}>
                                <Field>{e.titular.name}</Field>
                                {e.mandatar && <span> -{' '}
                                                            prin mandatar <Field>{e.mandatar.name}</Field></span>}
                                {i + 1 < dosar.ofertanti.length && ", "}
                            </Fragment>
                        ))}
                                                 am încheiat astăzi, {moment(dosar.anexe[cod]['dt']).format('DD.MM.YYYY')}, prezentul <Field>PROCES VERBAL DE RETRAGERE</Field> a ofertei de vânzare nr.
                                                 <Field>
                            {dosar.nr}/{moment(dosar.dt).format('DD.MM.YYYY')}.
                                                 </Field>

                    </Grid>
                    <Grid item xs={12} align="left">
                        <br />
                        {dosar.company.loc}, la {moment(dosar.anexe[cod]['pv'].dt).format("DD.MM.YYYY")}
                        <br />
                        <br />
                        <br />
                    </Grid>
                    <Grid item xs={5} align="center">
                        Primar, <br />
                        <Field>{getSetting(dosar.settings, "primar")}</Field>
                        <br /><br />
                                            .......................................................<br />
                        <small>(numele şi prenumele/semnătura)</small>
                    </Grid>
                    <Grid item xs={1}>
                        L.S.
                                            </Grid>
                    <Grid item xs={5} align="center">
                        Secretar general al U.A.T., <br />
                        <Field>{getSetting(dosar.settings, "secretar")}</Field>
                        <br /><br />
                                            .......................................................<br />
                        <small>(numele şi prenumele/semnătura)</small>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}
