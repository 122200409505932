import React, {useState, useEffect, Fragment} from 'react'
import { makeStyles, Grid, Card, CardContent, IconButton, Typography} from '@material-ui/core'
import WIP from '../../../../assets/wip.svg'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'
import { FaChevronCircleRight } from 'react-icons/fa'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import Field from '../../Common/Field'


const useStyles = makeStyles( theme => ({
    noprint: {
        "@media print": { display: "none"},
        "@media screen": {zoom: 1}
    }
}))

export default function ComVanzatori1FPrintTpl(props) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [currentIndex, setCurrentIndex] = useState()

    useEffect(() => {
        console.log("PROPS COM:", props)
        if ( props && props.data && props.data.item) {
            setItem(props.data.item)
        }
    }, [props])

    const nrHandler = i => ev => {
        let ni = {...item}
        ni.comVanzatori[i]['nr'] = ev.target.value
        props.onChange(ni)
    }
    const dtHandler = i => ev => {
        let ni = {...item}
        ni.comVanzatori[i]['dt'] = new Date(ev.target.value)
        props.onChange(ni)
    }
    return (
        <Grid container>
            {item && item.comVanzatori && (
                <Grid item xs = {12}>            
                    <Card className = {classes.noprint}>
                        <CardContent>
                           
                            {item.comVanzatori.map( (e,i) => (
                                 <Grid container 
                                    justifyContent= "space-between" alignItems="center" spacing={2}  
                                    key = {i}
                                    style = {i === currentIndex ? {background: "rgb(232, 244, 253)"}: null}
                                >
                                    <Grid item xs = {4}>
                                        {e.titular.name}
                                    </Grid>
                                    <Grid item xs = {3}>
                                        <FunkyInput value = {e.nr} onChange = {nrHandler(i)} />
                                    </Grid>
                                    <Grid item xs = {4}>
                                        <FunkyInput value = {moment(e.dt).format('YYYY-MM-DD')} type="date" onChange = {dtHandler(i)} />
                                    </Grid>
                                    <Grid item>
                                        <IconButton size = "small" onClick = {()=>setCurrentIndex(i)}>
                                            <FaChevronCircleRight/>
                                        </IconButton>
                                    </Grid>
                              </Grid>
                            ))}
                            
                        </CardContent>
                    </Card>
                   { currentIndex || currentIndex === 0 ? (
                       <div>
                           <ANTET_INSTITUTIE data = {props.data.dosar.company} />
                           <div align="right">
                                Nr.înreg. {item.comVanzatori[currentIndex].nr} / {moment(item.comVanzatori[currentIndex].dt).format("DD-MM-YYYY")}
                            </div>
                            <br/><br/>
                            <div align="left">
                                Către <strong>{item.comVanzatori[currentIndex].titular.name}</strong><br/>
                                Ref. la.: <strong>Oferta Dvs. de vânzare teren nr. {props.data.dosar.nr}
                                / {moment(props.data.dosar.dt).format('DD.MM.YYYY')}
                                </strong> 
                            </div>
                            <br/><br/>
                            <Typography variant = "body1" align='center'>
                                <strong>COMUNICARE OFERTĂ DE CUMPĂRARE</strong>
                            </Typography>
                            <br/><br/>
                            <div> Referitor la oferta de vânzare teren înregistrată la sediul nostru sub nr.{' '}
                                {props.data.dosar.nr} 
                                / {moment(props.data.dosar.dt).format('DD.MM.YYYY')}, {' '}
                                vă trasmitem alăturat oferta de cumpărare depusă de către {item.ofertant[0].titular.name}, {' '}
                            {' '}înregistrată  la sediul instituției noastre sub nr.{' '} 
                            <Field>{item.nr}</Field> / <Field>{moment(item.dt).format('DD.MM.YYYY')}</Field> {' '}
                            
                            </div>
                            <Grid item xs = {12} style ={{paddingLeft: "50%"}}>
                                        <br/><br/><br/>
                                        Întocmit, <br/>
                                        <Field>{ props.data.dosar.user.name}</Field>
                                        <br/><br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/><br/><br/>
                                        L.S. 
                                       
                        </Grid>  
                       </div>
                   ): null}
                </Grid>
            )}

            


           
        </Grid>
    )
}
