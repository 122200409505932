import React, { Fragment } from 'react'
import Field from './Field'

const fieldsPf = [
    { "key": "domLoc", "label": "loc.", "type": "text",           "xs": 6, "sm": 3, "lg": 3, "tab": "dom" },
    { "key": "domStr", "label": "str.", "type": "text",           "xs": 6, "sm": 4, "lg": 4, "tab": "dom" },
    { "key": "domNr", "label": "nr.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domBl", "label": "bl.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domSc", "label": "sc.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domEt", "label": "et.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domAp", "label": "ap.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domCod", "label": "cod poștal", "type": "text",     "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domJud", "label": "județ", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domTara", "label": "țara", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domTel", "label": "telefon", "type": "text",         "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domFax", "label": "fax", "type": "text",              "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domEmail", "label": "email", "type": "text",          "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
]

const fieldsPj = [
    { "key": "pjLoc", "label": "loc.", "type": "text",           "xs": 6, "sm": 3, "lg": 3, "tab": "pj" },
    { "key": "pjStr", "label": "str.", "type": "text",           "xs": 6, "sm": 4, "lg": 4, "tab": "pj" },
    { "key": "pjNr", "label": "nr.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjBl", "label": "bl.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjSc", "label": "sc.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjEt", "label": "et.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjAp", "label": "ap.", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjCod", "label": "cod poștal", "type": "text",     "xs": 6, "sm": 2, "lg": 2, "tab": "pj" },
    { "key": "pjJud", "label": "județ", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "pj" },
    { "key": "pjTara", "label": "țara", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "pj" },
    { "key": "pjTel", "label": "telefon", "type": "text",         "xs": 6, "sm": 2, "lg": 2, "tab": "pj" },
    { "key": "pjFax", "label": "fax", "type": "text",              "xs": 6, "sm": 2, "lg": 2, "tab": "pj" },
    { "key": "pjEmail", "label": "email", "type": "text",          "xs": 6, "sm": 2, "lg": 2, "tab": "pj" },
]

export default function ADRESAPOSTALA(props) {
    const data = props.data 
    // console.log("props.data:", props.data)
    return (
        <div style = {{ textAlign: "justify", textJustify: "inter-word" }}>
            
            {data && data.cnp 
            ? [...fieldsPf].map( (e,i) => (
                <Fragment key={i}>
                    
                    {e.label} <Field>{data[e.key]}</Field>,{' '}
                </Fragment>))
           : data && [...fieldsPj].map( (e,i) => (
                <Fragment key={i}>
                    
                    {e.label} <Field>{data[e.key]}</Field>,{' '}
                </Fragment>
            ))}
        </div>
    )
}
