import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { FaCheckCircle, FaCheckDouble, FaComment, FaCommentAlt, FaExclamationCircle, FaPlusCircle, FaRegComment, FaRegCommentAlt, FaTimesCircle } from 'react-icons/fa'
import { MdAdd, MdClose, MdPrint } from "react-icons/md"
import AvizEditModal from './AvizEditModal'
import Confirm from '../../components/Confirm'
import AvizPrintModal from './AvizPrintModal'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: "85vw", minHeight: "90vh"
    }
}))

const isDisabled = anexe => {
    return ( 
        (
            anexe
            && anexe.alegerePreemptor
            && anexe.alegerePreemptor.titular
            && anexe.alegerePreemptor.titular.name
        ) 
        || 
        (
            anexe 
            && anexe.anexa1g30
            && anexe.anexa1g30.oferta
            && anexe.anexa1g30.oferta.ofertant[0]
            && anexe.anexa1g30.oferta.ofertant[0].titular
            && anexe.anexa1g30.oferta.ofertant[0].titular.name
                                    
        )
    ) ? false: true
}



export default function AvizModal({ data, onClose }) {
    const classes = useStyles()
    const closeHandler = elem => () => onClose(elem)
    const [item, setItem] = useState()
    const [editModal, setEditModal] = useState(null)
    const [rmConfirm, setRmConfirm] = useState(null)
    const [printModal, setPrintModal] = useState(null)

    const { anexe } = (item && item.item) || {}
    const { avizFinal } = { anexe } || {}

    useEffect(() => setItem(data), [data])


    const newAvizHandler = etapa => () => setEditModal({
        nr: 1, 
        dt: new Date(),
        vanzator: item.item.ofertanti,
        cumparator:  ( anexe.alegerePreemptor && anexe.alegerePreemptor.titular )
        || (anexe.anexa1g30 && anexe.anexa1g30.oferta && anexe.anexa1g30.oferta.ofertant[0] && anexe.anexa1g30.oferta.ofertant[0].titular),
        rang:   anexe.alegerePreemptor && anexe.alegerePreemptor.rang.name,
        aviz: true,
        tip: etapa,
        inFapt: "",
        inDrept: "",
        originalItem: item.item  
    })

    const existingAvizHandler = () => setEditModal(item.item.anexe.avizDadr)

    const editCloseHandler = ev => {
        if (ev) {
            let ni = {...item.item}
            ni.anexe = { ...ni.anexe, avizDadr: ev }
            setItem({...item, item: ni})
        } 
        setEditModal(null)
    }

    const rmConfirCloseHandler = (ev) => {
        if (ev) {
            let ni = {...item.item}
            delete ni.anexe.avizDadr
            setItem({...item, item: ni})
        }
        setRmConfirm(null)

    }

    const previewHandler = () => setPrintModal(item.item.anexe.avizDadr)

    

    return (
        <Fragment>
       
        <Dialog open={Boolean(item)} onClose={closeHandler(null)} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item sm={true}>
                        {item && item.item && <>ELIBERARE/CONSULTARE AVIZ PENTRU CEREREA NR. {item.item.nr} / {item.item.dtHuman}<br />
                            {item.item.ofertantiHuman}
                        </>}
                    </Grid>
                    <IconButton onClick={closeHandler(null)}>
                        <FaTimesCircle size="2em" color="lightgrey" />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>


                {/* daca a fost depusa oferta de preemptori si exista ppc */}
                {
                    anexe && anexe.alegerePreemptor && anexe.alegerePreemptor.titular
                    && (
                        <Alert 
                            severity='success' 
                            icon = {<FaCheckCircle size="2em" style = {{marginTop: "30px"}}/>}
                            action = {
                                 <Button 
                                    variant = "contained" color ={anexe && anexe.avizDadr ? "primary": "secondary"}
                                    onClick = {anexe && anexe.avizDadr ? existingAvizHandler :  newAvizHandler("etapa45")  }
                                >
                                    {anexe && anexe.avizDadr 
                                        ? <small>modifică<br/>sau<br/>vizualizeză<br/>aviz</small>
                                        : <small>inițiază<br/>procedura de<br/>avizare</small>
                                    }
                                 </Button>
                            }
                        >
                            <h4>
                                A fost ales preemptorul potențial cumpărător (în perioada de 45 de zile), <br />
                                {anexe.alegerePreemptor.titular
                                    && anexe.alegerePreemptor.titular.name}
                                <br />
                                comunicare alegere preemptor nr. {anexe.alegerePreemptor.nr} / {moment(anexe.alegerePreemptor.dt).format("DD.MM.YYYY")}
                                <br />
                                la prețul de {anexe.alegerePreemptor.pret || "---"} lei

                            </h4>
                        </Alert>
                    )
                }

                {/* daca a fost depusa oferta de cumparatorii non-preemptori in cele 30 de zile dupa 40 */}
                {
                    anexe && anexe.anexa1g30
                    && anexe && anexe.anexa1g30.oferta
                    && (
                        <Alert 
                            severity='success' icon = {<FaCheckCircle size="2em" style = {{marginTop: "30px"}}/>}
                            action = {
                                <Button 
                                   variant = "contained" color ={anexe && anexe.avizDadr ? "primary": "secondary"}
                                   onClick = {anexe && anexe.avizDadr ? existingAvizHandler :  newAvizHandler("etapa30")  }
                               >
                                   {anexe && anexe.avizDadr 
                                       ? <small>modifică<br/>sau<br/>vizualizeză<br/>aviz</small>
                                       : <small>inițiază<br/>procedura de<br/>avizare</small>
                                   }
                                </Button>
                           }
                        >
                            <h4>
                                <br />
                                A fost ales promitentul potențial cumpărător (în perioada de 30 de zile),
                                <br />
                                {
                                    anexe.anexa1g30
                                    && anexe.anexa1g30.oferta
                                    && anexe.anexa1g30.oferta.ofertant[0]
                                    && anexe.anexa1g30.oferta.ofertant[0].titular
                                    && anexe.anexa1g30.oferta.ofertant[0].titular.name
                                }
                                <br />
                                comunicare alegere promitent nr. {anexe.anexa1g30.oferta.nr} / {moment(anexe.anexa1g30.oferta.dt).format("DD.MM.YYYY")}
                                <br />
                                la prețul de {anexe.anexa1g30.oferta.pret || "---"} lei


                            </h4>
                        </Alert>)
                }

                {anexe && !anexe.avizDadr
                   && <Alert  severity='error' icon = {<FaExclamationCircle size="2em"/>}   >
                        <Typography variant="h6" style = {{marginTop: "5px"}}>
                            Nu a fost încă eliberat nici un aviz pentru aceasta cerere.
                        </Typography>
                    </Alert>
                }

                {anexe && anexe.avizDadr
                   && <Alert
                    style = {{background: "azure"}}  
                    severity='info' icon = {<FaCheckCircle size="2em" color="blue"/>}   
                    action = {<>
                        <Button color="secondary" startIcon={<MdClose/>} onClick = {()=>setRmConfirm(true)}>ȘTERGERE</Button>
                        <Button startIcon={<MdPrint/>} onClick = {previewHandler}>PREVIZUALIZARE</Button>
                    </>}
                   >
                        <Typography variant="h6" style = {{marginTop: "5px"}}>
                           A fost eliberat  avizul {' '}
                           {anexe.avizDadr.aviz ? "POZITIV" : "NEGATIV"} {' '}
                           nr. {anexe.avizDadr.nr} / {moment(anexe.avizDadr.dt).format('DD.MM.YYYY')}.
                        </Typography>
                    </Alert>
                }

                { anexe && isDisabled(anexe) && (
                    <Alert severity = "warning" icon = {<FaComment size="2em" style={{marginTop: "10px"}} />} >
                          <Typography variant="subtitle2" style = {{marginTop: "5px"}}>
                                Nu poate fi emis un aviz întrucât procedura nu este completă<br/>
                                Nu a fost ales nici un preemptor potențial sau promitent cumpărător 
                            </Typography> 
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={closeHandler(null)}>
                    RENUNȚĂ
                </Button>
                <Button variant="contained" color="primary" size="small">
                    CONFIRMĂ
                </Button>
            </DialogActions>
        </Dialog>
        {editModal && <AvizEditModal data = {editModal} onClose = {editCloseHandler}/>}
        {rmConfirm && <Confirm open = {rmConfirm} onClose = {rmConfirCloseHandler} />}
        { printModal && <AvizPrintModal data = {printModal} onClose = {()=>setPrintModal(null)}/> }
        </Fragment>
    )
    
}
