const env = process.env.NODE_ENV

const config = {
    development: {
        APP_NAME: "terenuri.agricole.ro",
        NAMESPACE: "TERENURI2021",
        API_URL: "http://localhost:3300/graphql",
        // API_URL: "https://terenuri.aquis.ro/graphql"

    },
    production: {
        APP_NAME: "terenuri.agricole.ro",
        NAMESPACE: "legea17-prod",
        API_URL: "https://api.terenuri.agricole.ro/graphql"
        // API_URL: "https://terenuri.aquis.ro/graphql"
        // API_URL: "http://localhost:3300/graphql",
    }
}

export default config[env]
