import { useMutation } from '@apollo/client'
import { Button, Chip, Grid, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import { MdPrint } from 'react-icons/md'
import { useReactToPrint } from 'react-to-print'
import { PREEMPTOR_RM } from '../../../apollo/mutation'
import FunkyInput from '../../../components/FunkyInput'
import RichCard from '../../../components/RichCard'
import { getSetting } from '../../../lib/settings'
import ContactModal from '../../Contacts/ContactModal'
import ADRESAPOSTALA from '../Common/ADRESAPOSTALA'
import Antet2022 from '../Common/Antet2022'
import ANTET_PRE from '../Common/ANTET_PRE'
import AUTOCOMPLETE_CONTACT from '../Common/AUTCOMPLETE_CONTACT'
import Field from '../Common/Field'
import SemnaturiPrimarSecretar2022 from '../Common/SemnaturiPrimarSecretar2022'

const cod = "anexa1c";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        borderCollapse:"collapse",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export const PreemptorTemplate = props => {
    const classes = useStyles()
    // console.log("propsdata:", props.data)
    let data = props.data || []
    let rang = props.rang || 0
    data = [...data].filter( el => +el.rangPreemptorId === +rang)
    data = data.length !== 0 ? [...data] : []
   
     return   <table className={classes.table} cellSpacing = {0}>
            <tbody>
                <tr>
                    <td>Nr.crt</td>
                    <td width = "40%">
                        <div className = {classes.text} style = {{fontSize: "0.5rem", lineHeight:1}}>
                        Nume şi prenume persoană fizică/Denumire persoană/persoane 
                            juridică/juridice, asociaţii şi alte entităţi fără personalitate juridică, 
                            precum şi persoane juridice care nu se înregistrează în registrul comerţului
                        </div>

                       
                    </td>
                    <td><small>Adresă domiciliu/reşedinţă/sediu</small></td>
                </tr>
                {data.map( (e,i) => (
                    <tr key = {i}>
                        <td>{i+1}</td>
                        <td><Field>{e.titular && e.titular.name}</Field></td>
                        <td><ADRESAPOSTALA data = {e.titular} /></td>
                        {/* <td>{JSON.stringify(e.titular)}</td> */}
                    </tr>
                ))}
                {data.length === 0 && <tr><td> - </td><td>- - - </td><td>- - - </td></tr>}
                
            </tbody>
        </table>


}

export default function Anexa1CListaPreemptori(props) {
    const codAnexa = "1c"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()
    const [preemptorRm] = useMutation(PREEMPTOR_RM)    
    const [currentItem, setCurrentItem] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [currentType, setCurrentType] = useState()
    const [anchorEl, setAnchorEl] = useState()

   
    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const getRangById = (ranguri, id) => ranguri.filter( el => el.id === id )[0]['name']

    const addPreemptorHandler = () => {
        let nd = {...dosar, preemptori: [...dosar.preemptori].map(e =>({...e}))}; 
        // nd.preemptori.push({
        //     id:0, titularId:0,mandatarId:0,rangPreemptorId:1,registruId:dosar.id, companyId:dosar.company.id,
        //     titular: {name:""}, mandatar: {name: ""}
        // })
        nd.preemptori = [...nd.preemptori, {
                         titularId:0,mandatarId:0,rangPreemptorId:1,registruId:dosar.id, companyId:dosar.company.id,
            titular: {name:""}, mandatar: {name: ""}
        }]
        console.log("nd:", nd.preemptori)
        props.onChange(nd)  
    }

    const addContactModalOpen = (type,index) => ev=>{
        setCurrentType(type);setCurrentIndex(index);
        setAnchorEl(ev.currentTarget)
        
    }

    const closeAddMenuHandler = type => () =>{
 
        if ( type ==="pf" ) {setCurrentItem({name: " ", cnp: " ", 
            dNastere: moment().format('YYYY-MM-DD'), ciElib: moment().format('YYYY-MM-DD')})
        }
        if ( type ==="pj" ) {setCurrentItem({name: " ", cui: " ", 
        dNastere: moment().format('YYYY-MM-DD'), ciElib: moment().format('YYYY-MM-DD')})
        }
        setAnchorEl(null)
    }

    const closeContactModalHandler = ev => {
        console.log("close ev:", ev)
        if ( ev ){
            let nd={...dosar, preemptori: dosar.preemptori.map(e=>({...e})) }
                
            nd.preemptori[currentIndex].titularId = +ev.id
            nd.preemptori[currentIndex]['titular'] = ev
            props.onChange(nd)
        }
        setCurrentItem();setCurrentType();setCurrentIndex();
        
    }


    const removeHandler =  (type, index, id) =>async ()=>{
        let nd = {...dosar, preemptori: dosar.preemptori.map( el => ({...el}))}
        
        let res = await preemptorRm({variables: {id: id}})
        console.log("RES:", res)
        nd.preemptori.splice(index,1)
        res = await Promise.resolve(true)
        props.onChange(nd)
    }
    const createPre = (n, rangId) =>() => {
        let nd = {...dosar, preemptori: dosar.preemptori.map(e =>({...e}))}; 
        // nd.preemptori.push({
        //     id:0,  titularId:0,mandatarId:0,rangPreemptorId:rangId,registruId:dosar.id, companyId:dosar.company.id,
        //     titular: {name:n}, mandatar: {name: ""}
        // })
        nd.preemptori = [...nd.preemptori, {id:0,  titularId:0,mandatarId:0,rangPreemptorId:rangId,registruId:dosar.id, companyId:dosar.company.id}]
            //     titular: {name:n}, mandatar: {name: ""}}]
        props.onChange(nd)  
    }

    const changePersonHandler = (i, type) => ev => {
        let nd = {...dosar, preemptori: dosar.preemptori.map(e =>({...e}))}; 
        nd.preemptori[i][type]=ev
        nd.preemptori[i][`${type}Id`] = ev.id || 0
        props.onChange(nd)  
    }


    const rangHandler = i => ev => {
        console.log(ev.target.value)
        let nd = {...dosar, preemptori: dosar.preemptori.map(e =>({...e}))}; 
        nd.preemptori[i]['rangPreemptorId']=ev.target.value
        props.onChange(nd)  
    }



    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                        <Grid item sm={2} xs={4}>
                                            <FunkyInput
                                                value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                onChange={anexaInputHandler('nr')} />
                                        </Grid>
                                        <Grid item sm={4} xs = {6}>
                                            <FunkyInput
                                                value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                onChange={anexaInputHandler('dt')}
                                            />
                                        </Grid>

                                    </Grid>
                                </RichCard>
                            </Grid>
                            <Grid item sm = {12} xs = {12}>
                                <Alert severity="info" style = {{padding: "0px", paddingLeft:"10px"}}>
                                    <strong><small>Preemptorii identificați de vânzător(i) prin cererea de afișare a ofertei</small></strong>
                                    <br/>
                                    <Grid container spacing = {1} justifyContent= "flex-start" >
                                    {dosar && dosar.anexe.preemptoriCerere.map( (e,i) => (
                                        
                                            <Grid item key ={"chip"+i}>
                                                <Chip color = "primary" style = {{paddding: "10px"}}
                                                    onClick = {createPre(e.name, e.rangId)}
                                                    label = {<small>{e.name}</small>} 
                                                    deleteIcon = {<div style = {{paddingTop: "2px", width: "20px", height:"20px",background: "white",color: "navy", borderRadius: "10px", textAlign:"center", fontWeight:800}}>{
                                                    getRangById(dosar.ranguri, e.rangId).replace("preemptori de rang ", "")
                                                    }</div>}
                                                    onDelete = {()=>{}}
                                                />
                                            </Grid>

                                       
                                    ))}
                                    </Grid>
                                </Alert>
                            </Grid>
                            <Grid item xs = {12}>
                                <RichCard 
                                    title = "Preemptori identificați din evidențele UAT"
                                    action = {
                                        <IconButton size = "small" color = "primary" onClick = {addPreemptorHandler}>
                                            <FaPlusCircle/>
                                        </IconButton>
                                    }
                                >
                                    <Alert severity="info" style = {{padding: "0px", paddingLeft:"10px"}}>
                                        <small>Alege din agenda aplicație sau adaugă/modifică o persoană</small>
                                    </Alert>
                                    <br/>
                                    {dosar.preemptori.map( (e,i) => (
                                        <Grid container spacing = {1} alignItems="center" key = {i}>
                                        {i+1}.
                                        <FaTimesCircle color="red" onClick = {removeHandler("titular",i, e.id)} />
                                        <Grid item xs>
                                            <AUTOCOMPLETE_CONTACT data = {e.titular} label = "TITULAR"
                                                onChange ={changePersonHandler(i, "titular")}
                                            />
                                        </Grid>
                                        {e.titular && e.titular.id 
                                            ? <FaEdit color = "green" onClick = {()=>{
                                                setCurrentItem({
                                                    ...e.titular, 
                                                    dNastere: moment(e.titular.dNastere).format('YYYY-MM-DD'),
                                                    ciElib: moment(e.titular.ciElib).format('YYYY-MM-DD')
                                                });
                                                 setCurrentIndex(i); setCurrentType("titular")
                                            }}/>
                                            : <FaPlusCircle color = "blue"
                                            onClick = {addContactModalOpen("titular", i)}
                                                />
                                        }
                                       
                                       <Grid item xs ={3}>
                                           <FunkyInput 
                                                size = "small" value = {e.rangPreemptorId} select
                                                onChange = {rangHandler(i)}
                                           >
                                               {dosar.ranguri.map((e,i) => (
                                                   <MenuItem key={"m"+i} value = {e.id}>{e.name.replace("preemptori de ", "")}</MenuItem>
                                               ))}
                                           </FunkyInput>
                                       </Grid>
                                   </Grid>                                        
                                  
                                    ))}
                                  
                                </RichCard>
                            </Grid>
                          
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12} >

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action = {
                                <Button variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                    <MdPrint size = "1.6em"/>
                                </Button>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    className={classes.text}
                                    ref={printRef} >
                                     <Grid item xs={12} align="right">
                                     ANEXA Nr. 3 (Anexa nr. 1C la normele metodologice)
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD.MM.YYYY")}
                                    </Grid>
                                    <Grid item xs={12} align = "center">
                                        <strong>
                                        LISTA<br/>
                                        preemptorilor în vederea exercitării dreptului de preempţiune asupra 
                                        ofertei de vânzare în ordinea rangului de preferinţă
                                        </strong>
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Antet2022 data = {dosar}/>
                                    </Grid>
                                    <Grid item xs={12}  className = {classes.text}>
                                       <br/>
                                        
                                        Ca urmare a înregistrării Ofertei de vânzare nr.
                                         <Field>{dosar.nr}</Field> / <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>{' '}
                                        depuse de {dosar.ofertanti.map( (e,i) => (
                                            <Field key = {"field"+i}>
                                                {e.titular.name} 
                                                {dosar.ofertanti.length > i+1 ? ", ": " "}
                                            </Field>
                                        ))}
                                        în calitate de {dosar.ofertanti.length ===1 ? "vânzător" : "vânzători"}, 
                                        pe baza evidenţelor deţinute la nivelul primăriilor şi a informaţiilor cuprinse în oferta de vânzare au fost identificaţi următorii preemptori:
                                        
                                        {dosar.ranguri.map((e,i) => (
                                            <div className = {classes.text} key= {i}>
                                                {i+1}. {e.name}: {e.desc} <br/>
                                                <PreemptorTemplate data = {dosar.preemptori} rang = {e.id} />
                                                <br/>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}  align = "left"> 
                                        <SemnaturiPrimarSecretar2022 data = {dosar}/>
                                        <br/><br/>
                                        Data{' '}<Field>{moment(dosar.anexe.anexa1c.dt).format('DD.MM.YYYY')}</Field>
                                        <br/><br/>
                                        
                                    </Grid>   
                                    

                                    
                                    
                                    
                                
                            </Grid>
                             )}
                            </div>
                            </RichCard>
                        
                    </Grid>
                </Grid>
            )}
            <ContactModal data = {currentItem} onClose = {closeContactModalHandler} />
            <Menu open = {Boolean(anchorEl)} anchorEl={anchorEl} onClose = {closeAddMenuHandler(null)}>
                <MenuItem onClick = {closeAddMenuHandler('pf')}>PERSOANĂ FIZICĂ</MenuItem>
                <MenuItem onClick = {closeAddMenuHandler('pj')}>PERSOANĂ JURIDICĂ</MenuItem>
            </Menu>
        </Fragment>

    )
}

