import { Grid, IconButton } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import Boxy from '../../../components/Boxy';
import FunkyInput from '../../../components/FunkyInput';
import moment from "moment"
import AUTOCOMPLETE_CONTACT from '../../Dosar/Common/AUTCOMPLETE_CONTACT';
import { FaEdit, FaPlusCircle } from 'react-icons/fa';
import ContactModal from '../../Contacts/ContactModal';

export default function Cerere({ data, onChange }) {

    const item  = data || {}
    const [titularModal, setTitularModal] = useState(null)

    const intHandler = name => ev => onChange({ ...item, [name]: parseInt(ev.target.value) }) 
    const dtHandler = name => ev => onChange({
        ...item,
        [name]: moment(ev.target.value).toDate()
    })
    const titularHandler = ev => {
        if (!ev.id) {
            onChange({ ...item, contactId: null, contact: {} })
        } else {
            onChange({ ...item, contactId: ev.id, contact: ev })
        }
    }
    const titularModalHandler = ev => {
        if ( ev ) {
            onChange({...item, contactId: ev.id, contact: ev})
        }
        setTitularModal(null)
    }
    return (<Fragment>
        {item && (
            <Fragment>
                <Boxy title="DATE DE IDENTIFICARE CERERE"
                    style={{ background: "aliceblue" }}
                    contentStyle={{ background: "aliceblue" }}
                >
                    <Grid container spacing={1}>
                        <Grid item sm={2}>
                            <FunkyInput value={item.nr}
                                label="NR.CERERE" type="number" step={1} pattern="[0-9]*"
                                onChange={intHandler("nr")} />
                        </Grid>
                        <Grid item sm={4}>
                            <FunkyInput
                                type="date"
                                onChange={dtHandler("dt")}
                                value={moment(item.dt).format("YYYY-MM-DD")} label="DIN DATA" />
                        </Grid>
                        <Grid item xs={7} />
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={11}>
                                    <AUTOCOMPLETE_CONTACT data={item.contact} label="TITULAR"
                                        onChange={titularHandler}
                                    />
                                </Grid>
                                <Grid item xs={1} align="center">
                                    {item.contact && item.contact.id
                                        ? (<IconButton size="small" color="primary"
                                            onClick={() => setTitularModal(item.contact)}
                                        >
                                            <FaEdit />
                                        </IconButton>)
                                        : (<IconButton size="small" color="secondary"
                                            onClick={() => setTitularModal({
                                                name: "", cnp: 0, ciElib: moment().format("YYYY-MM-DD")
                                                , dNastere: new Date()
                                            })}
                                        >
                                            <FaPlusCircle />
                                        </IconButton>)
                                    }

                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Boxy>

            </Fragment>
        )}
         {titularModal && <ContactModal data = {titularModal} onClose ={titularModalHandler} />}
    </Fragment>)
}
