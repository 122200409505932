import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import Boxy from '../../../components/Boxy';
import FunkyInput from '../../../components/FunkyInput';
import moment from "moment"

export default function AvizConsultativ({ data, onChange }) {

    const item = data || {}

    const intHandler = name => ev => onChange({ ...item, [name]: parseInt(ev.target.value) }) 
    const dtHandler = name => ev => onChange({
        ...item,
        [name]: moment(ev.target.value).toDate()
    })
    const inputHandler = name => ev => onChange({
        ...item,
        [name]: ev.target.value
    })

    return (
        <Fragment>
            {item && (
                <Fragment>
                    <Boxy
                        title="AVIZ CONSULTATIV"
                        style={{ background: "aliceblue" }}
                        contentStyle={{ background: "aliceblue" }}

                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <FunkyInput
                                    type="number"
                                    value={item.avizNr}
                                    onChange={intHandler("avizNr")}
                                    label="NR.AVIZ" />
                            </Grid>
                            <Grid item xs={4}>
                                <FunkyInput
                                    value={moment(item.avizDt).format("YYYY-MM-DD")}
                                    type="date" label="DIN DATA"
                                    onChange={dtHandler("avizDt")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FunkyInput
                                    value={item.avizEmitent}
                                    label="EMITENT AVIZ"
                                    onChange={inputHandler("avizEmitent")}
                                />
                            </Grid>
                        </Grid>
                    </Boxy>
                </Fragment>
            )}
        </Fragment>
    )
}
