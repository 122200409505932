import React, { Fragment, useState } from 'react'

import {IconButton, makeStyles} from '@material-ui/core'
import TerenModal from './TerenModal'
import FunkyInput from '../../../../components/FunkyInput'
import { FaTimesCircle } from 'react-icons/fa'
import { RM_TEREN } from '../../../../apollo/mutationArenda'
import { useMutation } from '@apollo/client'



const FIELDS = [
    {key: "terenTarla"},
    {key: "terenParcela"},
    {key:"terenNrCadastral"},
    {key:"terenCf"},
    {key:"terenCfLoc"},
    {key:"terenHa", type: "number"},
    {key:"terenAct"},
    {key:"terenCategorie"},
]

const useStyles = makeStyles(theme =>({
    table: {
        width: "100%",
        padding:0,
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "0.1em solid lightgrey",
            background: theme.palette.grey[200],
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, 
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "0em solid lightgrey",
            fontSize: "0.6rem",
            // padding: theme.spacing(0.5),
            verticalAlign: "center",
            textAlign:"center",
            height: "100%"
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300]
        }
    }
}))

export default function Terenuri(props) {
    const classes = useStyles()
    const item = props.data
    const [curIndex, setCurIndex] = useState()
    const [curTeren, setCurTeren] = useState()
    const [rmTeren] = useMutation(RM_TEREN)

    const editTerenModal = (item, index) => ev => {
        setCurIndex(index)
        setCurTeren(item)
    }

    const closeTerenModal = ev => {
        if (ev) {
            let ni = {...item, arendaTeren: item.arendaTeren.map(e=>({...e}))}
            ni.arendaTeren[curIndex] = ev
            props.onChange(ni)

        }
        setCurTeren();setCurIndex();
     }

    const inputHandler = (name, index) => ev => {
        let ni = {...item, arendaTeren: item.arendaTeren.map(e=>({...e}))}
        ni.arendaTeren[index] = {...ni.arendaTeren[index], [name]: ev.target.value}
        props.onChange(ni)
    }

    const removeHandler = (ev, i) => async () => {
        let ni = {...item, arendaTeren: item.arendaTeren.map(e=>({...e}))}
        let res = await rmTeren({variables: {id:+ev.id}})
        console.log("Res:", res)
        if (res.data && res.data.rmTeren ) {
            ni.arendaTeren.splice(i,1)
            props.onChange(ni)
        }

    }
    return (
        <Fragment>
            {item && (
                 <table className = {classes.table} cellSpacing = {0} cellPadding = {0}>
                 <thead>
                     <tr>
                         <th>NR.CRT</th>
                         <th>TARLA</th>
                         <th>PARCELA</th>
                         <th>NUMĂR<br/>CADASTRAL</th>
                         <th>NUMĂR<br/>CARTE FUNCIARĂ</th>
                         <th>LOCALITATE<br/>C.F.</th>
                         <th>SUPRAFAȚĂ<br/>(HA)</th>
                         <th>ACT DE <br/>PROPRIETATE</th>
                         <th>CATEGORIE<br/>TEREN</th>
                         <th> </th>
                     </tr>
                 </thead>
                 <tbody>
                     {item.arendaTeren.map( (e,i) => (
                         <Fragment key = {i}>
                         <tr>
                             <td  onClick = {editTerenModal(e,i)}>{i+1}</td>
                            { FIELDS.map( (el,ix) => (
                                <td key = {ix}> 
                                    
                                    <FunkyInput 
                                        value = {e[el.key]} onChange = {inputHandler(el.key, i)} 
                                        type = {el.type ? el.type : "text"} step = "any"
                                    />
                                </td>
                            ))}

                            <td>
                                <IconButton size ="small" onClick = {removeHandler(e,i)}>
                                    <FaTimesCircle color = "red" size = "0.8rem"/>
                                </IconButton>
                            </td>
                         </tr>
                         
                         </Fragment>
                     ))}
                     
                 </tbody>
             </table>
            )}
             <TerenModal open = {Boolean(curTeren)} onClose = {closeTerenModal} data = {curTeren}/>
        </Fragment>
    )
}
