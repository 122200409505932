const keys = ["name", "cui", "cnp", "ciNr", "ciSeria", "ciElib", "ciElibDe", "dNastere", "locNastere", "judNastere", "taraNastere", "cetatenie", "stCiv", "domLoc"
    , "domStr", "domNr", "domBl", "domSc", "domEt", "domAp", "domCod", "domJud", "domTara", "domTel", "domFax", 'domEmail', "rsdLoc", "rsdStr", 'rsdNr', 'rsdBl', 'rsdSc', "rsdEt", "rsdAp", "rsdCod", 'rsdJud', "rsdTara", "rsdTel", 'rsdFax', "rsdEmail", "pjRegType", "pjRegNr", "pjCui", "pjLoc", "pjStr", "pjNr", "pjBl", "pjSc", "pjEt", "pjAp", "pjCod", "pjJud", "pjTara", "pjTel", "pjFax", "pjEmail", "pjSite", "pjTipSediu", "obs"]

const getTab = el => {
    switch (true) {

        case el.includes("dom"): return "dom"; break;
        case el.includes("rsd"): return "rsd"; break;
        case el.includes("pj"): return "pj"; break;
        default:
            return "pf"
            break;
    }
}

export const getInputFieldsProps = [
    { "key": "name", "label": "NUME", "type": "text",               "xs": 12, "sm": 5, "lg": 5, "tab": "pf" }, 
    { "key": "cnp", "label": "CNP", "type": "text",                 "xs": 12, "sm": 3, "lg": 3, "tab": "pf" },
    { "key": "ciTip", "label": "TIP ACT.ID.", "type": "text",           "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "ciSeria", "label": "SERIA", "type": "text",         "xs": 4, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "ciNr", "label": "NRr", "type": "text",               "xs": 6, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "ciElib", "label": "ELIB.LA", "type": "date",           "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "ciElibDe", "label": "ELIB.DE", "type": "text",       "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "dNastere", "label": "DATA NAȘTERE", "type": "date",       "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "locNastere", "label": "LOC.NAȘTERE", "type": "text",   "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "judNastere", "label": "JUD.NAȘTERE", "type": "text",   "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "taraNastere", "label": "ȚARA NAȘTERE", "type": "text", "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "cetatenie", "label": "CETĂȚENIE", "type": "text",     "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "stCiv", "label": "ST.CIVILĂ", "type": "text",             "xs": 1, "sm": 2, "lg": 2, "tab": "pf" },
    { "key": "obs", "label": "OBSERVAȚII", "type": "text",                 "xs": 12, "sm": 6, "lg": 6, "tab": "pf" },

    { "key": "domLoc", "label": "LOCALITATE", "type": "text",           "xs": 6, "sm": 3, "lg": 3, "tab": "dom" },
    { "key": "domStr", "label": "STR", "type": "text",           "xs": 6, "sm": 4, "lg": 4, "tab": "dom" },
    { "key": "domNr", "label": "NR", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domBl", "label": "BL", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domSc", "label": "SC", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domEt", "label": "ET", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domAp", "label": "AP", "type": "text",             "xs": 6, "sm": 1, "lg": 1, "tab": "dom" },
    { "key": "domCod", "label": "COD POȘTAL", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domJud", "label": "JUDEȚUL", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domTara", "label": "ȚARA", "type": "text",         "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domTel", "label": "TELEFON", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domFax", "label": "FAX", "type": "text",           "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },
    { "key": "domEmail", "label": "EMAIL", "type": "text",       "xs": 6, "sm": 2, "lg": 2, "tab": "dom" },

    { "key": "rsdLoc", "label": "LOC.REȘEDINȚĂ", "type": "text",           "xs": 1, "sm": 3, "lg": 3, "tab": "rsd" },
    { "key": "rsdStr", "label": "STR:", "type": "text",           "xs": 1, "sm": 4, "lg": 4, "tab": "rsd" },
    { "key": "rsdNr", "label": "NR.", "type": "text",             "xs": 1, "sm": 1, "lg": 1, "tab": "rsd" },
    { "key": "rsdBl", "label": "BL.", "type": "text",             "xs": 1, "sm": 1, "lg": 1, "tab": "rsd" },
    { "key": "rsdSc", "label": "SC.", "type": "text",             "xs": 1, "sm": 1, "lg": 1, "tab": "rsd" },
    { "key": "rsdEt", "label": "ET.", "type": "text",             "xs": 1, "sm": 1, "lg": 1, "tab": "rsd" },
    { "key": "rsdAp", "label": "AP.", "type": "text",             "xs": 1, "sm": 1, "lg": 1, "tab": "rsd" },
    { "key": "rsdCod", "label": "COD", "type": "text",           "xs": 1, "sm": 2, "lg": 2, "tab": "rsd" },
    { "key": "rsdJud", "label": "JUD", "type": "text",           "xs": 1, "sm": 2, "lg": 2, "tab": "rsd" },
    { "key": "rsdTara", "label": "ȚARA", "type": "text",         "xs": 1, "sm": 2, "lg": 2, "tab": "rsd" },
    { "key": "rsdTel", "label": "TELEFON", "type": "text",           "xs": 1, "sm": 2, "lg": 2, "tab": "rsd" },
    { "key": "rsdFax", "label": "FAX", "type": "text",           "xs": 1, "sm": 2, "lg": 2, "tab": "rsd" },
    { "key": "rsdEmail", "label": "EMAIL", "type": "text",       "xs": 1, "sm": 2, "lg": 2, "tab": "rsd" },
    
    { "key": "pjRegType", "label": "TIP REGISTRU", "type": "text",     "xs": 12, "sm": 2, "lg": 2, "tab": "pj" },
    { "key": "pjRegNr", "label": "NR.ÎNREG.", "type": "text",         "xs": 12, "sm": 2, "lg": 2, "tab": "pj" },
   
    { "key": "pjLoc", "label": "LOC", "type": "text",             "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },
    { "key": "pjStr", "label": "STR", "type": "text",             "xs": 12, "sm": 4, "lg": 4, "tab": "pj" },
    { "key": "pjNr", "label": "NR", "type": "text",               "xs": 12, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjBl", "label": "BL", "type": "text",               "xs": 12, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjSc", "label": "SC", "type": "text",               "xs": 12, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjEt", "label": "ET", "type": "text",               "xs": 12, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjAp", "label": "AP", "type": "text",               "xs": 12, "sm": 1, "lg": 1, "tab": "pj" },
    { "key": "pjCod", "label": "COD POSTAL", "type": "text",             "xs": 12, "sm": 2, "lg": 2, "tab": "pj" },
    { "key": "pjJud", "label": "JUD", "type": "text",             "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },
    { "key": "pjTara", "label": "ȚARA", "type": "text",           "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },

    { "key": "pjTel", "label": "TELEFON", "type": "text",             "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },
    { "key": "pjFax", "label": "FAX", "type": "text",             "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },
    { "key": "pjEmail", "label": "EMAIL", "type": "text",         "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },
    { "key": "pjSite", "label": "SITE WEB", "type": "text",           "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },
    { "key": "pjTipSediu", "label": "TIP SEDIU", "type": "text",   "xs": 12, "sm": 3, "lg": 3, "tab": "pj" },
]
