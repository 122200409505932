import React, { Fragment } from 'react';
import RichCard from '../../../components/RichCard';
import { Button, ButtonGroup } from "@material-ui/core"
import { Route, Switch, useHistory } from "react-router"
import RegAtestate from './RegAtestate';
import RegCarnete from './RegCarnete';

const getLastSegmentOfPathname = path => {
  let p = path.split("/")
  return p[p.length-1]
}

export default function Rapoarte(props) {
  const history = useHistory()
  return (
      <RichCard 
        title = "GENERARE RAPOARTE"
        contentStyle = {{height:"72vh", border: "0x solid lightgrey"}}
        // action = {<Button variant="contained" size="small" color ="primary">ADAUGA CERERE</Button>}
        action = {
          <Fragment>
              <Button 
                size="small" 
                variant={getLastSegmentOfPathname(props.location.pathname) === "registru-atestate" ? "contained"  : "outlined"}
                color="primary"
                onClick = {()=>history.push("/registru-atestate/rapoarte/registru-atestate")}>Registrul atestatelor de producător</Button>
                {' '}
              <Button 
                size="small" 
                variant={getLastSegmentOfPathname(props.location.pathname) === "registru-carnete" ? "contained"  : "outlined"}
                color="primary"
                onClick = {()=>history.push("/registru-atestate/rapoarte/registru-carnete")}>Registrul carnetelor de comercializare</Button>
       </Fragment>
        } 
      >
          <Switch>
          <Route path = "/registru-atestate/rapoarte/registru-atestate/:query?"  component = {RegAtestate} />
          <Route path = "/registru-atestate/rapoarte/registru-carnete/:query?"  component = {RegCarnete} />

          </Switch>

      </RichCard>
  )
}
