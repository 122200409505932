import { Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import FunkyInput from '../../../../components/FunkyInput';
import { FaEdit, FaPencilAlt, FaPlus, FaPlusCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import Boxy from '../../../../components/Boxy';
import { clearTypeName, deepClearTypeName } from '../../../../lib/graph-stuff';
import PRODUS from '../PRODUS';
import AUTOCOMPLETE_CONTACT from '../../../Dosar/Common/AUTCOMPLETE_CONTACT';
import ContactModal from '../../../Contacts/ContactModal';


const useStyles = makeStyles(theme => ({
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey", fontSize: "0.75em", fontWeight: 500,
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "crosshair",
            border: "2px solid navy",
        },
    }
}))

export default function CarnetGospodari({ data, title, tip, onChange }) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [contactModal, setContactModal] = useState(null)

    useEffect(() => setItem(data), [data])

    const addNewGospodarHandler = () => {
        let ni = { ...item }
        ni = deepClearTypeName(ni)
        ni.gospodari.push({
            contact: {}
        })
        onChange(ni)
    }

    const contactHandler = index => ev => {
        let ni = { ...item }
        ni = deepClearTypeName(ni)
        ni.gospodari[index]['contact'] = ev
        onChange(ni)
    }

    const contactModalOpenHandler = (e,i) => () => {
        let mData = e
        if (e === "new") {
            mData = {name:"", ciElib: new Date(), dNastere: new Date()}
        }
        setContactModal({data:mData, index: i})
    }

    const contactModalCloseHandler = ev => {
        if (ev) {
            let ni = { ...item }
            ni = deepClearTypeName(ni)
            ni.gospodari[contactModal.index]['contact'] = ev
            onChange(ni)
        }
        setContactModal(null)
    }

    const rmContactHandler = i => () => {
        let ni = {...item}
        ni = deepClearTypeName(ni)
        ni.gospodari.splice(i,1)
        onChange(ni)
    }



    return <Fragment>
        {item && (
            <div>
                <div align="right">
                    <IconButton size="small" color="primary" onClick={addNewGospodarHandler}><FaPlusCircle /></IconButton>
                </div>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th width="5%" align="center">Nr.crt</th>
                            <th width="60%" colSpan={2} align="left">Nume și prenume</th>
                            <th width="35%" align="left" colSpan={2}>CI/CNP</th>
                            {/* <th width="20%"  colSpan={2} align="left">CNP</th> */}

                        </tr>
                    </thead>
                    <tbody>
                        {item.gospodari.map((e, i) => (
                            <tr key={i}>

                                <td align="center">{i + 1}</td>
                                <td>
                                    <AUTOCOMPLETE_CONTACT data={e.contact} onChange={contactHandler(i)} />
                                </td>
                                <td align="center">
                                    {(e.contact && e.contact.id)
                                        ? (
                                            <IconButton 
                                                size="small" color="primary" 
                                                onClick = {contactModalOpenHandler(e.contact,i)}>
                                                <FaPencilAlt />
                                            </IconButton>
                                        )
                                        : (
                                            <IconButton 
                                                size="small" color="secondary"
                                                onClick = {contactModalOpenHandler("new",i)}>
                                                <FaPlus />
                                            </IconButton>
                                        )
                                    }


                                </td>
                                <td align='center'>
                                    {(e.contact && e.contact.ciSeria) || "---"}   {(e.contact && e.contact.ciNr) || "---"}
                                    <br/> {(e.contact && e.contact.cnp) || "---"}
                                </td>
                                {/* <td align='center'>
                                    {(e.contact && e.contact.cnp) || "---"}
                                </td> */}
                                <td><IconButton size="small" color="secondary" onClick = {rmContactHandler(i)}>
                                        <FaTimes/>
                                    </IconButton></td>

                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* <small>
                    <pre>{JSON.stringify(item, null, 2)}</pre>
                </small> */}
            </div>
        )}
        {contactModal && <ContactModal data = {contactModal.data} onClose = {contactModalCloseHandler} />}
    </Fragment>
}