import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React, { Fragment } from 'react'

const useStyles = makeStyles( theme => ({
    box: {
        position: "relative",
        border: "0.01rem solid lightgrey",
        padding: "10px",
        marginTop:"10px",
        width: "100%", height: "inherit", overflow: "none",
        boxShadow: "1px 1px 3px lightgrey"
       
        
    },
    titleContainer: {
        position: "absolute",
        display: "flex",
        alignItems:"flex-start", justifyContent: "center",
        width:"100%",
        marginTop: "-25px", 
        paddingLeft: "10px", paddingRight: "10px", 
    },
    title: {
        fontWeight: 400, color: "navy",
        fontSize: "0.75em",
        background: "none", 
        paddingBottom: "15px"
    },
    content: {
        margin: theme.spacing(1),
        height: "100%"
    },
    footer: {
        position: "absolute",
        left: 0, bottom:0, width: "100%",
        background:theme.palette.grey[200], color: "grey"
    }
}))

export default function Boxy({title, action, footer, children, contentStyle, onChange, ...rest}) {
    const classes = useStyles()
    return (
        <div className ={classes.box} {...rest}>
           {title && (
                <div className = {classes.titleContainer}>
                   <div className = {classes.title}>{title}</div>
                  
                </div>
              
           )}
           {action && <div align="right">{action}</div>}
           <div className={classes.content} style = {contentStyle || null}>
               
           { children }
           </div>
           {footer && (
           <div className= {classes.footer}>{footer}</div>
           )}

        </div>
    )
}
