import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Context } from '../../components/Store'
import Container from '../../components/Container'
import { useQuery, useApolloClient, useMutation } from '@apollo/client'
import { REG, NEXT_ARENDA_DOSAR_NUMBER } from '../../apollo/queryArenda'
import { RM_REGISTRU } from '../../apollo/mutationArenda'
import moment from 'moment'
import { FaDotCircle, FaTimesCircle } from 'react-icons/fa'
import { Button, Grid, Menu, MenuItem } from '@material-ui/core'
import NewContract from './NewItem/NewContract'
import NewConex from './NewItem/NewConex'
import {intervalToDuration,  differenceInHours} from 'date-fns'
import { SelectColumnFilter } from '../../components/ReactTable7/filters'
import Confirm from '../../components/Confirm'
import {FcAddDatabase, FcFilledFilter, FcTreeStructure, FcSurvey} from 'react-icons/fc'
import Filters from './Filters'
import MaterialTableInstance from '../../components/MaterialTableInstance'

const fixDate = dtRo => {
    let res = null;
    if (dtRo) {
        res = dtRo.split(".")
        res = dtRo.length === 3 
            ? dtRo[2] + "-" + dtRo[1] +"-"+ dtRo[0]
            : null
    } 
    return res
}

const diffDate = (endDate) => {
    const end = new Date(endDate)
    const now = new Date()
    let diff = intervalToDuration({
        start: now, end: end
    })
    const ani = (diff) => {
        let res = ""
        switch(true){
            case diff.years === 0: res = ""; break;
            case diff.years === 1: res = "1 an "; break;
            case  diff.years > 1: res = diff.years + " ani "; break;
        }
        return res
    }
    const luni = (diff) => {
        let res = ""
        switch(true){
            case diff.months === 0: res = ""; break;
            case diff.months === 1: res = "1 lună "; break;
            case  diff.months > 1: res = diff.months + " luni "; break;
        }
        return res
    }
    const zile = (diff) => {
        let res = ""
        switch(true){
            case diff.days === 0: res = ""; break;
            case diff.days === 1: res = "1 zi "; break;
            case  diff.days > 1: res = diff.days + " zile"; break;
        }
        return res
    }
    let expirat = differenceInHours(end, now)
    return expirat > 0 
    ? ani(diff) +  luni(diff) + zile(diff)
    : "expirat"
}

export default function Preview(props) {

    const [registru, setRegistru] = useState()
    const [item, setItem] = useState()
    const [ anchorEl, setAnchorEl ] = useState(null)
    const [ rowAnchorEl, setRowAnchorEl ] = useState(null)
    const [curIndex, setCurIndex] = useState()
    const [curItem, setCurItem] = useState()
    const [ confirm, setConfirm ] = useState()
    const [ filtersVisible, setFiltersVisible ] = useState(false)
    const [state] = useContext(Context)
    const client = useApolloClient()
    const [nextNr, setNextNr] = useState()
    const next = useQuery( NEXT_ARENDA_DOSAR_NUMBER )
    const [rmRegistru] = useMutation(RM_REGISTRU)


    const nxData = next.data

    const { data, loading, error, refetch } = useQuery(REG, {
        variables: {
            data: {
                chunk: {
                    // skip: 0, take: 20
                },
                ordering: [{key: "dt", value: "desc"}, { key: "nr", value: "desc" }]
            },
            

        },
        fetchPolicy: "cache-and-network"
    })

    useEffect(() => {
        refetch()
        next.refetch()
    }, [props.location.key])

    useEffect(() => {
        if ( nxData ) { setNextNr(nxData.nextArendaDosarNumber)}
    }, [nxData])



    useEffect(() => {
        if (data && data.registruArenda) { 
            client.writeQuery({
                query: REG,
                data: data
            })
            setRegistru(data.registruArenda.map( el => ({
            ...el,
            human: {
                fix: fixDate(el.dt),
                dt:moment(el.dt).format('DD.MM.YYYY'),
                from: el.from 
                    ? moment(el.from).format('DD.MM.YYYY') 
                    : "---",
                to: el.to 
                    ? moment(el.to).format('DD.MM.YYYY') 
                    : "---",
                diff: el.tip === "contract" ? diffDate(el.to): "---",
                // diff: moment(el.to).diff(moment(el.from), "days"),
                arendator: !el.parentId
                    ? el.arendaArendator.reduce( (acc,item, index) => {
                        if ( item.contact ) { 
                            acc += item.contact.name
                            acc += index < el.arendaArendator.length-1 ? ", " :" " 
                        }
                        return acc
                    }, "")
                    : el.parent ? el.parent.arendaArendator.reduce( (acc,item, index) => {
                        if ( item.contact ) { 
                            acc += item.contact.name
                            acc += index < el.parent.arendaArendator.length-1 ? ", " :" " 
                        }
                        return acc
                    }, "") 
                    :  "",
                arendas: !el.parentId
                    ? el.arendaArendas.reduce( (acc,item, index) => {
                        if ( item.contact ) { 
                            acc += item.contact.name
                            acc += index < el.arendaArendas.length-1 ? ", " :" " 
                        }
                        return acc
                    }, "")
                    : el.parent ? el.parent.arendaArendas.reduce( (acc,item, index) => {
                        if ( item.contact ) { 
                            acc += item.contact.name
                            acc += index < el.parent.arendaArendas.length-1 ? ", " :" " 
                        }
                        return acc
                    }, "") 
                    :  "",

                company: el.company && el.company.name ? el.company.name: ""
            }
        }))) 
        }
    }, [data])

    let cols = [
        { title: "Nr.", field: "nr", width: "7%", align:"center" },
        { title: "Data", field: "human.dt", width: "10%", align:"center"},
        { title: "Tip document", field: "tip", width: "15%", align:"center"},
        { title: "Valabil de la ", field: "human.from", width:"10%", align:"center" },
        { title: "Până la ", field: "human.to", width:"10%" , align:"center"},
        { title: "Per.rămasă", field: "human.diff", width:"10%" },
        { title: "ARENDAȘI", field:"human.arendas", width: "20%" },
        { title: "ARENDATORI",field:"human.arendator", width: "20%",
        },
    ]
console.log("state role: ", state.role)
    if (state.role === "app") { cols = [...cols, {title: "COMPANY", field: "human.company", Filter: SelectColumnFilter, filter: "equals", width:"20%"}]}

    const COLUMNS = React.useMemo(() => cols, [])

    const addContractHandler = () => {     
        setItem({
            nr: nextNr,
            tip: "contract"
        })
        setAnchorEl();
}

    const addConexHandler = () => {
        setItem({  
            nr: nextNr, 
            tip: "conex",
        })
        setAnchorEl();
    }

    const addModalCloseHandler = ev => {
        setItem()
        if ( ev && ev.id ) {
            props.history.push(`/registru-arendare/detalii/${ev.id}`)
        }
         
    }

    const rowHandler = (ev, row) => props.history.push(`/registru-arendare/detalii/${row.id}`)
    const contextHandler = (ev,row) => {
        setCurItem(row)
        setCurIndex(row.tableData.id)
        setRowAnchorEl(ev.currentTarget)
    }
    const closeRowContextHandler = () => {
        setRowAnchorEl()
    }

    const removeConfirmOpen = () => {
        setRowAnchorEl(null)
        setConfirm(true)
    }
    const removeConfirmOnClose = ev => {
        if (ev) {
            let reg = [...registru]
            rmRegistru({variables: {id: +curItem.id}}).then(
                r => {
                    reg.splice(curIndex,1)
                    let cached = client.readQuery({query: REG})
                    cached = cached.registruArenda.map(e => ({...e}))
                    cached = cached.reduce( (acc,el) => {
                        if (parseInt(el.id) !== parseInt(curItem.id)) { acc = [...acc, el]}
                        return acc
                    }, [])

                    
                    client.writeQuery({
                        query: REG,
                        data: cached
                    })
                    refetch()
                    setRegistru(reg)
                },
                err => { console.log("err rmReg", err)}
            )

        }
        setCurItem();setCurIndex();setConfirm()
    }
    const closeFilterModalHandler = ev => {
        if ( ev ) {console.log(ev)}
        setFiltersVisible()
    }

    return (
        <Container
            title={<Grid container alignItems = "center"> &nbsp;&nbsp;&nbsp;REGISTRU ARENDARE

            </Grid>}
            action = {
                <Fragment>
                    <Button 
                        variant = "outlined" 
                        color = "primary" 
                        size = "small" 
                        onClick = {()=>setFiltersVisible(true)}>
                        <FcFilledFilter size = "1rem"/>
                        <small>&nbsp;&nbsp;FILTREAZĂ</small>
                    </Button> 
                    &nbsp;
                    <Button 
                        variant = "outlined" 
                        color = "primary" 
                        size = "small" 
                        onClick = {ev => setAnchorEl(ev.currentTarget)} >
                        <FcAddDatabase size="1rem"/>  
                        <small>&nbsp;&nbsp;ADAUGĂ</small>
                    </Button> 
                    <Menu open = {Boolean(anchorEl)} anchorEl = {anchorEl} onClose = {()=>setAnchorEl(null)}>
                        <MenuItem onClick = {addContractHandler}><FcSurvey size = "1.4rem"/> &nbsp;  CONTRACT</MenuItem>
                        <MenuItem onClick ={addConexHandler}> <FcTreeStructure size = "1.4rem"/>&nbsp; DOCUMENT CONEX</MenuItem>
                    </Menu>&nbsp;
                </Fragment>
            }
        >
            <div style = {{height: "82vh", overflow:"auto"}}>
         
            {registru && (
                <MaterialTableInstance
                    columns={COLUMNS}
                    data={registru}
                    title="CONTRACTE ARENDARE"
                    options={{
                        filtering: true,
                        selection: false,
                        // maxBodyHeight: "68vh",
                        headerStyle: {
                            padding: "5px",
                            // border: "1px solid black",
                            lineHeight: 1.4,
                            // minHeight: "30px",
                            backgroundColor: 'aliceblue',
                            color: 'navy',
                            fontSize: "0.8rem"
                        },
                        cellStyle: { padding: 0, fontSize: "0.8rem", color: "navy" },
                        rowStyle: {
                            // height: "40px"
                        },
                        pageSize: 8,
                        pageSizeOptions: [5,8, 9,10,12,20,50,100,1000],
                        grouping:true
                        
                    }}
                    onRowClick={rowHandler}
                    actions={[
                        {
                          tooltip: 'ACȚIUNI ',
                          icon: ()=><FaDotCircle color = "grey" size ="0.8rem"/>,
                          onClick: contextHandler
                        }
                    ]}
                />
            )}
            </div>
            { item && item.tip === "contract" && <NewContract data = {item}  onClose = {addModalCloseHandler}/>}
            { item && item.tip !== "contract" && <NewConex data = {item}  onClose = {addModalCloseHandler}/>}
            <Menu open = {Boolean(rowAnchorEl)} anchorEl = {rowAnchorEl} onClose = {closeRowContextHandler}>
                <MenuItem onClick ={removeConfirmOpen}><FaTimesCircle color="red" /> &nbsp;&nbsp; șterge</MenuItem>
            </Menu>
           <Confirm 
                open = {confirm} 
                onClose = {removeConfirmOnClose} 
                message = {curItem && curItem.tip === "contract" && <div style = {{lineHeight:0.8}}>
                    <div style = {{color:"red", fontWeight: 800}}>ATENȚIE !!</div><br/>
                    <div>se vor șterge INCLUSIV toate documentele izvorâte din acest contract!!!</div>
                </div>}
            />
            <Filters data = {filtersVisible} onClose = {closeFilterModalHandler} />
        </Container>
    )
}
