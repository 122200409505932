import React, {Fragment, useState, useEffect} from 'react'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import UAT from '../../Common/UAT'
import Field from '../../Common/Field'
import PF_PJ from '../../Common/PF_PJ'

const useStyles = makeStyles( theme => ({
    noprint: {
        "@media print": {display: "none"},
        "@media screen": {zoom: 1.1}
    }
}))

export default function PvAfisare1FPrintTpl(props) {
    const classes = useStyles()
    const [item , setItem] = useState()
    const [dosar, setDosar] = useState()

    useEffect(()=>{
        if ( props.data && props.data.item ) {
            setItem(props.data.item)
            setDosar(props.data.dosar)
        }
    },[props])

    const nrHandler = ev => props.onChange({...item, pv: {...item.pv, nr: ev.target.value }})
    const dtHandler = ev => props.onChange({...item, pv: {...item.pv, dt: new Date(ev.target.value) }})

    return (
        <Fragment>
          
            {item && (
                <Fragment>
                   <hr/>
                    <Grid container alignItems="center" spacing={2} className = {classes.noprint}>
                        <Grid item xs >PROCES-VERBAL DE AFIȘARE </Grid>
                        <Grid item xs = {3} className = {classes.noprint}>
                            <FunkyInput value = {item && item.pv && item.pv.nr} label ="NR." onChange = {nrHandler}/>
                        </Grid>
                        <Grid item xs = {4}>
                            <FunkyInput type = "date" value = {item && item.pv && moment(item.pv.dt).format('YYYY-MM-DD')} label ="DIN DATA"
                                onChange = {dtHandler}
                            />
                        </Grid>
                    </Grid>
                    <hr/>
                    <Grid container>
                        <Grid item xs = {12}>
                            <ANTET_INSTITUTIE data = {dosar.company}/>
                            <div align = "right">
                                Nr.înreg. {item && item.pv && item.pv.nr} / {item && item.pv &&  moment(item.pv.dt).format("DD-MM-YYYY")}
                            </div>
                            <br/><br/>
                            {item && item.pv && (
                                <Fragment>
 <div align="center">
                                <Typography variant = "h6">
                                    PROCES-VERBAL <br/>
                                    de afișare a unei oferte de cumpărare<br/>
                                    pentru oferta de vânzare teren agricol extravilan <br/>
                                    nr. {dosar.nr} din {moment(dosar.dt).format('DD.MM.YYYY')}
                                </Typography>
                            </div>
                            <br/>
                            <div>
                                Subscrisa, <UAT data = {dosar.company} />, 
                                am procedat astăzi, <Field>{moment(item.pv.dt).format('DD.MM.YYYY')}</Field>,
                                la afișarea la avizierul instituției a ofertei de cumpărare 
                                depusă la sediul nostru și înregistrată sub nr. 
                                <Field>{item.pv.nr} / {moment(item.pv.dt).format('DD.MM.YYYY')} </Field> de către {' '}
                                
                                <Field>{item.ofertant[0].titular.name}</Field>, {' '}
                                potențial cumpărător ce îndeplinește condițiile condițiile art 4(alin.3)
                            </div>
                                </Fragment>
                            )}
                           
                        </Grid>
                        <Grid item xs = {12} style ={{paddingLeft: "50%"}}>
                                        <br/>
                                        Întocmit, <br/>
                                        <Field>{ dosar.user.name}</Field>
                                        <br/><br/>
                                        .......................................................<br/>
                                        <small>(numele şi prenumele/semnătura)</small> <br/><br/><br/>
                                        L.S. 
                                       
                        </Grid>  
                    </Grid>
                           
                </Fragment>
            )}

            
           
        </Fragment>
    )
}
