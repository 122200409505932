import { Grid } from '@material-ui/core'
import React from 'react'
import { getSetting } from '../../../lib/settings'

export default function SemnaturiPrimarSecretarConsilier({data}) {
  return (
    <Grid container justifyContent="center">
        <Grid item xs = {12} align="center">
            Primar,
            <br/>
            <strong>{getSetting(data.settings, "primar") }</strong>
            <br/>
            <small>(numele şi prenumele în clar)</small>
            <br/>
            Semnătura <br/><br/>
            .......................
            <br/>
            L.S.
        </Grid>
        <Grid item xs = {6} align="center">
            {getSetting(data.settings, "secretar", "desc") || 'Secretar general'} 
            <br/>
            <strong>
            {getSetting(data.settings, "secretar") }
            </strong>
            <br/>
            <small>(numele şi prenumele în clar)</small>
            <br/>
            Semnătura <br/><br/>
            .......................
            <br/>                       
        </Grid>  
        <Grid item xs = {6} align="center">
            {getSetting(data.settings, "operator", "desc") || "Consilier"},
            <br/>
            <strong>
            {getSetting(data.settings, "operator") || '..........................'} 
            </strong>
            <br/>
            <small>(numele şi prenumele în clar)</small>
            <br/>
            Semnătura <br/><br/>
            .......................
            <br/>                       
        </Grid>  
    </Grid>
  )   
}
