import { Container, ListItem, Typography } from '@material-ui/core'
import moment from 'moment'
import React, { useContext, Fragment } from 'react'
import { Context } from '../../components/Store'
import Field from '../Dosar/Common/Field'

const Paragraph = ({ children }) => (<Typography variant="body2" component="p"
  style={{ textAlign: "justify", textJustify: "inter-word" }}
>
  {children}
</Typography>)



const Vanzatori = ({ data }) => data.length > 0
  ? <ol style={{ paddingLeft: "1em", margin: 0 }}>
    {data.map((e, i) => (
      <li key={i}>
        <Field>{e.titular.name}</Field>,{' '}
        CNP/CIF/CUI <Field>{e.titular.cui || e.titular.cnp}</Field>
        având domiciliul/reşedinţa/sediul social/secundar în {' '}
        <Field>{e.titular.domLoc || e.titular.pjLoc}</Field>,{' '}
        str.<Field>{e.titular.domStr || e.titular.pjStr}</Field>,{' '}
        nr.<Field>{e.titular.domNr || e.titular.pjNr}</Field>,{' '}
        nr.<Field>{e.titular.domNr || e.titular.pjNr}</Field>,{' '}
        sc. <Field>{e.titular.domSc || e.titular.pjSc}</Field>,{' '}
        ap. <Field>{e.titular.domAp || e.titular.pjAp}</Field>,{' '}
        judeţul/sectorul <Field>{e.titular.domJud || e.titular.pjJud}</Field>,{' '}
        telefon <Field>{e.titular.domTel || e.titular.pjTel}</Field>,{' '}
        act de identitate <Field>{e.titular.ciTip}</Field>,{' '}
        seria <Field>{e.titular.ciSeria}</Field>,{' '}
        nr.<Field>{e.titular.ciNr}</Field>,{' '}
        eliberat de <Field>{e.titular.ciElibDe}</Field>,{' '}
        la data <Field>{e.titular.ciElib && moment(e.titular.ciElib).format("DD.MM.YYYY")}</Field>,{' '}
        cetăţenia  <Field>{e.titular.cetatenie}</Field>,{' '}
        naţionalitatea <Field>{e.titular.nationalitate}</Field>,{' '}
        /certificat de înregistrare în registrul comerţului <Field>{e.titular.pjRegNr}</Field>,{' '}
        eliberat de către Oficiul Registrului Comerţului de pe lângă Tribunalul <Field>{e.titular.pjJud}</Field>,{' '}
      </li>
    ))}
  </ol>
  : (<Fragment>
    <Field>{data.titular.name}</Field>,{' '}
    CNP/CIF/CUI <Field>{data.titular.cui || data.titular.cnp}</Field>
    având domiciliul/reşedinţa/sediul social/secundar în {' '}
    <Field>{data[0].titular.domLoc || data[0].titular.pjLoc}</Field>,{' '}
    str.<Field>{data[0].titular.domStr || data[0].titular.pjStr}</Field>,{' '}
    nr.<Field>{data[0].titular.domNr || data[0].titular.pjNr}</Field>,{' '}
    nr.<Field>{data[0].titular.domNr || data[0].titular.pjNr}</Field>,{' '}
    sc. <Field>{data[0].titular.domSc || data[0].titular.pjSc}</Field>,{' '}
    ap. <Field>{data[0].titular.domAp || data[0].titular.pjAp}</Field>,{' '}
    judeţul/sectorul <Field>{data[0].titular.domJud || data[0].titular.pjJud}</Field>,{' '}
    telefon <Field>{data[0].titular.domTel || data[0].titular.pjTel}</Field>,{' '}
    act de identitate <Field>{data[0].titular.ciTip}</Field>,{' '}
    seria <Field>{data[0].titular.ciSeria}</Field>,{' '}
    nr.<Field>{data[0].titular.ciNr}</Field>,{' '}
    eliberat de <Field>{data[0].titular.ciElibDe}</Field>,{' '}
    la data <Field>{data[0].titular.ciElib && moment(data[0].titular.ciElib).format("DD.MM.YYYY")}</Field>,{' '}
    cetăţenia  <Field>{data[0].titular.cetatenie}</Field>,{' '}
    naţionalitatea <Field>{data[0].titular.nationalitate}</Field>,{' '}
    /certificat de înregistrare în registrul comerţului <Field>{data[0].titular.pjRegNr}</Field>,{' '}
    eliberat de către Oficiul Registrului Comerţului de pe lângă Tribunalul <Field>{data[0].titular.pjJud}</Field>,{' '}
  </Fragment>)





export default function TplAvizEtapa30({ data }) {

  const [ctx] = useContext(Context)
  return (
    <Fragment>
      {data && (
        <Container>
          <Typography variant="body2">
            <strong>MINISTERUL AGRICULTURII ŞI DEZVOLTĂRII RURALE<br />
              {ctx.company.name}
            </strong>
          </Typography>
          <br /><br />
          <div align="center">
            <Typography varinat="body2">
              <strong>AVIZ</strong>
              <br />
              <strong>nr. {data.nr} / {moment(data.dt).format("DD.MM.YYYY")}</strong>
            </Typography>
          </div>
          <br />
          <Paragraph>
            În temeiul art. 9 alin. (1) din Legea nr. 17/2014 privind unele măsuri de reglementare a vânzării terenurilor agricole situate
            în extravilan şi de modificare a Legii nr. 268/2001 privind privatizarea societăţilor ce deţin în administrare terenuri
            proprietate publică şi privată a statului cu destinaţie agricolă şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările
            şi completările ulterioare, având în vedere oferta de vânzare depusă de{' '}

            <Vanzatori data={data.vanzator} />
          </Paragraph>
          <Paragraph>
            ca urmare a verificării modului de respectare a procedurii prevăzute de lege privind îndeplinirea condiţiilor
            de către potenţialul cumpărător, se emite
          </Paragraph>
          <div align="center">
            <Typography variant="body1" component="p"><strong>AVIZ</strong></Typography>
          </div>
          <Paragraph>
            în vederea încheierii contractului de vânzare-cumpărare în formă autentică de către notarul public sau pronunţării
            de către instanţă a unei hotărâri judecătoreşti care ţine locul contractului de vânzare, pentru {' '}
            numitul/numita/subscrisa{' '}
            <Field>{data.cumparator.name}</Field>,{' '}
            {/* în calitate de preemptor <Field>{data.rang.replace("preemptori", "")}</Field>,{' '} */}
            CNP/CIF/CUI  <Field>{data.cumparator.cui || data.cumparator.cnp}</Field>,{' '}
            având domiciliul/reşedinţa/sediul social/secundar în {' '}
            <Field>{data.cumparator.domLoc || data.cumparator.pjLoc}</Field>,{' '}
            str.<Field>{data.cumparator.domStr || data.cumparator.pjStr}</Field>,{' '}
            nr.<Field>{data.cumparator.domNr || data.cumparator.pjNr}</Field>,{' '}
            nr.<Field>{data.cumparator.domNr || data.cumparator.pjNr}</Field>,{' '}
            sc. <Field>{data.cumparator.domSc || data.cumparator.pjSc}</Field>,{' '}
            ap. <Field>{data.cumparator.domAp || data.cumparator.pjAp}</Field>,{' '}
            judeţul/sectorul <Field>{data.cumparator.domJud || data.cumparator.pjJud}</Field>,{' '}
            telefon <Field>{data.cumparator.domTel || data.cumparator.pjTel}</Field>,{' '}
            act de identitate <Field>{data.cumparator.ciTip}</Field>,{' '}
            seria <Field>{data.cumparator.ciSeria}</Field>,{' '}
            nr.<Field>{data.cumparator.ciNr}</Field>,{' '}
            eliberat de <Field>{data.cumparator.ciElibDe}</Field>,{' '}
            la data <Field>{data.cumparator.ciElib && moment(data.cumparator.ciElib).format("DD.MM.YYYY")}</Field>,{' '}
            cetăţenia  <Field>{data.cumparator.cetatenie}</Field>,{' '}
            naţionalitatea <Field>{data.cumparator.nationalitate}</Field>,{' '}
            /certificat de înregistrare în registrul comerţului <Field>{data.cumparator.pjRegNr}</Field>,{' '}
            eliberat de către Oficiul Registrului Comerţului de pe lângă Tribunalul <Field>{data.cumparator.pjJud}</Field>,{' '}
            , ales de vânzător pentru cumpărarea terenului agricol situat în extravilanul localităţii {' '}
            <Field>{data.originalItem.anexe.terenLoc}</Field>{' '}
            categoria de folosinţă <Field>{data.originalItem.anexe.terenCategorie}</Field>,{' '}
            tarlaua <Field>{data.originalItem.anexe.terenTarla}</Field>,{' '}
            parcela <Field>{data.originalItem.anexe.terenParcela}</Field>,{' '}
            judeţul <Field>{data.originalItem.anexe.terenJud}</Field>,{' '}
            identificat cu număr cadastral <Field>{data.originalItem.anexe.terenNrCadastral}</Field>,{' '}
            înscris în cartea funciară nr.<Field>{data.originalItem.anexe.terenCf}</Field>,{' '}
            a unităţii administrativ-teritoriale <Field>{data.originalItem.anexe.terenCfLoc}</Field>,{' '}
            în următoarele condiţii:
            <br />
            - suprafaţa de teren agricol care face obiectul vânzării <Field>{data.originalItem.anexe.terenHa}</Field> hectare,{' '}
            reprezentând cota-parte de <Field>{data.originalItem.anexe.cotaParte}</Field>;<br />
            - preţul de vânzare <Field>{data.originalItem.anexe.terenPret}</Field> lei.<br />
          </Paragraph>
          <br /><br /><br />

          <div style={{ paddingLeft: "30%", textAlign: "left", position: "relative" }}>
            Director/Director executiv,
            <br /><br />
            . . . . . . . . . .<br />
            (numele şi prenumele)<br /><br />
            . . . . . . . . . .<br />
            (semnătura)
          </div>
        </Container>
      )}
    </Fragment>
  )
}