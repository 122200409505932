import React, { useState, useEffect, Fragment } from 'react'
import { Alert, Autocomplete } from '@material-ui/lab'
import { CONTACTS } from '../../../apollo/query'
import { useQuery } from "@apollo/client"
import { useDebounce } from '../../../lib/reactHooks'
import FunkyInput from '../../../components/FunkyInput'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    autoc: {
        fontSize: "0.7rem", fontWeight:600, color: theme.palette.primary.dark
    }
}))

export default function AUTOCOMPLETE_CONTACT(props) {
    const classes = useStyles()
    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState()
    const [searchString, setSearchString] = useState()
    const debounced = useDebounce(searchString, 300)

    const {data, loading, error } = useQuery(CONTACTS, {
        variables:{
            data: {
                lookup: {
                    key: "name", 
                    value: debounced, 
                    op: "contains"
                }
            }
        }
    })
    const hideDetails = typeof props.hideDetails !== "undefined" ? true : false

    useEffect(() => {
        if (props.data) {
            setCustomers([props.data])
            setCustomer(props.data)
            if ( props.src) {setSearchString(props.src)}
            // lookup({ variables: { key: "name", value: props.data.name, op:"contains" } }) 
        } 
    }, [props, data])



    useEffect(() => {
        if (data && data.contacts) setCustomers(data.contacts)
    }, [data])


  

    const changeHandler = (ev, val, reason) => {
        if (reason === "clear") {
            setCustomer({})
            props.onChange({})
        }
        if (val && reason === "select-option") {

            setCustomer(val)
            // console.log("val changed:", val)
            props.onChange(val)
        }
    }

    return (
        <Fragment>
           
                <Fragment>
                    <Autocomplete
                        size = "small"
                        options={customer ? customers : []}
                        classes = {{"input":classes.autoc}}
                        getOptionLabel={option => option.name || ""}
                        value={customer ? customer : ""}
                        renderInput={
                            params => <FunkyInput {...params} label={props.label} 
                            placeholder = {props.placeholder || ""}
                            variant="outlined" size="small" fullWidth />
                        }
                        noOptionsText="nu există"
                        onInputChange={(__, value) => setSearchString(value || "xxxxxxx")}
                        onChange={changeHandler}
                    />
                </Fragment>
          
        </Fragment>



    )
}