import { IconButton, makeStyles } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { FaPlusCircle, FaTimes } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import moment from "moment"
import { Alert } from '@material-ui/lab';
import CarnetModal from './CarnetModal';


const useStyles = makeStyles(theme => ({
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "zoom-in",
            border: "2px solid navy",
        },
    }
}))

export default function ListaCarnete({ data, onChange }) {
    const classes = useStyles()
    const item = data || {}
    const [carnetModal, setCarnetModal] = useState(null)


    const newCarnetHadler = () => {
        setCarnetModal({
            index: null,
            item: {
                seria: "",
                nr: 1,
                dt: new Date(),
                dtElib: new Date(),
                filaStart: 1, filaEnd: 40,
                gospodari: [],
                produse: []
            }
        })
    }

    const carnetModalCloseHandler = ev => {
        if (ev) {
            let ni = {
                ...item,
                atProcesVerbal: {
                    ...item.atProcesVerbal,
                    atCarnete: item.atProcesVerbal.atCarnete.map(e => ({ ...e }))
                }
            }
            if (carnetModal.index || carnetModal.index === 0) {
                ni.atProcesVerbal.atCarnete[carnetModal.index] = ev
            } else {
                ni.atProcesVerbal.atCarnete = [
                    ...ni.atProcesVerbal.atCarnete,
                    ev
                ]
            }
            onChange(ni)
        }
        setCarnetModal(null)
    }
    const removeCarnetHandler = index => () => {
        let ni = {
            ...item,
            atProcesVerbal: {
                ...item.atProcesVerbal,
                atCarnete: item.atProcesVerbal.atCarnete.map(e => ({ ...e }))
            }
        }
        ni.atProcesVerbal.atCarnete.splice(index, 1)
        onChange(ni)
    }


    return (
        <Fragment>
            {item && (
                <Fragment>
                    <div align="right">
                        <IconButton size="small" color="primary" onClick={newCarnetHadler}
                            disabled = {!item.atProcesVerbal}
                        >
                            <FaPlusCircle />
                        </IconButton>
                    </div>
                    {item.atProcesVerbal
                        ? (
                            <table className={classes.table} width="100%">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Serie/nr/data</th>

                                        <th>Eliberat la</th>
                                        <th>Predat la</th>
                                        <th>Produse</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        item.atProcesVerbal
                                        && item.atProcesVerbal.atCarnete
                                        && item.atProcesVerbal.atCarnete.map((e, i) => (

                                            <tr
                                                key={i}
                                            >
                                                <td>{i + 1}</td>
                                                <td align="center">{e.seria} {e.nr} /  {moment(e.dt).format("DD.MM.YYYY")}</td>

                                                <td align="center">{moment(e.dtElib).format("DD.MM.YYYY")}</td>
                                                <td align="center">{
                                                    e.dtPredare
                                                        ? moment(e.dtPredare).format("DD.MM.YYYY")
                                                        : ""}
                                                </td>
                                                <td align="center">{e.produse.length}</td>
                                                <td align="right" style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <IconButton color="secondary" size="small"
                                                        onClick={removeCarnetHandler(i)}
                                                    >
                                                        <FaTimes />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary" size="small"
                                                        onClick={() => setCarnetModal({ index: i, item: e })}
                                                    >
                                                        <MdEdit />
                                                    </IconButton>

                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        ) : (<Alert severity="warning">  PENTRU A PUTEA ADĂUGA UN CARNET TREBUIE CA,
                            ÎN PREALABIL,
                            SĂ FIE ÎNTOCMIT P.V. EMITERE A ATESTATULUI ȘI IMPLICIT ATESTATUL DE
                            PRODUCĂTOR </Alert>)
                    }
                </Fragment>
            )}
            
            {carnetModal && <CarnetModal 
                data={carnetModal.item} 
                context = {{
                        cerere:data, 
                        pv: data.atProcesVerbal
                    }}
                onClose={carnetModalCloseHandler} />
            }
            {/* {item && <pre>{JSON.stringify(item,null,4)}</pre>} */}
        </Fragment>
    )
}

