import React, { Fragment } from 'react'
import moment from 'moment'

const Field = props => <strong>{ (props.children )|| ' - - - - -'}</strong>


export default function CONTACT(props) {
    let item = props.item || {}
    let mandat = props.mandat || null
    
    return (
        <div>
            {item && (item.cnp || !item.cui) && (
                <Fragment>
                    {!props.noPrefix && (<span>1. (*) Subsemnatul/Subsemnata,</span>)} {' '}
                    <Field>{item.name}</Field>
                        , CNP <Field>{item.cnp}</Field>,{' '}
                        
                        {(!mandat && props.acceptare) && <Fragment>în calitate de 
                            {props && props.acceptare && props.acceptare !== "ofertant" && (props && props.acceptare  ? "preemptor" : " proprietar")}
                            {props && props.acceptare && props.acceptare === "ofertant" && " potențial cumpărător"}
                            {props && props.acceptare && props.acceptare.rang && props.acceptare.rang.name
                                && props.acceptare.rang.name.replace("preemptori", "")
                            },
                            </Fragment>}{' '}
                        {' '},identificat/identificată cu <Field>{item.ciTip}</Field>,
                        seria <Field>{item.ciSeria}</Field>, nr. <Field>{item.ciNr}</Field>,
                        eliberat(ă) de <Field>{item.ciElibDe}</Field>,
                        la <Field>{item.ciElib && moment(item.ciElib).format('DD.MM.YYYY')}</Field>,
                        data nașterii <Field>{item.dNastere && moment(item.dNastere).format('DD.MM.YYYY') }</Field>,
                        locul nașterii <Field>{item.locNastere}</Field>,
                         judeţul/ţara <Field>{item.judNastere}</Field>
                    /<Field>{item.taraNastere}</Field>

                    <br />
                        2. (**) cu domiciliul în: localitatea <Field>{item.domLoc}</Field>,
                         str. <Field>{item.domStr}</Field>,  nr. <Field>{item.domNr}</Field>,
                        bl. <Field>{item.domBl}</Field>,  sc. <Field>{item.domSc}</Field>,
                        et. <Field>{item.domEt}</Field>,  ap. <Field>{item.domAp}</Field>,
                        judeţul/sectorul <Field>{item.domJud}</Field>,
                        codul poştal <Field>{item.domCod}</Field>,  ţara <Field>{item.domTara}</Field>,
                        telefon ..........<Field>{item.domTel}</Field>,
                        fax <Field>{item.domFax}</Field>,
                        e-mail <Field>{item.domEmail}</Field>,
                        cetăţenia <Field>{item.cetatenie}</Field>,  starea civilă <Field>{item.stCiv}</Field>,
                    <br />
                        3. (**) reşedinţa în România (dacă este cazul):
                        localitatea  <Field>{item.rsdLoc}</Field>, str. <Field>{item.rsdStr}</Field>,  
                        nr. <Field>{item.rsdNr}</Field>, bl. <Field>{item.rsdBl}</Field>,  
                        sc. <Field>{item.rsdSc}</Field>, et. <Field>{item.rsdEt}</Field>,
                         ap. <Field>{item.rsdAp}</Field>, judeţul/sectorul <Field>{item.rsdJud}</Field>, 
                         codul poştal <Field>{item.rsdCod}</Field>, telefon <Field>{item.rsdTel}</Field>,
                        fax <Field>{item.rsdFax}</Field>, e-mail <Field>{item.rsdEmail}</Field><br />

                </Fragment>
            )}
            {item && item.cui && (
                <Fragment>
                    1. (*) Subsemnatul/Subsemnata, <Field>{item.name}</Field>,  
                    domiciliat/domiciliată în <Field>{item.domLoc}</Field>,   
                    str. <Field>{item.domStr}</Field>, nr. <Field>{item.domNr}</Field>,   
                    bl. <Field>{item.domBl}</Field>,  sc. <Field>{item.domSc}</Field>,  
                    ap. <Field>{item.domAp}</Field>,  judeţul/sectorul <Field>{item.domJud}</Field>,  
                    telefon <Field>{item.domTel}</Field>,  
                    posesor/posesoare act identitate <Field>{item.ciTip}</Field>,  
                    seria <Field>{item.ciSeria}</Field>,  nr. <Field>{item.ciNr}</Field>,  
                    eliberat de <Field>{item.ciElibDe}</Field>,  la data <Field>{item.ciElib}</Field>,  
                    CNP/CIF/CUI <Field>{item.cui}</Field>,  
                   

                    

                  
                </Fragment>)}

                    {mandat && (
                        <Fragment>
                        
                        în calitate de^(1) <Field>{mandat.calitate}</Field>, 
                        prin <Field>{mandat.prin}</Field>, 
                        conform <Field>{mandat.conform}</Field>, 
                        </Fragment>
                    )}
        </div>
    )
}
