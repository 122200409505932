import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CARNETE } from '../../../../apollo/queryAtestate';
import ANTET_INSTITUTIE from '../../../Dosar/Common/ANTET_INSTITUTIE';
import { COMPANY } from '../../../../apollo/query';

import moment from 'moment';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { MdPrint } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import Filters from './Filters';
import { useHistory } from 'react-router';
import { deepClearTypeName } from "../../../../lib/graph-stuff"


const useStyles = makeStyles(theme => ({
  justify: {
    textAlign: "justify",
    textJustify: "inter-word"
  },
  screenOnly: { "@media print": { display: "none" } },
  printOnly: { "@media screen": { display: "none" } },
  table: {
    '&& thead>tr>th, tfoot>tr>td': {
      border: "1px solid lightgrey",
      fontSize: "0.7rem",
      background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
    },
    '&& tbody>tr>td, tfoot>tr>td,  tbody>tr>td>tr,  tbody>tr>td>tr>td': {
      border: "1px solid lightgrey",
      textAlign: "center",
      fontSize: "0.7rem",
      padding: theme.spacing(0.5)
    },

  },

}))

export default function RegCarnete({ match }) {
  const classes = useStyles()
  const company = useQuery(COMPANY)
  const [items, setItems] = useState([])
  const printRef = useRef()
  const history = useHistory()
  let {query} = match.params || {}

  const [queryParams, setQueryParams] = useState({
    between: {
      col: "dtElib",
      start: moment().startOf("year").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD")
    },
    ordering: [
      { key: "nr", value: "desc" }
    ]
  })

  const [getData, dispatchData] = useLazyQuery(CARNETE)


  useEffect(() => {
    // let { query } = match.params
    if (typeof query !== 'undefined') {
      query = decodeURIComponent(query)
      query = JSON.parse(query)
      setQueryParams(query)
    } else {
      query = queryParams
    }
    getData({ variables: { data: queryParams } })
    
  }, [match.params])


  useEffect(() => {
    if (dispatchData.data && dispatchData.data.atCarnete) {
      // console.log("disp:", dispatchData.data)
      let elems = dispatchData.data.atCarnete
      // console.log("elems:", elems)
      elems = elems.reduce((acc, elem) => {

        let newElem = {
          id: elem.id,
          contact: elem.contact || {},
          seria: elem.seria,
          nr: elem.nr,
          dt: moment(elem.dt).format("DD.MM.YYYY"),
          dtElib: elem.dt ? moment(elem.dt).format("DD.MM.YYYY") : "---",
          dtElib: elem.dtElib ? moment(elem.dtElib).format("DD.MM.YYYY") : "---",
          dtPredare: elem.dtPredare ? moment(elem.dtPredare).format("DD.MM.YYYY") : "---",
          produse: deepClearTypeName(elem.produse || []),
          atestatNr: elem.atProcesVerbal.atestatNr || "---",
          atestatSeria: elem.atProcesVerbal.atestatSeria || "---",
          atestatDt: elem.atProcesVerbal.atestatDt ? moment(elem.atProcesVerbal.atestatDt).format("DD.MM.YYYY") : "---"

        }
        acc = [...acc, newElem]

        return acc
      }, [])
      setItems(elems)
    }

  }, [dispatchData && dispatchData.data])

  const filtersApplyHandler = ev => {
    history.push("/registru-atestate/rapoarte/registru-carnete/" + encodeURIComponent(JSON.stringify(ev)))
    setQueryParams(ev)
  }

  const resetHandler = () => {
    const intialParams = {
      between: {
        col: "dtElib",
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD")
      },
      ordering: [
        { key: "nr", value: "desc" }
      ]
    }
    history.push("/registru-atestate/rapoarte/registru-carnete/" + encodeURIComponent(JSON.stringify(intialParams)))
    setQueryParams(intialParams)

  }

  const printHandler = useReactToPrint({ content: () => printRef.current })

  return <Fragment>
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item xs={11}>
        {items && queryParams && <Filters data={queryParams}
          onApply={filtersApplyHandler}
          onReset={resetHandler}
        />}
      </Grid>
      <Grid item xs={1} align="center">
        <IconButton onClick={printHandler}><MdPrint size="2.5em" color="grey" /></IconButton>
      </Grid>
    </Grid>
    <div className={classes.screenOnly}>
      <br /><br />
    </div>
    <Styles>
      <div ref={printRef}>
        <div className={classes.printOnly}>
          {company && company.data && company.data.company
            && <ANTET_INSTITUTIE data={company.data.company} />
          }
        </div>
        <div className={classes.printOnly}>
          <br /><br />
        </div>
        <div align="center">
          <Typography variant="body1">  Registrul carnetelor de comercializare a produselor agricole </Typography>
          <Typography variant="body1">  eliberate în perioada {' '}
            {moment(queryParams.between.start).format("DD.MM.YYYY")}  -
            {moment(queryParams.between.end).format("DD.MM.YYYY")}
          </Typography>
        </div>

        <div className={classes.printOnly}>
          <br />
        </div>
        <table className={classes.table} width="100%" cellPadding={0} cellSpacing={0}>
        <thead>
  <tr>
    <th rowSpan="3">Nrcrt</th>
    <th colSpan="6" rowSpan="2">Date carnet</th>
    <th colSpan="7">PRODUSE</th>
    <th colSpan="3" rowSpan="2">Atestat</th>
  </tr>
  <tr>
    <th rowSpan="2">VEGETALE</th>
    <th rowSpan="2">ANIMALE</th>
    <th rowSpan="2">HA</th>
    <th rowSpan="2">ARI</th>
    <th rowSpan="2">EFECTIVE</th>
    <th rowSpan="2">ESTIMAT</th>
    <th rowSpan="2">APROBAT</th>
  </tr>
  <tr>
    <th>Titular carnet</th>
    <th>Seria</th>
    <th>Nr</th>
    <th>Data</th>
    <th>Eliberat la</th>
    <th>Predat la</th>
    <th>Seria</th>
    <th>Nr</th>
    <th>Data</th>
  </tr>
</thead>
          <tbody>
            <tr>{Array.from(Array(17).keys()).map(e => <td align="center" key={e}><small>{e + 1}</small></td>)}</tr>
            {items.map((row, index) => (row.produse && row.produse.length > 0) 
            ? [...row.produse].map( (prod, iProd) =>(
              <tr key = {iProd} rowSpan={row.produse.length}>
                {iProd === 0  && (
                    <Fragment>
                <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{index + 1}</td>
                <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.contact.name}</td>
                <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.seria}</td>
                <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.nr}</td>
                <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.dt}</td>
                <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.dtElib}</td>
                <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.dtPredare}</td>
                    </Fragment>
                )}
                {/* {iProd !== 0 && <td colSpan={0} ></td>} */}
                <td>{prod.atProdus.tip_produs ? prod.atProdus.den_produs : "---"}</td>
                <td>{!prod.atProdus.tip_produs ? prod.atProdus.den_produs : "---"}</td>
                <td>{prod.ha}</td>
                <td>{prod.ari}</td>
                <td>{prod.efective}</td>
                <td>{prod.estimat}</td>
                <td>{prod.aprobat}</td>
                {iProd === 0 ? (
                  <Fragment>
                    <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.atestatSeria}</td>
                    <td rowSpan = {row.produse.length} style = {{verticalAlign: "top"}}>{row.atestatNr}</td>
                    <td rowSpan = {row.produse.length} align="left" style = {{verticalAlign: "top"}}>
                    {row.atestatDt}
                      {/* {<pre>{JSON.stringify(row, null,4)}</pre>} */}
                    </td>
                  </Fragment>
                ) : null}

                
      

              </tr>
            )
            ):<tr key = {index}>
                    <Fragment>
                <td  style = {{verticalAlign: "top"}}>{index + 1}</td>
                <td  style = {{verticalAlign: "top"}}>{row.contact.name}</td>
                <td  style = {{verticalAlign: "top"}}>{row.seria}</td>
                <td  style = {{verticalAlign: "top"}}>{row.nr}</td>
                <td style = {{verticalAlign: "top"}}>{row.dt}</td>
                <td  style = {{verticalAlign: "top"}}>{row.dtElib}</td>
                <td style = {{verticalAlign: "top"}}>{row.dtPredare}</td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
                <td  style = {{verticalAlign: "top"}}>{row.atestatSeria}</td>
                <td  style = {{verticalAlign: "top"}}>{row.atestatNr}</td>
                <td  align="left" style = {{verticalAlign: "top"}}></td>
                    </Fragment>
            </tr>)}

          </tbody>
        </table>

        {/* {queryParams && <small><pre>{JSON.stringify(queryParams, null, 4)}</pre></small>} */}
      </div>
    </Styles>
  </Fragment>;
}


export const Styles = styled.div`

@media print {
        @page { 
            size:  A4 landscape;
            margin: 10mm 10mm 10mm 30mm; 
        };
        div.printable{
            div {
                page-break-inside: avoid!important;
                break-inside: avoid!important;
            }
        }
}
`