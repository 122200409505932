import { gql } from '@apollo/client'


export const LOGIN = gql`query login($data:LoginInput) {
  login(data:$data) { id 
    name 
    username
    email
    token
    companyId
    acl 
    company {name loc jud}
    role
   
  }
}`



export const REGISTRU = gql`query registru($data: ComplexQuery) {
  registru(data:$data) {
    id nr dt tipDosar status
    # createdAt companyId 
    anexe
    company { 
      id name 
    # cui regcom 
      loc 
    # str nr bl sc et ap cod jud tara phone fax email logo obs 
    } 
    # settings { id prop value desc}
    ofertanti { 
        id 
      # tip titularId mandatarId registruId companyID calitate prin conform
        titular {id name}
        mandatar {id name}
    }
    # preemptori { id rangPreemptorId
    #   titular { id name}
    #   rang { name desc}
    # }

}
}`

export const COMPANY = gql`
query company {
  company { id name cui regcom loc str nr bl sc et ap cod jud tara phone fax email logo obs website
  settings { id prop value desc} }
}`

export const DOSAR = gql`
query dosar($id: ID) {
  dosar(id:$id) {
    id nr dt  tipDosar createdAt companyId userId status anexe
    company { id name cui regcom loc str nr bl sc et ap cod jud tara phone fax email logo obs website}id
    settings { id prop value desc}
    user {id name}
    ofertanti { id tip titularId mandatarId registruId companyID calitate prin conform
        titular { id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
        taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail 
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs }
        mandatar {    
          id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere taraNastere cetatenie stCiv domLoc
    domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax     domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs }
    }
    preemptori { id rangPreemptorId titularId mandatarId
      titular { id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere         
        taraNastere cetatenie stCiv domLoc domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail 
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs }
        mandatar {    
          id name cui cnp ciTip ciNr  ciSeria ciElib ciElibDe dNastere locNastere judNastere taraNastere cetatenie stCiv domLoc
    domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs }
      rang { name desc}
    }
    ranguri {id name desc}
   
  } 
}  `

export const CONTACTS = gql`
query contacts($data: ComplexQuery) {
  contacts(data:$data) {
    id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere  taraNastere cetatenie stCiv domLoc
    domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax  domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs 
  }
} `

export const COMPANIES = gql`query companies {
  companies { id  name cui vat regcom loc str nr bl sc et ap cod jud tara phone fax email iban1 bank1 logo obs website
      users {id name email username role acl companyId}
  }
}`

export const NEXTDOSARNUMBER = gql`query nextDosarNumner { nextDosarNumber }`


export const PUBLIC = gql`query public($data: PublicInput) {
  public(data:$data){
    id from to 
    ranguri {id name desc}
    settings {id prop value desc}
    company {
      id 
      name loc str nr jud cui  phone fax email
      registru {
        id nr dt status
        ofertanti {
          titular { 
            id name domLoc domTara  ciElib dNastere
            pjTara pjJud pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod 
            pjTel pjFax pjEmail pjTipSediu 
            cui pjRegType pjRegNr
          }
          mandatar { 
            id name domLoc domTara  ciElib dNastere 
            pjTara pjJud pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod 
            pjTel pjFax pjEmail pjTipSediu 
            cui pjRegType pjRegNr
          }
        }
        preemptori {
          titular {
            id name domLoc domTara ciElib dNastere
            pjTara pjJud pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod 
            pjTel pjFax pjEmail pjTipSediu 
            cui pjRegType pjRegNr
          }
          rang {id name}
          rangPreemptorId
        }
        anexe
      }
    }
    
    }
  
}`

export const DADR_LISTA_PRIMARII = gql`query listaPrimarii {
  dadrListaPrimarii{
    id name loc jud
  }
}`


export const DADR_REGISTRU_PRIMARIE = gql`query($data:DadrRegFilter) {
  dadrRegistruPrimarie(data:$data) {
    
        id nr dt 
        ofertanti {
          titular { 
            id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere  taraNastere cetatenie stCiv domLoc
    domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax  domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs 
          }
          mandatar { 
            id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere  taraNastere cetatenie stCiv domLoc
    domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax  domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs 
          }
        }
        preemptori {
          titular {
            id name cui cnp ciNr ciTip  ciSeria ciElib ciElibDe dNastere locNastere judNastere  taraNastere cetatenie stCiv domLoc
    domStr domNr domBl domSc domEt domAp domCod domJud domTara domTel domFax  domEmail
    rsdLoc rsdStr rsdNr rsdBl rsdSc rsdEt rsdAp rsdCod rsdJud rsdTara rsdTel rsdFax rsdEmail
    pjRegType pjRegNr pjCui pjLoc pjStr pjNr pjBl pjSc pjEt pjAp pjCod pjJud pjTara pjTel pjFax pjEmail pjSite pjTipSediu
  obs 
          }
          rang {id name}
          rangPreemptorId
        }
        anexe
      }
  
}`