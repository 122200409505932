import { Grid, makeStyles, Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import PF_PJ from '../Common/PF_PJ'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import SEMNATURI_VANZATORI from '../Common/SEMNATURI_VANZATORI'
import { MdPrint } from 'react-icons/md'

const cod = "anexa3b";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign:"center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function AdresaADS1(props) {
    const codAnexa = "ads1"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const printRef = useRef()

   
    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const anexaInputHandler = name => ev => {
        let newDosar = {...dosar}
        newDosar['anexe'][cod][name] = (name !== "dt") ? ev.target.value : moment(ev.target.value).toDate()
        props.onChange(newDosar)
    }

    const inputAnexeHandler = name => ev => {
        let nd = {...dosar}
        nd['anexe'][name] = ev.target.value
        props.onChange(nd)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            { dosar && dosar.anexe && (
                            <Grid item xs={12}>
                                <RichCard title="NUMĂR DE ÎNREGISTRARE" >
                                    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                        <Grid item sm={2} xs={4}>
                                            <FunkyInput
                                                value={dosar.anexe[cod].nr} type="number" step="1" label="NR.INREG"
                                                onChange={anexaInputHandler('nr')} />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <FunkyInput
                                                value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} type="date" label="DIN DATA"
                                                onChange={anexaInputHandler('dt')}
                                            />
                                        </Grid>
                                    </Grid>
                               </RichCard>
                            </Grid>
                            )}

                               
                           
                          
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE TIPĂRIRE"
                            action = {
                                <Button variant = "contained" size = "small" color= "primary" onClick={printHandler}>
                                    <MdPrint size = "1.6em"/>
                                </Button>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && (    
                                <Grid
                                    container alignItems="flex-start"
                                    style={{ border: "0px solid red" }}
                                    
                                    className="printable"
                                    ref={printRef} >
                                     <Grid item xs={12}>
                                     Anexa 3B la normele metodologice
                                     {/* <Grid item xs={12}>
                                        <ANTET_INSTITUTIE data = {dosar.company}/>
                                    </Grid> */}
                                    </Grid>
                                    <Grid item xs={12} align = "right">
                                        <br/>
                                        Nr.înreg. {dosar.anexe[cod].nr} / {moment(dosar.anexe[cod].dt).format("DD-MM-YYYY")}
                                    </Grid>
                                    <Grid item xs = {12} align="left">
                                    Către: <strong> Direcția pentru Cultură a Județului {dosar.company.jud}</strong>
                                    </Grid>

                                    <Grid item xs={12} align = "center">
                                        <br/>
                                        <strong>ADRESĂ </strong>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={12}  className = {classes.text}>
                                        
                                        <PF_PJ data = {dosar.ofertanti}/>

                                        prin prezenta cerere solicit eliberarea avizului specific pentru terenul agricol extravilan în suprafaţă de {' '}
                                        <Field>{dosar.anexe.terenHa}</Field> ha, {' '}
                                        identificat cu număr cadastral{' '}
                                        <Field>{dosar.anexe.terenNrCadastral}</Field>, {' '}
                                        înscris în cartea funciară nr.{' '}
                                        <Field>{dosar.anexe.terenCf}</Field>, {' '}
                                        a localităţii.{' '}
                                        <Field>{dosar.anexe.terenCfLoc}</Field>, {' '}
                                        
                                        <br/>
                                        Anexat depunem următoarele documente:<br/>
                                        a) copie a BI/CI al/a vânzătorului persoană fizică sau o copie a 
                                        paşaportului pentru vânzătorul persoană fizică cu domiciliul în străinătate;
                                        <br/>
                                        b) o copie legalizată sau certificată pentru conformitate de către funcţionarii 
                                        din cadrul serviciului public deconcentrat al Ministerului Culturii a actului de
                                         proprietate asupra terenului ce face obiectul ofertei de vânzare (după caz: 
                                         contract de vânzare-cumpărare, contract de donaţie, proces-verbal de predare-primire,
                                          sentinţă/decizie civilă definitivă şi irevocabilă, titlu de proprietate, certificate de
                                           moştenitor, contract de schimb, act de lichidare a patrimoniului, altele asemenea);
                                           <br/>
                                        c) extras de carte funciară însoţit de extrasul de plan cadastral al suprafeţei supuse 
                                        vânzării, în sistem de coordonate Stereografic 1970 emis cu maximum 30 de zile înainte de 
                                        depunerea cererii;
                                        <br/>
                                        d) alte documente doveditoare, după caz.
                                        <br/>
                                        <div className = "avoid">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        Vă rugăm să ne comunicaţi decizia dumneavoastră în termenul prevăzut de Legea nr. 17/2014 
                                        privind unele măsuri de reglementare a vânzării-cumpărării terenurilor agricole situate în
                                         extravilan şi de modificare a Legii nr. 268/2001 privind privatizarea societăţilor comerciale
                                          ce deţin în administrare terenuri proprietate publică şi privată a statului cu destinaţie 
                                          agricolă şi înfiinţarea Agenţiei Domeniilor Statului, cu modificările ulterioare.
                                          </div>
                                      
                                        <div className = "avoid">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        Subsemnatul/subsemnații, îmi/ne exprim(ăm) voluntar și explicit consimțământul pentru 
                                        prelucrarea datelor cu caracter personal furnizate pe această cale, în vederea soluționării 
                                        prezentei cereri și declar(ăm) că sunt(em) de acord ca datele personale să fie procesate și/sau 
                                        transmise, în baza unui temei legal justificat, către oricare dintre autoritățile nominalizate 
                                        de legislația în vigoare ca având atribuții în soluționarea cererii mele/noastre. 
                                        </div>
                                        <div className = "avoid">Înțeleg/
                                        înțelegem, de asemenea, faptul că, în baza Regulamentul (EU)679/2016 dispun(em) de drepturile
                                         de acces, intervenție și de opoziție asupra datelor personale transmise prin prezentul înscris.
                                         </div>
                                         <br/>

                                    </Grid>
   
                                    <Grid item xs={12}  align = "left">     
                                        <br/>
                                        <SEMNATURI_VANZATORI data = {dosar.ofertanti} />

                                    </Grid>                           
                                    
                                                                    
                                    
                                
                            </Grid>
                             )}
                            </div>
                            </RichCard>
                        
                    </Grid>
                </Grid>
            )}
        </Fragment>

    )
}

