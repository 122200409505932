import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Checkbox,  FormControlLabel } from '@material-ui/core';

const APP_MODULES = {legea17:true, arenda: false, atestate: false}

export default function AclModal({data, onClose}) {

    const [item, setItem] = useState()

    useEffect(()=>  {
        if (data) {
            data = {...data, acl: data.acl ? JSON.parse(data.acl): {...APP_MODULES} }
        }
        setItem(data)
    
    }, [data])



    const checkboxHandler = name => ev => {
        let ni = {...item, acl: {...item.acl, [name]: ev.target.checked}}
        setItem(ni)
    }

    const closeHandler = elem => () => {
        if(!elem) {
            onClose(null)
        } else {
            onClose({...item, acl: JSON.stringify(item.acl)})
        }
    }
  return (<Fragment>
      <Dialog open={Boolean(item)} onClose = { closeHandler(null) }>
          <DialogTitle>ACL</DialogTitle>
          <DialogContent>
              {item && Object.keys(item.acl).map( (e,i) => (
                  <FormControlLabel
                    key={i}
                    label = {e.toUpperCase()}
                    control = {
                    <Checkbox 
                        checked = {item.acl[e]} 
                        onChange = {checkboxHandler(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                  />
              ))}
              {/* {item && <pre>{JSON.stringify(item, null, 2)}</pre>} */}
          </DialogContent>
          <DialogActions>
              <Button variant="contained" color = "secondary" onClick={closeHandler(null)}>RENUNȚĂ</Button>
              <Button variant="contained" color = "primary" onClick={closeHandler(item)}>CONFIRMĂ</Button>
          </DialogActions>
      </Dialog>

  </Fragment>)
}
