import { Grid, makeStyles, Button, IconButton, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import FunkyInput from '../../../components/FunkyInput'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'
import Field from '../Common/Field'
import { FaArrowAltCircleRight, FaTimesCircle } from 'react-icons/fa'
import { Alert } from '@material-ui/lab'
import ANTET_PRE from '../Common/ANTET_PRE'
import LuckyChip from '../../../components/LuckyChip'
import {getSetting} from '../../../lib/settings'
import PlicB5 from '../Common/PlicB5'
import ConfirmarePrimire from '../Common/ConfirmarePrimire'
import { MdMail, MdPrint, MdReply } from 'react-icons/md'
import ANTET_INSTITUTIE from '../Common/ANTET_INSTITUTIE'
import Antet2022 from '../Common/Antet2022'
import SemnaturiPrimarSercretar2022 from "../Common/SemnaturiPrimarSecretar2022"

const cod = "anexa1d";


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    table: {
        width: "100%",
        '&& thead>tr>th,thead>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            textAlign: "center",
            fontSize: "0.6rem",
            lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid black",
            fontSize: "0.6rem",
            padding: theme.spacing(0.5),
            verticalAlign: "center",
            height: "100%"
        }
    }
}))

export default function Anexa1DNotificarePreemptori(props) {
    const codAnexa = "1d"
    const classes = useStyles()
    const [dosar, setDosar] = useState(null)
    const [plic,setPlic] = useState()
    const [confirmare, setConfirmare] = useState()
    const [currentItem, setCurrentItem] = useState()
    const printRef = useRef()


    useEffect(() => {
        if (props && props.data) { setDosar({ ...props.data }) }
    }, [props])

    const addNotificationHandler = e => () => {
        let nd = {...dosar }
        nd.anexe.notificari =[
            ...nd.anexe.notificari, 
            {
            ...e,
            nr: 1,
            dt: new Date()
        }]
        props.onChange(nd)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const removeHandler = i => ()=>{
        let nd = {...dosar}
        nd.anexe.notificari.splice(i,1)
        if ( currentItem && i === currentItem.index) {setCurrentItem()}
        props.onChange(nd)
    }
    const handleNrChange = i => ev => {
        let nd = {...dosar}
        nd.anexe.notificari[i].nr = ev.target.value
        props.onChange(nd)
    }
    const handleDateChange = i => ev => {
        let nd = {...dosar}
        nd.anexe.notificari[i].dt = moment(ev.target.value).toDate()
        props.onChange(nd)
    }
    return (
        <Fragment>

            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <Alert severity="info" className={classes.text}>
                                    <small>
                                        Mai jos, sunt afișați preemptorii evidențiați în lista preemptorilor. <br />
                                    Faceți click pe un preemptor pentru a-l aduce în lista notificărilor și introduceți 
                                    nr de înregistrare pentru a păstra evidența lor și a
                                    genera formularul <strong>1d</strong> pentru fiecare preemptor notificat.{' '}
                                    Folosiți butoanele din parte de sus a ferestrei de previzualizare pentru a lista plicul (format lung - DL).
                                    și confirmarea de primire aferentă fiecărei notificări. <br />
                                    Borderoul zilnic de corespondență (pentru Poșta Română) îl veți regăsi, în curând, în meniul principal
                                    (vertical, din stânga), în secțiunea "RAPOARTE"
                                    </small>
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>

                                <RichCard title="PREEMPTORI DIN LISTA PREEMPTORILOR">
                                    <Grid container justifyContent="flex-start" alignItems="center" spacing ={1}>

                                        
                                            {dosar && dosar.preemptori.map((e, i) => (
                                                <LuckyChip 
                                                    key={i}
                                                    data = {e.titular} 
                                                    icon = {e.rang && e.rang.name ? e.rang.name.replace("preemptori de rang ", ""): "?"} 
                                                    onClick = {addNotificationHandler(e)}
                                                    disabled = {dosar.anexe.notificari.filter( el => el.titular.id === e.titular.id).length > 0}
                                                />
                                            ))}
                                        
                                    </Grid>
                                </RichCard>
                                <RichCard title = "NOTIFICĂRI ÎNREGISTRATE">
                                    
                                        
                                        {dosar && dosar.anexe.notificari.map( (e,i) => (
                                            <Grid container 
                                                justifyContent="space-between" alignItems="center" key={i}
                                                style = {
                                                    currentItem && currentItem.index === i 
                                                ? { background: "lightblue"}
                                                :  null
                                                }    
                                            >
                                                {i+1}.
                                                <FaTimesCircle color = "red" onClick = {removeHandler(i)}/> &nbsp;&nbsp;
                                                <Grid item sm = {3} xs={12}>
                                                    <small><strong>{e.titular.name} </strong></small>
                                                </Grid>
                                                <Grid item xs={2} align = "right">
                                                    <small>{e.rang.name.replace("preemptori de ","").toUpperCase()}</small>
                                                </Grid>         
                                                <Grid item xs={2}>
                                                    <FunkyInput 
                                                        value = {e.nr} label = "NR"
                                                        onChange = {handleNrChange(i)}
                                                    />
                                                </Grid> 
                                                <Grid item xs={3}>
                                                <FunkyInput 
                                                    value = {moment(e.dt).format('YYYY-MM-DD')} type ="date" label="DATA"
                                                    onChange = {handleDateChange(i)}
                                                />
                                                </Grid>   
                                                <IconButton onClick = {()=>{
                                                     currentItem && currentItem.index === i
                                                        ? setCurrentItem() 
                                                        : setCurrentItem({data:e, index:i})}}>
                                                    <FaArrowAltCircleRight color = "navy" />
                                                </IconButton>                            
                                            </Grid>
                                            ))}
                                </RichCard>
                            </Grid>
                        </Grid>
                    </Grid>
{/* print             */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE"
                            action={
                                <Fragment>
                                    <Button size = "small" variant = "contained" color = "primary"
                                        onClick = {()=>setPlic({...currentItem, company: dosar.company})}
                                        disabled = {!currentItem} >
                                        <MdMail size = "1.6em"/>
                                    </Button>&nbsp;              
                                    <Button size = "small" variant = "contained"  color = "primary" 
                                    onClick = {()=>setConfirmare({...currentItem, company: dosar.company})}
                                    disabled = {!currentItem} >
                                        <MdReply size = "1.6em"/>
                                    </Button>&nbsp;
                                    <Button size = "small" variant = "contained" color = "primary" 
                                        onClick={printHandler}
                                        disabled = {!currentItem} >
                                        <MdPrint size = "1.6em" />
                                    </Button>
                                </Fragment>
                            }
                       
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && currentItem 
                                ?(
                                    <Grid
                                        container alignItems="flex-start"
                                        style={{ border: "0px solid red" }}
                                        className={classes.text}
                                        ref={printRef} >
                                            
                                        <Grid item xs = {12}>
                                            <ANTET_INSTITUTIE data = {dosar.company} />
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                            ANEXA Nr. 4 (Anexa nr. 1D la normele metodologice)
                                            <br/><br/>
                                        <br/>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <strong>NOTIFICARE PREEMPTORI</strong>
                                            <br/><br/>
                                        </Grid>

                                        <Grid item xs={12}>
                                        
                                            <Antet2022 data = {dosar} withUat={true}/>
                                        </Grid>
                                        
                                        <Grid item xs={12} align="right">
                                            {currentItem && currentItem.data &&(
                                                <div>
                                                    Nr. {currentItem.data.nr}
                                                    / {moment(currentItem.data.dt).format('DD.MM.YYYY')}
                                                </div>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} className={classes.text}>
                                            <br/><br/><br/><br/>
                                            În temeiul art. 6 alin. (6) din Legea nr. 17/2014 privind unele
                                            măsuri de reglementare a vânzării terenurilor agricole situate în
                                            extravilan şi de modificare a Legii nr. 268/2001 privind privatizarea
                                            societăţilor ce deţin în administrare terenuri proprietate publică
                                            şi privată a statului cu destinaţie agricolă şi înfiinţarea
                                            Agenţiei Domeniilor Statului, cu modificările şi completările ulterioare,
                                            prin prezenta se aduce la cunoştinţa preemptorului {' '}
                                            <Field>{currentItem.data.titular.name}</Field>, 
                                            CNP/CIF <Field>{currentItem.data.titular.cnp}{currentItem.data.titular.cui}</Field>,  (*),
                                            nr. înregistrare { ' '}
                                            <Field>
                                                {currentItem.data.titular.pjRegType}{currentItem.data.titular.pjRegNr}
                                            </Field>, 
                                            
                                            (**) în calitate de{' '} 
                                            <Field>{currentItem.data.rang.name.replace("preemptori", "preemptor")}</Field>, 
                                            având domiciliul/reşedinţa/sediul social/secundar în:
                                            localitatea <Field>{currentItem.data.titular.domLoc||currentItem.data.titular.pjLoc}</Field>,
                                            {' '}
                                            str. <Field>{currentItem.data.titular.domStr||currentItem.data.titular.pjStr}</Field>,{' '}
                                            nr. <Field>{currentItem.data.titular.domNr||currentItem.data.titular.pjNr}</Field>, {' '}
                                            bl. <Field>{currentItem.data.titular.domBl||currentItem.data.titular.pjBl}</Field>,{' '}
                                            sc. <Field>{currentItem.data.titular.domSc||currentItem.data.titular.pjSc}</Field>,{' '}
                                            et. <Field>{currentItem.data.titular.domEt||currentItem.data.titular.pjEt}</Field>,{' '}
                                            ap. <Field>{currentItem.data.titular.domAp||currentItem.data.titular.pjAp}</Field>,{' '}
                                            judeţul/sectorul <Field>{currentItem.data.titular.domJud||currentItem.data.titular.pjJud}</Field>,{' '}
                                            codul poştal <Field>{currentItem.data.titular.domCod||currentItem.data.titular.pjCod}</Field>,{' '}
                                            e-mail <Field>{currentItem.data.titular.domEmail||currentItem.data.titular.pjEmail}</Field>,{' '}
                                             tel. <Field>{currentItem.data.titular.domTel||currentItem.data.titular.pjTel}</Field>,{' '}
                                             
                                             <b>Oferta de vânzare nr. <Field>{dosar.nr}</Field>{' '}
                                                 din data de <Field>{moment(dosar.dt).format('DD.MM.YYYY')}</Field>,</b>{' '}
                                            depusă de {' '}
                                            <Field>
                                                {dosar.ofertanti.map( (e,i)=><span key = {i}>{e.titular.name}, </span>)}
                                            </Field>{' '}
                                            {console.log("ddd:", dosar.anexe)}
                                            privind terenul agricol situat în extravilan,
                                            în suprafaţă de <Field>{dosar.anexe.terenHa}</Field> (ha),{' '}
                                            nr. cadastral <Field>{dosar.anexe.terenNrCadastral}</Field>, {' '}
                                            nr. carte funciară <Field>{dosar.anexe.terenCf}</Field>,{' '} al localității {' '}
                                            <Field>{dosar.anexe.terenCfLoc}</Field>, {' '}
                                            nr. tarla <Field>{dosar.anexe.terenTarlaLot}</Field>,{' '}
                                            nr. parcelă <Field>{dosar.anexe.terenParcela}</Field>,{' '}
                                            categorie de folosinţă <Field>{dosar.anexe.terenCategorie}</Field>, {' '} 
                                            la preţul de <Field>{dosar.anexe.terenPret}</Field>, {' '}    (lei),{' '}
                                            afişată la sediul/site-ul <Field>{dosar.company.site}</Field> {' '}
                                            Primăriei <Field>{dosar.company.loc} </Field>, {' '} 
                                            judeţul <Field>{dosar.company.jud}</Field>
                                            <br/>
                                            <br/>
                                            <br/>
                                            </Grid>
                                        <Grid item xs = {12} align = "center">
                                            <SemnaturiPrimarSercretar2022 data = {dosar}/>
                                        </Grid>
                                    <Grid item>
                                    <br/><br/>
                                            <small>
                                            <strong>NOTE:</strong> <br/>
                                                - Câmpurile notate cu (*) sunt obligatoriu de completat.<br/>
                                                - Câmpurile notate cu (**) se completează cu numărul din registrul
                                                comerţului sau cu numărul din Registrul asociaţiilor şi fundaţiilor.<br/>
                                                - Notificarea se emite pentru fiecare preemptor menţionat în lista preemptorilor.
                                            </small>
                                    </Grid>
                                </Grid>
                                )
                                : <Typography variant = "h2" style={{color:"LightSteelBlue", textAlign:"center"}}>
                                    ALEGETI <br/>O <br/>NOTIFICARE <br/>DIN <br/>LISTĂ
                                </Typography>
                            }
                            </div>
                        </RichCard>

                    </Grid>
                </Grid>
            )}
            <PlicB5 data = {plic} onClose = {()=>setPlic()} />
            <ConfirmarePrimire data = {confirmare}  onClose = {()=>setConfirmare()} />
        </Fragment>

    )
}

