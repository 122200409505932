import React, { Fragment } from 'react'
import { makeStyles, Card } from "@material-ui/core"
import CONTACT from './CONTACT'
import PRIN from './PRIN'
import PENTRU from './PENTRU'
import CONTACTOFERTA from './CONTACTOFERTA'

const useStyles = makeStyles(theme => ({
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] },
        "@media print": {
            pageBreakInside: "avoid"
        }
    }
}))

export default function PF_PJ(props) {
    const classes = useStyles()
    const ofertanti = props.data || {}
    const noPrefix = props.noPrefix ? true : false

    return (
        <Fragment>

            {ofertanti.map((e, i) => (

                <Fragment key={i}>
                    {/* {console.log(i, e)}÷\ */}
                    {!props.isOferta ? (
                        <Fragment>
                            {((e.titular.cnp || !e.titular.cui))   //este PF
                                && (<Card className={classes.card} style={noPrefix ? { border: "none", padding: 0 } : null}>
                                    <CONTACT
                                        item={e.titular}
                                        acceptare={props.acceptare}
                                        noPrefix={noPrefix} />
                                    <PRIN {...e.mandatar} mandat={{ calitate: e.calitate, prin: e.prin, conform: e.conform }} />
                                </Card>)
                            }

                            {(e.titular.cui || !e.titular.cnp)
                                && (
                                    <Card className={classes.card}>
                                        <CONTACT
                                            item={e.mandatar}
                                            mandat={{ calitate: e.calitate, prin: e.prin, conform: e.conform }}
                                            noPrefix={noPrefix}
                                            acceptare={props.acceptare}
                                        />
                                        <PENTRU {...e.titular} />
                                    </Card>
                                )
                            }
                        </Fragment>
                    )
                    : <Card className={classes.card}><CONTACTOFERTA item = {e.titular}/></Card>
                }



                </Fragment>
            ))}
        </Fragment>
    )
}
