import React, { Fragment, useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { DOSAR } from '../../apollo/query'
import { DOSAR_MUTATION, OFERTANT_MUTATION, PREEMPTOR_MUTATION } from '../../apollo/mutation'
import { Alert } from '@material-ui/lab'
import { LinearProgress, Grid, Button, Typography, Toolbar, makeStyles, Tooltip,
    useTheme, useMediaQuery, FormControlLabel, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import Container from '../../components/Container'
import FunkyInput from '../../components/FunkyInput'
import moment from 'moment'
import Menu from './Menu'
import { ETAPE, menuItems } from './menuItems'
import RichCard from '../../components/RichCard'
import Anexa1CCererePF from './Anexe/Anexa1ACererePF'
import Anexa1BOfertaPF from './Anexe/Anexa1BOfertaPF'
import Pv1AfisareOfertaPF from './Anexe/Pv1AfisareOfertaPF'
import Pv1AfisareOfertaPJ from './Anexe/Pv1AfisareOfertaPJ'
import Pv2AfisareListaPreemptoriPF from './Anexe/Pv2AfisareListaPreemptoriPF'

import Anexa1CCererePJ from './Anexe/Anexa1ACererePJ'
import Anexa1BOfertaPJ from './Anexe/Anexa1BOfertaPJ'
import Anexa1CListaPreemptori from './Anexe/Anexa1CListaPreemptori'
import Anexa1GDecizieVanzator from './Anexe/Anexa1GDecizieVanzator'
import Anexa1DNotificarePreemptori from './Anexe/Anexa1DNotificarePreemptori'

import styled from 'styled-components'
import Notification from '../../components/Notification'
import AdresaADS1 from './Anexe/AdresaADS1'
import AdresaDA1 from './Anexe/AdresaDA1'
import Anexa3aCultura from './Anexe/Anexa3aCultura'
import Anexa3bCultura from './Anexe/Anexa3bCultura'
import Anexa2cMapn from './Anexe/Anexa2cMapn'
import Anexa1EComunicariAcceptare from './Anexe/Anexa1EComunicariAcceptare'
import AdresaNotificareUatVecine from './Anexe/AdresaNotificareUatVecine'
import Pv3Final from './Anexe/Pv3Final'
import PvrRetragere from './Anexe/PvRetragere'
import Anexa1Fnonpreemptori from './Anexe/Anexa1Fnopreemtori'
import Pv45 from './Anexe/Pv45'
import Pv46 from './Anexe/Pv46'
import Pv30 from './Anexe/Pv30'
import Pv31 from './Anexe/Pv31'
import InfoDrawer from './InfoDrawer'
import { FaCalendarCheck, FaInfoCircle } from 'react-icons/fa'
import AdresaDA2 from './Anexe/AdresaDA2'
import Anexa1G230 from './Anexe/Anexa1G30'
import AdresaDA3 from './Anexe/AdresaDA3'



const useStyles = makeStyles(theme => ({
    toolbar: {
        background: theme.palette.grey[0], display: "flex", justifyContent: "flex-start", alignItems: "center"
    },
    toolbarDense: { margin: 0, padding: 0, "&& :before": { padding: 0 } },
    buttonOn: {
        background: theme.palette.info.dark, color: theme.palette.info.contrastText,
        "&:hover": { background: theme.palette.primary.dark }
    },
    buttonOff: {
        background: theme.palette.info.light, color: theme.palette.info.contrastText,
        "&:hover": { background: theme.palette.primary.light }
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: 'white', color: "navy", padding: theme.spacing(1),
        fontSize: "0.9rem", textAlign: "center", border: "1px solid lightgrey",
        boxShadow: "1px 1px 3px grey"
    },
    customArrow: {
        color: 'navy',
    },

}))

const Styles = styled.div`

@media print {


    
        @page { 
            size:  letter;
            margin: 10mm 10mm 10mm 30mm; 
        };
        div.printable{
            div {
                page-break-inside: avoid!important;
                break-inside: avoid!important;
            
            }

        }
}
`

const isDisabled = (dosar) => {
    let res = 0
    if (dosar) {
        dosar.ofertanti.forEach(o => {
            if (!o.titularId) { res = res + 1 }
        });
        dosar.preemptori.forEach(p => {
            if (!p.rangPreemptorId || !p.titularId) { res = res + 1 }
        })
        if (!dosar.nr) { res = res = res + 1 }
    }
    return res === 0 ? false : true;

}



export default function Dosar(props) {
    const classes = useStyles()
    const [currentForm, setCurrentForm] = useState(menuItems[0])
    const [dosar, setDosar] = useState()
    const [msg, setMsg] = useState()
    const [drawer, setDrawer] = useState()
    const [mutateDosar, _] = useMutation(DOSAR_MUTATION)
    const [mutateOfertant] = useMutation(OFERTANT_MUTATION)
    const [mutatePreemptor] = useMutation(PREEMPTOR_MUTATION)
    const { data, loading, error, refetch } = useQuery(DOSAR, { variables: { id: props.match.params.id }, fetchPolicy: "cache-first" })
    const [stage, setStage] = useState(0)
    const theme = useTheme()
    const width = useMediaQuery(theme.breakpoints.up('sm'))
    const [dateFixModal, setDateFixModal] = useState(false)
    const [notify, setNotify] = useState(null)

    useEffect(() => {

        if (data && data.dosar) {

            let d = {
                ...data.dosar,
                anexe: JSON.parse(data.dosar.anexe)
            }


            //adding any anexes designed prior to the initial phase of development
            const anexeKeys = Object.keys(d.anexe)
            let unexistingAnexe = [...menuItems].reduce((acc, item) => {
                if (anexeKeys.indexOf(item.id) < 0) {
                    acc = {
                        ...acc, [item.id]: {
                            name: item.desc,
                            nr: d.nr,
                            dt: d.dt
                        }
                    }
                }
                return acc
            }, {})

            d.anexe = { ...d.anexe, ...unexistingAnexe }
            setDosar(d)
           
        }
    }, [data])

    const changeFormHandler = ev => setCurrentForm(ev)

    const dosarChangeHandler = ev => setDosar(ev)

    const submitHandler = async () => {
        
        let ofertanti = dosar.ofertanti.map(el => ({
            id: el.id ? +el.id : 0,
            tip: true,
            titularId: +el.titularId,
            mandatarId: +el.mandatarId || 0,
            companyId: +dosar.company.id,
            registruId: +dosar.id,
            calitate: el.calitate, prin: el.prin, conform: el.conform
        }))
        let preemptori = dosar.preemptori.map(el => ({
            id: +el.id || 0,
            // id: (el.id && el.id !== 0) ? el.id : null,
            titularId: el.titularId,
            mandatarId: el.mandatarId,
            registruId: +dosar.id,
            rangPreemptorId: +el.rangPreemptorId,
            companyId: +dosar.companyId
        }))
        let q = {
            id: dosar.id,
            tipDosar: dosar.tipDosar,
            nr: +dosar.nr,
            dt: dosar.dt,
            status: dosar.status,
            anexe: JSON.stringify(dosar.anexe),
            companyId: +dosar.companyId,
            userId: +dosar.userId
        }
        let qDosar = await mutateDosar({ variables: { data: q } })
        let qOfertanti = await ofertanti.map(async el => await mutateOfertant({ variables: { data: el } }))
        let qPreemptori = await preemptori.map(async el => await mutatePreemptor({ variables: { data: el } }))
        Promise.all([qDosar, ...qOfertanti, ...qPreemptori]).then(
            r => {
                setMsg("DOSARUL A FOST ACTUALIZAT CU SUCCES ÎN BAZA DE DATE!")
                refetch()
            },
            err => { console.log(err); alert(err.toString()) }
        )
    }

    const dateFixHandler = () => {
        let anexe = dosar.anexe
        console.log("anexe:", anexe)
        const keys = Object.keys(anexe)
        let res = keys.reduce((acc, key) => {
            let r;
            if ( Object.isExtensible(anexe[key]) && !Array.isArray(anexe[key])) {
                r = {
                    ...acc,
                    [key]: {
                        ...anexe[key],
                        dt: dosar.dt
                    }
                }
            } else {
                    r = {...acc, [key]:anexe[key]}
                }
            
            return r

        },{})
        // console.log("RES:", res)
        setDosar({...dosar, anexe:res})
        setDateFixModal(false)
        setNotify("DATELE CALENDARISTICE ALE DOCUMENTELOR AU FOST ACTUALIZATE ÎN CONCORDANȚĂ CU DATA DOSARULUI")

    }

    return (
        <div style={{ marginTop: "-24px" }}>
            {/* <img src = {Easter} style = {{height: "60px", zIndex: 99999,width: "auto", 
                            position: "fixed", marginLeft: "0", marginTop: "-10px"}} 
                            onClick = {()=>setMsg(<div align="center">
                            <Typography variant="h3">Sărbători fericite!</Typography>
                            <Typography variant="h2">CHRISTOS A ÎNVIAT!</Typography>
                            <img src = {Easter} style= {{width: "auto", height: "30vh"}}/>
                            </div>)} 
            /> */}
            {error && <Alert severity="danger">{error.toString()}</Alert>}
            {loading && <LinearProgress />}
            {dosar && (
                <Fragment>
                    {width && (
                        <Toolbar variant="dense" className={classes.toolbar} classes={{ dense: classes.toolbarDense }}>
                            {ETAPE.map((e, i) => (
                                <Tooltip title={e.desc} key={i} placement="bottom-end"
                                    // style={{ color: "navy", fontWeight: 350, fontSize: "0.8rem" }}
                                    classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }}
                                >
                                    <Button key={i} size="small"
                                        disableRipple={true}
                                        onClick={() => setStage(e.id)}
                                        style={{ marginTop: 0, borderRadius: 0 }} fullWidth
                                        className={stage === e.id ? classes.buttonOn : classes.buttonOff}
                                        variant={stage === e.id ? "contained" : "outlined"}
                                    // color = {stage === e.id ? "primary" : "default"}
                                    >
                                        <div style={{ fontWeight: 600, fontSize: '1.1em' }}><small>{e.label}</small></div>
                                    </Button>
                                </Tooltip>
                            ))}
                        </Toolbar>
                    )}



                    <Container
                        style={ width ? {marginTop: "-7px"}: {marginTop: "20px"} }
                        title={
                            <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>

                                &nbsp; &nbsp; &nbsp;
                                {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                                
                                DOSAR &nbsp;
                                {dosar.tipDosar.toUpperCase()}
                                &nbsp;&nbsp;
                                <Grid item sm = {1} xs={10}>
                                    <FunkyInput
                                        value={dosar.nr} onChange={ev => setDosar({ ...dosar, nr: ev.target.value })}
                                        type="number" step="1" label="NUMĂRUL"
                                    />

                                </Grid>

                                <Grid item sm = {2} xs={10}>
                                    <FunkyInput
                                        value={moment(dosar.dt).format('YYYY-MM-DD')}
                                        onChange={ev => setDosar({ ...dosar, dt: moment(ev.target.value).toDate() })}
                                        type="date"  label="DATA" />
                                </Grid>
{/* button pentru setare data la toate documentele                                 */}
                                {dosar && (
                                     <Tooltip 
                                     title={<>
                                         Seteaza pentru toate documentele aceași dată ca și data dosarului<br/>
                                         <i><small>
                                         (util când creați un dosar pentru o altă dată decât cea de azi)
                                         </small></i>
                                     </>}
                                     classes={{
                                         tooltip: classes.customTooltip,
                                         arrow: classes.customArrow
                                     }}
                                 >
                                 <IconButton 
                                     onClick = {()=>setDateFixModal(true)}
                                     size="medium" color="primary"
                                 >
                                     <FaCalendarCheck />
                                 </IconButton>
                                 </Tooltip>
                                )}
                               
{/* end button repunere data                                 */}
                                {width && (
                                    <Fragment>
                                        
                                <Grid item xs>
                                <Typography variant="caption" color="primary">
                                    {dosar.ofertanti.reduce((a, i, ix) => a + i.titular.name + (ix < dosar.ofertanti.length - 1 ? ", " : " "), "")}
                                </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                    labelPlacement = "start"
                                        label = {<Typography variant = "overline" color ="primary"><strong>STATUS&nbsp;</strong></Typography>}
                                        control = {
                                            <FunkyInput value = {dosar.status} select 
                                                onChange = {ev => setDosar({...dosar, status: ev.target.value})}>
                                                <MenuItem value = "OPERARE">ÎN OPERARE</MenuItem>
                                                <MenuItem value = "FINALIZAT">FINALIZAT</MenuItem>
                                                <MenuItem value = "RETRAS">RETRAS</MenuItem>
                                                <MenuItem value = "RESPINS">RESPINS</MenuItem>
                                            </FunkyInput>
                                        }
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Grid>
                                </Fragment>

                                )}


                            </Grid>
                        }
                        action={
                            <Grid container spacing={1} alignItems="center">
                                {width && (
                                    <Grid item sm = {5}>
                                    <Button color="secondary" style={{ lineHeight: 1, fontSize: "0.7rem" }} onClick={() => setDrawer(dosar)}>
                                        <FaInfoCircle size="2rem" color="red" />
                                        TERMENE DOSAR</Button>
                                    </Grid>
                                )}

                                <Grid item xs={12} sm={5}>
                                    <Button variant="contained" color="primary" size="small" style={{ marginRight: "10px" }}
                                        onClick={submitHandler} disabled={isDisabled(dosar)}>
                                        CONFIRMĂ
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    >
                        <Grid container justifyContent="space-between" alignItems="flex-start" style={{ height: "81vh" }}>


                            <Grid item xs={12} sm={3} lg={2} style={{
                                borderRight: "1px solid grey", padding: "5px",
                                height: width ? "76vh": "10vw", overflow: "auto"
                            }}>
                                <Menu data={menuItems} width = {width} onChange={changeFormHandler} stage={stage} />
                            </Grid>
                            <Grid item xs={12} sm={9} lg={10}>
                                <RichCard style={{ height: "78vh", overflow: "hidden" }} title={currentForm.desc + " (" + currentForm.id + ") "} >
                                    <div style={{
                                        height: "71vh", overflow: "auto",


                                    }}>
                                        <Styles>
                                            {dosar && dosar.tipDosar === "pf" && (
                                                <Fragment>
                                                    {currentForm.id === "anexa1a" && <Anexa1CCererePF width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1b" && <Anexa1BOfertaPF width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv1" && <Pv1AfisareOfertaPF width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1c" && <Anexa1CListaPreemptori width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv2" && <Pv2AfisareListaPreemptoriPF width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1d" && <Anexa1DNotificarePreemptori width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1e" && <Anexa1EComunicariAcceptare width={width} data={dosar} onChange={dosarChangeHandler} />}

                                                    {currentForm.id === "da1" && <AdresaDA1 data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "ads1" && <AdresaADS1 data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa3a" && <Anexa3aCultura width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa3b" && <Anexa3bCultura width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa2c" && <Anexa2cMapn width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "uat" && <AdresaNotificareUatVecine width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1g" && <Anexa1GDecizieVanzator width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "da2" && <AdresaDA2 data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv3" && <Pv3Final data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pvr" && <PvrRetragere data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1f" && <Anexa1Fnonpreemptori width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv45" && <Pv45 data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv46" && <Pv46 data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv30" && <Pv30 data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv31" && <Pv31 data={dosar} width={width} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1g30" && <Anexa1G230 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "da3" && <AdresaDA3 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                </Fragment>
                                            )}

                                            {dosar && dosar.tipDosar === "pj" && (
                                                <Fragment>
                                                    {currentForm.id === "anexa1a" && <Anexa1CCererePJ width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1b" && <Anexa1BOfertaPJ width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv1" && <Pv1AfisareOfertaPJ width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1c" && <Anexa1CListaPreemptori width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv2" && <Pv2AfisareListaPreemptoriPF width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1d" && <Anexa1DNotificarePreemptori width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1e" && <Anexa1EComunicariAcceptare width={width} data={dosar} onChange={dosarChangeHandler} />}

                                                    {currentForm.id === "da1" && <AdresaDA1 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "ads1" && <AdresaADS1 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa3a" && <Anexa3aCultura width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa3b" && <Anexa3bCultura width={width} data={dosar} onChange={dosarChangeHandler} />}

                                                    {currentForm.id === "anexa2c" && <Anexa2cMapn width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "uat" && <AdresaNotificareUatVecine width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1g" && <Anexa1GDecizieVanzator width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "da2" && <AdresaDA2 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv3" && <Pv3Final width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pvr" && <PvrRetragere width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1f" && <Anexa1Fnonpreemptori width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv45" && <Pv45 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv46" && <Pv46 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv30" && <Pv30 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "pv31" && <Pv31 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "anexa1g30" && <Anexa1G230 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                    {currentForm.id === "da3" && <AdresaDA3 width={width} data={dosar} onChange={dosarChangeHandler} />}
                                                </Fragment>
                                            )}

                                            {/* <pre>{JSON.stringify(dosar, null,4)}</pre> */}
                                        </Styles>
                                    </div>

                                </RichCard>
                            </Grid>
                        </Grid>
                        {msg && <Notification show={msg} onClose={() => setMsg()} />}
                        <InfoDrawer data={drawer} onClose={() => setDrawer()} />
                    </Container>
                    {dateFixModal && <Dialog open={Boolean(dateFixModal)} onClose = {()=>setDateFixModal(false)}>
                        <DialogTitle>Sincronizare documente cu data dosarului</DialogTitle>
                        <DialogContent>
                            <Alert severity ="error">
                                Toate documentele continute în acest dosar vor primi data dosarului - {moment(dosar.dt).format("DD.MM.YYYY")}
                                <br/>
                                <strong>NOTĂ:</strong> <i>Operațiunea nu va afecta documentele care presupun elemente multiple (ex. 1D, UAT, 1E, 1F, PVR)</i>
                            </Alert>
                            {/* <br/> */}
                           
                           <Alert severity='success'>
                               Modificările fi stocate în baza de dată doar după apăsarea butonului <strong>CONFIRMĂ</strong>{' '}
                                din colțul din dreapta sus al dosarului
                           </Alert>
                           <Alert severity="info">DORIȚI ÎNTR-ADEVĂR SĂ EFECTUAȚI ACEASTĂ OPERAȚIUNE?</Alert>
                           <br/>
                            
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick = {dateFixHandler}>CONFIRMĂ</Button>
                            <Button variant="contained" color="secondary" onClick={()=>setDateFixModal(false)}>REUNUȚĂ</Button>
                        </DialogActions>
                        </Dialog>}
                        { notify && <Notification severity="error" show={notify} onClose={() => setNotify()} />}
                </Fragment>
            )}
        </div>
    )
}
