import React, { Fragment, useEffect, useState } from 'react'
import {useApolloClient, useQuery} from '@apollo/client'
import {REGISTRU} from '../../../apollo/queryArenda'
import { LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Conex from './Conex'
import Contract from './Contract/index'


export default function ArendaItem(props) {
    const [item, setItem] = useState()
    let id = parseInt(props.match.params.id) === props.match.params.id ? props.match.params.id : 0
    const { data, loading, error, refetch } = useQuery(REGISTRU, {
        variables: {
            data: {id: props.match.params.id || 0}
        },
        fetchPolicy: "cache-and-network"
    })


    useEffect(() => {
        if ( data && data.registruArenda ) {
            setItem(data.registruArenda[0])
        }
    }, [data])

    return (
        <div>
            {loading && <LinearProgress/>}
            {error && <Alert severity = "error">{JSON.stringify(error, null, 4)}</Alert>}
            { item && (
                <Fragment>
                        { item.tip === "contract" 
                            ? <Contract {...{...item}} onChange = {() => refetch()} />
                            : <Conex {...{...item}} onChange = {() => refetch()} />
                        }
                </Fragment>
            )}
        </div>
    )
}
