import { Button, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import FunkyInput from '../../../../components/FunkyInput';
import { FaTimes } from 'react-icons/fa';
import Boxy from '../../../../components/Boxy';
import { MdPrint } from 'react-icons/md';

export default function CarnetIdentificare({ data, onChange, onPrint }) {

    const [item, setItem] = useState()

    const [anchorEl, setAnchorEl] = useState()
    const [anchorEl2, setAnchorEl2] = useState()

    useEffect(() => setItem(data), [data])

    const inputHandler = name => ev => onChange({
        ...item,
        [name]: ev.target.value
    })

    const dtHandler = name => ev => onChange({
        ...item,
        [name]: moment(ev.target.value).toDate()
    })

    const printHandler = elem => () => {
        onPrint(elem)
        setAnchorEl(null);setAnchorEl2(null);
    }

    return <Fragment>
        {item && (
            <Grid container spacing={1} alignItems="center">
                <Grid item xs = {12} sm = {4}>
                    <Boxy title = {<strong>IDENTIFICARE CARNET</strong>} style = {{background: "AliceBlue"}}>
                        <Grid container alignItems="center" justifyContent ="space-between">
                            <Grid item xs = {12} sm = {2}>
                                <FunkyInput value={item.seria} label="SERIA" onChange={inputHandler("seria")} />
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <FunkyInput value={item.nr} label="PRIMA FILA" type="number" step={1}
                                onChange={inputHandler("nr")} />
                            </Grid>
                            <Grid item xs = {12} sm = {5}>
                                <FunkyInput value={moment(item.dt).format("YYYY-MM-DD")} label="ELIBERAT LA" type="date"
                                    onChange={dtHandler("dt")}
                                />
                            </Grid>
                            {/* <Grid item xs={1} align="right">
                                <Tooltip title={<Typography variant = "body2" align="center">
                                    TIPĂRIRE<br/>
                                    PROCES-VERBAL<br/>
                                     DE ELIBERARE CARNET</Typography>}>
                                <IconButton size="small" color="primary"  onClick = {printHandler({type: "eliberare", data: item})}>
                                    <MdPrint/>
                                </IconButton>
                                </Tooltip>
                            </Grid> */}
                            <Grid item xs={1} align="right">
                                <IconButton size="small" color="primary" onClick = {ev=>setAnchorEl(ev.currentTarget)}>
                                    <MdPrint/>
                                </IconButton>
                                <Menu 
                                    id = "menu1"
                                    open = {Boolean(anchorEl)} 
                                    onClose = {()=>setAnchorEl(null)}
                                    anchorEl = {anchorEl}
                                    
                                >
                                    <MenuItem onClick = {printHandler({type: "cerere-eliberare", data: item})}>
                                        cerere eliberare carnet
                                    </MenuItem>
                                    <MenuItem onClick = {printHandler({type: "eliberare", data: item})}>
                                        proces-verbal de eliberare carnet
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Boxy>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Boxy title =  {<strong>VIZARE FILĂ NOUĂ (anual, cele 7 file)</strong>} style = {{background: "Ivory"}}>
                        <Grid container alignItems="center">
                            <Grid item xs ={12} sm = {3}>
                                 <FunkyInput value={moment(item.dtElib).format("YYYY-MM-DD")} label="DATA P.V." type="date"
                                    onChange={dtHandler("dtElib")}
                                />
                            </Grid>
                            <Grid item xs ={12} sm = {4}>
                                <FunkyInput value={item.filaStart} label="PRIMA FILĂ" onChange={inputHandler("filaStart")}/>
                            </Grid>
                            <Grid item xs ={12} sm = {4}>
                                <FunkyInput value={item.filaEnd} label="ULTIMA FILĂ" onChange = {inputHandler("filaEnd")} />
                            </Grid>
                            {/* <Grid item xs={1} align="right">
                                <Tooltip title={<Typography variant = "body2" align="center">
                                    TIPĂRIRE<br/>
                                    PROCES-VERBAL<br/>
                                     DE ACTUALIZARE <br/>
                                     ( FILE NOI ) </Typography>}>
                                <IconButton size="small" color="primary" onClick = {printHandler({type: "actualizare", data: item})}>
                                    <MdPrint/>
                                </IconButton>
                                </Tooltip>
                            </Grid> */}
                             <Grid item xs={1} align="right">
                                <IconButton size="small" color="primary" onClick = {ev=>setAnchorEl2(ev.currentTarget)}>
                                    <MdPrint/>
                                </IconButton>
                                <Menu 
                                    id = "menu2"
                                    open = {Boolean(anchorEl2)} 
                                    onClose = {()=>setAnchorEl2(null)}
                                    anchorEl = {anchorEl2}
                                    
                                >
                                    <MenuItem onClick = {printHandler({type: "cerere-actualizare", data: item})}>
                                        cerere vizare filă
                                    </MenuItem>
                                    <MenuItem onClick = {printHandler({type: "actualizare", data: item})}>
                                        proces-verbal de vizare carnet
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Boxy>
                </Grid>


                <Grid item xs={12} sm={3}>
                    <Boxy title = {<strong>PREDARE CARNET</strong>} style = {{background: "LavenderBlush"}}>
                        <Grid container>
                            <Grid item xs = {12} align="center">
                            {item.dtPredare
                        ? <Grid container justifyContent="space-between" alignItems='center'>
                            <Grid item xs = {2}>
                                <Tooltip title = {<Typography align="center" variant="body2">RENUNTARE<br/>RESTITUIRE</Typography>}>
                            <IconButton size="small" color="secondary" onClick={() => onChange({ ...item, dtPredare: null })}><FaTimes /></IconButton>
                            </Tooltip>
                            
                            </Grid>
                            <Grid item xs = {8}>
                            <FunkyInput value={moment(item.dtPredare).format("YYYY-MM-DD")} label="PREDAT LA" type="date"
                                onChange={dtHandler("dtPredare")}
                            />
                            </Grid>
                            
                            <Grid item xs={2} align="right">
                                <Tooltip title={<Typography variant = "body2" align="center">
                                    TIPĂRIRE<br/>
                                    PROCES-VERBAL<br/>
                                     DE RESTITUIRE CARNET</Typography>}>
                                <IconButton size="small" color="primary" onClick = {printHandler({type: "restituire", data: item})}>
                                    <MdPrint/>
                                </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        : <Button
                            variant="contained" color="secondary" size="small"
                            onClick={() => onChange({ ...item, dtPredare: new Date() })}
                        >INIȚIAZĂ RETURNARE</Button>
                    }
                            </Grid>
                        </Grid>
                    
                    </Boxy>
                </Grid>
            </Grid>
        )}

    </Fragment>
}
