import { Dialog, DialogContent, DialogTitle, Toolbar, Typography, makeStyles, Grid, MenuItem, DialogActions, Button, IconButton, Tooltip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { Fragment, useEffect, useState } from 'react'
import { FaInfoCircle, FaTimesCircle } from 'react-icons/fa'
import FunkyInput from '../../../../components/FunkyInput'
import inLitere from '../../../../lib/inLitere'
import AUTOCOMPLETE_CONTACT from '../../Common/AUTCOMPLETE_CONTACT'

const useStyles = makeStyles(theme => ({
    dialog: { minWidth: "70vw" },
    toolbar: {
        background: theme.palette.common.white,
        color: theme.palette.primary.dark
    },
    content: { padding: theme.spacing(2) },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: 'white', color: "blue", padding: theme.spacing(1),
        fontSize: "0.9rem", textAlign: "center", border: "1px solid lightgrey",
        boxShadow: "1px 1px 3px grey"
    },
    customArrow: {
        color: 'navy',
    },
}))



export default function SuplimentarModal(props) {
    const {open, onClose, ranguri} = props
    const classes = useStyles()

    const initialItem = {
        titular: { name: "" },
        titularId: null,
        mandatar: {},
        mandatarid: null,
        rang: ranguri[0],
        rangPreemptorId: 1,
        pret: "",
        documente: "",
        extra: true
    }

    const [item, setItem] = useState()

    useEffect(() => {
        setItem({ ...initialItem })
    }, [props])

    const closeHandler = name => () => onClose(typeof name === 'undefined' ? null : name)

    const titularHandler = ev => setItem({ ...item, titular: ev, titulatId: ev.id })
    const mandatarHandler = ev => setItem({ ...item, mandatar: ev, mandataId: ev.id })
    const rangHandler = ev => setItem({ ...item, rang: ev.target.value, rangPreemptorId: ev.target.value.id })
    const pretHandler = ev => setItem({ ...item, pret: ev.target.value })
    const docHandler = ev => setItem({ ...item, documente: ev.target.value })

    return (
        <Dialog open={Boolean(props.open)} onClose={closeHandler(null)} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.toolbar}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" component="div">
                        Comunicare de acceptare a unui preemptor neevidențiat în anexa 1C
                </Typography>
                    <IconButton onClick={closeHandler()} color="inherit" size="small">
                        <FaTimesCircle size="2rem" />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info" icon={<FaInfoCircle size="3rem" />}>
                            <Typography variant="caption" style={{ fontSize: "0.8rem", fontWeight:500 }}>

                                <span style={{ color: "black" }}>
                                    Această opțiune va fi folosită în cazul în care, în momentul întocmirii listei preemptorilor
                                    nu a fost posibilă identificarea unui anumit preemptor, care, în urma procedurii de publicitate
                                    prevăzute de lege, află despre oferta de vânzare teren, se identifică drept preemptor îndeplinind condițiile impuse de legislație și decide să depună o comunicare de acceptare a ofertei de vânzare în
                            termenul legal de 45 de zile lucrătoare. <br />
                                </span>
                                <strong style={{ color: "navy" }}>
                                    Atât titularul cât și (eventualul) mandatar trebui să fie anterior introduși  în agenda aplicației!
                            </strong>

                            </Typography>
                        </Alert>
                    </Grid>
                    {item && (
                        <Fragment>
                            <Grid item xs={4}>
                                <AUTOCOMPLETE_CONTACT data={item.titular} onChange={titularHandler} label="TITULAR" />
                            </Grid>
                            <Grid item xs={4}>

                                <AUTOCOMPLETE_CONTACT data={item.mandatar} onChange={mandatarHandler} label="PRIN MANDATAR"
                                    disabled={item && item.titular && !item.titular.id}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {open && ranguri && (

                                    <FunkyInput value={item.rang} select onChange={rangHandler}>
                                        {ranguri.map((e, i) => (

                                            <MenuItem value={e} key={i}>
                                                {e.name.replace("preemptori", "preemptor")}
                                            </MenuItem>

                                        ))}
                                    </FunkyInput>

                                )}

                            </Grid>

                            <Grid item xs={3}>
                                <FunkyInput value={item.pret} onChange={pretHandler} label="PREȚ" />
                            </Grid> lei, adică
                            <Grid item xs={8}>
                                <FunkyInput value={item.pret == parseFloat(item.pret) ? inLitere(item.pret) : ""} multiline disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="overline" color="primary">
                                    DOCUMENTE DOVEDITOARE ALE CALITĂȚII DE PREEMPTOR
                         </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FunkyInput value={item.documente} onChange={docHandler} multiline minRows={5} />
                            </Grid>
                        </Fragment>
                    )}


                </Grid>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={closeHandler(null)}>
                    RENUNȚĂ
                </Button>
                <Button variant="contained" color="primary" onClick={closeHandler(item)} disabled ={item && item.titular && !item.titular.name}>
                    CONFIRMĂ
                </Button>
            </DialogActions>
        </Dialog>
    )
}
