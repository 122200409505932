import { makeStyles, Grid, Button, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import RichCard from '../../../../components/RichCard'
import { useReactToPrint } from 'react-to-print'
import { Fragment } from 'react'
import LuckyChip from '../../../../components/LuckyChip'
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'
import { clearTypeName } from '../../../../lib/graph-stuff'
import Anexa1Etpl1E from './Anexa1Etpl1E'
import Anexa1EtlpPV from './Anexa1EtplPV'
import AUTOCOMPLETE_CONTACT from '../../Common/AUTCOMPLETE_CONTACT'
import { Alert } from '@material-ui/lab'
import Anexa1EtplAdresaVanzator from './Anexa1EtplAdresaVanzator'
import Anexa1EtplAdresaDADR from './Anexa1EtplAdresaDADR'
import SuplimentarModal from './SuplimentarModal'
import { DOSAR_MUTATION } from '../../../../apollo/mutation'
import Tooltip from '../../../../components/Tooltip'

const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    }
}))

const initAdreseVanzatori = (dosar, acceptare) => {
    let origAdrese = acceptare.adreseVanzatori || []
    let adrese = dosar.ofertanti.reduce( (acc,el)=>{
        let ofertantHasAdresa = origAdrese.filter( elem => elem.id === el.id )
        ofertantHasAdresa = ofertantHasAdresa.length > 0 ? ofertantHasAdresa[0]: null
        if (ofertantHasAdresa) { 
            acc.push(ofertantHasAdresa)
        } else {
            acc.push( { id: el.id, nr: 1, dt: new Date(), vanzator: el })
        }
        return acc;
    }, [])
    return adrese
}

export default function Anexa1EComunicariAcceptare(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState(null)
    const [currentItem, setCurrentItem] = useState()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentType, setCurrentType] = useState()
    const [suplimentarModal, setSuplimentarModal] = useState()

    useEffect(() => {
        // if (props && props.data) { 
            setDosar({ ...props.data }) 
        // }
    }, [props])

    useEffect( () => {
        let d = {...dosar}
        if ( d && d.anexe ) {
            d.anexe.acceptari = d.anexe.acceptari.map( el => ({
                ...el,
                adreseVanzatori: initAdreseVanzatori(dosar, el),
                adresaDADR: {
                    nr: el.adresaDADR && el.adresaDADR.nr ? el.adresaDADR.nr: 1, 
                    dt: el.adresaDADR && el.adresaDADR.dt ? el.adresaDADR.dt: new Date() 
                }
            }))
            setDosar(dosar)
        }

    }, [dosar])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const addAcceptare = e => () => {
        let d = { ...dosar }
        d = {
            ...d, 
            anexe: {
                ...d.anexe, 
                acceptari: [...d.anexe.acceptari, {
                    ...e,
                    dt: new Date(),
                    nr: d.anexe.acceptari.length + 1,
                    adreseVanzatori: initAdreseVanzatori(dosar, []),
                    adresaDADR: {nr: 1, dt: new Date() }
                    
                }],
                
            }
        }
        props.onChange(d)
        setCurrentIndex(); setCurrentType()
    }
    const removeHandler = i => () => {
        let d = { ...dosar }
        d.anexe.acceptari.splice(i, 1)
        props.onChange(d)
        setCurrentIndex(); setCurrentType()
    }
    const dateHandler = (e, i) => ev => {
        let d = { ...dosar }
        d.anexe.acceptari[i]['dt'] = moment(ev.target.value).toDate()
        props.onChange(d)
        setCurrentIndex(); setCurrentType()
    }
    const inputHandler = (e, i, name) => ev => {
        let d = { ...dosar }
        d.anexe.acceptari[i][name] = ev.target.value
        props.onChange(d)
        setCurrentIndex(); setCurrentType()
    }

    const mandatarHandler = (e, i) => ev => {
        if (ev) {
            let d = { ...dosar }
            d.anexe.acceptari[i]['mandatar'] = ev
            d.anexe.acceptari[i]['mandatarId'] = ev.id
            props.onChange(d)
            setCurrentIndex(); setCurrentType()
        }

    }

    const reportHandler = (e, i, type) => () => {
        
        setCurrentItem({
            index: i,
            ...e,
            company: dosar.company,
            user: dosar.user,
            ofertanti: dosar.ofertanti,
            anexe: dosar.anexe,
        });
        setCurrentIndex(i); setCurrentType(type)
       
    }

    const adresaVanzatorHandler = ev => {
        props.onChange(ev)
        
    }

    const suplimentarCloseHandler = e => {
        console.log("e:", e)
        if ( e ) {
            let d = { ...dosar }
            d = {
                ...d, 
                anexe: {
                    ...d.anexe, 
                    acceptari: [...d.anexe.acceptari, {
                        ...e,
                        dt: new Date(),
                        nr: d.anexe.acceptari.length + 1,
                        adreseVanzatori: initAdreseVanzatori(dosar, []),
                        adresaDADR: {nr: 1, dt: new Date() }
                        
                    }],
                    
                }
            }
            props.onChange(d)            
        }
        setSuplimentarModal()
    }


    return (
        <Fragment>
            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
                    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>

                                <RichCard title="PREEMPTORI DIN LISTA PREEMPTORILOR">
                                    <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>


                                        {dosar && dosar.preemptori.map((e, i) => (
                                            <Fragment key={i}>

                                                <LuckyChip
                                                    key={i}
                                                    data={e.titular}
                                                    icon={e.rang && e.rang.name ? e.rang.name.replace("preemptori de rang ", "") : "?"}
                                                    onClick={addAcceptare(clearTypeName(e))}
                                                    disabled={dosar.anexe.acceptari.filter(el => el.titular.id === e.titular.id).length > 0}
                                                />
                                            </Fragment>

                                        ))}

                                    </Grid>
                                </RichCard>
                                <RichCard  
                                    title="COMUNICĂRI DE ACCEPTARE"
                                    action = {
                                        <Button variant="contained" color="primary" size="small" onClick = {()=>setSuplimentarModal(true)}>
                                            <Grid container alignItems="center">
                                                <FaPlusCircle size="1.2rem"/>
                                            <div style = {{lineHeight: 1, fontSize:"0.65rem", textTransform:"none", marginLeft: "10px"}}>
                                               comunicare de acceptare<br/>preemptor suplimentar
                                            </div>
                                            </Grid>
                                        </Button>
                                    }
                                >


                                    {dosar && dosar.anexe.acceptari.map((e, i) => (
                                        <Grid container key={i}
                                            onMouseOver={() => { setCurrentIndex(i) }}
                                            spacing={1}
                                            justifyContent="space-between" alignItems="flex-start"
                                            style={{
                                                padding: "5px", margin: "5px 0 5px -5px", border: "1px dotted blue",
                                                borderRadius: "5px",
                                                background: currentIndex === i ? "#EBF5FB" : "#FEFFF2"
                                            }}

                                        >
                                            <Grid item xs={9}>
                                                <Grid container alignItems="center" spacing={1}>
                                                    {i + 1}.
                                                    <FaTimesCircle color="red" onClick={removeHandler(i)} /> &nbsp;&nbsp;
                                                    <Grid item sm={3} xs={4}>
                                                        <FunkyInput
                                                            type="number" step="any"
                                                            value={e.nr} label="NR"
                                                            onChange={inputHandler(e, i, 'nr')}

                                                        />
                                                    </Grid>
                                                    <Grid item sm={4} xs={6}>
                                                        <FunkyInput
                                                            value={moment(e.dt).format('YYYY-MM-DD')} type="date" label="DATA"
                                                            onChange={dateHandler(e, i)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={4} xs={5}>
                                                        <FunkyInput value={e.pret || ""} type="number" step="any" label="PREȚ"
                                                            onChange={inputHandler(e, i, 'pret')}
                                                        />

                                                    </Grid>
                                                    <Grid item sm={6} xs={7}>

                                                        <FunkyInput disabled value={e.titular.name}
                                                            label={e.rang.name.toUpperCase()} />
                                                    </Grid>
                                                    <Grid item sm={6} xs ={7}>
                                                        <AUTOCOMPLETE_CONTACT
                                                            data={e.mandatar}
                                                            label={<span>MANDATAR<sup>*</sup></span>}
                                                            onChange={mandatarHandler(e, i)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} xs={12}>
                                                        <FunkyInput value={e.documente || ""} label="ACTE DOVEDITOARE"
                                                            multiline minRows={3} onChange={inputHandler(e, i, "documente")} />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} align="center">
                                                        <Button fullWidth
                                                            onClick={reportHandler(e, i, "1e")}
                                                            variant={currentIndex === i && currentType === "1e" ? "contained" : "outlined"}
                                                            size="small"
                                                            style={{ fontSize: "0.7rem" }}
                                                            color="primary">
                                                            <small>COMUNICARE-Anexa 1E</small>
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} align="center">
                                                        <Button fullWidth
                                                            onClick={reportHandler(e, i, "pv")}
                                                            variant={currentIndex === i && currentType === "pv" ? "contained" : "outlined"}
                                                            style={{ fontSize: "0.7rem" }}
                                                            size="small" color="primary">
                                                            <small>PROCES-VERBAL AFIȘARE </small>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} align="center">
                                                        <Button fullWidth
                                                            onClick={reportHandler(e, i, "vanzatori")}
                                                            variant={currentIndex === i && currentType === "vanzatori" ? "contained" : "outlined"}
                                                            style={{ fontSize: "0.7rem" }}
                                                            size="small" color="primary">
                                                            <small>ADRESĂ VÂNZĂTOR </small>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} align="center">
                                                        <Button fullWidth
                                                            onClick={reportHandler(e, i, "dadr")}
                                                            variant={currentIndex === i && currentType === "dadr" ? "contained" : "outlined"}
                                                            style={{ fontSize: "0.7rem" }}
                                                            size="small" color="primary">
                                                            <small>ADRESĂ D.A.D.R. </small>
                                                        </Button>
                                                    </Grid>
                                                    {e.extra &&
                                                    
                                                        <Grid item xs = {12} align="center">
                                                            <Tooltip title = "nu se regăsește în lista preemptorilor, a depus comunicarea ulterior">
                                                            <Typography variant="caption" color="secondary" 
                                                                style={{fontWeight: 800, background:"red", color: "white", 
                                                                padding: "4px 10px 4px 10px"}}>
                                                                SUPLIMENTAR!
                                                            </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                   
                                                    }
                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    ))}

                                </RichCard>
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <sup>*</sup> &nbsp;&nbsp;- &nbsp;
                                        <small>în cazul în care cererea este depusă prin mandatar, vă rog să il adăugați
                                            în prealabil ca persoană în <b>AGENDA APLICAȚIEI</b> pentru a putea fi
                                            mai apoi căutat în căsuța MANDATAR (autocompletare prin căutare în agendă)
                                        </small>
                                    </Alert>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* print                     */}
                    <Grid item sm={5} xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE"
                            action={
                                <Fragment>
                                    {currentItem && (
                                        <Fragment>
                                            <Button variant="contained" color="primary" size="small" onClick={printHandler}>TIPĂRIRE</Button>
                                        </Fragment>
                                    )}

                                </Fragment>

                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && currentItem
                                    ? (

                                        <Grid container
                                            justifyContent="flex-start" className={classes.text} alignItems="flex-start"
                                            style={{ maxHeight: "70vh", overflow: "auto" }}

                                        >
                                            <div ref={printRef} style = {{width: "100%"}}>

                                                {currentIndex >= 0 && currentType === "1e" && <Anexa1Etpl1E
                                                    data={{ ...currentItem, dosar: dosar }} />}
                                                {/* { currentIndex  >= 0 && currentType === "1f" && <Anexa1Etlp1F 
                                            data = {{...currentItem, dosar:dosar }} /> }         */}
                                                {currentIndex >= 0 && currentType === "pv" && <Anexa1EtlpPV
                                                    data={{ ...currentItem, dosar: dosar }} />}
                                                {currentIndex >= 0 && currentType === "vanzatori" 
                                                    && <Anexa1EtplAdresaVanzator
                                                            index={currentIndex}
                                                            dosar = {{...dosar}}
                                                            onChange = {adresaVanzatorHandler}
                                                    />}
                                                {currentIndex >= 0 && currentType === "dadr" && <Anexa1EtplAdresaDADR
                                                            index={currentIndex}
                                                            dosar = {{...dosar}}
                                                            onChange = {adresaVanzatorHandler}
                                                    />
                                                }  

                                         
                                            </div>
                                        </Grid>


                                    )
                                    : (
                                        <div align="center">
                                            <Typography variant='h2' style={{ color: "LightSteelBlue" }} align="center">
                                                NU AȚI SELECTAT NICI O ANEXĂ
                                                    </Typography>
                                        </div>
                                    )}

                            </div>
                        </RichCard>

                    </Grid>
                </Grid>
            )}
            {dosar && (
                <SuplimentarModal  open = {suplimentarModal}  ranguri = {dosar.ranguri} onClose = {suplimentarCloseHandler}/>
                
            )}

        </Fragment> 
    )
}
