import { Typography, makeStyles, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment } from 'react';
import PrintModalWrapper from './PrintModalWrapper';

const useStyles = makeStyles(theme => ({
    justify: {
        textAlign: "justify",
        textJustify: "inter-word"
    },
    printOnly: { "@media screen" : {display: "none"}},
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "crosshair",
            border: "2px solid navy",
        },
    },
    
}))

export default function PrintCerere({ data, onClose }) {

    const classes = useStyles()

    return <Fragment>
        {data && (
            <PrintModalWrapper onClose={() => onClose(null)}>
                <div align="center">
                    <div className = {classes.printOnly}>
                            <br/><br/><br/><br/>
                    </div>
                   
                <Typography variant ="body1"><strong>Cerere pentru eliberarea atestatului de producător</strong></Typography>
                <Typography variant ="body1"><strong>nr. {data.nr} din {moment(data.dt).format("DD.MM.YYYY")}</strong></Typography>
                </div>
                <br/>
                <div className={classes.justify}>
                    Subsemnatul/subsemnata {data.contact.name}, având domiciliul în localitatea {data.contact.domLoc || "..........."}, {' '}
                    str. {data.contact.domStr || "................"}, {' '}
                    nr. {data.contact.domNr || "................"}, {' '}
                    bl. {data.contact.domBl || "................"}, {' '}
                    sc. {data.contact.domSc || "................"}, {' '}
                    et. {data.contact.domEt || "................"}, {' '}
                    ap. {data.contact.domAp || "................"}, {' '},
                    cnp. {data.contact.cnp || "................"}, {' '},
                    vă rog sa-mi aprobati eliberarea atestatului de producator conform datelor existente
                     în evidențele Registrului Agricol
                     <br/>
                     <br/>
                   
                </div>
                
               
                <div className = {classes.justify}>
                Alăturat anexez avizul consultativ nr. {data.avizNr|| "----"} 
                din {data.avizDt ? moment(data.avizDt).format("DD.MM.YYYY") : "---"}  {' '}
                emis de către {data.avizEmitent|| " ---"}.
                <br/><br/>
                Cunoscând că falsul în declaraţii se pedepseşte conform Legii nr. 286/2009 privind Codul penal, 
                cu modificările şi completările ulterioare, declar că datele sunt reale, corecte şi complete.
                <br/><br/>
                Prin completarea, semnarea și depunerea acestei cereri îmi exprim explicit consimțământul pentru 
                prelucrarea datelor cu caracter personal furnizate pe această cale, cu scopul exclusiv al soluționării 
                prezentei cereri și declar că suntm) de acord ca acestea să fie procesate, stocate și transmise, 
                în baza unui temei legal justificat, către oricare dintre autoritățile nominalizate de legislația în vigoare
                ca având atribuții în soluționarea cererii mele/noastre. Înțeleg, de asemenea, faptul că, în baza 
                Regulamentul (EU)679/2016 dispun de drepturile de acces, intervenție și de opoziție asupra datelor personale
                 transmise prin prezentul înscris.
                </div>
                <br/><br/>
                <Grid container justifyContent="space-between" alignItems='flex-start'>
                    <Grid item xs = {8} align = "left">
                         {moment(data.dt).format("DD.MM.YYYY")}
                    </Grid>
                    <Grid item align="center" xs = {4}>
                        Cu respect,
                        <br/><br/>
                        {data.contact.name}
                    </Grid>
                </Grid>

                {/* <pre>{data && JSON.stringify(data, null, 4)}</pre> */}
            </PrintModalWrapper>
        )}

    </Fragment>
}
