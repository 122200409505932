function inLitere(item) {
    var a=0, str='', bani=0
    if(item >= 1000000000) return "DEPASIRE";
    if(item >= 1000000)//un milion
    {
        a=parseInt(item/1000000);//cate milioane sint...
        if(a === 1) str+="unmilion";
        else // mai multe milioane
        str+=partial(a)+"milioane";
    }

    item=item-(a*1000000);//sute de mii

    if(item >= 1000)//o mie
    {
        a=parseInt(item/1000);//cate mii sint...
        if(a === 1) str+="unamie";
        else // mai multe milioane
        str+=partial(a)+"mii";
  }

  item=item-(a*1000);//sute
  if(item >= 1)
  {
  a=parseInt(item/1);//
  str+=partial(a);
  }

  if (item>2) str+='lei';
  else if (item>1) str+="leu";
  else if (item>=0) str+='zerolei';

  bani=(parseFloat(item)-parseInt(item)).toString();
  bani = bani.split('.')
  bani = bani[1]
  bani = typeof bani !== 'undefined' ? bani.slice(0,2): 0
  //banii
  if (bani>0) {
  str+="si";
  str+=partial(bani);
  if (bani>1) str+="bani";
  else str+="ban";
  }
  return typeof str !== 'undefined' ? str : '';

  }

  function partial(item)
  {
    item = typeof item !== 'undefined' ? item : 0;
    var mat = [],a=0,b=0,c=0,itemn=0,comun=''
  mat[0]="zero";
  mat[1]="unu";
  mat[2]="doua";
  mat[3]="trei";
  mat[4]="patru";
  mat[5]="cinci";
  mat[6]="sase";
  mat[7]="sapte";
  mat[8]="opt";
  mat[9]="noua";

  a=parseInt(item/100);
  if(a > 0)
  {
  if(a === 1)
  comun+="osuta";
  else
  if(a === 2)
  comun+="douasute";
  else
  comun+=mat[a]+"sute";
  }
  itemn=item-a*100;
  b=parseInt(itemn/10);
  c=itemn-b*10;

  if(b > 0)
  {
  if(b === 1)
  {
  if(c === 0) comun+="zece";
  else
  if(c === 1) comun+="unsprezece";
  else
  if(c === 2) comun+="doisprezece";
  else
  if(c === 4) comun+="paisprezece";
  else
  if(c === 5) comun+="cinsprezece";
  else
  if(c === 6) comun+="saisprezece";

  else
  comun+=mat[c]+"sprezece";
  return typeof comun !== 'undefined' ? comun : '';
  }
  else
  {
  if(b === 2)
  comun+="douazeci";
  else if(b === 5)
  comun+="cinzeci";
  else if(b === 6)
  comun+="saizeci";
  else
  comun+=mat[b]+"zeci";
  }
  }

  if(c > 0)
  {
  if(b > 0) comun+="si";
  if (c===1 && item<2) comun+="un";
  else if (c===2 && item<3) comun+="doi";
  else comun+=mat[c];
  }

  return typeof comun !== 'undefined' ? comun : '';

  }






export default inLitere