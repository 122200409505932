import { makeStyles, Grid, Button, Typography, Paper, Link } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import {Alert} from '@material-ui/lab'
import RichCard from '../../../../components/RichCard'
import { useReactToPrint } from 'react-to-print'
import { Fragment } from 'react'
import FunkyInput from '../../../../components/FunkyInput'
import moment from 'moment'
import ANTET_INSTITUTIE from '../../Common/ANTET_INSTITUTIE'
import Field from '../../Common/Field'
import { getSetting } from '../../../../lib/settings'
import { MdPrint } from 'react-icons/md'
import TplPv from './TplPv'
import TplCerere from './TplCerere'
import TplDADR from './TplDADR'
import TplADS from './TplADS'
import {FaChevronCircleRight} from 'react-icons/fa'


const useStyles = makeStyles(theme => ({
    text: { fontFamily: "Roboto", fontSize: "0.8rem", fontWeight: "400", textAlign: "justify", textJustify: "inter-word" },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`, padding: theme.spacing(1), marginTop: 0, boxShadow: "none",
        "&& :hover": { background: theme.palette.grey[300] }
    },
    paper: {  padding: theme.spacing(2),paddingRight:0, boxShadow: "none", border: "1px dotted navy"}
}))

const cod = "pvr"

export default function PvrRetragere(props) {
    const classes = useStyles()
    const printRef = useRef()
    const [dosar, setDosar] = useState(null)

    const [tabIndex,setTabIndex] = useState(1)


    useEffect(() => {
        if (props && props.data) { 
            setDosar({ 
                ...props.data,
                anexe: {
                    ...props.data.anexe,
                    [cod]: {
                        ...props.data.anexe[cod],
                        cerere: props.data.anexe[cod]['cerere']
                            ?  props.data.anexe[cod]['cerere']
                            : {nr: 1, dt: new Date(), label: "CERERE DE RETRAGERE A OFERTEI"},
                        
                        pv: props.data.anexe[cod]['pv']
                            ?  props.data.anexe[cod]['pv']
                            : {nr: 1, dt: new Date(), label: "P.V. ÎNDEPLINIRE PROCEDURĂ"},
                        
                        dadr: props.data.anexe[cod]['dadr']
                            ?  props.data.anexe[cod]['dadr']
                            : {nr: 1, dt: new Date(), label: "ADRESĂ ÎNAINTARE D.A.D.R."},
                        
                        ads: props.data.anexe[cod]['ads']
                            ?  props.data.anexe[cod]['ads']
                            : {nr: 1, dt: new Date(), label: "ADRESĂ ÎNAINTARE A.D.S."}
                    }
                }
            }) 
        }
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const DOCS = ['cerere', 'pv', 'dadr', 'ads']
    
    const inputHandler = (cod, e, nr) => ev => {
        let newDosar ={...dosar}
        
       
        newDosar.anexe[cod][e][nr] = ev.target.value
        
        props.onChange(newDosar)
    }
    const dtHandler = (name, elem) => ev => {
        let newDosar ={...dosar}
        console.log(cod, name, elem, ev.target.value, newDosar.anexe[cod][name][elem])
        newDosar.anexe[cod][name][elem] = new Date(ev.target.value)
        console.log("nnn:", newDosar.anexe)
        props.onChange(newDosar)
    }
    const cloneHandler = ev => {
        ev.preventDefault()
        let newDosar ={...dosar}
        const clonable = ['pv', 'dadr', 'ads']
        clonable.map( el => {
            newDosar.anexe[cod][el]['nr']=newDosar.anexe[cod]['cerere']['nr']
            newDosar.anexe[cod][el]['dt']=newDosar.anexe[cod]['cerere']['dt']
        })
        props.onChange(newDosar)

    }

    return (
        <Fragment>
            {dosar && dosar.anexe && (
                <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1} style={{ overflow: "auto" }}>
                    {/* kitchen             */}
                    <Grid item sm={7} xs={12}>
                        <Grid
                            container alignItems="flex-start"
                            style={{ overflow: "auto", maxHeight: "70vh", border: "0px solid red" }}
                        >
                            <Grid item xs={12}>
                                <RichCard >
                                    
                                    <Grid container justifyContent="flex-start" spacing={1}>
                                        <Grid item xs={12}>
                                            <Alert severity="info" className = {classes.text}>
                                                <strong><small>Ord.311/2021, articolul 9 (4)</small></strong><br/>
                                            <small>Înainte de împlinirea termenului prevăzut la art. 6 alin. (2) din lege, vânzătorul 
                                            poate depune la primăria unde a fost înregistrată cererea de afişare a ofertei de 
                                            vânzare o cerere prin care solicită retragerea ofertei. În acest caz, primăria va
                                             încheia un proces-verbal de anulare a procedurii prevăzute de lege şi va comunica
                                              o copie a acestuia structurii centrale sau structurii teritoriale, după caz,
                                               şi A.D.S. </small>
                                            </Alert>
                                            <Link href="" onClick={cloneHandler}>
                                            <small>Click pentru a prelua nr./data cererii la toate celelalte documente din procedura</small>
                                            </Link>
                                        </Grid>
                                        {DOCS.map( (e,i) => (
                                            <Grid item xs = {12} key={i} >
                                            <Paper 
                                                className = {classes.paper} 
                                                style = {{background: tabIndex === i ? "#FAEBD7": null} }
                                                onClick = {()=>setTabIndex(i)}>
                                                <Grid container alignItems="center" spacing={1}>
                                                    <Grid item xs = {12} sm={6}>
                                                        <Typography variant = "overline" color ="primary"
                                                         style = {{fontWeight: tabIndex === i ? 800: null} }
                                                        >
                                                            {dosar.anexe[cod][e]['label']}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs = {6} sm={2}>
                                                    <FunkyInput 
                                                        label = "NR."
                                                        value = {dosar.anexe[cod][e]['nr']} 
                                                        onChange = {inputHandler(cod,e,'nr')}/>
                                                    </Grid>
                                                    <Grid item xs = {6} sm={3}>
                                                    <FunkyInput 
                                                        label = "DIN DATA" type="date"
                                                        value = {moment(dosar.anexe[cod][e]['dt']).format('YYYY-MM-DD')} 
                                                        onChange = {dtHandler(e, 'dt')}/>
                                                    </Grid>
                                                    {/* <Grid item align="right"> */}
                                                    &nbsp;&nbsp;&nbsp;
                                                        <FaChevronCircleRight size = "1.6rem" color = {tabIndex !== i 
                                                            ?"lightgrey" 
                                                            : "navy"
                                                        }/>
                                                    {/* </Grid> */}
                                                   
                                                </Grid>
                                            </Paper>
                                            {/* {dosar && <pre>{JSON.stringify(dosar.anexe[cod], null,4)}</pre>} */}
                                            </Grid>
                                        ))}
                                        
                                        {/* <Grid item xs={2}>
                                            <FunkyInput value={dosar.anexe[cod].nr} label="NR" onChange={anexaInputHandler('nr')}
                                                type="number" step="1" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FunkyInput value={moment(dosar.anexe[cod].dt).format('YYYY-MM-DD')} label="NR.INREG"
                                                onChange={anexaInputHandler('dt')} type="date" />
                                        </Grid>
                                        <Grid item xs={4}/> */}
                                        {/* <Grid item xs={3}>
                                            <Button 
                                                onClick = {()=>setTabIndex(0)}
                                                variant={tabIndex===0 ? "contained": "outlined"}
                                                color="primary" fullWidth>
                                                <div style={{lineHeight:1.1}}><MdPrint size = "2em"/><br/>
                                                <small> CERERE<br/>RETRAGERE</small>
                                                </div>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button 
                                                onClick = {()=>setTabIndex(1)}
                                                variant={tabIndex===1 ? "contained": "outlined"}
                                                color="primary" fullWidth>
                                                <div style={{lineHeight:1.1}}><MdPrint size = "2em"/><br/>
                                                <small> PROCES<br/>VERBAL</small>
                                                </div>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button 
                                                onClick = {()=>setTabIndex(2)}
                                                variant={tabIndex===2 ? "contained": "outlined"}
                                                color="primary" fullWidth>
                                                <div style={{lineHeight:1.1}}><MdPrint size = "2em"/><br/>
                                                <small> ADRESĂ<br/>D.A.D.R.</small>
                                                </div>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button 
                                                onClick = {()=>setTabIndex(3)}
                                                variant={tabIndex===3 ? "contained": "outlined"}
                                                color="primary" fullWidth>
                                                <div style={{lineHeight:1.1}}><MdPrint size = "2em"/><br/>
                                                <small> ADRESĂ<br/>A.D.S.</small>
                                                </div>
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                </RichCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* print                     */}
                    <Grid item sm={5}  xs={12}>

                        <RichCard
                            title="PREVIZUALIZARE"
                            action={
                                <Fragment>
                                    {dosar && dosar.anexe && dosar.anexe[cod] && (
                                        <Fragment>
                                            <Button variant="contained" color="primary" size="small" onClick={printHandler}>TIPĂRIRE</Button>
                                        </Fragment>
                                    )}
                                </Fragment>
                            }
                        >
                            <div style={{ height: "73vh", padding: "5px", overflowY: "visible", zoom: 0.8 }}>
                                {dosar && dosar.anexe && dosar.anexe[cod]
                                    ? <div ref = {printRef}>
                                        {tabIndex === 0 && <TplCerere  data={dosar} cod= {cod} />}
                                        {tabIndex === 1 && <TplPv data={dosar} cod= {cod} />}
                                        {tabIndex === 2 && <TplDADR data={dosar} cod= {cod} />}
                                        {tabIndex === 3 && <TplADS data={dosar} cod= {cod} />}
                                    </div>
                                    : (
                                        <div>alegeti o anexa</div>
                                    )}

                            </div>
                        </RichCard>

                    </Grid>
                </Grid>
            )}
        </Fragment>
    )
}
