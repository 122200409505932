import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
// import Link from 'next/link'



const useStyles = makeStyles(theme => ({
    footer: {
        position: "fixed",
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: 'lightGrey',
        textAlign: 'right',
        borderTop: '1px dotted grey'

    },
    link: {
        textDecoration: 'none'
    }
}))

export default function Copyright() {
    const classes = useStyles()
    return (
        <div className = { classes.footer} >
        <Typography variant="caption" color="textSecondary" align="right">
            {'Copyright © '} 2016-
            {new Date().getFullYear()}
            {' - '}
            <a className = {classes.link} color="inherit" href="https://gmaftei.ro" rel="noopener noreferrer" target = "_blank">
                MAFTEI GABRIEL CLAUDIU P.F.A.
      </a>

        </Typography>
        </div>
    );
}